import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ModificacionCertificacionDetallePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ModificacionCertificacionDetallePage",
            ss: ss,
            ajax: {
                "url": ss.indexModificacionCertificacionDetalle(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                // { data: 'ModificacionCertificacionDetalle', name: 'ModificacionCertificacionDetalle', title: 'Modificación Certificación Detalle' },
                // { data: 'ModificacionCertificacion', name: 'ModificacionCertificacion', title: 'Modificación Certificación' },
                { data: 'Certificacion', name: 'Certificacion', title: 'Certificación' },
                { data: 'Reparticion', name: 'Reparticion', title: 'Repartición' },
                { data: 'CategoriaProgramatica', name: 'CategoriaProgramatica', title: 'Categoria Programática' },
                { data: 'Partida', name: 'Partida', title: 'Partida' },
                { data: 'Monto', name: 'Monto', title: 'Monto' },
                { data: 'Poa', name: 'Poa', title: 'Poa' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            reparticiones: [],
            categoriaProgramaticas: [],
            partidas: [],
            certificaciones: [],
            modificacionCertificaciones: [],
            modificacionCertificacionDetalles: [],
            modificacionCertificacionDetalle: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {}
        };
    },
    methods: {
        getCertificacion() {
            this.ss.listCertificacion().then(
                (result) => {
                    let response = result.data;
                    this.certificaciones = response.data;
                    console.log("Certificacion");
                    console.log(this.certificaciones);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        // getModificacionCertificacion() {
        //     this.ss.listModificacionCertificacion().then(
        //         (result) => {
        //             let response = result.data;
        //             this.modificacionCertificaciones = response.data;
        //             console.log("modificacionCertificacion");
        //             console.log(this.modificacionCertificaciones);
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },
        getReparticion() {
            this.ss.listReparticion(0).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramatica() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPartida() {
            this.ss.listPartida().then(
                (result) => {
                    let response = result.data;
                    this.partidas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newModificacionCertificacionDetalle() {
            this.modificacionCertificacionDetalle = {};
            this.$refs['frm-modificacionCertificacionDetalle'].show();
        },
        showModificacionCertificacionDetalle(id) {
            this.isLoading=true;
            this.ss.showModificacionCertificacionDetalle(id).then(
                (result) => {
                  
                    let response = result.data;
                    this.modificacionCertificacionDetalle = response.data;
                    console.log("show");
                    console.log(this.modificacionCertificacionDetalle);
                    this.$refs['view-modificacionCertificacionDetalle'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editModificacionCertificacionDetalle() {
            this.$refs['frm-modificacionCertificacionDetalle'].show();
            this.$refs['view-modificacionCertificacionDetalle'].hide();
        },
        cancelModificacionCertificacionDetalle() {
            if (this.modificacionCertificacionDetalle.id) {
                this.$refs['view-modificacionCertificacionDetalle'].show();
            }
            this.$refs['frm-modificacionCertificacionDetalle'].hide();
        },
        saveModificacionCertificacionDetalle() {
            this.ss.storeModificacionCertificacionDetalle(this.modificacionCertificacionDetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-modificacionCertificacionDetalle'].hide();
                    this.$refs['datatable-modificacionCertificacionDetalle'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteModificacionCertificacionDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyModificacionCertificacionDetalle(this.modificacionCertificacionDetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-modificacionCertificacionDetalle'].hide();
                                this.$refs['datatable-modificacionCertificacionDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-modificacionCertificacionDetalle'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-modificacionCertificacionDetalle'].show();
        },
        changePassword() {
            this.password.ModificacionCertificacionDetalle = this.modificacionCertificacionDetalle.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-modificacionCertificacionDetalle'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-ModificacionCertificacionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showModificacionCertificacionDetalle(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getCertificacion();
            this.getReparticion(0);
            this.getCategoriaProgramatica();
            this.getPartida();
        }
    }
};
