import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ModificacionCertificacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ModificacionCertificacionPage",
            ss: ss,
            ajax: {
                "url": ss.indexModificacionCertificacion(),
                headers: ss.getToken(),
            },
            
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Gerencial' },
                // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
               
                // { data: 'Numero', name: 'Numero', title: 'Numero' },
                { data: 'Concatenado', name: 'Concatenado', title: 'Nº Documento' },
                
                { data: 'Certificacion', name: 'Certificacion', title: 'Glosa' },
                // { data: 'Anexo', name: 'Anexo', title: 'Anexo' }, 
                { data: 'Estado', name: 'Estado', title: 'Revertido', render:function(data, type, row){
                    if (row.Estado==1){ return '<span class="badge bg-warning text-white ">Revertido</span>'; }
                    else{ return '<span></span>'}
                } },             
                // { data: 'Aprobado', name: 'Aprobado', title: 'Aprobado' },
                // { data: 'AprobadoPor', name: 'AprobadoPor', title: 'Aprobado Por' },
                { data: 'Aprobado', name: 'Aprobado', title: 'Estado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>';}
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';}
                } },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexModificacionCertificacionDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                    }
    
            },
            columnsDetalle: [
            
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Poa', name: 'a.Poa', title: 'N Cer Poa'  },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición', render:function(data, type, row){
                    return row.CodRep +' - ' + row.Reparticion;
               }  },
                { data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render:function(data, type, row){
                    return row.CodCat +' - ' + row.CategoriaProgramatica; 
                }  },
                { data: 'Partida', name: 'p.Partida', title: 'Partida', render:function(data, type, row){
                    return row.CodPar +' - ' + row.Partida; 
                } },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                { data: 'Monto', name: 'a.Monto', title: 'Monto' },
                // { data: 'Monto', name: 'a.Monto', title: 'Monto Destino', render:function(data, type, row){
                //     if (row.Tipo)
                //         return row.Monto;
                //     else
                //         return 0;
                //      }},
                     

                // {
                //     data: 'action',
                //     orderable: false,
                //     title: 'Acciones',
                //     searchable: false
                // },
            ],
            certificaciones: [],
            personas: [],
            formulaciones: [],
            modificacionCertificaciones: {},
            modificacionCertificacion: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {}
        };
    },
    methods: {
      
        getCertificacion() {
            this.ss.listCertificacion().then(
                (result) => {
                    let response = result.data;
                    this.certificaciones = response.data;
                    console.log("certificacion");
                    console.log(this.certificaciones);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFormulacion() {
            this.ss.listFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.formulaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newModificacionCertificacion() {
            this.modificacionCertificacion = {};
            this.$refs['frm-modificacionCertificacion'].show();
        },
        showModificacionCertificacion(id) {
            this.isLoading=true;
            this.ss.showModificacionCertificacion(id).then(
                (result) => {
                    let response = result.data;
                    this.modificacionCertificacion = response.data;
                    this.$refs['view-modificacionCertificacion'].show();
                    this.isLoading=false;
                    var dd =  window.$('#datatable-certificacionDetalle').DataTable();
                    console.log("filas", dd.rows());
                    this.ajaxDetalle.data = function(d){
                        d.id = id;
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
       
        editModificacionCertificacion() {
            this.$refs['frm-modificacionCertificacion'].show();
            this.$refs['view-modificacionCertificacion'].hide();
        },
        cancelModificacionCertificacion() {
            // if (this.modificacionCertificacion.id) {
            //     this.$refs['view-modificacionCertificacion'].show();
            // }
            this.$refs['frm-modificacionCertificacion'].hide();
        },
        saveModificacionCertificacion() {
            console.log(" save ModificacionCertificacion");
            console.log(this.modificacionCertificacion);
            this.ss.storeModificacionCertificacion(this.modificacionCertificacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-modificacionCertificacion'].hide();
                    this.$refs['datatable-modificacionCertificacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteModificacionCertificacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyModificacionCertificacion(this.modificacionCertificacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-modificacionCertificacion'].hide();
                                this.$refs['datatable-modificacionCertificacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-modificacionCertificacion'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-modificacionCertificacion'].show();
        },
        changePassword() {
            this.password.ModificacionCertificacion = this.modificacionCertificacion.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-modificacionCertificacion'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-ModificacionCertificacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showModificacionCertificacion(data);
            });
        },
        drawDetalle() {
          // this.idCertificacion=1;
            window.$('.btn-datatable-ModificacionCertificacionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showModificacionCertificacionDetalle(data);
            });

        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getFormulacion();
            this.getPersona();
            this.getCertificacion();
            
        }
    }
};
