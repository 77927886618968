import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FormulacionDetallePage",
    data() {
        let ss = new MainService();
        return {
            msg: "FormulacionDetallePage",
            ss: ss,
            ajax: {
                "url": ss.indexFormulacionDetalle(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Reparticion', name: 'Reparticion', title: 'Repartición' },
                { data: 'CategoriaProgramatica', name: 'CategoriaProgramatica', title: 'Categoria Programática' },
                { data: 'Partida', name: 'Partida', title: 'Partida' },
                { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
                { data: 'Monto', name: 'Monto', title: 'Monto' },
                { data: 'Porcentaje', name: 'Porcentaje', title: 'Porcentaje' },
                { data: 'Ejecutado', name: 'Ejecutado', title: 'Ejecutado' },
                { data: 'Saldo', name: 'Saldo', title: 'Saldo' },
                { data: 'Disponible', name: 'Disponible', title: 'Vigente' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            reparticiones: [],
            categoriaProgramaticas: [],
            partidas: [],
            formulaciones: [],
            formulacionDetalles: [],
            formulacionDetalle: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {}
        };
    },
    methods: {
      
        getFormulacion() {
            this.ss.listFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.formulaciones = response.data;
                    // console.log("formulacion");
                    // console.log(this.formulaciones);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            //    alert(this.idUnidadAcademica);
                this.ss.listReparticion(0).then(
                    (result) => {
                        let response = result.data;
                        this.reparticiones = response.data;
                    }
                ).catch(error => {
                    console.log(error);
                });
            },
        getCategoriaProgramatica() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPartida() {
            this.ss.listPartida().then(
                (result) => {
                    let response = result.data;
                    this.partidas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newFormulacionDetalle() {
            this.formulacionDetalle = {};
            this.$refs['frm-formulacionDetalle'].show();
        },
        showFormulacionDetalle(id) {
            this.isLoading=true;
            this.ss.showFormulacionDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.formulacionDetalle = response.data;
                    this.$refs['view-formulacionDetalle'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editFormulacionDetalle() {
            this.$refs['frm-formulacionDetalle'].show();
            this.$refs['view-formulacionDetalle'].hide();
        },
        cancelFormulacionDetalle() {
            if (this.formulacionDetalle.id) {
                this.$refs['view-formulacionDetalle'].show();
            }
            this.$refs['frm-formulacionDetalle'].hide();
        },
        saveFormulacionDetalle() {
            this.ss.storeFormulacionDetalle(this.formulacionDetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-formulacionDetalle'].hide();
                    this.$refs['datatable-formulacionDetalle'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFormulacionDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFormulacionDetalle(this.formulacionDetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-formulacionDetalle'].hide();
                                this.$refs['datatable-formulacionDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-formulacionDetalle'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-formulacionDetalle'].show();
        },
        changePassword() {
            this.password.FormulacionDetalle = this.formulacionDetalle.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-formulacionDetalle'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-FormulacionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFormulacionDetalle(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getFormulacion();
            this.getReparticion(0);
            this.getCategoriaProgramatica();
            this.getPartida();
        }
    }
};
