import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import PrincipalIngresosPage from '@/pages/PrincipalIngresos/PrincipalIngresosPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import ComprobanteContablePage from '@/pages/ComprobanteContable/ComprobanteContablePage.vue'
import ComprobanteContableDetallePage from '@/pages/ComprobanteContableDetalle/ComprobanteContableDetallePage.vue'
import GastoContablePage from '@/pages/GastoContable/GastoContablePage.vue'
import IngresoPresupuestarioPage from '@/pages/IngresoPresupuestario/IngresoPresupuestarioPage.vue'
import IngresoPresupuestarioDetallePage from '@/pages/IngresoPresupuestarioDetalle/IngresoPresupuestarioDetallePage.vue'
import IngresoTesoreriaPage from '@/pages/IngresoTesoreria/IngresoTesoreriaPage.vue'
import IngresoTesoreriaActualPage from '@/pages/IngresoTesoreriaActual/IngresoTesoreriaActualPage.vue'
import IngresosCPage from '@/pages/IngresosC/IngresosCPage.vue'
import IngresosCactualPage from '@/pages/IngresosCactual/IngresosCactualPage.vue'
import ReversionPage from '@/pages/Reversion/ReversionPage.vue'
import ReversionDetallePage from '@/pages/ReversionDetalle/ReversionDetallePage.vue'

import FuenteFinancieraPage from '@/pages/FuenteFinanciera/FuenteFinancieraPage.vue'
import PartidaPage from '@/pages/Partida/PartidaPage.vue'
import CategoriaProgramaticaPage from '@/pages/CategoriaProgramatica/CategoriaProgramaticaPage.vue'
import ReparticionPage from '@/pages/Reparticion/ReparticionPage.vue'
import FormulacionPage from '@/pages/Formulacion/FormulacionPage.vue'
import FormulacionDetallePage from '@/pages/FormulacionDetalle/FormulacionDetallePage.vue'
import EjecucionDetallePage from '@/pages/EjecucionDetalle/EjecucionDetallePage.vue'
import EjecucionPage from '@/pages/Ejecucion/EjecucionPage.vue' 
import EjecucionSubCuentaPage from '@/pages/EjecucionSubCuenta/EjecucionSubCuentaPage.vue'
import CertificacionEjecucionPage from '@/pages/CertificacionEjecucion/CertificacionEjecucionPage.vue'
import TipoComprobantePage from '@/pages/TipoComprobante/TipoComprobantePage.vue'
import CuentaPage from '@/pages/Cuenta/CuentaPage.vue'
import SubCuentaPage from '@/pages/SubCuenta/SubCuentaPage.vue'
import CertificacionPage from '@/pages/Certificacion/CertificacionPage.vue'
import CertificacionDetallePage from '@/pages/CertificacionDetalle/CertificacionDetallePage.vue'
import TipoCambioPage from '@/pages/TipoCambio/TipoCambioPage.vue'
import RubroPage from '@/pages/Rubro/RubroPage.vue'
import ReformulacionPage from '@/pages/Reformulacion/ReformulacionPage.vue'
import ReformulacionDetallePage from '@/pages/ReformulacionDetalle/ReformulacionDetallePage.vue'
import ModificacionCertificacionPage from '@/pages/ModificacionCertificacion/ModificacionCertificacionPage.vue'
import ModificacionCertificacionDetallePage from '@/pages/ModificacionCertificacionDetalle/ModificacionCertificacionDetallePage.vue'
import NivelAcademicoPage from '@/pages/NivelAcademico/NivelAcademicoPage.vue'
import TipoPagoPage from '@/pages/TipoPago/TipoPagoPage.vue'
import BancoPage from '@/pages/Banco/BancoPage.vue'
import FuerzaPage from '@/pages/Fuerza/FuerzaPage.vue'
import RetencionPage from '@/pages/Retencion/RetencionPage.vue'
import EspecialidadPage from '@/pages/Especialidad/EspecialidadPage.vue'
import GradoPage from '@/pages/Grado/GradoPage.vue'

import PeriodoAcademicoPage from '@/pages/PeriodoAcademico/PeriodoAcademicoPage.vue'
import TalonarioPage from '@/pages/Talonario/TalonarioPage.vue'
import InscripcionPage from '@/pages/Inscripcion/InscripcionPage.vue'

import PlanPagoPage from '@/pages/PlanPago/PlanPagoPage.vue'
import DepositoPage from '@/pages/Deposito/DepositoPage.vue'
import ProgramaPage from '@/pages/Programa/ProgramaPage.vue'
import DetalleFacturaPage from '@/pages/DetalleFactura/DetalleFacturaPage.vue'

import CursoPage from '@/pages/Curso/CursoPage.vue'
import FacturaPage from '@/pages/Factura/FacturaPage.vue'
import ItemTesoreriaPage from '@/pages/ItemTesoreria/ItemTesoreriaPage.vue'

import ModalidadPage from '@/pages/Modalidad/ModalidadPage.vue'
import DosificacionPage from '@/pages/Dosificacion/DosificacionPage.vue'

import PagoTesoreriaPage from '@/pages/PagoTesoreria/PagoTesoreriaPage.vue'
import PagoTesoreriaCursoPage from '@/pages/PagoTesoreriaCurso/PagoTesoreriaCursoPage.vue'
import InscripcionPagoTesoreriaPage from '@/pages/InscripcionPagoTesoreria/InscripcionPagoTesoreriaPage.vue'

import TransferenciaPage from '@/pages/Transferencia/TransferenciaPage.vue'
// import TransferenciaDetallePage from '@/pages/TransferenciaDetalle/TransferenciaDetallePage.vue'
import ComprobanteEgresoPage from '@/pages/ComprobanteEgreso/ComprobanteEgresoPage.vue'
import ComprobanteDevengadoPage from '@/pages/ComprobanteDevengado/ComprobanteDevengadoPage.vue'
import ComprobanteAperturaPage from '@/pages/ComprobanteApertura/ComprobanteAperturaPage.vue'
import ComprobanteTraspasoPage from '@/pages/ComprobanteTraspaso/ComprobanteTraspasoPage.vue'
import ComprobanteIngresoPage from '@/pages/ComprobanteIngreso/ComprobanteIngresoPage.vue'
import ComprobanteGastoPage from '@/pages/ComprobanteGasto/ComprobanteGastoPage.vue'

import DevengadoPage from '@/pages/Devengado/DevengadoPage.vue'
import DevengadoDetallePage from '@/pages/DevengadoDetalle/DevengadoDetallePage.vue'
import TipoPage from '@/pages/Tipo/TipoPage.vue'
import EstudiantePage from '@/pages/Estudiante/EstudiantePage.vue'
import InscripcionesPage from '@/pages/Inscripciones/InscripcionesPage.vue'

import DescargoEstadoPage from '@/pages/DescargoEstado/DescargoEstadoPage.vue'
import DescargoPage from '@/pages/Descargo/DescargoPage.vue'
import DescargoGralPage from '@/pages/DescargoGral/DescargoGralPage.vue'
import DescargoGralGestionPasPage from '@/pages/DescargoGralGestionPas/DescargoGralGestionPasPage.vue'
import AsignarDescargoPage from '@/pages/AsignarDescargo/AsignarDescargoPage.vue'
import DescargoRevPage from '@/pages/DescargoRev/DescargoRevPage.vue'
import DescargoCorPage from '@/pages/DescargoCor/DescargoCorPage.vue'
import DescargoEstPage from '@/pages/DescargoEst/DescargoEstPage.vue'
import DescargoFinPage from '@/pages/DescargoFin/DescargoFinPage.vue'
import DescargoSegPage from '../pages/DescargoSeg/DescargoSegPage.vue'
import DescargoCerPage from '../pages/DescargoCer/DescargoCerPage.vue'
import CertificacionDescargoPage from '@/pages/CertificacionDescargo/CertificacionDescargoPage.vue'
import FacturaEgresoPage from '@/pages/FacturaEgreso/FacturaEgresoPage.vue'
import DescargoArchivoPage from '@/pages/DescargoArchivo/DescargoArchivoPage.vue'

import ChequePage from '@/pages/Cheque/ChequePage.vue'
import EstadoChequePage from '@/pages/EstadoCheque/EstadoChequePage.vue'
import ChequeAsignadoPage from '@/pages/ChequeAsignado/ChequeAsignadoPage.vue'

import SintesisPage from '@/pages/Sintesis/SintesisPage.vue'
import CajeroPage from '@/pages/Cajero/CajeroPage.vue'
import HistorialCajeroPage from '@/pages/HistorialCajero/HistorialCajeroPage.vue'

import LibroBancarioPage from '@/pages/LibroBancario/LibroBancarioPage.vue'
import ConciliacionLibroBancarioPage from '@/pages/ConciliacionLibroBancario/ConciliacionLibroBancarioPage.vue'

import ComprobanteFondoRotativoPage from '@/pages/ComprobanteFondoRotativo/ComprobanteFondoRotativoPage.vue'
import ComprobanteTesoreriaPage from '@/pages/ComprobanteTesoreria/ComprobanteTesoreriaPage.vue'

import RRTPage from '@/pages/RRT/RRTPage.vue'
import FormulariobpPage from '@/pages/Formulariobp/FormulariobpPage.vue'
import ArchivoCPage from '@/pages/ArchivoC/ArchivoCPage.vue'
import ReportePage from '@/pages/Reporte/ReportePage.vue'
import CertificadoSolvenciaPage from '@/pages/CertificadoSolvencia/CertificadoSolvenciaPage.vue'
import TributacionPage from '@/pages/Tributacion/TributacionPage.vue'
import CuentaCobrarPage from '@/pages/CuentaCobrar/CuentaCobrarPage.vue'
import TipoSolvenciaPage from '@/pages/TipoSolvencia/TipoSolvenciaPage.vue'
import CuentaCobroPage from '@/pages/CuentaCobro/CuentaCobroPage.vue'
import CuentaPagoPage from '@/pages/CuentaPago/CuentaPagoPage.vue'
import ReversionGPage from '@/pages/ReversionG/ReversionGPage.vue'
import ComprobantesTotalPage from '@/pages/ComprobanteContabletotal/ComprobanteContabletotalPage.vue'
import SocorroMasPage from '@/pages/SocorroMas/SocorroMasPage.vue'
import BuscadorCobroPage from '@/pages/BuscadorCobro/BuscadorCobroPage.vue'
import BuscadorPagoPage from '@/pages/BuscadorPago/BuscadorPagoPage.vue'
import CajaChicaPage from '@/pages/CajaChica/CajaChicaPage.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/Ingresos',
      name: 'PrincipalIngresosPage',
      component: PrincipalIngresosPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/ComprobanteContable',
      name: 'Comprobante Contable',
      component: ComprobanteContablePage
    },
    {
      path: '/ComprobanteContableDetalle',
      name: 'Comprobante Contable Detalle',
      component: ComprobanteContableDetallePage
    },
    {
      path: '/GastoContable',
      name: 'Gasto Contable',
      component: GastoContablePage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/Retencion',
      name: 'Retencion',
      component: RetencionPage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/FuenteFinanciera',
      name: 'FuenteFinanciera',
      component: FuenteFinancieraPage
    },
    {
      path: '/Partida',
      name: 'Partida',
      component: PartidaPage
    },
    {
      path: '/CategoriaProgramatica',
      name: 'CategoriaProgramatica',
      component: CategoriaProgramaticaPage
    },
    {
      path: '/Reparticion',
      name: 'Reparticion',
      component: ReparticionPage
    },
    {
      path: '/Formulacion',
      name: 'Formulacion',
      component: FormulacionPage
    },
    {
      path: '/FormulacionDetalle',
      name: 'FormulacionDetalle',
      component: FormulacionDetallePage
    },
    {
      path: '/CertificacionEjecucion',
      name: 'CertificacionEjecucion',
      component: CertificacionEjecucionPage
    },
    {
      path: '/Ejecucion',
      name: 'Ejecucion',
      component: EjecucionPage
    },
    {
      path: '/EjecucionDetalle',
      name: 'EjecucionDetalle',
      component: EjecucionDetallePage
    },
    {
      path: '/TipoComprobante',
      name: 'TipoComprobante',
      component: TipoComprobantePage
    },
    {
      path: '/EjecucionSubCuenta',
      name: 'EjecucionSubCuenta',
      component: EjecucionSubCuentaPage
    },
    {
      path: '/Cuenta',
      name: 'Cuenta',
      component: CuentaPage
    },
    {
      path: '/SubCuenta',
      name: 'SubCuenta',
      component: SubCuentaPage
    },
    {
      path: '/TipoCambio',
      name: 'TipoCambio',
      component: TipoCambioPage
    },
    {
      path: '/Rubro',
      name: 'Rubro',
      component: RubroPage
    },
    {
      path: '/Reformulacion',
      name: 'Reformulacion',
      component: ReformulacionPage
    },
    {
      path: '/ReformulacionDetalle',
      name: 'ReformulacionDetalle',
      component: ReformulacionDetallePage
    }, 
    {
      path: '/Transferencia',
      name: 'Transferencia',
      component: TransferenciaPage
    },
    // {
    //   path: '/TransferenciaDetalle',
    //   name: 'TransferenciaDetalle',
    //   component: TransferenciaDetallePage
    // },
    {
      path: '/NivelAcademico',
      name: 'NivelAcademico',
      component: NivelAcademicoPage
    },
    {
      path: '/TipoPago',
      name: 'TipoPago',
      component: TipoPagoPage
    },
    {
      path: '/Banco',
      name: 'Banco',
      component: BancoPage
    },
    {
      path: '/Certificacion',
      name: 'Certificacion',
      component: CertificacionPage
    },
    {
      path: '/CertificacionDetalle',
      name: 'CertificacionDetalle',
      component: CertificacionDetallePage
    },
    {
      path: '/ModificacionCertificacion',
      name: 'ModificacionCertificacion',
      component: ModificacionCertificacionPage
    },
    {
      path: '/ModificacionCertificacionDetalle',
      name: 'ModificacionCertificacionDetalle',
      component: ModificacionCertificacionDetallePage
    },
    {
      path: '/Fuerza',
      name: 'Fuerza',
      component: FuerzaPage
    },
    {
      path: '/Especialidad',
      name: 'Especialidad',
      component: EspecialidadPage
    },
    {
      path: '/PlanPago',
      name: 'PlanPago',
      component: PlanPagoPage
    },
    {
      path: '/Deposito',
      name: 'Deposito',
      component: DepositoPage
    },
    {
      path: '/Programa',
      name: 'Programa',
      component: ProgramaPage
    },
    {
      path: '/DetalleFactura',
      name: 'DetalleFactura',
      component: DetalleFacturaPage
    },
    { 
      path: '/Grado',
      name: 'Grado',
      component: GradoPage
    },
    {
      path: '/PeriodoAcademico',
      name: 'PeriodoAcademico',
      component: PeriodoAcademicoPage
    },
    {
      path: '/Talonario',
      name: 'Talonario',
      component: TalonarioPage
    },
    {
      path: '/Inscripcion',
      name: 'Inscripcion',
      component: InscripcionPage
    },
    {
      path: '/Curso',
      name: 'Curso',
      component: CursoPage
    },
    {
      path: '/Factura',
      name: 'Factura',
      component: FacturaPage
    },
    {
      path: '/ItemTesoreria',
      name: 'ItemTesoreria',
      component: ItemTesoreriaPage
    },
    {
      path: '/IngresoPresupuestario',
      name: 'Ingreso Presupuestario',
      component: IngresoPresupuestarioPage
    },
    {
      path: '/IngresoPresupuestarioDetalle',
      name: 'Ingreso Presupuestario Detalle',
      component: IngresoPresupuestarioDetallePage
    },
    {
      path: '/IngresoTesoreria',
      name: 'IngresoTesoreria',
      component: IngresoTesoreriaPage
    },
    {
      path: '/IngresoTesoreriaActual',
      name: 'IngresoTesoreriaActual',
      component: IngresoTesoreriaActualPage
    },
    {
      path: '/IngresosC',
      name: 'IngresosC',
      component: IngresosCPage
    },
    {
      path: '/IngresosCactual',
      name: 'IngresosCactual',
      component: IngresosCactualPage
    },
    {
      path: '/Reversion',
      name: 'Reversion',
      component: ReversionPage
    },
    {
      path: '/ReversionDetalle',
      name: 'Reversion Detalle',
      component: ReversionDetallePage
    }, 
    {
      path: '/Modalidad', 
      name: 'Modalidad',
      component: ModalidadPage
    }, 
    {
      path: '/Dosificacion', 
      name: 'Dosificacion',
      component: DosificacionPage
    },
    {
      path: '/PagoTesoreria', 
      name: 'PagoTesoreria',
      component: PagoTesoreriaPage
    },
    {
      path: '/PagoTesoreriaCurso', 
      name: 'PagoTesoreriaCurso',
      component: PagoTesoreriaCursoPage
    },
    {
      path: '/InscripcionPagoTesoreria', 
      name: 'InscripcionPagoTesoreria',
      component: InscripcionPagoTesoreriaPage
    },
    {
      path: '/Sintesis', 
      name: 'Sintesis',
      component: SintesisPage
    },
    {
      path: '/ComprobanteEgreso', 
      name: 'ComprobanteEgreso',
      component: ComprobanteEgresoPage
    },
    {
      path: '/ComprobanteDevengado', 
      name: 'ComprobanteDevengado',
      component: ComprobanteDevengadoPage
    },
    {
      path: '/ComprobanteApertura', 
      name: 'ComprobanteApertura',
      component: ComprobanteAperturaPage
    },
    {
      path: '/ComprobanteTraspaso', 
      name: 'ComprobanteTraspaso',
      component: ComprobanteTraspasoPage
    },
    {
      path: '/ComprobanteIngreso', 
      name: 'ComprobanteIngreso',
      component: ComprobanteIngresoPage
    },
    {
      path: '/Devengado',
      name:'Devengado',
      component: DevengadoPage
    },
    {
      path: '/DevengadoDetalle',
      name:'DevengadoDetalle',
      component: DevengadoDetallePage
    },
    {
      path: '/Tipo',
      name: 'Tipo',
      component: TipoPage
    },
    {
      path: '/AsignarDescargo',
      name:'AsignarDescargo',
      component: AsignarDescargoPage
    },
    {
      path: '/Descargo',
      name:'Descargo',
      component: DescargoPage
    },
    {
      path: '/DescargoGral',
      name:'DescargoGral',
      component: DescargoGralPage
    },
    {
      path: '/DescargoGralGestionPas',
      name:'DescargoGralGestionPas',
      component: DescargoGralGestionPasPage
    },
    {
      path: '/DescargoRev',
      name:'DescargoRev',
      component: DescargoRevPage
    },
    {
      path: '/DescargoFin',
      name:'DescargoFin',
      component: DescargoFinPage
    },
    {
      path: '/DescargoCer',
      name:'DescargoCer',
      component: DescargoCerPage
    },
    {
      path: '/DescargoSeg',
      name:'DescargoSeg',
      component: DescargoSegPage
    },
    {
      path: '/DescargoCor',
      name:'DescargoCor',
      component: DescargoCorPage
    },
    {
      path: '/DescargoEst',
      name:'DescargoEst',
      component: DescargoEstPage
    },
    {
      path: '/DescargoEstado',
      name:'DescargoEstado',
      component: DescargoEstadoPage
    },
    {
      path: '/DescargoArchivo',
      name:'DescargoArchivo',
      component: DescargoArchivoPage
    },
    {
      path: '/CertificacionDescargo',
      name:'CertificacionDescargo',
      component: CertificacionDescargoPage
    },
    {
      path: '/Estudiante',
      name: 'Estudiante',
      component: EstudiantePage
    },
    {
      path: '/Inscripciones',
      name: 'Inscripciones',
      component: InscripcionesPage
    },
    {
      path: '/FacturaEgreso',
      name: 'FacturaEgreso',
      component: FacturaEgresoPage
    },
    {
      path: '/Cheque',
      name: 'Cheque',
      component: ChequePage
    },
    {
      path: '/EstadoCheque',
      name: 'EstadoCheque',
      component: EstadoChequePage
    },
    {
      path: '/ChequeAsignado',
      name: 'ChequeAsignado',
      component: ChequeAsignadoPage
    },
    {
      path: '/Cajero',
      name: 'Cajero',
      component: CajeroPage
    },
    {
      path: '/HistorialCajero',
      name: 'HistorialCajero',
      component: HistorialCajeroPage
    },
    {
      path: '/LibroBancario',
      name: 'LibroBancario',
      component: LibroBancarioPage
    },
    {
      path: '/ConciliacionLibroBancario',
      name: 'ConciliacionLibroBancario',
      component: ConciliacionLibroBancarioPage
    },
    {
      path: '/ComprobanteFondoRotativo',
      name: 'ComprobanteFondoRotativo',
      component: ComprobanteFondoRotativoPage
    },
    {
      path: '/ComprobanteGasto', 
      name: 'ComprobanteGasto',
      component: ComprobanteGastoPage
    },
    {
      path: '/ComprobanteTesoreria',
      name: 'ComprobanteTesoreria',
      component: ComprobanteTesoreriaPage
    },
    {
      path: '/RRT', 
      name: 'RRT',
      component: RRTPage
    },
    {
      path: '/Formulariobp',
      name: 'Formulariobp',
      component: FormulariobpPage
    },
    {
      path: '/ArchivoC',
      name: 'ArchivoC',
      component: ArchivoCPage
    },
     {
       path: '/Reporte',
       name: 'Reporte',
       component: ReportePage
     },
    {
      path: '/CertificadoSolvencia',
      name:'CertificadoSolvencia',
      component: CertificadoSolvenciaPage
    },
    {
      path: '/Tributacion',
      name: 'Tributacion',
      component: TributacionPage
    },
    {
      path: '/CuentaCobrar',
      name: 'CuentaCobrar',
      component: CuentaCobrarPage
    },
    {
      path: '/TipoSolvencia',
      name: 'TipoSolvencia',
      component: TipoSolvenciaPage
    },
    {
      path: '/CuentaCobro',
      name: 'CuentaCobro',
      component: CuentaCobroPage
    },
    {
      path: '/CuentaPago',
      name: 'CuentaPago',
      component: CuentaPagoPage
    },
    {
      path: '/ReversionG',
      name: 'ReversionG',
      component: ReversionGPage
    },
    {
      path: '/ComprobantesTotal',
      name: 'ComprobantesTotal',
      component: ComprobantesTotalPage
    },
    {
      path: '/SocorroMas',
      name: 'SocorroMas',
      component: SocorroMasPage
    },
    {
      path: '/BuscadorCobro',
      name: 'BuscadorCobro',
      component: BuscadorCobroPage
    },
    {
      path: '/BuscadorPago',
      name: 'BuscadorPago',
      component: BuscadorPagoPage
    },
    {
      path: '/CajaChica',
      name: 'CajaChica',
      component: CajaChicaPage
    }

  ]
})