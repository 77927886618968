import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReportePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReportePage",
            ss: ss,
            auth: {},
            reportes: [],
            reporte: {},
            individual: {},
            fondo: {},
            presu: {},
            cheque: {},
            subcuentas: [],
            categoriaprogramatica: [],
            searchSubcuenta: "",
            searchCategoriaProgramatica: "",
            selectedSubCuenta:null,
            selectedCategoriaProgramatica:null,
            reparticiones: [],
            searchReparticion: "",
            selectedReparticion:null,
            partidas: [],
            searchPartida: "",
            selectedPartida:null,
            fuenteFinancieras: [],
            talonarios: [],
            estadocheques: [],
            isLoading: false,
            errorBag: {},
            comprobantesfr: [],
            searchComprobantefr: "",
            selectedComprobante:null,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            Partida: 0,
            personas: [],
            desde: null,
            hasta: null,
            benefin:{},
            pagodir:{},
            desembolso:{},
            formularioben: [],
            formulariopd: [],
            formulariodesemb: [],
            searchFormulariobp: "",
            selectedFormulariobp:null,
            searchFormulariopd: "",
            selectedFormulariopd:null,
            searchFormulariodesemb: "",
            selectedFormulariodesemb:null,
            ingresos:{},
            ingresopadre: [],
            rubrosPadres:[],
            rubrosHijos:[],
            rubros:[],
            archivo:{},
        };
    },
    methods: {
        updateDesde() {
            if (this.cheque.desde && this.cheque.hasta && this.cheque.desde > this.cheque.hasta) {
              this.cheque.desde = this.cheque.hasta;
            }
          },
          updateHasta() {
            if (this.cheque.desde && this.cheque.hasta && this.cheque.hasta < this.cheque.desde) {
              this.cheque.hasta = this.cheque.desde;
            }
          },
        updateDesdeCheque() {
            if (this.cheque.desdecheque && this.cheque.hastacheque && this.cheque.desdecheque > this.cheque.hastacheque) {
              this.cheque.desdecheque = this.cheque.hastacheque;
            }
          },
          updateHastaCheque() {
            if (this.cheque.desdecheque && this.cheque.hastacheque && this.cheque.hastacheque < this.cheque.desdecheque) {
              this.cheque.hastacheque = this.cheque.desdecheque;
            }
          },
        convertirMayusculas() {
            this.fondo.Glosa = this.fondo.Glosa.toUpperCase();
          },
        convertirMayusculaspre() {
            this.presu.Glosa = this.presu.Glosa.toUpperCase();
          },
        convertirMayusculascheque() {
            this.cheque.Glosa = this.cheque.Glosa.toUpperCase();
          },
          convertirMayusculasArchivo() {
            this.archivo.Glosa = this.archivo.Glosa.toUpperCase();
          },
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTalonario() {
            this.ss.listTalonariosimple().then(
                (result) => {
                    let response = result.data;
                    this.talonarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoCheque() {
            this.ss.listEstadoCheque().then(
                (result) => {
                    let response = result.data;
                    this.estadocheques = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersonaSuper().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newReporte() {
            this.reporte = {};
            this.$refs['frm-reporte'].show();
        },
        showReporte(id) {
            this.isLoading=true;
            this.ss.showReporte(id).then(
                (result) => {
                    let response = result.data;
                    this.reporte = response.data;
                    this.$refs['view-reporte'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editReporte() {
            this.$refs['frm-reporte'].show();
            this.$refs['view-reporte'].hide();
        },
        cancelReporte() {
            if (this.reporte.id) {
                this.$refs['view-reporte'].show();
            }
            this.$refs['frm-reporte'].hide();
        },
        cancelReporteFondo() {
            if (this.fondo.id) {
                this.$refs['view-reporte'].show();
            }
            this.$refs['frm-reportecontabilidad'].hide();
        },
        saveReporte() {
            this.ss.storeReporte(this.reporte).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reporte'].hide();
                    this.$refs['datatable-reporte'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteReporte() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyReporte(this.reporte)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-reporte'].hide();
                                this.$refs['datatable-reporte'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        setReset() {
            this.reporte = {};
        },
        setResetFondo() {
            this.fondo = {};
        },
        setResetPresu() {
            this.presu = {};
        },
        setResetCheque() {
            this.cheque = {};
        },
        abrirModalReporteDescargo() {
            this.reporte = {};
            this.$refs['frm-reportedescargo'].show();
        },
        ReporteIndividual() {
            this.individual = {};
            this.$refs['frm-reporteindividual'].show();
        },
        ReporteGestionPasada() {
            this.individual = {};
            this.$refs['frm-reportegestionpasada'].show();
        },
        ConsiDescargocc() {
            this.ss.printConsiDescargocc(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        }, 
        ConsiDescargoff() {
            this.ss.printConsiDescargoff(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        ConsiDescargoccm() {
            this.ss.printConsiDescargoccm(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        }, 
        ConsiDescargoffm() {
            this.ss.printConsiDescargoffm(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        abrirModalReporteContabilidad() {
            this.reporte = {};
            this.$refs['frm-reportecontabilidad'].show();
        },
        ModalReporteContaGral() {
            this.fondo = {};
            this.$refs['frm-contabilidadgenerales'].show();
        },
        ModalReporteContaGralCheque() {
            this.fondo = {};
            this.$refs['frm-contabilidadgeneralescheques'].show();
        },
        ModalReportePresuGral() {
            this.presu = {};
            this.$refs['frm-presupuestogenerales'].show();
        },
        ConsiContabilidadcc() {
            this.ss.printConsiContabilidadcc(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        }, 
        ConsiContabilidadff() {
            this.ss.printConsiContabilidadff(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        abrirModalReporteTesoreria() {
            this.reporte = {};
            this.$refs['frm-reportetesoreria'].show();
        },
        reportesubcuenta() {
            this.ss.printreportesubcuenta(this.fondo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportereparticion() {
            this.ss.printreportereparticion(this.fondo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportepartida() {
            this.ss.printreportepartida(this.fondo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefuente() {
            this.ss.printreportefuente(this.fondo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechas() {
            this.ss.printreportefechas(this.fondo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        reportefechasglosa() {
            this.ss.printreportefechasglosa(this.fondo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        individualDescargoSuper() {
            this.ss.printIndividualDescargoSuper(this.individual)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        individualDescargoCierreGestionPasada() {
            this.ss.printDescargoGestionPasada(this.individual)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        individualDescargoCierreGestionPasadaDet() {
            this.ss.printDescargoGestionPasadaDet(this.individual)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportecategoriaprogramatica() {
            this.ss.printcategoriaprogramatica(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportereparticionpre() {
            this.ss.printreportereparticionpre(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportereparticionprepartida() {
            this.ss.printreportereparticionprepartida(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportepartidapre() {
            this.ss.printreportepartidapre(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefuentepre() {
            this.ss.printreportefuentepre(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechaspre() {
            this.ss.printreportefechaspre(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportefechaspredet() {
            this.ss.printreportefechaspredet(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportefechaspreglosa() {
            this.ss.printreportefechaspreglosa(this.presu)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportecheque() {
            this.ss.printreportecheque(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportechequesdh() {
            this.ss.printreportechequesdh(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportenumeroscheques() {
            this.ss.printreportenumcheques(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportesubcuentacheques() {
            this.ss.printreportesubcuentacheques(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportereparticioncheques() {
            this.ss.printreportereparticioncheques(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reportetalonario() {
            this.ss.printreporteretetalonario(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },        
        reporteestadocheque() {
            this.ss.printreportereteestadocheque(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechascheques() {
            this.ss.printreportefechascheques(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },         
        reportefechaschequeglosa() {
            this.ss.printreportefechaschequeglosa(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },       

        reportefechaschequeglosab() {
            this.ss.printreportefechaschequeglosab(this.cheque)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        ModalReporteBenFin() {
            this.benefin = {};
            this.$refs['frm-beneficiariofinal'].show();
        },
        ModalReportePagoDir() {
            this.pagodir = {};
            this.$refs['frm-pagodirecto'].show();
        },
        ModalReporteDesembolsos() {
            this.desembolso = {};
            this.$refs['frm-desembolso'].show();
        },
        setResetBenFin() {
            this.benefin = {};
        },
        setResetPagoDir() {
            this.pagodir = {};
        },
        setResetDesembolso() {
            this.desembolso = {};
        },
        reportebn() {
            this.ss.printreportebn(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },        
        reportebndh() {
            this.ss.printreportebndh(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },        
        reportebndhprev() {
            this.ss.printreportebndhprev(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        reportesubcuentabn() {
            this.ss.printreportesubcuentabn(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportereparticionbn() {
            this.ss.printreportereparticionbn(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefuentebn() {
            this.ss.printreportefuentebn(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechasbn() {
            this.ss.printreportefechasbn(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechasglosabn() {
            this.ss.printreportefechasglosabn(this.benefin)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportepd() {
            this.ss.printreportepd(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },        
        reportepddh() {
            this.ss.printreportepddh(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },        
        reportepddhprev() {
            this.ss.printreportepddhprev(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        reportesubcuentapd() {
            this.ss.printreportesubcuentapd(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportereparticionpd() {
            this.ss.printreportereparticionpd(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefuentepd() {
            this.ss.printreportefuentepd(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechaspd() {
            this.ss.printreportefechaspd(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechasglosapd() {
            this.ss.printreportefechasglosapd(this.pagodir)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },           
        reportedesembo() {
            this.ss.printreportedesembo(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },        
        reportedesembodh() {
            this.ss.printreportedesembodh(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },        
        reportedesembodhprev() {
            this.ss.printreportedesembodhprev(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        reportesubcuentadesembo() {
            this.ss.printreportesubcuentadesembo(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportereparticiondesembo() {
            this.ss.printreportereparticiondesembo(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefuentedesembo() {
            this.ss.printreportefuentedesembo(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechasdesembo() {
            this.ss.printreportefechasdesembo(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechasglosadesembo() {
            this.ss.printreportefechasglosadesembo(this.desembolso)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        ModalReporteIngresos() {
            this.ingresos = {};
            this.$refs['frm-ingresos'].show();
        },
        getGestion(ges) {
            console.log(ges);
            this.ss.listIngresoPresupuestarioGestion(ges).then(
                (result) => {
                    let response = result.data;
                    this.ingresopadre = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        reporteingreso() {
            console.log("reporte cc", this.ingresos.Rubro);
            this.ss.imprimirIngresoS(this.ingresos.Rubro)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },           
        reporteimprimirDevengadoRango() {
            console.log("reporte cc", this.ingresos);
            this.ss.imprimirDevengadoRango(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },    
        getRubroPadre() {
            this.ss.listRubroPadre().then(
                (result) => {
                    let response = result.data;
                    this.rubrosPadres = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRubroHijo(id) {
            this.ss.listRubroHijo(id).then(
                (result) => {
                    let response = result.data;
                    this.rubrosHijos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        reporteRubroPadre() {
            this.ss.imprimirRubroPadre(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteRubroPadreS() {
            this.ss.imprimirRubroPadreS(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteRubroPadreFechas() {
            this.ss.imprimirRubroPadreFechas(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteRubro() {
            this.ss.imprimirRubro(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteRubroFechas() {
            this.ss.imprimirRubroFechas(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        setResetIngresos() {
            this.ingresos = {};
        },
        getRubro() {
            this.ss.listRubro().then(
                (result) => {
                    let response = result.data;
                    this.rubros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        reporteRubroGestion() {
            this.ss.imprimirRubroGestion(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteRubroFechasSolo() {
            this.ss.imprimirRubroFechasSolo(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionGestion() {
            this.ss.imprimirReparticionGestion(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionRubroPadre() {
            this.ss.imprimirReparticionRubroPadre(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionRubroPadreAnual() {
            this.ingresos.ges = this.ingresos.Gestion.toString().slice(-2);
            console.log(this.ingresos,'esto viaje para ela anul aaaaaaaaa');
            this.ss.imprimirReparticionRubroPadreAnual(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionRubro() {
            this.ss.imprimirReparticionRubro(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionSoloRubro() {
            this.ss.imprimirReparticionSoloRubro(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionFechas() {
            this.ss.imprimirReparticionFechas(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionRubroPadreFechas() {
            this.ss.imprimirReparticionRubroPadreFechas(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionRubroFechas() {
            this.ss.imprimirReparticionRubroFechas(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteReparticionSoloRubroFechas() {
            this.ss.imprimirReparticionSoloRubroFechas(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteIngresosFechas() {
            this.ss.imprimirIngresosFechas(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        reporteIngresosFavor() {
            this.ss.imprimirIngresosFavor(this.ingresos)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        ModalReporteArchivos() {
            this.archivo = {};
            this.$refs['frm-archivos'].show();
        },
        reportesubcuentaarchivo() {
            this.ss.printreportesubcuentaarchivo(this.archivo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportereparticionarchivo() {
            this.ss.printreportereparticionarchivo(this.archivo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefuentearchivo() {
            this.ss.printreportefuentearchivo(this.archivo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechasarchivo() {
            this.ss.printreportefechasarchivo(this.archivo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reportefechasglosaarchivo() {
            this.ss.printreportefechasglosaarchivo(this.archivo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        setResetArchivo() {
            this.archivo = {};
        },
    },
    watch: {
        searchCategoriaProgramatica(q) {
            this.ss.select2CategoriaProgramatica(q).then((sub) => {
                console.log("",sub);
                if (sub.data.success) {
                    this.categoriaprogramatica = sub.data.items;
                } else {
                    this.categoriaprogramatica = [];
                }
            })
        },
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((sub) => {
                console.log("",sub);
                if (sub.data.success) {
                    this.subcuentas = sub.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
        },
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                   
                    this.reparticiones = res.data.items;
                
                } else {
                    this.reparticiones = [];
                }
            })
        },
        searchPartida(q) {
            this.ss.select2Partida(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                   
                    this.partidas = res.data.items;
                
                } else {
                    this.partidas = [];
                }
            })
        },
        searchComprobantefr(q) {
            this.cheque.TipoComprobante=10;
            this.cheque.q=q;
            this.ss.select2Comprobantefr(this.cheque).then((res) => {
                if (res.data.success) {
                    this.comprobantesfr = res.data.items;
                } else {
                    this.comprobantesfr = [];
                }
            })
        },
        searchFormulariobp(q) {
            this.benefin.CargoCuenta=2;
            this.benefin.q=q;
            this.ss.select2Formulariobpfiltro(this.benefin).then((res) => {
                if (res.data.success) {
                    this.formularioben = res.data.items;
                } else {
                    this.formularioben = [];
                }
            })
        },
        searchFormulariopd(q) {
            this.pagodir.CargoCuenta=0;
            this.pagodir.q=q;
            this.ss.select2Formulariobpfiltro(this.pagodir).then((res) => {
                if (res.data.success) {
                    this.formulariopd = res.data.items;
                } else {
                    this.formulariopd = [];
                }
            })
        },
        searchFormulariodesemb(q) {
            this.desembolso.CargoCuenta=5;
            this.desembolso.q=q;
            this.ss.select2Formulariobpfiltro(this.desembolso).then((res) => {
                if (res.data.success) {
                    this.formulariodesemb = res.data.items;
                } else {
                    this.formulariodesemb = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } 
        this.getFuenteFinanciera();
        this.getPersona();
        this.getTalonario();
        this.getEstadoCheque();
        this.getRubroPadre();
        this.getRubroHijo();
        this.getRubro();
    }
};
