import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "SocorroMasPage",
    data() {
        let ss = new MainService();
        return {
            msg: "SocorroMasPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexSocorro(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Numero', name: 'so.Numero', title: 'Núm.' },
                { data: 'Preventivo', name: 'so.Preventivo', title: 'Prev.' },
                { data: 'FuenteFinanciera', name: 'so.FuenteFinanciera', title: 'F. F.' },
                { data: 'Subcuenta', name: 'so.Subcuenta', title: 'Interesado' },
                { data: 'Reparticion', name: 'so.Reparticion', title: 'Repartición' },
                { data: 'ComprobanteContable', name: 'so.ComprobanteContable', title: 'Glosa' },
                { data: 'Monto', name: 'so.Monto', title: 'Monto' },
                { data: 'Estado', name: 'so.Estado', title: 'Estado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personas: [],
            mensajeexcel: {},
            socorro: {},
            sumamonto: {},
            reconocido: {},
            noreconocido: {},
            ingresosc: {},
            ingresoscdetalle: {},
            isLoading: false,
            errorBag: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            reporte: {},
        };
    },
    methods: {
        handleFileUpload(event) {
            this.selectedFile = event.target.files[0];
          },


          uploadFilea() {
            console.log('envia esto el excel');
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            console.log(this.selectedFile, 'envia esto el excel tambien');
            this.ss.importExcelSocorro(formData).then(
                (result) => {
                    let response = result.data;
                    console.log(result.data);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.mensajeexcel = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newIngresosC() {
            this.ingresosc = {};
            this.$refs['frm-ingresosc'].show();
        },
        cancelIngresosCDetalle(){
            this.$refs['frm-ingresoscdetalle'].hide();

        },

        showIngresosc(id) {
            this.isLoading=true;
            this.ss.showIngresosc(id).then(
                (result) => {
                    let response = result.data;
                    this.ingresosc = response.data;
                    this.$refs['view-ingresosc'].show();
                    this.isLoading=false;
                    this.aplicarFiltroDetalle(id);

                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editIngresosC() {
            this.$refs['frm-ingresosc'].show();
            this.$refs['view-ingresosc'].hide();
        },
        cancelIngresosC() {
            if (this.ingresosc.id) {
                this.$refs['view-ingresosc'].show();
            }
            this.$refs['frm-ingresosc'].hide();
        },
        saveIngresosC() {
            this.ss.storeIngresosC(this.ingresosc).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-ingresosc'].hide();
                    //this.$refs['view-ingresosc'].show(); //para volver al modal
                    this.$refs['datatable-ingresosc'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteIngresosC() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyIngresosC(this.ingresosc)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ingresosc'].hide();
                                this.$refs['datatable-ingresosc'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        excelIngresosCDetalle(){
            let url = "api/IngresosCDetalle/get_excel?IngresosC="+this.ingresosc.id;
            console.log(this.rutaApp+url);
            window.open(this.rutaApp+url);
        },
        aplicarFiltroDetalle(){
            console.log("se aplican los filtros");
            let Ingresosc = this.ingresosc.id;
            this.ajaxDetalle.data = function (d) {
                d.Ingresosc = Ingresosc;
            }
            this.$refs['datatable-ingresoscDetalle'].reloadAjax(this.ajaxDetalle);
            // window.e2 = this.$refs["datatable-ingresopresupuestarioDetalle"];
        },
        draw() {
            window.$('.btn-datatable-ingresosc').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showIngresosc(data);
            });
        },
        drawDetalle() {
            window.$('.btn-datatable-ingresoscDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showIngresoscDetalle(data);
            });
        },
        abrirModalReporte(){
            this.$refs['frm-reporte'].show();
        },
        cancelarModalReporte(){
            this.reporte = {};
            this.$refs['frm-reporte'].hide();
        },

        abrirModalCierreArchivo(){
            this.$refs['modalCierreArchivo'].show();
        },
        ReporteSocorro() {
            this.ss.printreportesocorro(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },


    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getPersona();
        }
    }
};
