import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "TipoCambioPage",
    data() {
        let ss = new MainService();
        return {
            msg: "TipoCambioPage",
            ss: ss,
            ajax: {
                "url": ss.indexTipoCambio(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Compra', name: 'Compra', title: 'Compra' },
                { data: 'Venta', name: 'Venta', title: 'Venta' },
                { data: 'UFV', name: 'UFV', title: 'UFV' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            TipoCambios: [],
            TipoCambio: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newTipoCambio() {
            this.TipoCambio = {};
            this.$refs['frm-TipoCambio'].show();
        },
        showTipoCambio(id) {
            this.isLoading=true;
            this.ss.showTipoCambio(id).then(
                (result) => {
                    let response = result.data;
                    this.TipoCambio = response.data;
                    this.$refs['view-TipoCambio'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editTipoCambio() {
            this.$refs['frm-TipoCambio'].show();
            this.$refs['view-TipoCambio'].hide();
        },
        cancelTipoCambio() {
            if (this.TipoCambio.id) {
                this.$refs['view-TipoCambio'].show();
            }
            this.$refs['frm-TipoCambio'].hide();
        },
        getTipoCambio(fecha) {
            //let fecha = this.TipoCambio.Fecha;
          //  console.log(this.TipoCambio.Fecha);
            this.ss.obtenerTipoCambio({"Fecha":fecha}).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response.data);
                  
                   this.TipoCambio=response.data[0];
                   this.TipoCambio.Fecha=fecha;
                //    console.log(result.data.Compra);
                   
                //    this.TipoCambio.Venta=response.data[0].Venta;
                //    this.TipoCambio.UFV=response.data[0].UFV;
                   console.log(this.TipoCambio);
                    // this.$refs['datatable-TipoCambio'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveTipoCambio() {
            this.ss.storeTipoCambio(this.TipoCambio).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-TipoCambio'].hide();
                    this.$refs['datatable-TipoCambio'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteTipoCambio() {
            this.$swal({
                title: "Esta seguro que desea eliminar este registro ?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyTipoCambio(this.TipoCambio)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-TipoCambio'].hide();
                                this.$refs['datatable-TipoCambio'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-TipoCambio').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showTipoCambio(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
    }
};
