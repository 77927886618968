import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import BarChart from "@/components/vue-chartjs/BarChart";
import LineChart from "@/components/vue-chartjs/LineChart";
import RadarChart from "@/components/vue-chartjs/RadarChart";
import PolarAreaChart from "@/components/vue-chartjs/PolarAreaChart";
import PieChart from "@/components/vue-chartjs/PieChart";
import DoughnutChart from "@/components/vue-chartjs/DoughnutChart";
//import axios from 'axios';
window.$ = window.jQuery = require("jquery");

export default {
  name: "PrincipalIngresosPage",
  data() {
    let ss = new MainService();
    return {
      msg: "PrincipalIngresosPage",
      ss: ss,
      auth: {},
      totalinicial: [],
      chartData: null,
      barChart: {
        data: {
          labels: [],
          datasets: [{
            label: 'Rubros',
            backgroundColor: "#3292e0",
            borderColor: "#3292e0",
            borderWidth: 2,
            hoverBackgroundColor: "#3292e0",
            hoverBorderColor: "#3292e0",
            data: []
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false
        }
      },
      toto:[],
      pieChart: {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [
                    "#45bbe0",
                    "#3292e0",
                    "#c8a2c8",
                    "#ff0000",
                    "#00ff00",
                ],
                hoverBackgroundColor: [
                    "#45bbe0",
                    "#3292e0",
                    "#c8a2c8",
                    "#ff0000",
                    "#00ff00",
                ],
                hoverBorderColor: "#fff"
            }]
    }
    };
  },
  methods: {
    getmontosgenerales() {
      this.ss.montosGenerales().then(
        (result) => {
          let response = result.data;
          this.totalinicial = response.data;
        }
      ).catch(error => {
        console.log(error);
      });
    },
    // getcantidad() {
    //   axios.get('https://safiejto.serv.emi.edu.bo/api/DashboardIngresos/cantidadrubros').then(
    //     (result) => {
    //       let response = result.data;
    //       this.toto = response.data;
    //       const data = response.data;
    //       this.barChart.data.labels = data.map(item => item.codigopadre+ " "+ item.rubropadre);
    //       this.barChart.data.datasets[0].data = data.map(item => item.total);
    //     }
    //   ).catch(error => {
    //     console.log(error);
    //   });
    // },
    getcantidadrubros() {
      this.ss.cantidadrubros().then(
        (result) => {
          let response = result.data;
          this.toto = response.data;
          const data = response.data;
          this.barChart.data.labels = data.map(item =>  item.rubropadre);
          this.barChart.data.datasets[0].data = data.map(item => item.total);
        }
      ).catch(error => {
        console.log(error);
      });
    },
    getcantidadrubrosb() {
      this.ss.cantidadrubros().then(
        (result) => {
          let response = result.data;
          this.toto = response.data;
          const data = response.data;
          this.pieChart.labels = data.map(item => item.codigopadre +" "+ item.rubropadre);
          this.pieChart.datasets[0].data = data.map(item => item.total);
        }
      ).catch(error => {
        console.log(error);
      });
    },
    handleWindowResize() {
      this.fetchData();
    },
  },
  components: {
    dataTable,
    Loader,
    BarChart,
    LineChart,
    RadarChart,
    PolarAreaChart,
    PieChart,
    DoughnutChart
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    this.auth = JSON.parse(localStorage.getItem('persona'));
    if (!persona) {
      this.$router.push("/Login");
    } else {
      this.getmontosgenerales();
     // this.getcantidad();
      this.getcantidadrubros();
      this.getcantidadrubrosb();
      window.addEventListener('resize', this.handleWindowResize);
    }
  },
};