import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CuentaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CuentaPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexCuenta(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Codigo', name: 'Codigo', title: 'Código' },
                { data: 'Cuenta', name: 'Cuenta', title: 'Cuenta' },
                { data: 'CuentaMovimiento', name: 'CuentaMovimiento', title: 'Movimiento' },
                { data: 'CuentaEconomia', name: 'CuentaEconomia', title: 'CTA. ECO.' },
                { data: 'Nivel', name: 'Nivel', title: 'Nivel' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            
           
            cuentas: [],
            cuenta: {},

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newCuenta() {
            this.cuenta = {};
            this.$refs['frm-cuenta'].show();
        },
        showCuenta(id) {
            this.isLoading = true;
            this.ss.showCuenta(id).then(
                (result) => {
                    let response = result.data;
                    this.cuenta = response.data;
                    this.$refs['view-cuenta'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editCuenta() {
            this.$refs['frm-cuenta'].show();
            this.$refs['view-cuenta'].hide();
        },
        cancelCuenta() {
            if (this.cuenta.id) {
                this.$refs['view-cuenta'].show();
            }
            this.$refs['frm-cuenta'].hide();
        },
        saveCuenta() {
            this.ss.storeCuenta(this.cuenta).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-cuenta'].hide();
                    this.$refs['datatable-cuenta'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCuenta() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyCuenta(this.cuenta)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-cuenta'].hide();
                                this.$refs['datatable-cuenta'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Cuenta').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCuenta(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            /*
            this.getEjecucion();
            this.getReparticion();
            this.getPartida();
            this.categoriaProgramatica();
            */
        }
    }
};
