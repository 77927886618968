import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FuenteFinancieraPage",
    data() {
        let ss = new MainService();
        return {
            msg: "FuenteFinancieraPage",
            ss: ss,
            auth: {},
            
            ajax: {
                "url": ss.indexFuenteFinanciera(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Codigo', name: 'Codigo', title: 'Código' },
                { data: 'FuenteFinanciera', name: 'FuenteFinanciera', title: 'Fuente Financiera' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripción' },
                { data: 'Cuenta', name: 'Cuenta', title: 'Cuenta' },
                { data: 'Subcuenta', name: 'Subcuenta', title: 'Subcuenta' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            fuenteFinancieras: [],
            fuenteFinanciera: {},
            isLoading: false,
            errorBag: {},
            persona: {}
        };
    },
    methods: {
        newFuenteFinanciera() {
            this.fuenteFinanciera = {};
            this.$refs['frm-fuenteFinanciera'].show();
        },
        showFuenteFinanciera(id) {
            this.isLoading=true;
            this.ss.showFuenteFinanciera(id).then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinanciera = response.data;
                    this.$refs['view-fuenteFinanciera'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editFuenteFinanciera() {
            this.$refs['frm-fuenteFinanciera'].show();
            this.$refs['view-fuenteFinanciera'].hide();
        },
        cancelFuenteFinanciera() {
            if (this.fuenteFinanciera.id) {
                this.$refs['view-fuenteFinanciera'].show();
            }
            this.$refs['frm-fuenteFinanciera'].hide();
        },
        saveFuenteFinanciera() {
            console.log(this.fuenteFinanciera);
            this.ss.storeFuenteFinanciera(this.fuenteFinanciera).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-fuenteFinanciera'].hide();
                    this.$refs['datatable-fuenteFinanciera'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data.errors);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFuenteFinanciera() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFuenteFinanciera(this.fuenteFinanciera)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-fuenteFinanciera'].hide();
                                this.$refs['datatable-fuenteFinanciera'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-FuenteFinanciera').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFuenteFinanciera(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
            
        if (!persona) {
            this.$router.push('/Login');
        }
    }
};
