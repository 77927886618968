import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-responsive");

export default {
    name: "FormulacionPage",
    data() {
        
        let ss = new MainService();
        return {
            
            max: 100,
            msg: "FormulacionPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexFormulacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'a.Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'a.Fecha', title: 'Fecha' },
                { data: 'CodigoFor', name: 'a.CodigoFor', title: 'Código' },
                { data: 'Concatenado', name: 'a.Concatenado', title: 'Nº Documento' },
                { data: 'Preventivo', name: 'a.Preventivo', title: 'Nº Preventivo' },
                { data: 'id', name: 'a.id', title: 'Nº id' },
                { data: 'Formulacion', name: 'a.Formulacion', title: 'Formulación' },
                {
                    data: 'Aprobado', name: 'a.Aprobado', title: 'Aprobado', render: function (data, type, row) {
                        if (row.Aprobado == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>'; }
                        else { return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>'; }
                    }
                },
                // { data: 'Aprobado', name: 'Aprobado', title: 'Aprobado' },
                // { data: 'Persona', name: 'Persona', title: 'Aprobado Por' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            ajaxDetalle: {
                "url": ss.indexFormulacionDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                    }
    
            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición', render:function(data, type, row){
                    return row.CodRep +': ' + row.Reparticion;
               }  },
               
                { data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render:function(data, type, row){
                    return row.CodCat +': ' + row.CategoriaProgramatica; 
                }  },
                { data: 'FuenteFinanciera', name: 'ff.FuenteFinanciera', title: 'Fuente Financiera' },
                { data: 'Partida', name: 'p.Partida', title: 'Partida', render:function(data, type, row){
                    return row.CodPar +': ' + row.Partida; 
                } },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                { data: 'Monto', name: 'a.Monto', title: 'Inicial' },
                { data: 'Disponible', name: 'a.Disponible', title: 'Vigente' },
                { data: 'Ejecutado', name: 'a.Ejecutado', title: 'Ejecutado' },
                { data: 'Porcentaje', name: 'a.Porcentaje', title: 'Porcentaje' },
                { data: 'Saldo', name: 'a.Saldo', title: 'Saldo' },
               

                // { data: 'Saldo', name: 'Saldo', title: 'Saldo', render:function(data, type, row){
                //      return row.Monto +'-' + row.Partida;
                // } },

                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            cuentas: [],
            editar:0,
            idFormulacion:0,
            idUnidadAcademica:0,
            partida:{},
            categoriaProgramatica: {},
            reparticion: {},
            reparticiones: [],
            categoriaProgramaticas: [],
            partidas: [],
            unidadAcademicas: [],
            fuenteFinancieras: [],
            personas: [],
            formulacionDetalles: [],
            formulacionDetalle: {},
            formulaciones: [],
            formulacion: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            selectedPartida: null,
            searchPartida: "",
            selectedReparticion: null,
            searchCategoriaProgramatica: "",
            selectedCategoriaProgramatica: null,
            searchReparticion: "",
            placeholderPartida:"",
            placeholderReparticion:"",
            placeholderCategoriaProgramatica:{},
            //placeholderCategoriaProgramatica:"",
            categoriaProgramaticashow: {},
           // formulacionDetalleFields: ['Reparticion', 'Categoria Programatica', 'Partida', 'Monto', 'Porcentaje', 'Ejecutado','Saldo'],
           // formulacionDetalleItems: [],
        };
    },
    methods: {
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPartida() {
            this.partida = {};
            this.$refs['frm-partida'].show();
        },
        imprimirFormulacion() {
            this.ss.imprimirFormulacionGral()
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        savePartida() {
            this.ss.storePartida(this.partida).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-partida'].hide();
                    // this.$refs['datatable-partida'].reload();
                   // this.getPartida();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        newCategoriaProgramatica() {
            this.categoriaProgramatica = {};
            this.$refs['frm-categoriaProgramatica'].show();
        },
        saveCategoriaProgramatica() {
            this.ss.storeCategoriaProgramatica(this.categoriaProgramatica).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-categoriaProgramatica'].hide();
                    // this.$refs['datatable-categoriaProgramatica'].reload();
                    this.getCategoriaProgramatica();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cancelPartida() {
            if (this.partida.id) {
                this.$refs['view-partida'].show();
            }
            this.$refs['frm-partida'].hide();
        },
        cancelCategoriaProgramatica() {
            if (this.categoriaProgramatica.id) {
                this.$refs['view-categoriaProgramatica'].show();
            }
            this.$refs['frm-categoriaProgramatica'].hide();
        },
        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        saveReparticion() { 
            this.reparticion.UnidadAcademica=this.formulacion.UnidadAcademica;
            console.log(this.reparticion);
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.getReparticion(this.idUnidadAcademica);
                    // this.$refs['datatable-reparticion'].reload();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                
        },
        cancelReparticion() {
            if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            }
            this.$refs['frm-reparticion'].hide();
        },
         borrarReparticion(){
            this.formulacionDetalle.Reparticion = "";
            
        },
        borrarCategoriaProgramatica(){
            this.formulacionDetalle.CategoriaProgramatica = "";
          
        },
        borrarPartida(){
            this.formulacionDetalle.Partida = "";
        
        },
        newFormulacion() {
            this.formulacion = {};
            this.$refs['frm-formulacion'].show();
            this.editar=0;
        },
        showFormulacion(id) {
            this.isLoading=true;
            this.ss.showFormulacion(id).then(
                (result) => {
                    let response = result.data;
                    this.formulacion = response.data;
                    this.$refs['view-formulacion'].show();
                    this.isLoading=false;
                  //  this.idFormulacion=this.formulacion.id;
                   console.log("unooo",this.formulacion.UnidadAcademica);
                   this.idUnidadAcademica=this.formulacion.UnidadAcademica;
                    this.getReparticion(this.idUnidadAcademica);
                 
                    // this.dataTable = window.$(this.$el).DataTable(this.parameters);
                    // window.$('#datatable-formulacionDetalle').DataTable({
                    //     columns:"columnsDetalle",
                    //     ajax:"ajaxDetalle",
                    //     width:"100%",
                    //     draw:"drawDetalle"
                    // });
      
                    // this.drawDetalle(id);
                    var dd =  window.$('#datatable-formulacionDetalle').DataTable();
                    this.ajaxDetalle.data = function(d){
                        d.id = id;
                    }
                    dd.reload();
                    
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editFormulacion() {
            this.$refs['frm-formulacion'].show();
            this.$refs['view-formulacion'].hide();
            this.editar=1;
        },
        cancelFormulacion() {
            if (this.formulacion.id) {
                this.$refs['view-formulacion'].show();
            }
            this.$refs['frm-formulacion'].hide();
        },
        saveFormulacion() {
            console.log(this.formulacion);
            this.ss.storeFormulacion(this.formulacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                   // console.log("id")
                   // console.log(response.data.id);
                   if(this.editar==0){
                    this.showFormulacion(response.data.id);
                   } 
                 
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                   
                    this.$refs['frm-formulacion'].hide();
                    this.$refs['datatable-formulacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFormulacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFormulacion(this.formulacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-formulacion'].hide();
                                this.$refs['datatable-formulacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        aprobarFormulacion() {
                    console.log(this.formulacion.suma.monto);
                    this.isLoading = true;
                    this.ss.aprobarFormulacion({ 'id': this.formulacion.id, 'Aprobado': true, 'Monto': this.formulacion.suma.monto }).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['datatable-formulacion'].reload();
                            // this.$swal.fire({
                            //     position: 'center',
                            //     icon: 'success',
                            //     title: 'Aprobado correctamente.',
                            //     showConfirmButton: false,
                            //     timer: 1500
                            //   });
                            this.$refs['view-formulacion'].hide();

                            this.isLoading = false;
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });

                

            

        },
        desaprobarFormulacion() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de la formulacion.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarFormulacion({ 'id': this.formulacion.id, 'Aprobado': false }).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['datatable-formulacion'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-formulacion'].hide();

                                this.isLoading = false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        }, 
        getFormulacion() {
            this.ss.listFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.formulaciones = response.data;
                    // console.log("formulacion");
                    // console.log(this.formulaciones);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion(id) {
        //    alert(this.idUnidadAcademica);
            this.ss.listReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramatica() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        // getPartida() {
        //     this.ss.listPartida().then(
        //         (result) => {
        //             let response = result.data;
        //             this.partidas = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },
        newFormulacionDetalle() {
            this.formulacionDetalle = {};
            this.$refs['frm-formulacionDetalle'].show();
            this.placeholderPartida="Escriba la partida a buscar...";
            this.placeholderCategoriaProgramatica="Escriba la categoria programatica a buscar...";
            this.placeholderReparticion="Escriba la reparticion a buscar...";
            
        },
        showFormulacionDetalle(id) {
            this.isLoading=true;
            this.ss.showFormulacionDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.formulacionDetalle = response.data;
                    this.$refs['view-formulacionDetalle'].show();
                    this.isLoading=false;
                    //this.$refs['view-formulacionDetalle'].DataTable();
                    //$('#detalleFormulacion').DataTable();
                    
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        reporteFormulacion() {
          
            this.ss.imprimirFormulacion(this.formulacion.id)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;

                    console.log(urlFile);
                    console.log("-------------------------------------------------------");

                    console.log(this.rutaApp);
                    // window.open(urlFile.url);

                    // var urlFile =this.rutaApp+'tmp/'+this.programaElegido.id;
                    var dir =this.rutaApp + urlFile.url;


                    console.log("-------------------------------------------------------");

                    console.log(dir);
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })

           
       
        },
        reporteFormulacionIndividual() {
            this.ss.imprimirFormulacionIndividual(this.formulacion.id)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    var dir =this.rutaApp + urlFile.url;
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })
        },
        editFormulacionDetalle() {
            this.$refs['frm-formulacionDetalle'].show();
            this.$refs['view-formulacionDetalle'].hide();
            if(this.formulacionDetalle.Partida==null){
                this.placeholderPartida="Escriba la partida a buscar...";
               }else{
                this.placeholderPartida=this.formulacionDetalle.partida.Codigo+': '+this.formulacionDetalle.partida.Partida;
               }
               if(this.formulacionDetalle.CategoriaProgramatica==null){
                this.placeholderCategoriaProgramatica="Escriba la categoria programatica a buscar...";
               }else{
                this.placeholderCategoriaProgramatica=this.formulacionDetalle.categoria_programatica.Codigo+': '+this.formulacionDetalle.categoria_programatica.CategoriaProgramatica;
               }
               if(this.formulacionDetalle.Reparticion==null){
                this.placeholderReparticion="Escriba la reparticion a buscar...";
               }else{
                this.placeholderReparticion=this.formulacionDetalle.reparticion.Codigo+': '+this.formulacionDetalle.reparticion.Reparticion;
               }
               

        },
        cancelFormulacionDetalle() {
            if (this.formulacionDetalle.id) {
                this.$refs['view-formulacionDetalle'].show();
            }
            this.$refs['frm-formulacionDetalle'].hide();
        },
        saveFormulacionDetalle() {
            this.formulacionDetalle.Formulacion=this.formulacion.id;
            console.log(this.formulacionDetalle);
            this.ss.storeFormulacionDetalle(this.formulacionDetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-formulacionDetalle'].hide();
                    this.$refs['datatable-formulacionDetalle'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFormulacionDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFormulacionDetalle(this.formulacionDetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-formulacionDetalle'].hide();
                                this.$refs['datatable-formulacionDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-formulacion'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-formulacion'].show();
        },
        changePassword() {
            this.password.Formulacion = this.formulacion.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-formulacion'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-Formulacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFormulacion(data);

            });
        },
        drawDetalle() {
            //this.idFormulacion=1;
            window.$('.btn-datatable-FormulacionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFormulacionDetalle(data);
            });
        },
        showCategoriaProgramatica(id) {
            this.isLoading=true;
            this.ss.showCategoriaProgramatica(id).then(
                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticashow = response.data;
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
    },
    watch: {
        searchPartida(q) {

            this.ss.select2Partida(q).then((res) => {
               // console.log("partida",res);
                if (res.data.success) {
                   
                    this.partidas = res.data.items;
                
                } else {
                    this.partidas = [];
                }
            });
   
    
        },
        searchCategoriaProgramatica(q) {

            this.ss.select2CategoriaProgramatica(q).then((res) => {
               // console.log("partida",res);
                if (res.data.success) {
                   
                    this.categoriaProgramaticas = res.data.items;
                
                } else {
                    this.categoriaProgramaticas = [];
                }
            });
   
    
        },
        searchReparticion(q) {
        this.ss.select2Reparticion(q).then((res) => {
            console.log(res);
            if (res.data.success) {
               
                this.reparticiones = res.data.items;
            
            } else {
                this.reparticiones = [];
            }
        })
    },
    selectedReparticion(reparticion){
        console.log(reparticion)
        this.formulacionDetalle.CategoriaProgramatica=this.selectedReparticion.CategoriaProgramatica;
        this.showCategoriaProgramatica(this.formulacionDetalle.CategoriaProgramatica)
    }

    
    },
    
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getCuenta();
            this.getUnidadAcademica();
            this.getFuenteFinanciera();
            this.getPersona();
            //this.getReparticion(0);
            this.getCategoriaProgramatica();
            //this.getPartida();
            
        }
    }
};
