import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DescargoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DescargoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexDescargoGral(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'CargoCuenta', name: 'e.CargoCuenta', title: 'Tipo', 
                render: function (data, type, row) {
                             if (row.CargoCuenta == 1) {
                                 return '<i class="fa fa-check text-success font-italic font-weight-bold "> Cargo de Cuenta </i>';
                             } else if (row.CargoCuenta == 3) {
                                 return '<i class="fa fa-check text-info  font-italic font-weight-bold"> Fondo Rotativo </i>';
                             } 
                         }
                },
                { data: 'Preventivo', name: 'de.Preventivo', title: 'Preventivo', orderable: true  },
                { data: 'Subcuenta', name: 'sc.Subcuenta', title: 'Subcuenta', searchable: true },
                { data: 'Partida', name: 'pa.Partida', title: 'Partida', searchable: true },
                { data: 'Desembolsado', name: 'de.Desembolsado', title: 'Desembolsado', searchable: false },
                { data: 'Retenido', name: 'de.Retenido', title: 'Retenido', searchable: false },
                //{ data: 'Gestion', name: 'cc.Gestion', title: 'Gestion', searchable: false },
                 {
                     data: 'DescargoEstado',
                     name: 'de.DescargoEstado',
                     title: 'Estado',
                     render: function (data, type, row) {
                         if (row.DescargoEstado == 1) {
                             return '<i class="fa fa-times text-warning font-italic font-weight-bold "> Pendiente </i>';
                         } else if (row.DescargoEstado == 2) {
                             return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado Revisor </i>';
                         } else if (row.DescargoEstado == 3) {
                             return '<i class="fa fa-check text-danger  font-italic font-weight-bold"> Observado </i>';
                         } else if (row.DescargoEstado == 5) {
                             return '<i class="fa fa-check text-primary  font-italic font-weight-bold"> Cerrado </i>';
                         }
                     }
                 },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            comprobanteContableDetalles: [],
            descargo: {},
            descargod: {},
            descargor: {},
            observacion: {},
            descargoc: {},
            estado: {},
            comprobantecontables: [],
            descargoestados: [],
            subcuentas: [],
            subcuenta: {},
            reparticions: [],
            placeholderSubcuenta: "",
            placeholderCuenta: "",
            searchCuenta: "",
            selectedCuenta: null,
            selectedSubcuenta: null,
            searchSubcuenta: "",
            isLoading: false,
            isLoadingFile: false,
            isLoadedFile: false,
            selecteddescargoestado: 0,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            detalleDescargo:{},
            seguimiento:[],
            tipoRetencion:0
        };
    },
    methods: {
        filtro() {
            let DescargoEstado = this.selecteddescargoestado;
            this.ajax.data = function (d) {
                d.DescargoEstado = DescargoEstado;
            }
            this.$refs['datatable-descargo'].reloadAjax(this.ajax);
        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.descargo.Respaldo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        newObservacion() {
            this.observacion = {};
            this.$refs['modalObservar'].show();
        },
        getComprobanteContable() {
            this.ss.listComprobanteContable().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontables = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion(0).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDescargoEstado() {
            this.ss.listDescargoEstado(0).then(
                (result) => {
                    let response = result.data;
                    this.descargoestados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newDescargo() {
            this.descargo = {};
            this.$refs['frm-descargo'].show();
            this.placeholderCuenta = "Escriba la cuenta...";
            this.placeholderSubcuenta = "Escriba la subcuenta...";
        },
        showDescargo(id) {
            this.tipoRetencion=0;
            this.isLoading = true;
            this.ss.showDescargo(id).then(
                (result) => {
                    let response = result.data;
                    this.descargo = response.data;
                    this.$refs['view-descargo'].show();
                    this.isLoading = false;
                    this.getSeguimiento(this.descargo.id);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        editDescargo() {
            this.placeholderCuenta = this.descargo.cuenta ? this.descargo.cuenta.Codigo + ': ' + this.descargo.cuenta.Cuenta : "Escribe la cuenta..";
            this.placeholderSubcuenta = this.descargo.subcuenta ? this.descargo.subcuenta.Codigo + ': ' + this.descargo.subcuenta.Subcuenta : "Escribe la subcuenta..";
            this.$refs['frm-descargo'].show();
            this.$refs['view-descargo'].hide();
        },
        saveDescargo() {
            this.ss.storeDescargo(this.descargo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-descargo'].hide();
                    this.$refs['datatable-descargo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveDescargod() {
            this.descargod.id = this.descargo.id
            this.descargod.EjecutadoD = this.descargo.EjecutadoD
            this.descargod.RetenidoD = this.descargo.RetenidoD
            this.descargod.RevertidoD = this.descargo.RevertidoD
            this.descargod.AprobadoD = this.descargo.AprobadoD
            this.descargod.Nota = this.descargo.Nota
            this.ss.storeDescargod(this.descargod ).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['view-descargo'].reload();
                    this.$refs['datatable-descargo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveCierre() {
            this.descargoc.id = this.descargo.id
            this.descargoc.Fojas = this.descargo.Fojas
            this.descargoc.Mes = this.descargo.Mes
            this.descargoc.HdtCierre = this.descargo.HdtCierre
            this.ss.storeCierre(this.descargoc ).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['view-descargo'].reload();
                    this.$refs['datatable-descargo'].reload();
                    this.$refs['descargoc'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveRadio() {
            this.descargor.id = this.descargo.id
            this.descargor.NumeroRadio = this.descargo.NumeroRadio
            this.descargor.DetalleRadio = this.descargo.DetalleRadio
            this.descargor.FechaRadio = this.descargo.FechaRadio
            this.ss.storeRadio(this.descargor ).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['view-descargo'].reload();
                    this.$refs['datatable-descargo'].reload();
                    this.$refs['descargor'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveEstado(numero) {
             this.estado.estado = numero;
             this.estado.id = this.descargo.id;
            //console.log(this.estado);
            this.ss.storeCierreEstado(this.estado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.estado = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['view-descargo'].reload();
                    this.$refs['datatable-descargo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editDescargod() {
            this.$refs['frm-descargo'].show();
            this.$refs['view-descargo'].hide();
        },
        deleteDescargo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyDescargo(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                //console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-descargo'].hide();
                                this.$refs['datatable-descargo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        abrirModalAprobar(){
            this.$refs['modalAprobar'].show();
        },
        abrirModalRadio(){
            this.$refs['modalRadio'].show();
        },
        cerrarModalAprobar(){
            this.$refs['modalAprobar'].hide();
        },

        abrirModalSeguimiento(){
            this.$refs['modalSeguimiento'].show();
        },
        cerrarModalSeguimiento(){
            this.$refs['modalSeguimiento'].hide();
        },
        getSeguimiento(id) {
            this.ss.listDescargoDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.seguimiento = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        ObservarDescargo() {
            this.$swal({
                title: "Estas seguro de derivar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.observacion.Descargo=this.descargo.id;
                        this.observacion.Persona=this.auth.id;
                        this.observacion.Rol=this.auth.Rol;
                        //this.observacion.Aprobado=estado;
                        this.ss.storeObservacionDetalle(this.observacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['modalSeguimiento'].hide();
                                this.$refs['modalObservar'].hide();
                                this.$refs['modalSeguimiento'].show();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        aprobarDescargo(estado) {
            this.$swal({
                title: "Estas seguro de derivar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.detalleDescargo.Descargo=this.descargo.id;
                        this.detalleDescargo.Persona=this.auth.id;
                        this.detalleDescargo.Rol=this.auth.Rol;
                        this.detalleDescargo.Aprobado=estado;
                        this.ss.storeDescargoDetalle(this.detalleDescargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.$refs['modalAprobar'].hide();
                                this.$refs['view-descargo'].hide();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        recalcular(){
            console.log("tipo reversion");
            if(this.tipoRetencion==1){
                // console.log("tr 1",this.tipoRetencion);
                // console.log(parseFloat(this.descargo.Desembolsado) * 0.155);
                this.descargo.Retenido=parseFloat(this.descargo.Desembolsado) * 0.155;
                this.descargo.Ejecutado=parseFloat(this.descargo.Desembolsado)-this.descargo.Retenido;
                this.descargo.Aprobado=this.descargo.Ejecutado;
            }
            if(this.tipoRetencion==2){
                // console.log("tr 1",this.tipoRetencion);

                this.descargo.Retenido=parseFloat(this.descargo.Desembolsado) * 0.08;
                this.descargo.Ejecutado=parseFloat(this.descargo.Desembolsado)-this.descargo.Retenido;
                this.descargo.Aprobado=this.descargo.Ejecutado;


            }
            if(this.tipoRetencion==3){
                // console.log("tr 1",this.tipoRetencion);

                this.descargo.Retenido=parseFloat(this.descargo.Desembolsado) * 0.13;
                this.descargo.Ejecutado=parseFloat(this.descargo.Desembolsado)-this.descargo.Retenido;
                this.descargo.Aprobado=this.descargo.Ejecutado;


            }
        },

        draw() {
            window.$('.btn-datatable-Descargo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDescargo(data);
            });
        },
        printDescargo() {
            this.ss.printDescargo(this.descargo.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printRevisor() {
            console.log(this.descargo.comprobante_contable.id);
            this.ss.printRevisor(this.descargo.comprobante_contable.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printRevisor2022() {
            console.log(this.descargo.comprobante_contable.id);
            this.ss.printRevisor2022(this.descargo.comprobante_contable.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printSeguimiento2022() {
            this.ss.printSeguimiento2022(this.descargo.comprobante_contable.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printSeguimiento() {
            this.ss.printSeguimiento(this.descargo.comprobante_contable.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printSupervisor() {
            this.ss.printSupervisor(this.descargo.comprobante_contable.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printRadioSup() {
            this.ss.printRadioSup(this.descargo.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printRadioUni() {
            this.ss.printRadioUni(this.descargo.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printEstadocargo() {
            console.log(this.descargo.Subcuenta);
            this.ss.printEstadocargo(this.descargo.Subcuenta)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printEstadofondo() {
            console.log(this.descargo.Subcuenta);
            this.ss.printEstadofondo(this.descargo.Subcuenta)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        }
    },
    watch: {
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getReparticion(0);
            this.getSubcuenta();
            this.getComprobanteContable();
            this.getDescargoEstado();
        }
    }
};
