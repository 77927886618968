<template>
    <v-list nav dense
    >
      <router-link
            class="black--text text--accent-4"
            to="Profile"
            active-class="blue--text text--accent-4"
        >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon large  color="green darken-2">mdi-account-circle</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{
                        $t("nav.Profile")
                    }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </router-link>

        <v-list-group v-if="
                persona.Rol < 3 ||
                persona.Rol == 4 ||
                persona.Rol == 6 ||
                persona.Rol == 8 ||
                persona.Rol == 10 
            ">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon large color="blue darken-2">mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Administración</v-list-item-title>
                    </v-list-item-content>
                </template>
                <router-link
                    class="black--text text--accent-4"
                    to="Persona"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon   color="blue lighten-2">mdi-account-multiple</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Persona")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="Rol"
                    active-class="blue--text text--accent-4"
                    v-if="persona.Rol == 1"   
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="blue lighten-2">mdi-key</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title >{{ $t("nav.Rol") }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="UnidadAcademica"
                    active-class="blue--text text--accent-4"
                    v-if="persona.Rol == 1" 
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="blue lighten-2">mdi-school</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.UnidadAcademica")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <!-- <router-link
                    class="black--text text--accent-4"
                    to="TipoReporte"
                    active-class="blue--text text--accent-4"
                    v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4 ||
                        persona.Rol == 10 "
                > -->
                
        </v-list-group>

        <v-list-group v-if="
                persona.Rol < 3 ||
                persona.Rol == 4 ||
                persona.Rol == 6 ||
                persona.Rol == 8 ||
                persona.Rol == 10 || 
                persona.Rol == 25 
            ">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon large color="success darken-2">mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Tablero de Control</v-list-item-title>
                    </v-list-item-content>
                </template>
                <router-link
                    class="black--text text--accent-4"
                    to="/"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon   color="success lighten-2">mdi-view-dashboard</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                Tablero de Control
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="/Ingresos"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon   color="success lighten-2">mdi-view-dashboard</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                Tab. de Control Ingresos
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
        </v-list-group>

        <v-list-group v-if="persona.Rol != 9 || persona.Rol == 15 || persona.Rol == 21 || persona.Rol == 20 ||  persona.Rol == 23 || persona.Rol == 25">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large color="blue darken-2">mdi-dots-grid</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Paramétricas</v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="FuenteFinanciera"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="purple">mdi-cash</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.FuenteFinanciera")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Partida"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="purple">mdi-view-dashboard</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Partida")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Tipo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 || persona.Rol == 10 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi mdi-buffer</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Tipo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="CategoriaProgramatica"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="purple">mdi-vector-combine</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.CategoriaProgramatica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Reparticion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8 || persona.Rol == 15 ||  persona.Rol == 23"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="purple">mdi-vector-triangle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reparticion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="TipoComprobante"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 ||
                 persona.Rol == 10 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon  color="blue lighten-2">mdi-animation</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoComprobante")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="TipoCambio"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 ||
                 persona.Rol == 10 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon  color="blue lighten-2">mdi-calculator</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoCambio")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Rubro"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="purple">mdi-view-column</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rubro")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Cuenta"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 ||
                 persona.Rol == 10 ||  persona.Rol == 23 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-wallet</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Cuenta")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Subcuenta"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 || persona.Rol == 6 ||
                 persona.Rol == 10 || persona.Rol == 20 || persona.Rol == 21 ||  persona.Rol == 23 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-folder-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Subcuenta")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!-- <router-link
                class="black--text text--accent-4"
                to="NivelAcademico"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-angle-double-up</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.NivelAcademico")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="PeriodoAcademico"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-angle-double-up</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.PeriodoAcademico")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="TipoPago"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-piggy-bank</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoPago")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <router-link
                class="black--text text--accent-4"
                to="Banco"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-coin </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Banco")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="LibroBancario"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Libro Bancario
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ConciliacionLibroBancario"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Conciliación Bancaria
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Fuerza"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-seal </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Fuerza")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- <router-link
                class="black--text text--accent-4"
                to="Especialidad"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Especialidad")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="Grado"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> fas fa-graduation-cap </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Grado")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Retencion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> fas fa-graduation-cap </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Retencion
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="TipoSolvencia"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 20 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> fas fa-graduation-cap </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Tipo Cert. Solvencia
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>

        <v-list-group v-if="persona.Rol <= 3 || persona.Rol == 8 || persona.Rol == 26">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large  color="blue darken-2">mdi-bank</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Presupuestos</v-list-item-title>
                </v-list-item-content>
            </template>

            <router-link
                class="black--text text--accent-4"
                to="Formulacion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-currency-usd</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Formulacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Reformulacion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-page-next-outline</v-icon>
                        <!-- <i class="fa fa-long-arrow" aria-hidden="true"></i> -->
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reformulacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Transferencia"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 2 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-swap-horizontal-bold</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Transferencia")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Certificacion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-text-box-check-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Certificacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Reversion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-file-undo</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reversion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="CajaChica"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 26"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-file-undo</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Caja Chica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!-- <router-link
                class="black--text text--accent-4"
                to="ModificacionCertificacion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-bookmark-box-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ModificacionCertificacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="IngresoPresupuestario"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-page-previous-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.IngresoPresupuestario")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="IngresoPresupuestarioDetalle"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 8"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-folder-multiple-plus-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.IngresoPresupuestarioDetalle")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
        </v-list-group>

        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 ||
                 persona.Rol == 10 || persona.Rol == 17 || persona.Rol == 18 || persona.Rol == 19 || persona.Rol == 20 || persona.Rol == 21 || persona.Rol == 22 ||  persona.Rol == 23 ||  persona.Rol == 24 || persona.Rol == 25"
        >
            <template v-slot:activator>
                <v-list-item-icon>
                    <!-- bank-transfer-out -->
                    <v-icon large  color="blue darken-2">mdi-bank-transfer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Contabilidad</v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="Ejecucion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-view-dashboard-edit</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Ejecucion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteFondoRotativo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 || persona.Rol == 19 ||  persona.Rol == 21 ||  persona.Rol == 24 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Comprob. Fondo Rotativo
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteDevengado"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-file-replace-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteDevengado")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteEgreso"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-page-next-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteEgreso")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteIngreso"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-page-previous-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteIngreso")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!-- <router-link
                class="black--text text--accent-4"
                to="ComprobanteContable"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-angle-double-up</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteContable")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteContableDetalle"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-angle-double-up</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteContableDetalle")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteGasto"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 23 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-folder-download-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteGasto")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <!-- <router-link
                class="black--text text--accent-4"
                to="FacturaEgreso"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-file-chart</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.FacturaEgreso")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteApertura"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-folder-upload-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteApertura")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
           
            <!-- <router-link
                class="black--text text--accent-4"
                to="ComprobanteTraspaso"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteTraspaso")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="CuentaCobrar"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 24"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-folder-remove</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Cuentas por Cobrar
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
           <!-- <router-link
                class="black--text text--accent-4"
                to="RRT"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 18 || persona.Rol == 19 || persona.Rol == 17 || persona.Rol == 4 || persona.Rol == 5 || persona.Rol == 10"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            RRT
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="Formulariobp"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 21 ||  persona.Rol == 22 ||  persona.Rol == 19 ||  persona.Rol == 24"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Ben. Final. y Pago Dir.
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Tributacion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 ||   persona.Rol == 23"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Tributación
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="SocorroMas"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 21 || persona.Rol == 26"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Socorro Masivo
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ReversionG"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 26"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Reversiones Gestión Pas.
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="CajaChica"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol <= 3 || persona.Rol == 4 || persona.Rol == 5"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-file-undo</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Caja Chica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>

        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 ||
                 persona.Rol == 10 || persona.Rol == 17 || persona.Rol == 18 || persona.Rol == 19 || persona.Rol == 20 || persona.Rol == 21 || persona.Rol == 22 ||  persona.Rol == 23 ||  persona.Rol == 24 || persona.Rol == 25"
        >
            <template v-slot:activator>
                <v-list-item-icon>
                    <!-- bank-transfer-out -->
                    <v-icon large  color="green darken-2">mdi-bank-transfer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Cuentas Por </v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="CuentaCobro"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 20 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">fab fa-viadeo</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Cuentas Por Cobrar
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="CuentaPago"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 20 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">fab fa-viadeo-square</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Cuentas Por Pagar
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="BuscadorCobro"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 20 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">fa fa-user</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Buscador de CPC
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="BuscadorPago"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 20 || persona.Rol == 25"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">fa fa-user</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Buscador de CPP
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
           
            <!-- <router-link
                class="black--text text--accent-4"
                to="ArchivoC"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 ||  persona.Rol == 21"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Entrega C.C. a Descargo
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            
            <!-- <router-link
                class="black--text text--accent-4"
                to="Talonario"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 "
                >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2"> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Talonario")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ChequeAsignado"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4  || persona.Rol == 5"

            >
                <v-list-item>
                <v-list-item-icon>
                    <v-icon color="green lighten-2"> mdi-assistant </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Cheques Asignados</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </router-link> -->
        </v-list-group>

        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 12 ||persona.Rol == 13 ||persona.Rol == 14 ||persona.Rol == 15 ||persona.Rol == 16 ||persona.Rol == 19 ||persona.Rol == 20">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large color="blue darken-2">mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Descargo</v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="AsignarDescargo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1  ||persona.Rol == 16" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-6">mdi-account-arrow-right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.AsignarDescargo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="DescargoGral"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 12 ||persona.Rol == 13 ||persona.Rol == 14 ||persona.Rol == 15 ||persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-clipboard-flow</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title> Descargo Gral.</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!-- <router-link
                class="black--text text--accent-4"
                to="Descargo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 12 ||persona.Rol == 13 ||persona.Rol == 14 ||persona.Rol == 15 ||persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-clipboard-flow</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoSin")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="DescargoGralGestionPas"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-clipboard-flow</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Descargo Gestión Pasadas</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
           
            <!-- <router-link
                class="black--text text--accent-4"
                to="DescargoRev"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 12 ||persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-format-horizontal-align-right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoRev")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="DescargoFin"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 15 ||persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-format-horizontal-align-right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoFin")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
          <!--   <router-link
                class="black--text text--accent-4"
                to="DescargoSeg"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 12 ||persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-format-horizontal-align-left</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoSeg")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="DescargoCor"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 13 ||persona.Rol == 16" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-format-horizontal-align-right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoCor")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="DescargoEst"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 13 ||persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-format-horizontal-align-right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoEst")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
           
            <router-link
                class="black--text text--accent-4"
                to="DescargoArchivo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 19" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-book-arrow-down</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoArchivo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>

        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 || persona.Rol == 19"
        >
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large color="blue darken-2">mdi-cash-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Tesoreria</v-list-item-title>
                </v-list-item-content>
            </template>
            <!-- TESORERIA PRESUPUESTOS FORMULACION -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="Formulacion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-currency-usd</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Formulacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- TESORERIA PRESUPUESTOS ejecucuion -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="Certificacion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-text-box-check-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Certificacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteTesoreria"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 || persona.Rol == 23 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi mdi-dns</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteTesoreria")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteGasto"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 || persona.Rol == 23"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-folder-download-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ComprobanteGasto")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Ejecucion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-view-dashboard-edit</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Ejecucion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ComprobanteFondoRotativo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 || persona.Rol == 19 ||  persona.Rol == 21 ||  persona.Rol == 24"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Comprob. Fondo Rotativo
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Talonario"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 "
                >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2"> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Talonario")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="ChequeAsignado"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6  || persona.Rol == 7 "

            >
                <v-list-item>
                <v-list-item-icon>
                    <v-icon color="green lighten-2"> mdi-assistant </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Cheques Asignados</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </router-link>  
            <!-- <router-link
                class="black--text text--accent-4"
                to="Ejecucion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-view-dashboard-edit</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Ejecucion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <!-- <router-link
        class="black--text text--accent-4"
        to="ReformulacionDetalle"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file-chart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $t("nav.ReformulacionDetalle")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="PlanPago"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.PlanPago")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="PagoTesoreria"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.PagoTesoreria")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
            class="black--text text--accent-4"
            to="Cajero"
            active-class="blue--text text--accent-4"
        >
            <v-list-item>
            <v-list-item-icon>
                <v-icon> mdi-assistant </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>Gestión Cajeros</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </router-link> -->

            <!-- ASIGNACION DE CHEQUES -->
            <!-- <router-link
            class="black--text text--accent-4"
            to="Cheque"
            active-class="blue--text text--accent-4"
        >
            <v-list-item>
            <v-list-item-icon>
                <v-icon> mdi-assistant </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>Asignación Cheques</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </router-link> -->


            

            <router-link
                class="black--text text--accent-4"
                to="EstadoCheque"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 "

            >
                <v-list-item>
                <v-list-item-icon>
                    <v-icon> mdi-assistant </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Estado Cheques</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- <router-link
                class="black--text text--accent-4"
                to="Curso"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Curso")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="ItemTesoreria"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.ItemTesoreria")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="PagoTesoreriaCurso"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.PagoTesoreriaCurso")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Dosificacion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Dosificacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Estudiante"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Estudiante")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Inscripciones"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Inscripciones")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Inscripcion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Inscripcion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="LibroBancario"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Libro Bancario
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="InscripcionPagoTesoreria"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-account-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.InscripcionPagoTesoreria")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Factura"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Factura")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="DetalleFactura"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DetalleFactura")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Deposito"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Deposito")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Programa"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-assistant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Programa")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
        class="black--text text--accent-4"
        to="ModificacionCertificacionDetalle"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file-chart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $t("nav.ModificacionCertificacionDetalle")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Modalidad"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Modalidad")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="Sintesis"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Sintesis")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <router-link
                class="black--text text--accent-4"
                to="IngresoTesoreria"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 || persona.Rol == 19" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Ingresos Tesoreria 2023
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="IngresoTesoreriaActual"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 || persona.Rol == 19" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Ingresos Tesoreria 2024
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="IngresosC"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Ingresos C21 2023
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="IngresosCactual"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Ingresos C21 2024
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="ComprobanteCheque"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 6 || persona.Rol == 7 "

            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Comprobante Cheque
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

        </v-list-group>
        
        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4 || persona.Rol == 6 ||
                    persona.Rol == 10 || persona.Rol == 12 || persona.Rol == 15 ||
                    persona.Rol == 16 || persona.Rol == 19 || persona.Rol == 20 || persona.Rol == 21"
        >
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large color="blue darken-2">mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Reportes</v-list-item-title>
                </v-list-item-content>
            </template>

            <router-link
                class="black--text text--accent-4"
                to="TipoReporte"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-note-text</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoReporte")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="TipoReporteVer"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4 || persona.Rol == 6 ||
                    persona.Rol == 10 || persona.Rol == 12 || persona.Rol == 13 || persona.Rol == 15 ||
                    persona.Rol == 16 || persona.Rol == 19 || persona.Rol == 20 || persona.Rol == 21 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-file-chart-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoReporteVer") 
                        }} Grals.</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Reporte"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 4 || persona.Rol == 5 || persona.Rol == 6 || persona.Rol == 12 || persona.Rol == 13 || persona.Rol == 14 || persona.Rol == 15 || persona.Rol == 16 || persona.Rol == 19 || persona.Rol == 20 || persona.Rol == 21"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-file-chart-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reporte") 
                        }} </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>

        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 16 || persona.Rol == 20"
        >
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large color="blue darken-2">mdi-file-powerpoint</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Certificación</v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="DescargoEst"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 13 ||persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-format-horizontal-align-right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoEst")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="CertificadoSolvencia"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 16 || persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-6">mdi-format-float-center</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.CertificadoSolvencia")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="DescargoCer"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 16 ||persona.Rol == 20" 
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue lighten-2">mdi-checkbox-multiple-marked-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DescargoCertificacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
        </v-list-group>

        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4 "
        >
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large color="green darken-2">mdi mdi-odnoklassniki</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Módulo Cajeros</v-list-item-title>
                </v-list-item-content>
            </template>

            <router-link
                class="black--text text--accent-4"
                to="HistorialCajero"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi mdi-run-fast</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Historial Cajeros
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Cajero"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi mdi-run-fast</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Cajeros
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Cajero"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi-file-chart-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reporte") 
                        }} </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>
        <v-list-group v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 4 || persona.Rol == 21"
        >
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon large color="green darken-2">mdi-magnify-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Consultas</v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="ComprobantesTotal"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 
                || persona.Rol == 2 
                || persona.Rol == 3 
                || persona.Rol == 4
                || persona.Rol == 5
                || persona.Rol == 6
                || persona.Rol == 7
                || persona.Rol == 8
                || persona.Rol == 10
                || persona.Rol == 11
                || persona.Rol == 12
                || persona.Rol == 17
                || persona.Rol == 18
                || persona.Rol == 19
                || persona.Rol == 20
                || persona.Rol == 21
                || persona.Rol == 22
                || persona.Rol == 23
                || persona.Rol == 24
                || persona.Rol == 25
                || persona.Rol == 26
                "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="green lighten-2">mdi-magnify</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Historial Comprobantes
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>

    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona: {},
        };
    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem("persona"));
    },
};
</script>