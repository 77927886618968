import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DetalleFacturaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DetalleFacturaPage",
            ss: ss,
            ajax: {
                "url": ss.indexDetalleFactura(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Numero', name: 'f.Numero', title: 'Numero Factura' },
                { data: 'Curso', name: 'ptc.Curso', title: 'Curso' },
                { data: 'MontoTotal', name: 'df.MontoTotal', title: 'MontoTotal' },
                { data: 'Descuento', name: 'df.Descuento', title: 'Descuento' },
                { data: 'Pagable', name: 'df.Pagable', title: 'Pagable' },
                { data: 'Saldo', name: 'df.Saldo', title: 'Saldo' },
                { data: 'Fecha', name: 'df.Fecha', title: 'Fecha' },
                { data: 'Anulado', name: 'df.Anulado', title: 'Anulado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            facturas: [],
            pagosTesoreiaCurso: [],
            detalleFactura: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {

        getPagosTesoreriaCurso() {
            this.ss.listTipoPago().then(
                (result) => {
                    let response = result.data;
                    this.pagosTesoreiaCurso = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getFacturas() {
            this.ss.listFactura().then(
                (result) => {
                    let response = result.data;
                    this.facturas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newDetalleFactura() {
            this.detalleFactura = {};
            this.$refs['frm-detalleFactura'].show();
        },
        showDetalleFactura(id) {
            this.isLoading=true;
            this.ss.showDetalleFactura(id).then(
                (result) => {
                    let response = result.data;
                    this.detalleFactura = response.data;
                    this.$refs['view-detalleFactura'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editDetalleFactura() {
            this.$refs['frm-detalleFactura'].show();
            this.$refs['view-detalleFactura'].hide();
        },
        cancelDetalleFactura() {
            if (this.detalleFactura.id) {
                this.$refs['view-detalleFactura'].show();
            }
            this.$refs['frm-detalleFactura'].hide();
        },
        saveDetalleFactura() {
            this.ss.storeDetalleFactura(this.detalleFactura).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-detalleFactura'].hide();
                    this.$refs['datatable-detalleFactura'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDetalleFactura() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyDetalleFactura(this.detalleFactura)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-detalleFactura'].hide();
                                this.$refs['datatable-detalleFactura'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-DetalleFactura').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDetalleFactura(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }

        this.getPagosTesoreriaCurso();
        this.getFacturas();
    }
};
