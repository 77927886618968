import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "IngresoPresupuestarioDetallePage",
    data() {
        let ss = new MainService();
        return {
            msg: "IngresoPresupuestarioDetallePage",
            ss: ss,
            ajax: {
                "url": ss.indexIngresoPresupuestarioDetalle(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Reparticion', name: 'Reparticion', title: 'Repartición' },
                { data: 'Rubro', name: 'Rubro', title: 'Rubro' },
                { data: 'CategoriaProgramatica', name: 'CategoriaProgramatica', title: 'Categoria Programática' },
                { data: 'Monto', name: 'Monto', title: 'Monto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ingresoPresupuestarioDetalles: {},
            ingresopresupuestariodetalle: {},
            ingresosPresupuestarios: [],
            reparticiones: [],
            rubros: [],
            categoriasProgramaticas: [],
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        getIngresoPresupuestario() {
            this.ss.listIngresoPresupuestario().then(
                (result) => {
                    let response = result.data;
                    this.ingresosPresupuestarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRubro() {
            this.ss.listRubro().then(
                (result) => {
                    let response = result.data;
                    this.rubros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramatica() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriasProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newIngresoPresupuestarioDetalle() {
            this.ingresopresupuestariodetalle = {};
            this.$refs['frm-ingresopresupuestariodetalle'].show();
        },
        showIngresoPresupuestarioDetalle(id) {
            this.isLoading=true;
            this.ss.showIngresoPresupuestarioDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.ingresopresupuestariodetalle = response.data;
                    this.$refs['view-ingresopresupuestariodetalle'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editIngresoPresupuestarioDetalle() {
            this.$refs['frm-ingresopresupuestariodetalle'].show();
            this.$refs['view-ingresopresupuestariodetalle'].hide();
        },
        cancelIngresoPresupuestarioDetalle() {
            if (this.ingresopresupuestariodetalle.id) {
                this.$refs['view-ingresopresupuestariodetalle'].show();
            }
            this.$refs['frm-ingresopresupuestariodetalle'].hide();
        },
        saveIngresoPresupuestarioDetalle() {
            this.ss.storeIngresoPresupuestarioDetalle(this.ingresopresupuestariodetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-ingresopresupuestariodetalle'].hide();
                    this.$refs['datatable-ingresopresupuestariodetalle'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteIngresoPresupuestarioDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyIngresoPresupuestarioDetalle(this.ingresopresupuestariodetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ingresopresupuestariodetalle'].hide();
                                this.$refs['datatable-ingresopresupuestariodetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-IngresoPresupuestarioDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showIngresoPresupuestarioDetalle(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getIngresoPresupuestario();
            this.getReparticion();
            this.getRubro();
            this.getCategoriaProgramatica();
        }
    }
};
