import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FacturaEgresoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "FacturaEgresoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexFacturaEgreso(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'c.UnidadAcademica', title: 'Unidad Gerencial' },
                { data: 'Concatenado', name: 'c.Concatenado', title: 'Comprobante Contable' },
                 { data: 'Mes', name: 'ch.Mes', title: ' Mes Factura', render:function(data, type, row){
                    if(row.Mes==13)
                        return 'Por adjudicar';
                    else
                        return row.Mes;
                    
                   }  },
                { data: 'FechaFactura', name: 'ch.FechaFactura', title: 'Fecha Factura' },
                { data: 'Nit', name: 'ch.Nit', title: 'Nit' },
                { data: 'RazonSocial', name: 'ch.RazonSocial', title: 'Razon Social' },
                { data: 'NumeroFactura', name: 'ch.NumeroFactura', title: 'N Factura' },
               // { data: 'NumeroAutorizacion', name: 'ch.NumeroAutorizacion', title: 'N Autorizacion' },
                { data: 'ImporteTotal', name: 'ch.ImporteTotal', title: 'Importe Total' },
                { data: 'Aprobado', name: 'Aprobado', title: 'Estado', render:function(data, type, row){
                    if (row.Aprobado==0){
                        if(row.Estado==1){
                            return '<i class="fa fa-times text-warning font-italic font-weight-bold "> Pendiente</i>'
                        }else{
                            return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>';}
                        }
                       
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';}
                } },
             
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            devengados: [],
            facturaEgresos: [],
            facturaEgreso: {},
            isLoading: false,
            errorBag: {},
            unidadAcademicas: [],
            comprobanteContableDetalles:[]
          
        };
    },
    methods: {
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getComprobanteContable() {
            this.ss.listComprobanteContable({'TipoComprobante':3}).then(
                (result) => {
                    let response = result.data;
                    this.devengados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newFacturaEgreso() {
            this.facturaEgreso = {};
            this.$refs['frm-facturaEgreso'].show();
        },
        showFacturaEgreso(id) {
            this.isLoading=true;
            this.ss.showFacturaEgreso(id).then(
                (result) => {
                    let response = result.data;
                    this.facturaEgreso = response.data;
                    this.facturaEgreso.ComprobanteContable = response.data.comprobante_contable_detalle.ComprobanteContable;
                    this.$refs['view-facturaEgreso'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editFacturaEgreso() {
            this.$refs['frm-facturaEgreso'].show();
            this.$refs['view-facturaEgreso'].hide();
        },
        cancelFacturaEgreso() {
            if (this.facturaEgreso.id) {
                this.$refs['view-facturaEgreso'].show();
            }
            this.$refs['frm-facturaEgreso'].hide();
        },
        saveFacturaEgreso() {
            console.log(this.facturaEgreso);
            this.facturaEgreso.Estado=2;
            this.ss.storeFacturaEgreso(this.facturaEgreso).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-facturaEgreso'].hide();
                    this.$refs['datatable-facturaEgreso'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFacturaEgreso() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFacturaEgreso(this.facturaEgreso)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-facturaEgreso'].hide();
                                this.$refs['datatable-facturaEgreso'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        calcular(a){
           
          
                console.log("Combustible",a);
                if(a==true){
                    this.facturaEgreso.ImporteNoSujetoCreditoFiscal= (this.facturaEgreso.ImporteTotal*30)/100;
                    this.facturaEgreso.Subtotal= this.facturaEgreso.ImporteTotal-this.facturaEgreso.ImporteNoSujetoCreditoFiscal;
                    this.facturaEgreso.DescuentosBonificacionesRebajasObtenidas=0;
                     this.facturaEgreso.ImporteBaseCreditoFiscal= this.facturaEgreso.Subtotal;
                     this.facturaEgreso.CreditoFiscal= this.facturaEgreso.Subtotal*0.13;
                }
                    
                else{
                this.facturaEgreso.ImporteNoSujetoCreditoFiscal=0;
                this.facturaEgreso.Subtotal=this.facturaEgreso.ImporteTotal;
                this.facturaEgreso.DescuentosBonificacionesRebajasObtenidas=0;
                this.facturaEgreso.ImporteBaseCreditoFiscal=this.facturaEgreso.ImporteTotal;
                this.facturaEgreso.CreditoFiscal=this.facturaEgreso.ImporteTotal*0.13;
                }
                
            
        },
        draw() {
            window.$('.btn-datatable-FacturaEgreso').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFacturaEgreso(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    filters: {
        intToMonth: function (value) {
            if (!value) return '';
            let mes = "";
            if (value == 1) {
                mes = "Enero"
            } else if (value == 2) {
                mes = "Febrero"
            } else if (value == 3) {
                mes = "Marzo"
            } else if (value == 4) {
                mes = "Abril"
            } else if (value == 5) {
                mes = "Mayo"
            } else if (value == 6) {
                mes = "Junio"
            } else if (value == 7) {
                mes = "Julio"
            } else if (value == 8) {
                mes = "Agosto"
            } else if (value == 9) {
                mes = "Septiembre"
            } else if (value == 10) {
                mes = "Octubre"
            } else if (value == 11) {
                mes = "Noviembre"
            } else if (value == 12) {
                mes = "Diciembre"
            }
            else if (value == 13) {
                mes = "Por adjudicar"
            }
            return mes;
        }
    },   
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getComprobanteContable();
            
        }
    }
};
