import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "InscripcionesPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InscripcionesPage",
            ss: ss,
            ajax: {
                "url": ss.indexEstudiante(),
                headers: ss.getToken(),
            },
            ajaxInscripcionPagoTesoreria: {
                "url": ss.indexInscripcionPagoTesoreria(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Nombre del Estudiante' },
                { data: 'Carnet', name: 'Carnet', title: 'Carnet' },
                // { data: 'EmailPersonal', name: 'EmailPersonal', title: 'Email Personal' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Académmica' },
                { data: 'EmailInstitucional', name: 'EmailInstitucional', title: 'Email Institucional' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Inscribir',
                    searchable: false
                },
                {
                    data: 'facturar',
                    orderable: false,
                    title: 'Facturar',
                    searchable: false
                },
            ],
            columnsInscripcionPagoTesoreria: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'HPagoTesoreria', name: 'HPagoTesoreria', title: 'Pago Tesoreria' },
                // { data: 'Nombres', name: 'Nombres', title: 'Nombres' },
                // { data: 'ApellidoPaterno', name: 'ApellidoPaterno', title: 'ApellidoPaterno' },
                // { data: 'ApellidoMaterno', name: 'ApellidoMaterno', title: 'ApellidoMaterno' },
                { data: 'MontoTotal', name: 'MontoTotal', title: 'MontoTotal' },
                { data: 'Descuento', name: 'Descuento', title: 'Descuento %' },
                { data: 'Pagable', name: 'Pagable', title: 'Pagable' },
                { data: 'Saldo', name: 'Saldo', title: 'Saldo' },
                { data: 'Cantidad', name: 'Cantidad', title: 'Cantidad' },
                { data: 'Pagado', name: 'Pagado', title: 'Pagado' },
                // {
                //     data: 'action',
                //     orderable: false,
                //     title: 'Acciones',
                //     searchable: false
                // },
                {
                    data: 'eliminar',
                    orderable: false,
                    title: 'Borrar',
                    searchable: false
                },
            ],
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            itemstesoreria: [],
            estudiante: {},
            inscripcion:{},
            isLoading: false,
            errorBag: {},
            unidadacademicas: [],
            cursos: [],
            periodosacademicos: [],
            decuentos: [],
            carreras:[],
            nivelesacademicos: [],
            grados: [],
            cuentas: [],
            subcuentas: [],
            pagospendientes:[],
            pagospendientesFields: ['selected', 'Curso', 'Codigo','PagoTesoreria', 'Saldo', 'Monto'],
            pagosafacturar:[],
            pagosafacturarFields: ['selected', 'Curso', 'Codigo','PagoTesoreria', 'MontoFacturar', 'Saldo', 'Monto'],
            // itemsAFacturar:[],
            // itemsAFacturarFields: ['Item', 'Codigo','PagoTesoreria', 'Saldo', 'Monto', 'Acciones'],
            // itemPagoTesoreria: {},
            pagosElegidos: [],
            pagosAFacturarElegidos: [],
            persona: {},
            fields: ['Fecha', 'TipoPago', 'Monto', 'Numero', 'Acciones'],
            depositos : [],
            dosificaciones : [],
            cajeroDosificaciones: [],
            dosificacion: {},
            factura: {},
            tiposPagos: [],
            nuevoDep:{},
            editarPagos: false,
            BotonPlanPago:"N", //N: normal C: creditaje 
            montoSagaObtenigo:0,
           
            filtrosPagoTesoreria: {},
            filtrosPagosEstudiante: {},
            pagosTesoreria: [],
            pagoTesoreria: {},
            search: null,
            cantidadPagoTesoreria: 1,
        };
    },
    methods: {
        
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getNivelAcademico() {
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.nivelesacademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCursos() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPeriodoAcademico() {
            this.ss.listPeriodoAcademico().then(
                (result) => {
                    let response = result.data;
                    this.periodosacademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getDescuentos() {
            this.ss.listPlanPago().then(
                (result) => {
                    let response = result.data;
                    this.decuentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCarreras() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGrado() {
            this.ss.listGrado().then(
                (result) => {
                    let response = result.data;
                    this.grados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPagosEstudiante() {
            let idEstudiante = this.estudiante.id;
            let params = {
                Estudiante : idEstudiante,
                Pagado : false
            }
            this.ss.listPagosInscripcionPagoTesoreria(params).then(
                (result) => {
                    let response = result.data;
                    this.pagospendientes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTiposPagos() {
            this.ss.listTipoPago().then(
                (result) => {
                    let response = result.data;
                    this.tiposPagos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDosificaciones() {
            this.ss.listDosificacion().then(
                (result) => {
                    let response = result.data;
                    this.dosificaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCajeroDosificaciones() {
            this.ss.listCajeroDosificaciones(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.cajeroDosificaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        selecionarPagos(items) {
            this.pagosElegidos = items
        },

        selecionarAFacturarPagos(items) {
            this.pagosAFacturarElegidos = items
        },

        agregarAPagosAFacturar(){
            this.pagosElegidos.forEach(item =>{
                if(this.pagospendientes.includes(item)){          //checking weather array contain the id
                    console.log("Incluye", item);                //adding to array because value doesnt exists
                    let newObj = { ...item };   
                    newObj.MontoFacturar = newObj.Saldo;     
                    this.pagosafacturar.push(newObj);
                    this.pagospendientes = this.pagospendientes.filter(element =>{
                        if(element.id == item.id){
                            return false;
                        }else{
                            return true;
                        }
                    });
                    //this.pagospendientes.splice(this.pagospendientes.indexOf(item), 1); 
                }
            })
        },
        quitarPagosAFacturar(){
            
            // this.pagosafacturar.forEach(item =>{
            //     if(this.pagosafacturar.includes(item)){          //checking weather array contain the id
            //         console.log("Incluye", item);             //adding to array because value doesnt exists
            //         this.pagospendientes.push(item);
            //         this.pagosafacturar = this.pagosafacturar.filter(element =>{
            //             if(element.id == item.id){
            //                 return false;
            //             }else{
            //                 return true;
            //             }
            //         });
            //         // this.pagosafacturar.splice(this.pagosafacturar.indexOf(item), 1); 
            //     }
            // })
            this.pagosAFacturarElegidos.forEach(item =>{
                if(this.pagosafacturar.includes(item)){          //checking weather array contain the id
                    console.log("Incluye", item);             //adding to array because value doesnt exists
                    this.pagospendientes.push(item);
                    this.pagosafacturar = this.pagosafacturar.filter(element =>{
                        if(element.id == item.id){
                            return false;
                        }else{
                            return true;
                        }
                    });
                    // this.pagosafacturar.splice(this.pagosafacturar.indexOf(item), 1); 
                }
            })
        },
        ////////////////////////////////////////////////////////////
        newInscripcion() {
            this.estudiante = {};
            this.$refs['frm-estudiante'].show();
        },
        showEstudiante(id) {
            this.isLoading=true;
            this.ss.showEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.estudiante = response.data;
                    this.$refs['view-inscripcion'].show();
                    this.isLoading=false;
                    this.aplicarFiltroEstudiante();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        aplicarFiltroEstudiante(){
            console.log("se aplican los filtros");
            let idEstudiante = this.estudiante.id;
            let filtrosLocal = this.filtrosPagosEstudiante;
            this.ajaxInscripcionPagoTesoreria.data = function (d) {
                d.Estudiante = idEstudiante;
                d.Pagado = filtrosLocal.Pagado;
            }
            this.$refs['datatable-inscripcionpagotesoreria'].reloadAjax(this.ajaxInscripcionPagoTesoreria);

        },
        anadirdepositos(){
            // this.depositos.push({ isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' });
            this.depositos.push(this.nuevoDep);
            this.nuevoDep={};
        },

        actualizarNit(){
            this.ss.storeEstudiante(this.estudiante).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    
                    this.estudiante=response.data;
                    // this.showEstudiante(this.estudiante.id);
                    // this.saveInscripcion();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteObjetivo(datos){
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        console.log("borrar deposito",datos);
                        console.log(this.depositos.indexOf(datos));
                        let indice=this.depositos.indexOf(datos);
                        this.depositos.splice(indice, 1)
                    } else {
                        console.log("cancelar");

                    }
                });

        },
        showFacturar(id) {
            this.isLoading=true;
            this.ss.showEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.estudiante = response.data;
                    this.$refs['view-facturar'].show();
                    this.isLoading=false;
                    //this.aplicarFiltroEstudiante();
                    this.getPagosEstudiante();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });

            console.log("facturar",id);
            ///////////////////////////////////
            // agarrar la fecha de hoy 
            // para la fecha de la factura
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth()+1; //Enero es 0!
            var yyyy = today.getFullYear();
            if(dd<10) {
                dd = '0'+dd
            }
            if(mm<10) {
                mm = '0'+mm
            }
            var fechaLiteral = yyyy + '-' + mm + '-' + dd;
            console.log(fechaLiteral);
            this.factura.FechaFactura = fechaLiteral;
            ///////////////////////////////////
            this.$refs['view-facturar'].show();

        },

        editInscripcion() {
            this.$refs['frm-inscripcion'].show();
            this.$refs['view-inscripcion'].hide();
        },
        cancelInscripcion() {
            if (this.inscripcion.id) {
                this.$refs['view-inscripcion'].show();
            }
            this.$refs['frm-inscripcion'].hide();
        },
        saveEstudiante(tipo) {    
            
            if(this.inscripcion.UnidadAcademica==null){
                this.$swal({
                    title: "Debe seleccionar la Unidad Gerencial para poder continuar con la inscripción",
                    text: "Selecione una Unidad Academica",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            if(this.inscripcion.Especialidad==null){
                this.$swal({
                    title: "Debe seleccionar la Carrera para poder continuar con la inscripción",
                    text: "Selecione una Carrera",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            if(this.inscripcion.Curso==null){
                this.$swal({
                    title: "Debe seleccionar un Curso para poder continuar con la inscripción",
                    text: "Selecione un Curso",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            if(this.inscripcion.PeriodoAcademico==null){
                this.$swal({
                    title: "Debe seleccionar el Periodo Académico para poder continuar con la inscripción",
                    text: "Selecione un Periodo Académico",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            
            this.BotonPlanPago=tipo;
            if(this.inscripcion.Especialidad){
                let carrera = this.carreras.filter(e => {
                    return e.id == this.inscripcion.Especialidad;
                })[0];
                // console.log("Esta es la carrera", this.estudiante.Especialidad);
                this.estudiante.HEspecialidad =  carrera.Especialidad;
                this.estudiante.Especialidad =  carrera.id;
            }

            if(this.inscripcion.Curso){
                let curso = this.cursos.filter(e => {
                    return e.id == this.inscripcion.Curso;
                })[0];
                this.estudiante.HCurso =  curso.Curso;
                this.estudiante.Curso =  curso.id;
            }

            if(this.inscripcion.PeriodoAcademico){
                let peraca = this.periodosacademicos.filter(e => {
                    return e.id == this.inscripcion.PeriodoAcademico;
                })[0];
                this.estudiante.HPeriodoAcademico =  peraca.PeriodoAcademico;
                this.estudiante.PeriodoAcademico =  peraca.id;
            }

            this.estudiante.UnidadAcademica=this.inscripcion.UnidadAcademica;
            this.estudiante.PeriodoAcademico=this.inscripcion.PeriodoAcademico;

            this.ss.storeEstudiante(this.estudiante).then(
            (result) => {
                console.log(result);
                let response = result.data;
                
                this.estudiante=response.data;
                this.showEstudiante(this.estudiante.id);
                this.saveInscripcion();
                
            })
            .catch((error) => {
                this.errorBag = error.response.data.errors;
                this.$bvToast.toast(
                    'Problema al Guardar el Registro, favor verificar los Datos',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            });

            
        },

        saveInscripcion(){

            this.inscripcion.Estudiante= this.estudiante.id;
            this.inscripcion.ApellidoPaterno=this.estudiante.ApellidoPaterno;
            this.inscripcion.ApellidoMaterno=this.estudiante.ApellidoMaterno;
            this.inscripcion.Nombres=this.estudiante.Nombres;

            if(this.inscripcion.PlanPago){
                let planPago = this.decuentos.filter(e => {
                    return e.id == this.inscripcion.PlanPago;
                })[0];
                this.inscripcion.HPlanPago =  planPago.PlanPago;
                this.inscripcion.PorcentajeDescuento =  planPago.Porcentaje;
            }

            if(this.inscripcion.Curso){
                let curso = this.cursos.filter(e => {
                    return e.id == this.inscripcion.Curso;
                })[0];
                this.inscripcion.HCurso =  curso.Curso;
            }

            if(this.inscripcion.Especialidad){
                let especialidad = this.carreras.filter(e => {
                    return e.id == this.inscripcion.Especialidad;
                })[0];
                this.inscripcion.HEspecialidad =  especialidad.Especialidad;
            }

            if(this.inscripcion.NivelAcademico){
                let nivelaca = this.nivelesacademicos.filter(e => {
                    return e.id == this.inscripcion.NivelAcademico;
                })[0];
                this.inscripcion.HNivelAcademico =  nivelaca.NivelAcademico;
            }

            

            this.ss.storeInscripcion(this.inscripcion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.inscripcion = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )

                    if(this.BotonPlanPago == 'N'){
                        this.saveInscripcionPagoTesoreria();
                    }

                    
                    if(this.BotonPlanPago == 'C'){
                        this.saveInscripcionPagoTesoreriaCred();
                    }

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    // console.log(response);
                    // this.$refs['frm-inscripcion'].hide();
                    // this.$refs['datatable-inscripcion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteiInscripcion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyInscripcion(this.inscripcion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-inscripcion'].hide();
                                this.$refs['datatable-inscripcion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        saveInscripcionPagoTesoreria(){
            console.log("Se esta guardando save inscripcion pago tesoreria");
            let params = {
                Inscripcion: this.inscripcion.id,
                Curso: this.inscripcion.Curso,
                Estudiante: this.estudiante.id,
            };
            console.log("Estos son los params de inscripcion pago tesoreria", params);

            this.ss.storeInscripcionCurso(params).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.inscripcion = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )

                    this.$refs['datatable-inscripcionpagotesoreria'].reload();
                    this.inscripcion={};
                    this.montoSagaObtenigo=0;

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    // console.log(response);
                    // this.$refs['frm-inscripcion'].hide();
                    // this.$refs['datatable-inscripcion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteiInscripcionPagoTesoreria(id) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyInscripcionPagoTesoreria({"id":id})
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['datatable-inscripcionpagotesoreria'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },


        obtenerMontoSaga(){

            if(this.inscripcion.UnidadAcademica==null){
                this.$swal({
                    title: "Debe seleccionar la Unidad Gerencial para poder continuar con la inscripción",
                    text: "Selecione una Unidad Academica",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            if(this.inscripcion.Especialidad==null){
                this.$swal({
                    title: "Debe seleccionar la Carrera para poder continuar con la inscripción",
                    text: "Selecione una Carrera",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            if(this.inscripcion.Curso==null){
                this.$swal({
                    title: "Debe seleccionar un Curso para poder continuar con la inscripción",
                    text: "Selecione un Curso",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            if(this.inscripcion.PeriodoAcademico==null){
                this.$swal({
                    title: "Debe seleccionar el Periodo Académico para poder continuar con la inscripción",
                    text: "Selecione un Periodo Académico",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }

            this.montoSagaObtenigo=0;
            this.estudiante.PeriodoAcademico=this.inscripcion.PeriodoAcademico;
            this.ss.consultarMontoSaga(this.estudiante).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log(response.data[0]);
                    // this.estudiante=response.data;
                    if(response.data[0].monto==null){
                        this.$swal({
                            title: "El estudiante no tiene un monto en el SAGA para poder calcular su plan de pagos por creditaje",
                            text: "El estudiante debe primero inscribirse por el SAGA",
                            icon: "warning",
                            buttons: false,
                            dangerMode: true,
                        })
                        return;
                    }

                    console.log("monto",response.data[0].monto);

                    this.montoSagaObtenigo=response.data[0].monto;
                    this.saveEstudiante('C');
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        saveInscripcionPagoTesoreriaCred(){
            console.log("Se esta guardando save inscripcion pago tesoreria");
            let params = {
                Inscripcion: this.inscripcion.id,
                Curso: this.inscripcion.Curso,
                Monto: this.montoSagaObtenigo,
                Estudiante: this.estudiante.id
            };
            console.log("Estos son los params de inscripcion pago tesoreria", params);

            this.ss.storeinscripcionCursoCreditaje(params).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.inscripcion = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )

                    this.$refs['datatable-inscripcionpagotesoreria'].reload();
                    this.inscripcion={};
                    this.montoSagaObtenigo=0;

                    //this.$refs['view-consulta'].show(); //para volver al modal
                    // console.log(response);
                    // this.$refs['frm-inscripcion'].hide();
                    // this.$refs['datatable-inscripcion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        facturarPagos() {

            if(this.totalMontoFacturar != this.totalDepositado){
                this.$swal({
                    title: "El total a facturar no coincide con el total depositado!",
                    text: "Corrija los pagos",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }

            if(!this.factura.Dosificacion){
                this.$swal({
                    title: "Debe seleccionar una dosificacion!",
                    text: "Falta una dosificacion",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }

            let params = {
                //los pagos que se van a facturar, se van a convertir en detalle factura
                InscripcionPagoTesoreria: this.pagosafacturar,
                //los depositos que se realizaron
                Depositos: this.depositos,
                //la dosificacion que se eligio para realizar esta facturacion
                Dosificacion: this.factura.Dosificacion,
                //el id del estudiante
                Estudiante: this.estudiante.id,
                
                ClienteNit: this.estudiante.FacturaNit,
                ClienteNombre: this.estudiante.FacturaNombre,
                FechaFactura: this.factura.FechaFactura,
                AlumnoNombre: this.estudiante.Persona,
                AlumnoCarrera: this.estudiante.Especialidad,
                ApellidoPaterno: this.estudiante.ApellidoPaterno,
                ApellidoMaterno: this.estudiante.ApellidoMaterno,
                Nombres: this.estudiante.Nombres,
                CI: this.estudiante.Carnet,
            };

            console.log(params);

            this.ss.facturarPagos(params)
                .then((result) => {
                    let response = result.data;
                    console.log(response);
                    this.pagosafacturar = [];
                    this.depositos = [];
                    // this.itemsAFacturar = [];
                    this.getPagosEstudiante();
                    if(response.factura.id){
                        this.imprimirFactura(response.factura.id);
                    }
                })
                .catch(error => {
                    this.errorBag = error.response.data.errors;
                    let html = "";
                    for (const key in this.errorBag) {
                        html += `<span class="text-danger"><b>${key}</b>: ${this.errorBag[key]} </span><br>`
                    }
                    this.$swal({
                        icon: 'error',
                        title: 'Error...',
                        text: 'Ocurrieron errores al facturar',
                        html: html,
                    });
                    console.log(error);
                })
        },
        imprimirFactura(idFactura) {

            this.ss.imprimirFactura(idFactura)
                .then((result) => {
                    let response = result.data;
                    window.open(this.rutaApp+response.data.url);
                    console.log(response, "Se imprimio la factura");
                })
                .catch(error => {
                    console.log(error);
                })
        },
        draw() {
            window.$('.btn-datatable-Estudiante').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showEstudiante(data);
            });

            window.$('.btn-datatable-Facturar').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFacturar(data);
            });
        },
        drawInscripcionPagoTesoreria(){
            window.$('.btn-datatable-inscripcionpagotesoreria').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showEstudiante(data);
            });

            window.$('.btn-datatable-InscripcionPagoTesoreria-borrar').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.deleteiInscripcionPagoTesoreria(data);
            });
            
        },
        editarPagosAFacturar(){
            this.editarPagos = !this.editarPagos;
            // if(this.editarPagos){
            //     this.pagosafacturarFields = ['selected', 'Curso', 'Codigo','PagoTesoreria', 'MontoFacturar', 'Saldo', 'Monto'];
            // }else{
            //     this.pagosafacturarFields = ['selected', 'Curso', 'Codigo','PagoTesoreria', 'Saldo', 'Monto'];
            // }
        },
        agregarPagoDemasia(){
            this.$refs['frm-pagoDemasia'].show();
            console.log("Pago en demasia");
        },
        savePagoDemasia(){
            //itemsAFacturarFields: ['Item', 'Codigo','PagoTesoreria', 'Saldo', 'Monto'],
            this.ss.obtenerPagoDemasiaPagoTesoreria()
                .then((result) => {
                    let response = result.data;

                    console.log("Este es el pago en demasia", response);
                })
                .catch(error => {
                    console.log(error);
                })

            // this.itemPagoTesoreria.Item = "Pago en demasia";
            // this.itemPagoTesoreria.Codigo = "123456";
            // this.itemPagoTesoreria.PagoTesoreria = "Nombre Pago Tesoreria";
            // this.itemPagoTesoreria.Monto = this.itemPagoTesoreria.Saldo;
            // this.itemsAFacturar.push(this.itemPagoTesoreria);
            this.$refs['frm-pagoDemasia'].hide();
        },
        // editarPagoDemasia(item){
        //     console.log("Editar pago demasia");
        //     this.itemPagoTesoreria = item;
        //     this.$refs['frm-pagoDemasia'].show();
        // },
        borrarPagoDemasia(item){
            console.log("Editar pago demasia", item);
            // const index = this.itemsAFacturar.indexOf(item);
            // if (index > -1) {
            //     this.itemsAFacturar.splice(index, 1);
            // }
        },
        agregarPagoTesoreria(){
            console.log("Agregar Pagos");
            let params = {
                PagoTesoreria: this.pagoTesoreria,
                Estudiante: this.estudiante.id,
                Cantidad: this.cantidadPagoTesoreria,
            };

            this.ss.agregarPagoTesoreria(params).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )

                    this.$refs['datatable-inscripcionpagotesoreria'].reload();
                    this.inscripcion={};
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        select2PagoTesoreria(){
            this.isLoading = true;
            this.filtrosPagoTesoreria.q = this.search;
            this.ss.select2PagoTesoreria(this.filtrosPagoTesoreria).then((res) => {
                this.pagosTesoreria = res.data.items;
                this.isLoading = false;
                return;
            })
        }
    },
    watch: {
        search (val) {
            console.log(val);
            this.select2PagoTesoreria();
        },
    },
    components: {
        dataTable,
        Loader
    },
    computed: {
        // totalProgramados: function(){
        //     console.log("se llamo al computed");
        //     let suma = 0;
        //     this.pagosafacturar.forEach(item =>{
        //         if(item.Saldo){
        //             suma = suma + parseInt(item.Saldo);
        //         }
        //     });
        //     return suma;
        // },
        // totalItems: function(){
        //     console.log("se llamo al computed");
        //     let suma = 0;
        //     this.itemsAFacturar.forEach(item =>{
        //         if(item.Saldo){
        //             suma = suma + parseInt(item.Saldo);
        //         }
        //     });
        //     return suma;
        // },
        // totalAPagar: function(){
        //     return this.totalProgramados + this.totalItems;
        // },
        totalMontoFacturar: function(){
            console.log("se llamo al computed");
            let suma = 0;
            this.pagosafacturar.forEach(item =>{
                if(item.MontoFacturar){
                    suma = suma + parseInt(item.MontoFacturar);
                }
            });
            return suma;
        },
        totalDepositado: function () {
            let suma = 0;
            this.depositos.forEach(item =>{
                if(item.Monto){
                    suma = suma + parseInt(item.Monto);
                }
            });
            return suma;
        },

    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.persona = persona;
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getUnidadAcademica();
            this.getCursos();
            this.getPeriodoAcademico();
            this.getGrado();
            this.getNivelAcademico();
            this.getDescuentos();
            this.getCarreras();
            this.getTiposPagos();
            //this.getDosificaciones();
            this.getCajeroDosificaciones();
            this.select2PagoTesoreria();
        }
        
    }
};
