import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexCajeros(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'Rol', name: 'Rol', title: 'Rol' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            
            
           
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            cajero: {},
            personaDosif: {},
            dosificaciones : [],
            cajeroDosificaciones:[],
            fields: ['NumeroAutorizacion', 'Descripcion', 'FechaLimiteEmision', 'UnidadAcademica', 'Acciones'],

            
            
        };
    },
    methods: {
        

        showCajero(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.cajero = response.data;
                    this.$refs['view-cajero'].show();
                    this.isLoading=false;
                    //this.getCajeroDosificaciones();
                    this.getAntecedente(id);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
       
        cancelCajero() {
            this.$refs['view-cajero'].hide();
        },

        deleteObjetivo(id) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCajeroDosificacion(id)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.getCajeroDosificaciones();
                                this.getDosificaciones();


                                
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        getDosificaciones() {
            this.ss.listDosificacion().then(
                (result) => {
                    let response = result.data;
                    this.dosificaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        getCajeroDosificaciones() {
            this.ss.listCajeroDosificaciones(this.cajero.id).then(
                (result) => {
                    let response = result.data;
                    this.cajeroDosificaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        // deleteObjetivo(id){
        //     console.log("borrar",id);
        // },
        savePersonaDosificacion(){
            if(this.personaDosif.Dosificacion == null){
                this.$swal({
                    title: "Debe seleccionar una Dosificación",
                    icon: "warning",
                    buttons: false,
                    dangerMode: true,
                })
                return;
            }
            // console.log("dosificacion",this.personaDosif.Dosificacion);
            this.personaDosif.Persona=this.cajero.id;

            this.ss.storeCajero(this.personaDosif).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log(response.data);

                    this.$bvToast.toast(
                        'Guardado de forma exitosa',
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.personaDosif={};
                    this.getCajeroDosificaciones();
                    this.getDosificaciones();

                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

        },
        

        draw() {
            window.$('.btn-datatable-Cajero').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCajero(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        
        }else{
            this.getDosificaciones();
        } 
        
    }
};
