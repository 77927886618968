import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FacturaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "FacturaPage",
            ss: ss,
            ajax: {
                "url": ss.indexFactura(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Numero', name: 'Numero', title: 'Numero' },
                { data: 'Autorizacion', name: 'Autorizacion', title: 'Autorizacion' },
                { data: 'CodigoControl', name: 'CodigoControl', title: 'Codigo Control' },
                { data: 'ClienteNombre', name: 'ClienteNombre', title: 'Nombre del Cliente' },
                { data: 'ClienteNit', name: 'ClienteNit', title: 'Nit del Cliente' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'TipoPago', name: 'TipoPago', title: 'Tipo de Pago' },
                { data: 'NumeroAutorizacion', name: 'NumeroAutorizacion', title: 'Numero Autorizacion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            facturas: [],
            factura: {},
            isLoading: false,
            errorBag: {},
            pagostesoreria:[],
            tipospago:[],
            tiposcambio:[],
            dosificaciones:[],
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
        };
    },
    methods: {
        getPagosTesoreria() {
            this.ss.listPagoTesoreria().then(
                (result) => {
                    let response = result.data;
                    this.pagostesoreria = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoPago() {
            this.ss.listTipoPago().then(
                (result) => {
                    let response = result.data;
                    this.tipospago = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoCambio() {
            this.ss.listTipoCambio().then(
                (result) => {
                    let response = result.data;
                    this.tiposcambio = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDosificacion() {
            this.ss.listDosificacion().then(
                (result) => {
                    let response = result.data;
                    this.dosificaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newFactura() {
            this.factura = {};
            this.$refs['frm-factura'].show();
        },
        showFactura(id) {
            this.isLoading=true;
            this.ss.showFactura(id).then(
                (result) => {
                    let response = result.data;
                    this.factura = response.data;
                    this.$refs['view-factura'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editFactura() {
            this.$refs['frm-factura'].show();
            this.$refs['view-factura'].hide();
        },
        cancelFactura() {
            if (this.factura.id) {
                this.$refs['view-factura'].show();
            }
            this.$refs['frm-factura'].hide();
        },
        saveFactura() {
            this.ss.storeFactura(this.factura).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-factura'].hide();
                    this.$refs['datatable-factura'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFactura() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFactura(this.factura)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-factura'].hide();
                                this.$refs['datatable-factura'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        imprimirFactura() {
            
            this.ss.imprimirFactura(this.factura.id)
                .then((result) => {
                    let response = result.data;
                    window.open(this.rutaApp+response.data.url);
                    console.log(response, "Se imprimio la factura");
                })
                .catch(error => {
                    console.log(error);
                })
        },
        anularFactura() {
            
            this.ss.anularFactura({id: this.factura.id})
                .then((result) => {
                    let response = result.data;
                    console.log(response, "Se imprimio la factura");
                })
                .catch(error => {
                    console.log(error);
                })
        },
        draw() {
            window.$('.btn-datatable-Factura').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFactura(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getTipoPago();
            this.getTipoCambio();
            this.getPagosTesoreria();
            this.getDosificacion();
        }
        
    }
};
