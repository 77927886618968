import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import moment from "moment/moment";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FormulariobpPage",
    data() {
        let ss = new MainService();
        return {
            msg: "FormulariobpPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexFormulariobp(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                {
                    data: 'CargoCuenta',
                    name: 'fbp.CargoCuenta',
                    title: 'Tipo',
                    render: function (data, type, row) {
                        if (data === null) {
                            return 'Valor Nulo';
                        } else if (row.CargoCuenta == 0) {
                            return '<i class="fa fa-check text-info font-italic font-weight-bold">Pago Directo</i>';
                        } else if (row.CargoCuenta == 2) {
                            return '<i class="fa fa-check text-success font-italic font-weight-bold">Beneficiario Final</i>';
                        } else if (row.CargoCuenta == 5) {
                            return '<i class="fa fa-check text-warning font-italic font-weight-bold">Desembolso</i>';
                        } else {
                            return '';
                        }
                    },
                    searchable: false
                },
                { data: 'created_at', name: 'fbp.created_at', title: 'Fecha Cre.', render: function (data)
                    {return moment(data).format('MMMM DD YYYY')}
                   },
                { data: 'FuenteFinanciera', name: 'ff.FuenteFinanciera', title: 'F.F.', searchable: false  },
                { data: 'Preventivo', name: 'fbp.Preventivo', title: 'Preventivo', orderable: true },
                { data: 'Formulario', name: 'fbp.Formulario', title: 'Formulario', searchable: false },
                { data: 'Subcuenta', name: 'su.Subcuenta', title: 'Subcuenta', searchable: false },
                { data: 'Fecha', name: 'fbp.Fecha', title: 'Fecha', searchable: false },
                { data: 'MontoTotal', name: 'fbp.MontoTotal', title: 'Monto Total', searchable: false },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición', searchable: false },
                {
                    data: 'Estado', name: 'fbp.Estado', title: 'Estado', render: function (data, type, row) {
                        if (row.Estado == 0) {
                                return '<i class="fa fa-check text-secundary font-italic font-weight-bold "> Sin revisar </i>';
                            } else if (row.Estado == 1) {
                                return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';
                            } else if (row.Estado == 2) {
                                return '<i class="fa fa-times text-danger  font-italic font-weight-bold"> Observado </i>';
                            } else if (row.Estado == 3) {
                            return '<i class="fa fa-check text-warning  font-italic font-weight-bold"> Sin Descargo </i>';
                        }
                    }, searchable: false
                },
                { data: 'Aprobado', name: 'fbp.Aprobado', title: 'Aprobado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                    else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                }, searchable: false
                },
                { data: 'Activo', name: 'fbp.Activo', title: 'Entregado Archivo', render:function(data, type, row){
                    if (row.Activo==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Sin entregar </i></span>';}
                    else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Entregado </i></span>';}
                } , searchable: false
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            formulariobps: [],
            formulariobp: {},
            isLoading: false,
            errorBag: {},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            subcuentas: [],
            searchSubcuenta: "",
            selectedSubCuenta:null,
            reparticiones: [],
            searchReparticion: "",
            selectedReparticion: null,
            fuenteFinancieras: [],
            archivo: {},
            personabenfin: [],
        };
    },
    methods: {
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        abrirModalCierreFormulariobp() {
            this.formulariobp = {};
            this.$refs['frm-Formulariobp'].show();
        },       
        newFormulariobp() {
            this.formulariobp = {};
            this.$refs['frm-formulariobp'].show();
        },
        showFormulariobp(id) {
            this.isLoading=true;
            this.ss.showFormulariobp(id).then(
                (result) => {
                    let response = result.data;
                    this.formulariobp = response.data;
                    this.$refs['view-formulariobp'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editFormulariobp() {
            this.$refs['frm-formulariobp'].show();
            this.$refs['view-formulariobp'].hide();
        },
        cancelFormulariobp() {
            if (this.formulariobp.id) {
                this.$refs['view-formulariobp'].show();
            }
            this.$refs['frm-formulariobp'].hide();
        },
        saveFormulariobp() {
            console.log(this.formulariobp, ' aqui estoy formulario');
            this.ss.storeFormulariobp(this.formulariobp).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-formulariobp'].hide();
                    this.$refs['datatable-formulariobp'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteFormulariobp() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyFormulariobp(this.formulariobp)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-formulariobp'].hide();
                                this.$refs['datatable-formulariobp'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Formulariobp').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showFormulariobp(data);
            });
        },
        abrirModalCierreArchivobp(){
            this.$refs['modalCierreArchivobp'].show();
        },
        saveCierreArchivobp() {
             this.archivo.id = this.formulariobp.id
             this.archivo.FojasArchivo = this.formulariobp.FojasArchivo
             this.archivo.FechaCierreArchivo = this.formulariobp.FechaCierreArchivo
             this.archivo.Observaciones = this.formulariobp.Observaciones
             this.archivo.Activo = this.formulariobp.Cerrado
             this.ss.storeCierreFormulariobp(this.archivo ).then(
                 (result) => {
                     console.log(result);
                     let response = result.data;
                     this.$bvToast.toast(
                         response.msg,
                         {
                             title: 'Correcto',
                             variant: 'success',
                             autoHideDelay: 5000
                         }
                     )
                     this.$refs['modalCierreArchivobp'].hide();
                     this.$refs['datatable-formulariobp'].reload();
                     this.$refs['archivo'].reload();
                 })
                 .catch((error) => {
                     this.errorBag = error.response.data.errors;
                     this.$bvToast.toast(
                         'Problema al Guardar el Registro, favor verificar los Datos',
                         {
                             title: 'Error',
                             variant: 'danger',
                             autoHideDelay: 5000
                         }
                     )
                 });
         },
         printCierreArchivobp() {
             console.log("id al reporte archivo",this.formulariobp.id);
             this.ss.printCierreArchivobp(this.formulariobp.id)
                 .then(
                     result => {
                         let response = result.data;
                         var urlFile = response.data;
                         var dir = this.rutaApp + urlFile.url;
                         window.open(dir);
                     })
                 .catch(error => {
                     console.log(error);
                     this.isLoading = false;
                 })
 
         },
         aprobarFormulariobp() {
            if(this.formulariobp.Aprobado.length===0){
                this.$bvToast.toast(
                    'Registre detalles de formulario.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );
            }else{
                    this.isLoading=true;

                    this.ss.aprobarFormulariobp({'id':this.formulariobp.id, 'Aprobado':true, 'MontoTotal':this.formulariobp.MontoTotal}).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                           this.$refs['datatable-formulariobp'].reload();
                            this.$refs['view-formulariobp'].hide();
                            this.isLoading=false;
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }
        },
         desaprobarFormulariobp() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificación de este Proceso.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarFormulariobp({'id':this.formulariobp.id, 'Aprobado':false}).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                               this.$refs['datatable-formulariobp'].reload();
                               this.$refs['view-formulariobp'].hide();
                               this.isLoading=false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                         //swal("Your imaginary file is safe!");
                    }
                });
        },
        setReset() {
            this.formulariobp = {};
        },
        imprimirPagoDirectoGral() {
            console.log(this.formulariobp);
            this.ss.imprimirPagoDirectoGral(this.formulariobp)
                .then(
                    result => {
                        let response = result.data;
                        console.log(response);
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirDesembolsoGral() {
            console.log(this.formulariobp);
            this.ss.imprimirDesembolsoGral(this.formulariobp)
                .then(
                    result => {
                        let response = result.data;
                        console.log(response);
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirBeneficiarioFinalGral() {
            this.ss.imprimirBeneficiarioFinalGral(this.formulariobp)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirBeneficiarioFinalGralG() {
            this.ss.imprimirBeneficiarioFinalGralG(this.formulariobp)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        imprimirBeneficiarioFinalGralGAprob() {
            this.ss.imprimirBeneficiarioFinalGralGAprob(this.formulariobp)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        imprimirBFGralG() {
            this.ss.imprimirBFGralG(this.formulariobp)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        getPersonaBenFin() {
            this.ss.listPersonaAsignadaBenFin().then(
                (result) => {
                    let response = result.data;
                    this.personabenfin = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
    },
    watch: {
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
        },
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                   
                    this.reparticiones = res.data.items;
                
                } else {
                    this.reparticiones = [];
                }
            })
        }
    },
    components: {
        dataTable,
        Loader
    },
    filters: {
       
        intToTipo: function (value) {
            let tipo = "";
            //0 debe 1 Haber
            if (value == 0) {
                tipo = "Pago Directo"
            } 
             else if (value == 2) {
            tipo = "Beneficiario Final"
        }
            return tipo;
        },

        intToEstado: function (value) {
            let estado = "";
            //0 debe 1 Haber
            if (value == 0) {
                estado = "Sin revisar"
            } 
             else if (value == 1) {
                estado = "Aprobado"
            }
            else if (value == 2) {
                estado = "Observado"
            }
            else if (value == 3) {
                estado = "Sin Descargo"
            }
            return estado;
        },
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        this.getFuenteFinanciera();
        this.getPersonaBenFin();
    }
};
