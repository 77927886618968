import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ArchivoCPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ArchivoCPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexArchivoC(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                {
                    data: 'CargoCuenta', name: 'fbp.CargoCuenta', title: 'Tipo', render: function (data, type, row) {
                        if (row.CargoCuenta == 0) { return '<i class="fa fa-check text-info font-italic font-weight-bold "> Pago Directo</i></span>'; }
                        else { return '<i class="fa fa-check text-success font-italic font-weight-bold"> Beneficiario Final </i></span>'; }
                    }
                },
                { data: 'FuenteFinanciera', name: 'ff.FuenteFinanciera', title: 'F.F.', searchable: false  },
                { data: 'Preventivo', name: 'fbp.Preventivo', title: 'Preventivo' },
                { data: 'Formulario', name: 'fbp.Formulario', title: 'Formulario' },
                { data: 'Subcuenta', name: 'su.Subcuenta', title: 'Subcuenta' },
                { data: 'Fecha', name: 'fbp.Fecha', title: 'Fecha', searchable: false },
                { data: 'MontoTotal', name: 'fbp.MontoTotal', title: 'Monto Total', searchable: false },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                {
                    data: 'Estado', name: 'fbp.Estado', title: 'Estado', render: function (data, type, row) {
                        if (row.Estado == 0) {
                                return '<i class="fa fa-check text-secundary font-italic font-weight-bold "> Sin revisar </i>';
                            } else if (row.Estado == 1) {
                                return '<i class="fa fa-times text-danger  font-italic font-weight-bold"> Aprobado </i>';
                            } else if (row.Estado == 2) {
                                return '<i class="fa fa-check text-warning  font-italic font-weight-bold"> Observado </i>';
                            } else if (row.Estado == 3) {
                            return '<i class="fa fa-check text-warning  font-italic font-weight-bold"> Sin Descargo </i>';
                        }
                    },
                },
                { data: 'Aprobado', name: 'fbp.Aprobado', title: 'Aprobado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                    else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                } 
            },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            archivoc: {},
            isLoading: false,
            errorBag: {},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            egresos: [],
            egreso:{},
            searchEgreso: "",
            placeholderEgreso:"",
            selectedEgreso: null,
            unidadAcademicas: [],

        };
    },
    methods: {
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newArchivoC() {
            this.archivoc = {};
            this.$refs['view-ArchivoC'].show();
        },
        abrirModalCierreArchivoC() {
            this.archivoc = {};
            this.$refs['frm-ArchivoC'].show();
        },
        showArchivoC(id) {
            this.isLoading=true;
            this.ss.showArchivoC(id).then(
                (result) => {
                    let response = result.data;
                    this.archivoc = response.data;
                    this.$refs['view-ArchivoC'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editArchivoC() {
            this.$refs['frm-archivoc'].show();
            this.$refs['view-ArchivoC'].hide();
        },
        cancelArchivoC() {
            if (this.archivoc.id) {
                this.$refs['view-ArchivoC'].show();
            }
            this.$refs['frm-ArchivoC'].hide();
        },
        saveArchivoC() {
            console.log(this.archivoc, ' aqui estoy Archivo');
            this.ss.storeArchivoC(this.archivoc).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-archivoc'].hide();
                    this.$refs['datatable-archivoc'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteArchivoC() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyArchivoC(this.banco)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ArchivoC'].hide();
                                this.$refs['datatable-archivoc'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-ArchivoC').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showArchivoC(data);
            });
        },
        abrirModalCierreArchivobp(){
            this.$refs['modalCierreArchivoC'].show();
        },
        
         imprimirArchivoC() {
             console.log("id al reporte archivo",this.archivoc.id);
             this.ss.imprimirArchivoC(this.archivoc.id)
                 .then(
                     result => {
                         let response = result.data;
                         var urlFile = response.data;
                         var dir = this.rutaApp + urlFile.url;
                         window.open(dir);
                     })
                 .catch(error => {
                     console.log(error);
                     this.isLoading = false;
                 })
         },
         newComprobanteContable() {
            this.comprobanteContable = {};
            this.$refs['frm-comprobanteContable'].show();
            this.placeholderDevengado="Escriba el comprobante Egreso a buscar...";
            
        },
                
    },
    watch: {
        searchEgreso(q) {
            console.log("egreso",q);
            this.egreso.TipoComprobante=4;
            this.egreso.q=q;
            this.ss.select24ComprobanteContable(this.egreso).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.egresos = res.data.items;
                } else {
                    this.egresos = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
   
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
        }
    }
};
