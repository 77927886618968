import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DevengadoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DevengadoPage",
            ss: ss,
            ajax: {
                "url": ss.indexDevengado(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Glosa', name: 'Glosa', title: 'Glosa' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Gerencial' },
                // { data: 'FuenteFinanciera', name: 'ff.FuenteFinanciera', title: 'Fuente Financiera' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            devengados: [],
            devengado: {},
            // comprobantecontabledetalle: {},
            persona: {},
            personas:[],
            Ufvs: [],
            Ufv:{},
            unidadacademicas: [],
            fuentefinancieras: [],
            fuentefinanciera: {},
            ejecuciones: [],
            isLoading: false,
            errorBag: {},

        
            tipocambios: [],


            searchEjecucion: "",
            selectedEjecucion: null,
            selectedSubCuenta: null,

            subcuenta: {},
            subcuentas: [],
            subcuentaElegida: {},
            searchSubcuenta: "",
        };
    },
    methods: {
        
       
        // resetEjecucion(){
            
        //     this.searchEjecucion="";
        //    this.selectedEjecucion=null;
        //     this.devengado.Ejecucion= "";
        // },

        // getEjecucion() {
        //     this.ss.listEjecucion().then(
        //         (result) => {
        //             let response = result.data;
        //             this.ejecuciones = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },

        
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuentefinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        // newComprobanteContableDetalle() {
        //     this.comprobantecontabledetalle = {};
        //     this.comprobantecontabledetalle.ComprobanteContable = this.comprobantecontable.id;
        //     this.getCuenta();
        //     this.getSubcuenta();
        //     this.getReparticion();
        //     this.$refs['frm-comprobantecontabledetalle'].show();
        // },
        // cancelComprobanteContableDetalle() {
        //     if (this.comprobantecontable.id) {
        //         this.$refs['view-comprobantecontable'].show();
        //     }
        //     this.$refs['frm-comprobantecontabledetalle'].hide();
        // },

        // saveComprobanteContableDetalle() {
        //     this.ss.storeComprobanteContableDetalle(this.comprobantecontabledetalle).then(
        //         (result) => {
        //             let response = result.data;
        //             this.$bvToast.toast(
        //                 response.msg,
        //                 {
        //                     title: 'Correcto',
        //                     variant: 'success',
        //                     autoHideDelay: 5000
        //                 }
        //             )
        //             this.$refs['frm-comprobantecontabledetalle'].hide();
        //             this.showComprobanteContable(this.comprobantecontable.id);

        //         })
        //         .catch((error) => {
        //             this.errorBag = error.response.data.errors;
        //             this.$bvToast.toast(
        //                 'Problema al Guardar el Registro, favor verificar los Datos',
        //                 {
        //                     title: 'Error',
        //                     variant: 'danger',
        //                     autoHideDelay: 5000
        //                 }
        //             )
        //         });
        // },
        newDevengado() {
            this.devengado = {};
            this.$refs['frm-devengado'].show();
        },
        showDevengado(id) {
            this.isLoading = true;
            this.ss.showDevengado(id).then(
                (result) => {
                    let response = result.data;
                    this.devengado = response.data;
                    this.$refs['view-devengado'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editDevengado() {
            this.$refs['frm-devengado'].show();
            this.$refs['view-devengado'].hide();
        },
        cancelDevengado() {
            if (this.devengado.id) {
                this.$refs['view-devengado'].show();
            }
            this.$refs['frm-devengado'].hide();
        },
        saveDevengado() {

            if(this.editar==0){
                console.log("longitud", this.devengado.detallesCertificacion.length);//aqui
            if(this.devengado.Ejecucion==null || this.devengado.Monto== null || this.devengado.Cuenta == null ){
                this.$bvToast.toast(
                    'Registre todos los campos',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            }else{
                if(parseFloat(this.devengado.Monto)>parseFloat(this.devengado.montoDisponible) ){
                    this.$bvToast.toast(
                        'El monto no puede ser mayor al disponible.',
                        {
                            title: 'Error',
                            variant: 'warning',
                            autoHideDelay: 5000
                        }
                    );

                }else{
                    if(this.ejecucion.detallesCertificacion.length>1 && parseFloat(this.devengado.Monto)<parseFloat(this.devengado.montoDisponible)){
                        this.$bvToast.toast(
                            'El devengado debe ser por el total certificado.',
                            {
                                title: 'Error',
                                variant: 'warning',
                                autoHideDelay: 5000
                            }
                        );
                    }else{
                        
                        this.ss.storeDevengado(this.devengado).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                //this.$refs['view-consulta'].show(); //para volver al modal
                                console.log(response);
                                if(this.editar==0){
                                    this.showDevengado(response.data.id);
                                    } 
                                this.$refs['frm-devengado'].hide();
                                this.$refs['datatable-devengado'].reload();
                                this.getEjecucion();
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                        }
                    }}
                }else{
                    
                    this.ss.storeDevengado(this.devengado).then(
                        (result) => {
                            console.log(result);
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            //this.$refs['view-consulta'].show(); //para volver al modal
                        console.log(response);
                        if(this.editar==0){
                            this.showDevengado(response.data.id);
                            } 
                        this.$refs['frm-devengado'].hide();
                        this.$refs['datatable-devengado'].reload();
                        this.getEjecucion();
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
                }

            
            // this.ss.storeDevengado(this.devengado).then(
            //     (result) => {
            //         console.log(result);
            //         let response = result.data;
            //         this.$bvToast.toast(
            //             response.msg,
            //             {
            //                 title: 'Correcto',
            //                 variant: 'success',
            //                 autoHideDelay: 5000
            //             }
            //         )
            //         //this.$refs['view-consulta'].show(); //para volver al modal
            //         console.log(response);
            //         this.$refs['frm-devengado'].hide();
            //         this.$refs['datatable-devengado'].reload();
            //     })
            //     .catch((error) => {
            //         this.errorBag = error.response.data.errors;
            //         this.$bvToast.toast(
            //             'Problema al Guardar el Registro, favor verificar los Datos',
            //             {
            //                 title: 'Error',
            //                 variant: 'danger',
            //                 autoHideDelay: 5000
            //             }
            //         )
            //     });
        },
        deleteDevengado() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyDevengado(this.devengado)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-devengado'].hide();
                                this.$refs['datatable-devengado'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        aprobarDevengado() {
           
            if(this.devengado.devengado_detalle.length===0){
                
                this.$bvToast.toast(
                    'Registre detalles de devengado.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            }else{
               
                    this.isLoading=true;
                    this.ss.aprobarDevengado({'id':this.devengado.id, 'Aprobado':true, 'Monto': this.devengado.suma}).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                           this.$refs['datatable-devengado'].reload();
                            // this.$swal.fire({
                            //     position: 'center',
                            //     icon: 'success',
                            //     title: 'Aprobado correctamente.',
                            //     showConfirmButton: false,
                            //     timer: 1500
                            //   });
                            this.$refs['view-devengado'].hide();
                                       
                            this.isLoading=false;
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
                     
                 
              
            }
           
        },
        desaprobarDevengado() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de este devengado.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarDevengado({'id':this.devengado.id, 'Aprobado':false}).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                               this.$refs['datatable-devengado'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-devengado'].hide();
                                           
                                this.isLoading=false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        reporteDevengado() {
            console.log("Reporte de Devengado", this.devengado.id);
            this.ss.imprimirDevengado(this.devengado.id)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    console.log(urlFile);
                    console.log("-------------------------------------------------------");
                    console.log(this.rutaApp);
                    var dir =this.rutaApp + urlFile.url;
                    console.log("-------------------------------------------------------");
                    console.log(dir);
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },

        draw() {
            window.$('.btn-datatable-Devengado').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDevengado(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    watch: {
        
        searchEjecucion(q) {
            
            this.ss.select2Ejecucion(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.ejecuciones = res.data.items;
                } else {
                    this.ejecuciones = [];
                }
            })
           // }
            
        },
        searchSubcuenta(q) {
   
            this.ss.select2Subcuenta(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
            }
            
        

    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.Devengado.Persona = persona.id;
            this.getUnidadAcademica();
            this.getFuenteFinanciera();
            this.getPersona();
            this.getEjecucion();
        }
    }
};
