import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";

window.$ = window.jQuery = require("jquery");

export default {
    name: "ComprobanteDevengadoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ComprobanteDevengadoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexComprobanteContable(),
                headers: ss.getToken(),
                data: function (d) {
                            d.TipoComprobante = 3;
                            }
            },
            
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'd.Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'd.Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Gerencial' },
                // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
               
                // { data: 'Numero', name: 'Numero', title: 'Numero' },
                { data: 'Concatenado', name: 'd.Concatenado', title: 'Nº Documento' },
                { data: 'Preventivo', name: 'd.Preventivo', title: 'Nº Preventivo' },
                { data: 'ComprobanteContable', name: 'd.ComprobanteContable', title: 'Glosa' },
                // { data: 'Anexo', name: 'Anexo', title: 'Anexo' }, 
                // { data: 'Estado', name: 'Estado', title: 'Revertido', render:function(data, type, row){
                //     if (row.Estado==1){ return '<span class="badge bg-warning text-white ">Revertido</span>'; }
                //     else{ return '<span></span>'}
                // } },             
                // { data: 'Aprobado', name: 'Aprobado', title: 'Aprobado' },
                // { data: 'AprobadoPor', name: 'AprobadoPor', title: 'Aprobado Por' },
                { data: 'Aprobado', name: 'd.Aprobado', title: 'Estado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>';}
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';}
                } },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexComprobanteContableDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                    }
    
            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                // { data: 'Poa', name: 'rd.Poa', title: 'N Cer Poa'  },
                { data: 'Reparticion', name: 'tc.Reparticion', title: 'Repartición', render:function(data, type, row){
                    return row.CodRep +' - ' + row.Reparticion;
               }  },
                { data: 'Cuenta', name: 'e.Cuenta', title: 'Cuenta', render:function(data, type, row){
                    return row.CodCuenta +' - ' + row.Cuenta; 
                }  },
                { data: 'Subcuenta', name: 'f.Subcuenta', title: 'Subcuenta', render:function(data, type, row){
                    return row.CodSubcuenta +' - ' + row.Subcuenta; 
                } },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                { data: 'debe', name: 'debe', title: 'Debe' },
                { data: 'haber', name: 'haber', title: 'Haber' },
                { data: 'Factura', name: 'd.Factura', title: 'Factura', render:function(data, type, row){
                    if (row.Factura==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "></i>';}
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"></i>';}
                } },
               // { data: 'ChequeIva', name: 'ChequeIva', title: 'Factura' },
                // { data: 'Monto', name: 'a.Monto', title: 'Monto Destino', render:function(data, type, row){
                //     if (row.Tipo)
                //         return row.Monto;
                //     else
                //         return 0;
                //      }},
                     

                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            
            total:0,
           // saldo:0,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            editarDetalle:0,
            editar:0,
            idFormulacion:0,
            reparticion: {},
            reparticiones: [],
      
            partida: {},
            partidas: [],
            comprobanteContableDetalles: [],
            comprobanteContableDetalle: {},
            personas: [],
            formulaciones: [],
            comprobanteContables: {},
            comprobanteContable: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            unidadAcademicas: [],
            ejecuciones: [],
            tipoCambios: [],
            searchEjecucion: "",
            selectedEjecucion: null,
            searchCuenta: "",
            selectedCuenta: null,
            selectedSubcuenta: null,
            searchSubcuenta: "",
            tipoCambio:{},
            cuentas: [],
            cuenta: {},
            subcuenta: {},
            subcuentas:[],
            factura:false,
            placeholderSubcuenta:"",
            placeholderCuenta:"",
            placeholderEjecucion:"",
            facturaEgreso: {},
            motivo:"",
        };
    },
    methods: {
        // getCuenta() {
        //     this.ss.listCuenta().then(
        //         (result) => {
        //             let response = result.data;
        //             this.cuentas = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },
        // getSubcuenta() {
        //     this.ss.listSubcuenta().then(
        //         (result) => {
        //             let response = result.data;
        //             this.subcuentas = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoCambios() {
            this.ss.listTipoCambio().then(
                (result) => {
                    let response = result.data;
                    this.tipoCambios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
            //{"Fecha":this.comprobanteContable.Fecha}
        },
        setTipoCambio(fecha){

            //console.log("holi :3", fecha);
            this.ss.listTipoCambio({"Fecha":fecha}).then(
                (result) => {
                    let response = result.data;
                    console.log("hol2",response.data[0])
                    if(response.success){
                        this.comprobanteContable.TipoCambio = response.data[0].id;
                        
                        console.log(this.comprobanteContable);
                      //  vm.
                      this.$forceUpdate(); //obliga a actualizar el objeto
                    }
                    else{
                        this.ss.obtenerTipoCambio({"Fecha":fecha}).then(
                            (result) => {
                                let response = result.data;
                               this.TipoCambio=response.data[0];
                               this.TipoCambio.Fecha=fecha;
                           
                               console.log(this.TipoCambio);
                                this.ss.storeTipoCambio(this.TipoCambio).then(
                                    (result) => {
                                        console.log(result);
                                        let response = result.data;
                                        this.$bvToast.toast(
                                            response.msg,
                                            {
                                                title: 'Correcto',
                                                variant: 'success',
                                                autoHideDelay: 5000
                                            }
                                        )
                                        this.getTipoCambios();
                                        console.log(response);
                                     //   console.log(this.TipoCambio.Fecha);
                                        this.setTipoCambio(fecha);
                                       // this.$forceUpdate(); 
                                    })
                                    .catch((error) => {
                                        this.errorBag = error.response.data.errors;
                                        this.$bvToast.toast(
                                            'Problema al Guardar el Registro, favor verificar los Datos',
                                            {
                                                title: 'Error',
                                                variant: 'danger',
                                                autoHideDelay: 5000
                                            }
                                        )
                                    });
                             })
                            .catch((error) => {
                                this.errorBag = error.response;
                                this.$bvToast.toast(
                                    'Problema al obtener el tipo de cambio BCB, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    }
                 
                   
                }
            ).catch(error => {
                console.log(error);
            });
        },
      
        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        saveReparticion() { 
            this.reparticion.UnidadAcademica=this.comprobanteContable.UnidadAcademica;
            console.log(this.reparticion);
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.getReparticion();
                    // this.$refs['datatable-reparticion'].reload();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                
        },
        cancelReparticion() {
            if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            }
            this.$refs['frm-reparticion'].hide();
        },
        getReparticion(id) {
            //console.log("REparticion",id);
            this.ss.listReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newDesaprobar() {
            this.motivo = null;
            this.$refs['frm-comprobanteContableDesprobar'].show();
            
            
        },
        cancelDesaprobar() {
            this.$refs['frm-comprobanteContableDesprobar'].hide();
        },
        newComprobanteContable() {
            this.comprobanteContable = {};
            this.$refs['frm-comprobanteContable'].show();
            this.editar=0;
            this.placeholderEjecucion="Escriba la ejecucion a buscar...";
            
        },
        showComprobanteContable(id) {
            //console.log("id show certi", id);
            this.isLoading=true;
            this.ss.showComprobanteContable(id).then(
                (result) => {
                    let response = result.data;
                    this.comprobanteContable = response.data;
                    this.$refs['view-comprobanteContable'].show();
                    this.isLoading=false;
                    console.log(this.comprobanteContable.UnidadAcademica);
                   // this.idFormulacion=this.comprobanteContable.Formulacion;
                 //   console.log("comprobanteContable Formulacion",  this.comprobanteContable.Formulacion);
                 this.getReparticion(this.comprobanteContable.UnidadAcademica);
                 var dd =  window.$('#datatable-comprobanteContableDetalle').DataTable();
                 console.log("filas", dd.rows());
                    this.ajaxDetalle.data = function(d){
                        d.id = id;
                    }


                    
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editComprobanteContable(a) {
            this.$refs['frm-comprobanteContable'].show();
            this.$refs['view-comprobanteContable'].hide();
            this.editar=1;
            this.placeholderEjecucion=a.ejecucion.Concatenado+': '+a.ejecucion.Ejecucion;
            
        },
        cancelComprobanteContable() {
            if (this.comprobanteContable.id) {
                this.$refs['view-comprobanteContable'].show();
            }
            this.$refs['frm-comprobanteContable'].hide();
        },
        saveComprobanteContable() {
            
            this.comprobanteContable.TipoComprobante=3;
            console.log(" save ComprobanteContable");
            console.log(this.comprobanteContable);
            this.ss.storeComprobanteContable(this.comprobanteContable).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    if(this.editar==0){
                        this.showComprobanteContable(response.data.id);
                       } 
                    console.log(response);
                    this.$refs['frm-comprobanteContable'].hide();
                    this.$refs['datatable-comprobanteContable'].reload();
                })
                .catch((error) => {
                    // this.errorBag = error.response.data.errors;
                    console.log(error);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteComprobanteContable() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyComprobanteContable(this.comprobanteContable)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-comprobanteContable'].hide();
                                this.$refs['datatable-comprobanteContable'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        
        newComprobanteContableDetalle() {
            this.factura=false;
            this.comprobanteContableDetalle = {};
            this.$refs['frm-comprobanteContableDetalle'].show();
            this.editarDetalle=0;
            // this.saldo=0;
            this.placeholderCuenta="Escriba la cuenta...";
            this.placeholderSubcuenta="Escriba la subcuenta...";
        },
        showComprobanteContableDetalle(id) {
            this.isLoading=true;
            this.ss.showComprobanteContableDetalle(id).then(
                (result) => {
                  
                    let response = result.data;
                    this.comprobanteContableDetalle = response.data;
                    console.log("show");
                    console.log(this.comprobanteContableDetalle);
                    this.$refs['view-comprobanteContableDetalle'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editComprobanteContableDetalle(a) {
            this.editarDetalle=1;
            this.$refs['frm-comprobanteContableDetalle'].show();
            this.$refs['view-comprobanteContableDetalle'].hide();
            this.placeholderCuenta=a.cuenta.Codigo+': '+a.cuenta.Cuenta;
            this.placeholderSubcuenta=a.subcuenta.Codigo+': '+a.subcuenta.Subcuenta;
            if(a.Factura)
            this.factura=true;
        else
            this.factura=false;

                
        },
        cancelComprobanteContableDetalle() {
            if (this.comprobanteContableDetalle.id) {
                this.$refs['view-comprobanteContableDetalle'].show();
            }
            this.$refs['frm-comprobanteContableDetalle'].hide();
           // this.saldo=0;
        },
        saveComprobanteContableDetalle() {
            ///
            this.comprobanteContableDetalle.ComprobanteContable=this.comprobanteContable.id;
            console.log(" save ComprobanteContable");
            console.log(this.comprobanteContableDetalle);
            
            if(this.comprobanteContableDetalle.Factura==true && this.comprobanteContableDetalle.Mes==null){
                this.$bvToast.toast(
                    'Seleccione el mes la que pertenece la factura.',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                    )
            
    
                
            }else{
                 if(this.comprobanteContableDetalle.Factura!=true){
                    this.comprobanteContableDetalle.Factura=false;
                    this.comprobanteContableDetalle.Mes="";
                 }
                 
                this.ss.storeComprobanteContableDetalle(this.comprobanteContableDetalle).then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        console.log(response.success);
                        if(response.success){
                            if(this.comprobanteContableDetalle.Factura){
                                console.log(this.facturaEgreso);
                                if(this.editarDetalle){
                                    console.log("factura egreso id", this.comprobanteContableDetalle.id);
                                  // this.ss.showFacturaEgreso({'ComprobanteContableDetalle': this.comprobanteContableDetalle.id}).then(
                                    this.ss.destroyFacturaEgreso({'ComprobanteContableDetalle':this.comprobanteContableDetalle.id}).then(
                                        (result) => {
                                            console.log(result);
                                            let response = result.data;
                                            this.$bvToast.toast(
                                                response.msg,
                                                {
                                                    title: 'Correcto',
                                                    variant: 'success',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                            console.log(response);
                                        })
                                        .catch((error) => {
                                            this.errorBag = error.response.data.errors;
                                            this.$bvToast.toast(
                                                'Problema al Guardar el Registro, favor verificar los Datos',
                                                {
                                                    title: 'Error',
                                                    variant: 'danger',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                        });
                                 }
                                this.facturaEgreso.Estado=1;
                                this.facturaEgreso.ComprobanteContableDetalle=response.data.id;
                                this.facturaEgreso.ComprobanteContable=this.comprobanteContable.id;
                                this.facturaEgreso.UnidadAcademica=this.comprobanteContable.UnidadAcademica;
                                this.facturaEgreso.Mes=this.comprobanteContableDetalle.Mes;
                                this.facturaEgreso.ImporteTotal=this.comprobanteContableDetalle.Monto;
                                this.facturaEgreso.ImporteTotal=this.comprobanteContableDetalle.Monto;
                                this.facturaEgreso.ImporteNoSujetoCreditoFiscal=0;
                                this.facturaEgreso.Subtotal=this.comprobanteContableDetalle.Monto;
                                this.facturaEgreso.DescuentosBonificacionesRebajasObtenidas=0;
                                this.facturaEgreso.ImporteBaseCreditoFiscal=this.comprobanteContableDetalle.Monto
                                this.facturaEgreso.CreditoFiscal=this.comprobanteContableDetalle.Monto*0.13;
                                console.log(this.facturaEgreso);
                                this.ss.storeFacturaEgreso(this.facturaEgreso).then(
                                    (result) => {
                                        console.log(result);
                                        let response = result.data;
                                        this.$bvToast.toast(
                                            response.msg,
                                            {
                                                title: 'Correcto',
                                                variant: 'success',
                                                autoHideDelay: 5000
                                            }
                                        )
                                        console.log(response);
                                    })
                                    .catch((error) => {
                                        this.errorBag = error.response.data.errors;
                                        this.$bvToast.toast(
                                            'Problema al Guardar el Registro, favor verificar los Datos',
                                            {
                                                title: 'Error',
                                                variant: 'danger',
                                                autoHideDelay: 5000
                                            }
                                        )
                                    });
                            }
                            else{
                                 if(this.editarDetalle){
                                    console.log("factura egreso id", this.comprobanteContableDetalle.id);
                                    this.ss.destroyFacturaEgreso({'ComprobanteContableDetalle':this.comprobanteContableDetalle.id}).then(
                                        (result) => {
                                            console.log(result);
                                            let response = result.data;
                                            this.$bvToast.toast(
                                                response.msg,
                                                {
                                                    title: 'Correcto',
                                                    variant: 'success',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                            console.log(response);
                                        })
                                        .catch((error) => {
                                            this.errorBag = error.response.data.errors;
                                            this.$bvToast.toast(
                                                'Problema al Guardar el Registro, favor verificar los Datos',
                                                {
                                                    title: 'Error',
                                                    variant: 'danger',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                        });
                                 }
                             
                            }
                        } 
                      
                        //this.$refs['view-consulta'].show(); //para volver al modal
                        console.log(response);
                        this.$refs['frm-comprobanteContableDetalle'].hide();
                        this.$refs['datatable-comprobanteContableDetalle'].reload();
                        this.showComprobanteContable(this.comprobanteContable.id);
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }

        
        },
        deleteComprobanteContableDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyComprobanteContableDetalle(this.comprobanteContableDetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.showComprobanteContable(this.comprobanteContable.id);
                                this.$refs['view-comprobanteContableDetalle'].hide();
                                this.$refs['datatable-comprobanteContableDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        }, 
        
        cambiopassword() {
            this.$refs['view-comprobanteContable'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-comprobanteContable'].show();
        },
        changePassword() {
            this.password.ComprobanteContable = this.comprobanteContable.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-comprobanteContable'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        showMes(a){
            console.log("Factura",a);
            if(a==true)
                this.factura=true;
            else
                this.factura=false;
        },
      
        aprobarComprobanteContable() {
           
            if(this.comprobanteContable.comprobante_contable_detalle.length===0){
                
                this.$bvToast.toast(
                    'Registre detalles de devengado.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            }else{
                    if(this.comprobanteContable.suma.diferencia!=0){
                        this.$bvToast.toast(
                            'El comprobante no cuadra.',
                            {
                                title: 'Error',
                                variant: 'warning',
                                autoHideDelay: 5000
                            }
                        );

                    }else{

                        this.isLoading=true;
                         console.log(this.comprobanteContable.suma.debe);
                        this.ss.aprobarComprobanteContable({'id':this.comprobanteContable.id, 'Aprobado':true, 'Monto': this.comprobanteContable.suma.debe}).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                               this.$refs['datatable-comprobanteContable'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-comprobanteContable'].hide();
                                           
                                this.isLoading=false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    }
                  
                     
                 
              
            }
           
        },

        
        desaprobarComprobanteContable() {
            console.log("Motivo", this.comprobanteContable.Observacion);
            if(this.comprobanteContable.Observacion.trim()===null) {
                this.$bvToast.toast(
                    'Registre un motivo.',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                    )

             }else {
                this.ss.aprobarComprobanteContable({'id':this.comprobanteContable.id, 'Aprobado':false, 'Observacion':this.comprobanteContable.Observacion }).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                       this.$refs['datatable-comprobanteContable'].reload();
                      
                       this.$refs['frm-comprobanteContableDesprobar'].hide();
                       this.showComprobanteContable(this.comprobanteContable.id);        
                        this.isLoading=false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
             }
           
                       
                  
        },

        reporteComprobanteContable() {
            console.log("reporte comprobanteContable", this.comprobanteContable.id);
            this.ss.imprimirComprobanteContable(this.comprobanteContable.id)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    console.log(urlFile);
                    console.log("-------------------------------------------------------");
                    console.log(this.rutaApp);
                    var dir =this.rutaApp + urlFile.url;
                    console.log("-------------------------------------------------------");
                    console.log(dir);
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        draw() {
            window.$('.btn-datatable-ComprobanteContable').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showComprobanteContable(data);
            });
        },
        drawDetalle() {
          // this.idCertificacion=1;
            window.$('.btn-datatable-ComprobanteContableDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showComprobanteContableDetalle(data);
            });

        }
    },
    watch: {
        searchEjecucion(q) {
           
            this.ss.select2EjecucionDevengado(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                   
                    this.ejecuciones = res.data.items;
                
                } else {
                    this.ejecuciones = [];
                }
            })
            

        },
        searchCuenta(q) {

            this.ss.select2Cuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                   
                    this.cuentas = res.data.items;
                
                } else {
                    this.cuentas = [];
                }
            })
            

        },
        searchSubcuenta(q) {

           // this.item.q=q;
           // this.item.Devengado=1;
            this.ss.select2Subcuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                   
                    this.subcuentas = res.data.items;
                
                } else {
                    this.subcuentas = [];
                }
            })
            

        },

    },
    components: {
        dataTable,
        Loader
    },
    filters: {
        intToMonth: function (value) {
            if (!value) return '';
            let mes = "";
            if (value == 1) {
                mes = "Enero"
            } else if (value == 2) {
                mes = "Febrero"
            } else if (value == 3) {
                mes = "Marzo"
            } else if (value == 4) {
                mes = "Abril"
            } else if (value == 5) {
                mes = "Mayo"
            } else if (value == 6) {
                mes = "Junio"
            } else if (value == 7) {
                mes = "Julio"
            } else if (value == 8) {
                mes = "Agosto"
            } else if (value == 9) {
                mes = "Septiembre"
            } else if (value == 10) {
                mes = "Octubre"
            } else if (value == 11) {
                mes = "Noviembre"
            } else if (value == 12) {
                mes = "Diciembre"
            }
            return mes;
        },
        intToTipo: function (value) {
          //  console.log(value);
            if (value==null) return '';
            let tipo = "";
            //0 debe 1 Haber
            if (value == 0) {
                tipo = "Debe"
            } else if (value == 1) {
                tipo = "Haber"
            }
            return tipo;
        },
        intToFactura: function (value) {
            //  console.log(value);
              if (value==null) return '';
              let tipo = "";
              //0 debe 1 Haber
              if (value == false) {
                  tipo = "No"
              } else if (value == true) {
                  tipo = "Si"
              }
              return tipo;
          }

    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            
            this.getPersona();
           
            this.getUnidadAcademica();
            this.getTipoCambios();
            //this.getCuenta();
          //  this.getSubcuenta();
            this.getReparticion(0);
          //  this.getPartida();
          
            
        }
    }
};
