import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PagoTesoreriaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PagoTesoreriaPage",
            ss: ss,
            ajax: {
                "url": ss.indexPagoTesoreria(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'PagoTesoreria', name: 'PagoTesoreria', title: 'Nombre del Pago' },
                { data: 'Codigo', name: 'Codigo', title: 'Codigo' },
                { data: 'Descuento', name: 'Descuento', title: 'Descuento' },
                { data: 'TipoPago', name: 'TipoPago', title: 'TipoPago' },
                { data: 'NivelAcademico', name: 'NivelAcademico', title: 'Nivel Academico' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            pagotesorerias: [],
            pagotesoreria: {},
            nivelesacademicos: [],
            pagosrequisito: [],
            unidadesacademicas: [],
            tipospago: [],
            categoriasprogramaticas: [],
            reparticiones: [],
            rubros: [],
            cuentas: [],
            subcuentas: [],
            subcuenta: {},
            searchSubcuentas: null,
            searchCuentas: null,
            filtrosSubcuenta: {},
            filtrosCuenta: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        //////////get pagos requisito ////////////////////
        getNivelAcademico() {
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.nivelesacademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get pagos requisito ////////////////////
        getPagoRequisito() {
            this.ss.listPagoTesoreria().then(
                (result) => {
                    let response = result.data;
                    this.pagosrequisito = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get unidad academica ////////////////////
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadesacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get tipospago ////////////////////
        getTipoPago() {
            this.ss.listTipoPago().then(
                (result) => {
                    let response = result.data;
                    this.tipospago = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get categoria programatica ////////////////////
        getCategoriaProgramatica() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriasprogramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get reparticiones ////////////////////
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get rubros ////////////////////
        getRubro() {
            this.ss.listRubro().then(
                (result) => {
                    let response = result.data;
                    this.rubros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get cuentas ////////////////////
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////get subcuentas ////////////////////
        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPagoTesoreria() {
            this.pagotesoreria = {};
            this.errorBag = {};
            this.$refs['frm-pagotesoreria'].show();
        },
        showPagoTesoreria(id) {
            this.isLoading=true;
            this.ss.showPagoTesoreria(id).then(
                (result) => {
                    let response = result.data;
                    this.pagotesoreria = response.data;
                    this.$refs['view-pagotesoreria'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPagoTesoreria() {
            this.$refs['frm-pagotesoreria'].show();
            this.$refs['view-pagotesoreria'].hide();
        },
        cancelPagoTesoreria() {
            if (this.pagotesoreria.id) {
                this.$refs['view-pagotesoreria'].show();
            }
            this.$refs['frm-pagotesoreria'].hide();
        },
        savePagoTesoreria() {
            this.ss.storePagoTesoreria(this.pagotesoreria).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-pagotesoreria'].hide();
                    this.$refs['datatable-pagotesoreria'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePagoTesoreria() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPagoTesoreria(this.pagotesoreria)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-pagotesoreria'].hide();
                                this.$refs['datatable-pagotesoreria'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-PagoTesoreria').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPagoTesoreria(data);
            });
        },
        select2Cuenta(){
            this.isLoading = true;
            this.filtrosCuenta.q = this.searchCuentas;
            this.ss.select2CuentaAutocomplete(this.filtrosCuenta).then((res) => {
                this.cuentas = res.data.items;
                this.isLoading = false;
                return;
            })
        },
        select2Subcuenta(){
            this.isLoading = true;
            this.filtrosSubcuenta.q = this.searchSubcuentas;
            this.ss.select2SubcuentaAutocomplete(this.filtrosSubcuenta).then((res) => {
                this.subcuentas = res.data.items;
                this.isLoading = false;
                return;
            })
        },
    },
    watch: {
        searchCuentas (val) {
            console.log(val);
            this.select2Cuenta();
        },
        searchSubcuentas (val) {
            console.log(val);
            this.select2Subcuenta();
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getNivelAcademico();
            this.getPagoRequisito();
            this.getUnidadAcademica();
            this.getTipoPago();
            this.getCategoriaProgramatica();
            this.getReparticion();
            this.getRubro();
            // this.getCuenta();
            this.select2Cuenta();
            //this.getSubcuenta();
            this.select2Subcuenta();
        }
    }
};
