import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "SubCuentaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "SubCuentaPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexSubcuenta(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Codigo', name: 'sc.Codigo', title: 'Código' },
                { data: 'Subcuenta', name: 'sc.Subcuenta', title: 'SubCuenta' },
                 { data: 'Tipo', name: 't.Tipo', title: 'Tipo' },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            
           
            subcuentas: [],
            subcuenta: {},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            tipos:[],

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {

        getTipo() {
            this.ss.listTipo().then(
                (result) => {
                    let response = result.data;
                    this.tipos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newSubcuenta() {
            this.cuenta = {};
            this.$refs['frm-subcuenta'].show();
        },
        showSubcuenta(id) {
            this.isLoading = true;
            this.ss.showSubcuenta(id).then(
                (result) => {
                    let response = result.data;
                    this.subcuenta = response.data;
                    this.$refs['view-subcuenta'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editSubcuenta() {
            this.$refs['frm-subcuenta'].show();
            this.$refs['view-subcuenta'].hide();
        },
        cancelSubcuenta() {
            if (this.subcuenta.id) {
                this.$refs['view-subcuenta'].show();
            }
            this.$refs['frm-subcuenta'].hide();
        },
        saveSubcuenta() {
            this.ss.storeSubcuenta(this.subcuenta).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-subcuenta'].hide();
                    this.$refs['datatable-subcuenta'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteSubcuenta() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroySubcuenta(this.subcuenta)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-subcuenta'].hide();
                                this.$refs['datatable-subcuenta'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Subcuenta').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showSubcuenta(data);
            });
        },
        printCerAprobado() {
            console.log(this.subcuenta.id);
            this.ss.printCerAprobado(this.subcuenta.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printCerObservado() {
            console.log(this.subcuenta.id);
            this.ss.printCerObservado(this.subcuenta.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getTipo();
        }
    }
};
