import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "EstudiantePage",
    data() {
        let ss = new MainService();
        return {
            msg: "EstudiantePage",
            ss: ss,
            ajax: {
                "url": ss.indexEstudiante(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Nombre del Estudiante' },
                { data: 'Carnet', name: 'Carnet', title: 'Carnet' },
                { data: 'EmailPersonal', name: 'EmailPersonal', title: 'Email Personal' },
                { data: 'EmailInstitucional', name: 'EmailInstitucional', title: 'Email Institucional' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            itemstesoreria: [],
            estudiante: {},
            isLoading: false,
            errorBag: {},
            unidadacademicas: [],
            cursos: [],
            periodosacademicos: [],
            grados: [],
            cuentas: [],
            subcuentas: [],
            fuerzas: [],
        };
    },
    methods: {
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCursos() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPeriodoAcademico() {
            this.ss.listPeriodoAcademico().then(
                (result) => {
                    let response = result.data;
                    this.periodosacademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFuerza() {
            this.ss.listFuerza().then(
                (result) => {
                    let response = result.data;
                    this.fuerzas = response.data;
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGrado() {
            const parametros = {
                "Fuerza": this.estudiante.Fuerza,
            }
            this.ss.listGrado(parametros).then(
                (result) => {
                    let response = result.data;
                    this.grados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        ////////////////////////////////////////////////////////////
        newEstudiante() {
            this.estudiante = {};
            this.$refs['frm-estudiante'].show();
        },
        showEstudiante(id) {
            this.isLoading=true;
            this.ss.showEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.estudiante = response.data;
                    this.$refs['view-estudiante'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editEstudiante() {
            this.$refs['frm-estudiante'].show();
            this.$refs['view-estudiante'].hide();
        },
        cancelEstudiante() {
            if (this.estudiante.id) {
                this.$refs['view-estudiante'].show();
            }
            this.$refs['frm-estudiante'].hide();
        },
        saveEstudiante() {
            // Si el estudiante no es militar
            if (this.estudiante.Militar === null || this.estudiante.Militar == false) {
                this.estudiante.Grado = 142;
            }
            this.ss.storeEstudiante(this.estudiante).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-estudiante'].hide();
                    this.$refs['datatable-estudiante'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteEstudiante() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyEstudiante(this.estudiante)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-estudiante'].hide();
                                this.$refs['datatable-estudiante'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Estudiante').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showEstudiante(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getUnidadAcademica();
            this.getCursos();
            this.getPeriodoAcademico();
            this.getFuerza();
            this.getGrado();
        }
        
    }
};
