import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
//import { BIconArrowRightShort } from "bootstrap-vue";
window.$ = window.jQuery = require("jquery");

export default {
    name: "InscripcionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InscripcionPage",
            ss: ss,
            ajax: {
                "url": ss.indexInscripcion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Estudiante', name: 'Estudiante', title: 'Estudiante' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Gerencial' },
                { data: 'PlanPago', name: 'PlanPago', title: 'PlanPago' },
                { data: 'Curso', name: 'Curso', title: 'Curso' },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'Programa', name: 'Programa', title: 'Programa' },
                { data: 'NivelAcademico', name: 'NivelAcademico', title: 'Nivel Académico' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            inscripcion: {},
            inscripciones: [],
            
            estudiante:{},
            estudiantes:[],

            unidadacademica: {},
            unidadacademicas:[],

            planpago:{},
            planpagos:[],

            curso:{},
            cursos:[],

            especialidad:{},
            especialidades:[],

            programa:{},
            programas:[],

            nivelacademico:{},
            nivelacademicos:[],

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
       /*  getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.estudiantes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, */
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       /*  getPlanPago() {
            this.ss.listPlanPago().then(
                (result) => {
                    let response = result.data;
                    this.planpagos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, */
        /* getCurso() {
            this.ss.listCurso().then(
                (result) => {
                    let response = result.data;
                    this.cursos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, */
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        /* getPrograma() {
            this.ss.listPrograma().then(
                (result) => {
                    let response = result.data;
                    this.programas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, */
        getNivelAcademico() {
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.nivelacademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //////////////////////////////////////////////////////////////////////
        newInscripcion() {
            this.inscripcion = {};
            this.$refs['frm-inscripcion'].show();
        },
        showInscripcion(id) {
            this.isLoading=true;
            this.ss.showInscripcion(id).then(
                (result) => {
                    let response = result.data;
                    this.inscripcion = response.data;
                    this.$refs['view-inscripcion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editInscripcion() {
            this.$refs['frm-inscripcion'].show();
            this.$refs['view-inscripcion'].hide();
        },
        cancelInscripcion() {
            if (this.inscripcion.id) {
                this.$refs['view-inscripcion'].show();
            }
            this.$refs['frm-inscripcion'].hide();
        },
        saveInscripcion() {
            this.ss.storeInscripcion(this.inscripcion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-inscripcion'].hide();
                    this.$refs['datatable-inscripcion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteInscripcion() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyInscripcion(this.inscripcion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-inscripcion'].hide();
                                this.$refs['datatable-inscripcion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Inscripcion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInscripcion(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));

        //this.getEstudiante();
        this.getUnidadAcademica();
        //this.getPlanPago();
        //this.getCurso();
        this.getEspecialidad();
        //this.getPrograma();
        this.getNivelAcademico();
        
        if (!persona) {
          this.$router.push('/Login');
        }
    }
};
