import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DevengadoDetallePage",
    data() {
        let ss = new MainService();
        return {
            msg: "DevengadoDetallePage",
            ss: ss,
            ajax: {
                "url": ss.indexDevengadoDetalle(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Devengado', name: 'Devengado', title: 'Devengado' },
                { data: 'reparticion.Reparticion', name: 'Reparticion', title: 'Repartición' },
                // { data: 'cuenta.Cuenta', name: 'Cuenta', title: 'Cuenta' },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            devengado: [],
            devengados: {},
            reparticiones: [],
            partidas: [],
            categoriasProgramaticas: [],
            devengadoDetalles: [],
            devengadoDetalle: {},
            cuentas: [],
            cuenta: {},
            subcuentas: [],
            subcuenta: {},
            isLoading: false,
            errorBag: {},
            queryDevengado: "",
        };
    },
    methods: {
        getDevengado() {
            this.ss.listDevengado().then(
                (result) => {
                    let response = result.data;
                    this.devengados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.Reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newDevengadoDetalle() {
            this.devengadoDetalle = {};
            this.$refs['frm-devengadoDetalle'].show();
        },
        showDevengadoDetalle(id) {
            this.isLoading = true;
            this.ss.showDevengadoDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.devengadoDetalle = response.data;
                    this.$refs['view-devengadoDetalle'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editDevengadoDetalle() {
            this.$refs['frm-devengadoDetalle'].show();
            this.$refs['view-devengadoDetalle'].hide();
        },
        cancelDevengadoDetalle() {
            if (this.EjecucionDetalle.id) {
                this.$refs['view-devengadoDetalle'].show();
            }
            this.$refs['frm-devengadoDetalle'].hide();
        },
        saveDevengadoDetalle() {
            this.ss.storeDevengadoDetalle(this.devengadoDetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-devengadoDetalle'].hide();
                    this.$refs['datatable-devengadoDetalle'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDevengadoDetalle(id) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyDevengadoDetalle({'id':id})
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-devengadoDetalle'].hide();
                                this.$refs['datatable-devengadoDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-devengadoDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.deleteDevengadoDetalle(data);
            });
        }
    },
    watch: {
        queryDevengado(q) {
            this.ss.select2Devengado(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.devengados = res.data.items;
                } else {
                    this.devengados = [];
                }
            })
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            
            this.getDevengado();
            this.getReparticion();
            this.getCuenta();
            this.getSubcuenta();
        }
    }
};
