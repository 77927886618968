import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "LibroBancarioPage",
    data() {
        let ss = new MainService();
        return {
            msg: "LibroBancarioPage",
            ss: ss,
            ajax: {
                "url": ss.indexLibroBancario(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Banco', name: 'ba.Banco', title: 'Banco', searchable: false},
                { data: 'Fecha', name: 'lb.Fecha', title: 'Fecha', searchable: false},
                { data: 'Concepto', name: 'lb.Concepto', title: 'Concepto' },
                { data: 'Debe', name: 'lb.Debe', title: 'Debe' },
                { data: 'Haber', name: 'lb.Haber', title: 'Haber' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            libroBancarios: [],
            libroBancario: {},
            unidadAcademicas: [],
            bancos: [],
            banco: {},
            isLoading: false,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            egreso:{},
            egresos: [],
            searchEgresoF: "",
            placeholderEgreso:"",
            montooriginal:0,
            montoSaldoDetalle: 0,
            reportelibroBancario: {}
            
        };
    },
    methods: {
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getBanco() {
            this.ss.listBanco().then(
                (result) => {
                    let response = result.data;
                    this.bancos = response.data;
                    console.log(" este es el id",this.libroBancario.Banco);
                    // this.libroBancario.Banco == this.montooriginal
                    //this.getMontoInicial(this.libroBancario.Banco);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newLibroBancario() {
            this.libroBancario = {};
            this.$refs['frm-libroBancario'].show();

        },

        newReporteLibro(){
            this.$refs['frm-reporteLibro'].show();

        },

        cancelReporteLibro(){
            this.$refs['frm-reporteLibro'].hide();

        },
        showLibroBancario(id) {
            this.isLoading=true;
            this.ss.showLibroBancario(id).then(
                (result) => {
                    let response = result.data;
                    this.libroBancario = response.data;
                    this.$refs['view-libroBancario'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        showBanco() {
            this.isLoading=true;
            this.ss.showBanco(this.libroBancario.Banco).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data)
                    this.banco = response.data;
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editLibroBancario() {
            this.$refs['frm-libroBancario'].show();
            this.$refs['view-libroBancario'].hide();
        },
        cancelLibroBancario() {
            if (this.libroBancario.id) {
                this.$refs['view-libroBancario'].show();
            }
            this.$refs['frm-libroBancario'].hide();
        },
        saveLibroBancario() {
            this.libroBancario.MontoAuxiliar = this.banco.MontoSaldo;
            console.log(this.banco.MontoSaldo);
            console.log(this.libroBancario.MontoAuxiliar);
            console.log(this.libroBancario.Debe);
            console.log(this.libroBancario.Haber);
            this.montoSaldoDetalle = ((parseFloat(this.libroBancario.MontoAuxiliar)) + (parseFloat(this.libroBancario.Debe)) - (parseFloat(this.libroBancario.Haber)));
            this.libroBancario.MontoSaldoDetalle =  this.montoSaldoDetalle
            console.log(this.libroBancario.MontoSaldoDetalle);
            this.ss.storeLibroBancario(this.libroBancario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-libroBancario'].hide();
                    this.$refs['datatable-libroBancario'].reload();
                    this.getBanco().reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteLibroBancario() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyLibroBancario(this.libroBancario)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-libroBancario'].hide();
                                this.$refs['datatable-libroBancario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-LibroBancario').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showLibroBancario(data);
            });
        },
        ReporteLibroBancario() {
            this.ss.reporteLibroBancario(this.reportelibroBancario)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        // getMontoInicial(id){
        //     console.log(" este es el id",this.libroBancario.Banco)
        //     this.ss.showLibroBancario(id).then(
        //         (result) => {
        //             let response = result.data;
        //             this.montooriginal = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // }
    },
    watch: {
        searchEgresoF(q) {
            console.log("egreso",q);
            this.egreso.TipoComprobante=10;
            this.egreso.q=q;
            this.ss.select26ComprobanteContable(this.egreso).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.egresos = res.data.items;
                } else {
                    this.egresos = [];
                }
            })
        },
        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getBanco();
        }
    }
};
