import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "RubroPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RubroPage",
            ss: ss,
            ajax: {
                "url": ss.indexRubro(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Rubro', name: 'Rubro', title: 'Rubro' },
                { data: 'Codigo', name: 'Codigo', title: 'Código' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripción' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rubros: [],
            rubro: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newRubro() {
            this.rubro = {};
            this.$refs['frm-rubro'].show();
        },
        showRubro(id) {
            this.isLoading=true;
            this.ss.showRubro(id).then(
                (result) => {
                    let response = result.data;
                    this.rubro = response.data;
                    this.$refs['view-rubro'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editRubro() {
            this.$refs['frm-rubro'].show();
            this.$refs['view-rubro'].hide();
        },
        cancelRubro() {
            if (this.rubro.id) {
                this.$refs['view-rubro'].show();
            }
            this.$refs['frm-rubro'].hide();
        },
        saveRubro() {
            this.ss.storeRubro(this.rubro).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-rubro'].hide();
                    this.$refs['datatable-rubro'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteRubro() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,  
                showCancelButton: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyRubro(this.rubro)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-rubro'].hide();
                                this.$refs['datatable-rubro'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Rubro').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showRubro(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
    }
};
