import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import BarChart from "@/components/vue-chartjs/BarChart";
import axios from 'axios';
window.$ = window.jQuery = require("jquery");

export default {
  name: "PrincipalPage",
  data() {
    let ss = new MainService();
    return {
      msg: "PrincipalPage",
      ss: ss,
      auth: {},
      ajax: {
        url: ss.indexPersona(),
        headers: ss.getToken(),
      },
      columns: [
        { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
        { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
        { data: 'Persona', name: 'p.Persona', title: 'Persona' },
        { data: 'Rol', name: 'r.Rol', title: 'Rol' },
        { data: 'email', name: 'p.email', title: 'email' },
        {
          data: 'Foto', name: 'Foto', title: 'Foto', render: function(data, type, row) {
            if (row.Foto == null) {
              return '<img src="https://safiejto.serv.emi.edu.bo/images/default_image_profile.png" class="rounded-circle" with="40" height="40"></span>';
            } else {
              return '<img src="' + row.URLFoto + '" class="rounded-circle" alt="" height="40" width="40"/>';
            }
          }
        },
        {
          data: 'Activo', name: 'p.Activo', title: 'Activo', render: function(data, type, row) {
            if (row.Activo == 0) {
              return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Inactivo</i></span>';
            } else {
              return '<i class="fa fa-check text-success font-italic font-weight-bold"> Activo </i></span>';
            }
          }
        },
        {
          data: 'action',
          orderable: false,
          title: 'Acciones',
          searchable: false
        },
      ],
      cantidadFormulacion: [],
      cantidadFormulacionb: [],
      totalinicial: [],
      totalporcentaje: [],
      totalejecutado: [],
      totalsaldo: [],
      totalvigente: [],
      cantidadForDetalle: [],
      cantidadCertificaciones: [],
      cantidadEjecuciones: [],
      cantidadFondosRotativos: [],
      cantidadDescargos: [],
      chartData: null,
      toto: [],
      barChart: {
        data: {
          labels: [],
          datasets: [{
            label: 'Partidas',
            backgroundColor: "#3292e0",
            borderColor: "#3292e0",
            borderWidth: 2,
            hoverBackgroundColor: "#3292e0",
            hoverBorderColor: "#3292e0",
            data: []
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false
        }
      },
    };
  },
  methods: {
    getCantidadFormulacion() {
      this.ss.cantidadFormulacion().then(
        (result) => {
          let response = result.data;
          this.cantidadFormulacion = response.data;
        }
      ).catch(error => {
        console.log(error);
      });
    },
    getCantidadFormulacion23() {
      this.ss.cantidadFormulacion23().then(
        (result) => {
          let response = result.data;
          this.cantidadFormulacionb = response.data;
        }
      ).catch(error => {
        console.log(error);
      });
    },
    getmontosgenerales() {
      this.ss.montosGenerales().then(
        (result) => {
          let response = result.data;
          this.totalinicial = response.data;
          this.totalporcentaje = response.data1;
          this.totalejecutado = response.data2;
          this.totalsaldo = response.data3;
          this.totalvigente = response.data4;
          this.cantidadForDetalle = response.data5;
          this.cantidadCertificaciones = response.data6;
          this.cantidadEjecuciones = response.data7;
          this.cantidadFondosRotativos = response.data8;
          this.cantidadDescargos = response.data9;
          this.cantidadArchivos23 = response.data10;
          this.cantidadArchivos24 = response.data11;
          this.pdirecto = response.data12;
          this.bfinal = response.data13;
          this.desembolso = response.data14;
          this.pdirectoa = response.data15;
          this.bfinala = response.data16;
          this.desembolsoa = response.data17;
          this.cantidadCertificacionesb = response.data18;
          this.cantidadEjecucionesb = response.data19;
          this.cantidadFondosRotativosb = response.data20;
          this.cantidadDescargosb = response.data21;
        }
      ).catch(error => {
        console.log(error);
      });
    },

    draw() {
      window.$('.btn-datatable-Persona').on('click', (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showPersona(data);
      });
    },
    getcantidad() {
      axios.get('https://spdiafbackend.serv.emi.edu.bo/api/Dashboard/cantidadgrado').then(
        (result) => {
          let response = result.data;
          this.toto = response.data;
        }
      ).catch(error => {
        console.log(error);
      });
    },
    fetchData() {
      axios.get('https://spdiafbackend.serv.emi.edu.bo/api/Dashboard/cantidadgradoa').then(
        response => {
          const data = response.data;
          console.log(data);
          this.barChart.data.labels = data.map(item => item.Grado);
          this.barChart.data.datasets[0].data = data.map(item => item.count);
          console.log(this.barChart.data.labels)
        }
      ).catch(error => {
        console.log(error);
      });
    },
    handleWindowResize() {
      // Actualizar el gráfico de barras cuando se redimensione la ventana
      this.fetchData();
    },
  },
  components: {
    dataTable,
    Loader,
    BarChart
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    this.auth = JSON.parse(localStorage.getItem('persona'));
    if (!persona) {
      this.$router.push("/Login");
    } else {
      this.getCantidadFormulacion();
      this.getCantidadFormulacion23();
      this.getmontosgenerales();
      this.fetchData();
      window.addEventListener('resize', this.handleWindowResize);
      //window.addEventListener('resize', this.fetchData);
      this.getcantidad();
    }
  },
};