import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CertificacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CertificacionPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexCertificacion(),
                headers: ss.getToken(),
            },

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'c.Gestion', title: 'Gestión', searchable: false },
                { data: 'Fecha', name: 'c.Fecha', title: 'Fecha', searchable: false },
                { data: 'NumeroInicial', name: 'c.NumeroInicial', title: 'Num. Certificado' },
                { data: 'Certificacion', name: 'c.Certificacion', title: 'Glosa' },
                {
                    data: 'Estado', name: 'c.Estado', title: 'Revertido', render: function (data, type, row) {
                        if (row.Estado == 1) { return '<span class="badge bg-warning text-white ">Revertido</span>'; }
                        else { return '<span></span>' }
                    },searchable: false
                },
                { data: 'Persona', name: 'p.Persona', title: 'Aprobado Por', searchable: false },
                {
                    data: 'Aprobado', name: 'c.Aprobado', title: 'Estado', render: function (data, type, row) {
                        if (row.Aprobado == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>'; }
                        else { return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>'; }
                    },searchable: false
                },

                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexCertificacionDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                }

            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Poa', name: 'rd.Poa', title: 'N Cer Poa' },
                { data: 'FuenteFinanciera', name: 'ff.FuenteFinanciera', title: 'Fuente Financiera' },
                {
                    data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición', render: function (data, type, row) {
                        return row.CodRep + ' - ' + row.Reparticion;
                    }
                },
                {
                    data: 'Reparticion', name: 'd.Reparticion', title: 'Repartición Nueva', render: function (data, type, row) {
                        return row.CodRepdos + ' - ' + row.Reparticiondos;
                    }
                },
                {
                    data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render: function (data, type, row) {
                        return row.CodCat + ' - ' + row.CategoriaProgramatica;
                    }
                },
                {
                    data: 'Partida', name: 'p.Partida', title: 'Partida', render: function (data, type, row) {
                        return row.CodPar + ' - ' + row.Partida;
                    }
                },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                { data: 'Monto', name: 'Monto', title: 'Monto Bs' },
                // { data: 'Monto', name: 'a.Monto', title: 'Monto Destino', render:function(data, type, row){
                //     if (row.Tipo)
                //         return row.Monto;
                //     else
                //         return 0;
                //      }},


                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            total: 0,
            saldo: 0,
            editar:0,
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            editarDetalle: 0,
            idFormulacion: 0,
            reparticion: {},
            reparticiones: [],
            reparticionesdos: [],
            searchReparticiondos: "",
            selectedReparticiondos: null,
            categoriaProgramatica: {},
            categoriaProgramaticas: [],
            partida: {},
            partidas: [],
            certificacionDetalles: [],
            certificacionDetalle: {},
            searchFormulacion: "",
            selectedFormulacion:null,
            formulacionCertificacion: {},
            fuenteFinancieras: [],
            personas: [],
            formulaciones: [],
            certificaciones: {},
            certificacion: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            idFuenteFinanciera:0,
        };
    },
    methods: {
        newPartida() {
            this.partida = {};
            this.$refs['frm-partida'].show();
        },
        savePartida() {
            this.ss.storePartida(this.partida).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-partida'].hide();
                    // this.$refs['datatable-partida'].reload();
                    this.getPartida();
                    this.getPartidaTodas();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        newCategoriaProgramatica() {
            this.categoriaProgramatica = {};
            this.$refs['frm-categoriaProgramatica'].show();
        },
        saveCategoriaProgramatica() {
            this.ss.storeCategoriaProgramatica(this.categoriaProgramatica).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-categoriaProgramatica'].hide();
                    // this.$refs['datatable-categoriaProgramatica'].reload();
                    this.getCategoriaProgramatica();
                    this.getCategoriaProgramaticaTodas();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cancelPartida() {
            if (this.partida.id) {
                this.$refs['view-partida'].show();
            }
            this.$refs['frm-partida'].hide();
        },
        cancelCategoriaProgramatica() {
            if (this.categoriaProgramatica.id) {
                this.$refs['view-categoriaProgramatica'].show();
            }
            this.$refs['frm-categoriaProgramatica'].hide();
        },
        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        saveReparticion() {
            this.reparticion.UnidadAcademica = this.certificacion.UnidadAcademica;
            console.log(this.reparticion);
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.getReparticionFormulacion();
                    // this.$refs['datatable-reparticion'].reload();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

        },
        cancelReparticion() {
            if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            }
            this.$refs['frm-reparticion'].hide();
        },
        getCertificacion() {
            this.ss.listCertificacion().then(
                (result) => {
                    let response = result.data;
                    this.certificaciones = response.data;
                    console.log("certificacion");
                    console.log(this.certificaciones);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getReparticionFormulacion(fuenteFinanciera) {
            this.certificacionDetalle.Reparticion = null;
            this.certificacionDetalle.CategoriaProgramatica = null;
            this.certificacionDetalle.Partida = null;
            this.saldo = 0;
            this.idFuenteFinanciera=fuenteFinanciera;
            console.log("REparticion", this.certificacion);
            this.ss.listFormulacionReparticion({
                'FuenteFinanciera': fuenteFinanciera,
                'Formulacion': this.certificacion.Formulacion
            }).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });

        },
        // getReparticion2() {
        //     let parametros = {
        //         Formulacion: this.certificacion.Formulacion
        //     }
        //     this.ss.listReparticion2(parametros).then(
        //         (result) => {
        //             let response = result.data;
        //             this.reparticiones = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },

        getCategoriaProgramatica(id) {
            console.log("idRep", id);
            this.certificacionDetalle.CategoriaProgramatica = null;
            this.certificacionDetalle.Partida = null;
            this.saldo = 0;
            this.idReparticion = id;
            this.ss.listFormulacionCategoria({ "Formulacion": this.idFormulacion, "Reparticion": this.idReparticion, "FuenteFinanciera":this.idFuenteFinanciera}).then(

                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticas = response.data;
                }

            ).catch(error => {
                console.log(error);
            });


        },
        getPartida(id) {
            console.log("idCat", id);
            this.certificacionDetalle.Partida = null;
            this.saldo = 0;
            this.idCategoria = id;
            this.ss.listFormulacionPartida({ "Formulacion": this.idFormulacion, "Reparticion": this.idReparticion, "CategoriaProgramatica": this.idCategoria,"FuenteFinanciera":this.idFuenteFinanciera }).then(

                (result) => {
                    let response = result.data;
                    this.partidas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
            // this.certificacionDetalle.Partida=null;
        },

        getFormulacion() {
            this.ss.listFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.formulaciones = response.data;
                    console.log("formulaciones");
                    console.log(this.formulaciones);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        resetFormulacion(){
            
            this.searchFormulacion="";
           this.selectedFormulacion=null;
            this.certificacion.Formulacion= "";
        },

        getFuenteFinanciera(item) {
            console.log(item);

            this.ss.listFormulacionFuenteFinanciera({ "Formulacion": item }).then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
         
        },

        newCertificacion() {
            this.certificacion = {};
            this.$refs['frm-certificacion'].show();
            this.editar = 0;
        },
        showCertificacion(id) {
            console.log("id show certi", id);
            this.isLoading = true;
            this.ss.showCertificacion(id).then(
                (result) => {
                    let response = result.data;
                    this.certificacion = response.data;
                    this.$refs['view-certificacion'].show();
                    this.isLoading = false;
                    this.idFormulacion = this.certificacion.Formulacion;
                    console.log("certificacion Formulacion", this.certificacion.Formulacion);
                    //this.getReparticionFormulacion(this.certificacion);
                    this.getFuenteFinanciera(this.certificacion.Formulacion);
                    var dd = window.$('#datatable-certificacionDetalle').DataTable();
                    console.log("filas", dd.rows());
                    this.ajaxDetalle.data = function (d) {
                        d.id = id;
                    }

                    //hacer el list de Reparticion enviando el id de Formulacion
                    //this.getReparticion2();


                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editCertificacion() {
            this.$refs['frm-certificacion'].show();
            this.$refs['view-certificacion'].hide();
            this.editar = 1;
        },
        reverseCertificacion() {
            this.$refs['frm-certificacion'].show();
            this.$refs['view-certificacion'].hide();
        },
        cancelCertificacion() {
            if (this.certificacion.id) {
                this.$refs['view-certificacion'].show();
            }
            this.$refs['frm-certificacion'].hide();
        },
        saveCertificacion() {
            console.log(" save Certificacion");
            console.log(this.certificacion);
            this.certificacion.Anexo = this.certificacion.Certificacion
            this.ss.storeCertificacion(this.certificacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    if (this.editar == 0) {
                        this.showCertificacion(response.data.id);
                    }
                    console.log(response);
                    this.$refs['frm-certificacion'].hide();
                    this.$refs['datatable-certificacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCertificacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCertificacion(this.certificacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-certificacion'].hide();
                                this.$refs['datatable-certificacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        newCertificacionDetalle() {
            this.certificacionDetalle = {};
            this.$refs['frm-certificacionDetalle'].show();
            this.editarDetalle = 0;
            this.saldo = 0;
        },
        showCertificacionDetalle(id) {
            this.isLoading = true;
            this.ss.showCertificacionDetalle(id).then(
                (result) => {

                    let response = result.data;
                    this.certificacionDetalle = response.data;
                    console.log("show");
                    console.log(this.certificacionDetalle);
                    this.$refs['view-certificacionDetalle'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editCertificacionDetalle() {
            this.$refs['frm-certificacionDetalle'].show();
            this.$refs['view-certificacionDetalle'].hide();
            
            var categoria = this.certificacionDetalle.CategoriaProgramatica;
            var partida = this.certificacionDetalle.Partida;
            var reparticion = this.certificacionDetalle.Reparticion;
            console.log("edit detalle",this.certificacionDetalle.FuenteFinanciera, reparticion, categoria, partida);
            this.getReparticionFormulacion(this.certificacionDetalle.FuenteFinanciera);
            this.getCategoriaProgramatica(reparticion);
            this.getPartida(categoria);
            this.getSaldo(this.certificacionDetalle.FuenteFinanciera,reparticion, categoria, partida);
            this.certificacionDetalle.Reparticion = reparticion;
            this.certificacionDetalle.CategoriaProgramatica = categoria;
            this.certificacionDetalle.Partida = partida;
            this.editarDetalle = 1;

        },
        cancelCertificacionDetalle() {
            if (this.certificacionDetalle.id) {
                this.$refs['view-certificacionDetalle'].show();
            }
            this.$refs['frm-certificacionDetalle'].hide();
            this.saldo = 0;
        },
        saveCertificacionDetalle() {
            ///
            this.certificacionDetalle.Certificacion = this.certificacion.id;
            this.certificacionDetalle.Formulacion = this.certificacion.Formulacion;
            console.log(" save Certificacion");
            console.log(this.certificacionDetalle);
            if (this.editarDetalle == 1) {

                this.ss.storeCertificacionDetalle(this.certificacionDetalle).then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        if (!response.success) {
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Error',
                                    variant: 'warning',
                                    autoHideDelay: 5000
                                }
                            )

                        } else {
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            console.log(response);
                            this.$refs['frm-certificacionDetalle'].hide();
                            this.$refs['datatable-certificacionDetalle'].reload();
                            this.showCertificacion(this.certificacion.id);
                        }

                        //this.$refs['view-consulta'].show(); //para volver al modal

                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });

            }
            else {
                if (this.saldo >= parseFloat(this.certificacionDetalle.Monto)) {
                    this.ss.storeCertificacionDetalle(this.certificacionDetalle).then(
                        (result) => {
                            console.log(result);
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            //this.$refs['view-consulta'].show(); //para volver al modal
                            console.log(response);
                            this.$refs['frm-certificacionDetalle'].hide();
                            this.$refs['datatable-certificacionDetalle'].reload();
                            this.showCertificacion(this.certificacion.id);
                        })
                        .catch((error) => {
                            this.errorBag = error.response.data.errors;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });

                } else {
                    this.$bvToast.toast(
                        'El monto no debe ser mayor al saldo.',
                        {
                            title: 'Error',
                            variant: 'warning',
                            autoHideDelay: 5000
                        }
                    );
                }
            }

            ///

        },
        deleteCertificacionDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCertificacionDetalle(this.certificacionDetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-certificacionDetalle'].hide();
                                this.$refs['datatable-certificacionDetalle'].reload();
                                this.showCertificacion(this.certificacion.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-certificacion'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-certificacion'].show();
        },
        changePassword() {
            this.password.Certificacion = this.certificacion.id;
            this.ss.changePassword(this.password)
                .then(result => {
                    let response = result.data;
                    if (response.success) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-certificacion'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        getSaldo(a, b, c, d) {
            console.log(this.idFormulacion, a, b, c, d);
            if (a === null || b === null || c === null || d === null) {
                alert("Debe seleccionar la Fuente, Reparticion, la Categoria Programatica y la partida");
            } else {
                this.ss.saldoFormulacionDetalle({ "Formulacion": this.idFormulacion, "FuenteFinanciera": d, "Reparticion": a, "CategoriaProgramatica": b, "Partida": c }).then(
                    (result) => {
                        let response = result.data;
                        console.log("saldo", response.data.Saldo);

                        this.saldo = response.data.Saldo;
                    }
                ).catch(error => {
                    console.log(error);
                    this.saldo = 0;
                });
            }

        },
        aprobarCertificacion() {

            if (this.certificacion.certificacion_detalle.length === 0) {

                this.$bvToast.toast(
                    'Registre detalles de certificacion.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            } else {

                this.isLoading = true;
                this.ss.aprobarCertificacion({ 'id': this.certificacion.id, 'Aprobado': true, 'Monto': this.certificacion.suma }).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['datatable-certificacion'].reload();
                        // this.$swal.fire({
                        //     position: 'center',
                        //     icon: 'success',
                        //     title: 'Aprobado correctamente.',
                        //     showConfirmButton: false,
                        //     timer: 1500
                        //   });
                        this.$refs['view-certificacion'].hide();

                        this.isLoading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });



            }

        },
        desaprobarCertificacion() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de esta certificacion.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarCertificacion({ 'id': this.certificacion.id, 'Aprobado': false }).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['datatable-certificacion'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-certificacion'].hide();

                                this.isLoading = false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        reporteCertificacion() {
            console.log("reporte certificacion", this.certificacion.id);
            this.ss.imprimirCertificacion(this.certificacion.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        console.log(urlFile);
                        console.log("-------------------------------------------------------");
                        console.log(this.rutaApp);
                        var dir = this.rutaApp + urlFile.url;
                        console.log("-------------------------------------------------------");
                        console.log(dir);
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        draw() {
            window.$('.btn-datatable-Certificacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCertificacion(data);
            });
        },
        drawDetalle() {
            // this.idCertificacion=1;
            window.$('.btn-datatable-CertificacionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCertificacionDetalle(data);
            });

        }
    },

    watch: {
        searchFormulacion(q) {
            this.ss.select2Formulacion(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.formulaciones = res.data.items;
                } else {
                    this.formulaciones = [];
                }
            })
        },
        searchReparticiondos(q) {
            this.ss.select2Reparticion(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                   
                    this.reparticionesdos = res.data.items;
                
                } else {
                    this.reparticionesdos = [];
                }
            })
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getFormulacion();
            this.getPersona();
            this.getReparticionFormulacion();
            this.getCategoriaProgramatica();
            this.getPartida();
            this.getFuenteFinanciera();
        }
    }
};
