import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "SintesisPage",
    data() {
        let ss = new MainService();
        return {
            msg: "SintesisPage",
            ss: ss,
            auth: {},
            
            ajax: {
                "url": ss.indexSintesis(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Estudiante', name: 'Estudiante', title: 'Estudiante' },
                { data: 'CodigoAlumno', name: 'CodigoAlumno', title: 'CodigoAlumno' },
                { data: 'Programa', name: 'Programa', title: 'Programa' },
                { data: 'Concepto', name: 'Concepto', title: 'Concepto' },
                { data: 'Curso', name: 'Curso', title: 'Curso' },
                { data: 'MontoSubtotal', name: 'MontoSubtotal', title: 'Monto Subtotal' },
                { data: 'MontoDescuento', name: 'MontoDescuento', title: 'Monto Descuento' },
                { data: 'MontoProntoPago', name: 'MontoProntoPago', title: 'Monto ProntoPago' },
                { data: 'MontoRecaudacion', name: 'MontoRecaudacion', title: 'Monto Recaudacion' },
                { data: 'FechaTransaccion', name: 'FechaTransaccion', title: 'Fecha Transaccion' },
                { data: 'NombreFactura', name: 'NombreFactura', title: 'Nombre Factura' },
                { data: 'Procesado', name: 'Procesado', title: 'Procesado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadesacademicas: [],
            unidadacademica: {},
            sintesis: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            persona: {}
        };
    },
    methods: {
        //////////get unidad academica ////////////////////
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadesacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newUnidadAcademica() {
            this.unidadacademica = {};
            this.$refs['frm-unidadacademica'].show();
        },
        showSintesis(id) {
            this.isLoading=true;
            console.log("Show sintesis"+ id);
            // this.ss.showSintesis(id).then(
            //     (result) => {
            //         let response = result.data;
            //         this.sintesis = response.data;
            //         //this.$refs['view-unidadacademica'].show();
            //         this.isLoading=false;
            //     }
            // ).catch(error => {
            //     console.log(error);
            //     this.isLoading=false;
            // });
        },
        editUnidadAcademica() {
            this.$refs['frm-unidadacademica'].show();
            this.$refs['view-unidadacademica'].hide();
        },
        cancelDescargarSintesis() {
            this.$refs['frm-unidadacademica'].hide();
        },
        cancelCargarArchivo() {
            this.$refs['frm-cargararchivo'].hide();
        },
        deleteUnidadAcademica() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        // this.ss.destroyUnidadAcademica(this.unidadacademica)
                        //     .then((result) => {
                        //         let response = result.data;
                        //         console.log(response);
                        //         this.$bvToast.toast(
                        //             response.msg,
                        //             {
                        //                 title: 'Correcto',
                        //                 variant: 'success',
                        //                 autoHideDelay: 5000
                        //             }
                        //         )
                        //         this.$refs['view-unidadacademica'].hide();
                        //         this.$refs['datatable-sintesis'].reload();
                        //     })
                        //     .catch(error => {
                        //         console.log(error);
                        //     })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        seleccionarUnidadAcademica(){
            console.log("Seleccionar UA");
            this.$refs['frm-unidadacademica'].show();
        },
        abrirModalCargarArchivo(){
            this.sintesis = {};
            console.log("abrir modal cargar archivo");
            this.$refs['frm-cargararchivo'].show();
        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                // var reader = new FileReader();
                // reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.sintesis.Archivo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        descargarSintesis(){
            console.log("Descargar sintesis");
            
            this.ss.descargarSintesis({}).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    let url = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    var link = document.createElement('a');
                    link.href = url;
                    console.log(link.href);
                    link.download = 'archivo';
                    link.click();

                    console.log(response);
                    this.$refs['frm-unidadacademica'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al descargar el archivo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        registrarArchivoSintesis(){
            console.log("Descargar sintesis");
            
            this.ss.registrarArchivoSintesis({Archivo: this.sintesis.Archivo}).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-unidadacademica'].hide();
                    this.$refs['datatable-sintesis'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al descargar el archivo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        draw() {
            window.$('.btn-datatable-Sintesis').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showSintesis(data);
            });
        },
        grabarFacturas(){
            console.log("grabar facturas");
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth= JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        this.getUnidadAcademica();
    }
};
