import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ComprobanteContablePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ComprobanteContablePage",
            ss: ss,
            ajax: {
                "url": ss.indexComprobanteContable(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Gerencial' },
                // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
               
                // { data: 'Numero', name: 'Numero', title: 'Numero' },
                { data: 'Concatenado', name: 'Concatenado', title: 'Nº Documento' },
                
                { data: 'Certificacion', name: 'Certificacion', title: 'Observación' },
                { data: 'TipoCambio', name: 'TipoCambio', title: 'Tipo Cambio' },
                // { data: 'Anexo', name: 'Anexo', title: 'Anexo' }, 
                          
                // { data: 'Aprobado', name: 'Aprobado', title: 'Aprobado' },
                // { data: 'AprobadoPor', name: 'AprobadoPor', title: 'Aprobado Por' },
                { data: 'Aprobado', name: 'Aprobado', title: 'Estado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>';}
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';}
                } }
            ],
            comprobanteContables: [],
            comprobantecontable: {},
            comprobantecontabledetalle: {},
            tipocomprobantes: [],
            unidadacademicas: [],
            fuentefinancieras: [],
            cuentas: [],
            subcuentas: [],
            reparticions: [],
            tipocambios: [],
            ejecucions: [],
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion(0).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEjecucion() {
            this.ss.listEjecucion().then(
                (result) => {
                    let response = result.data;
                    this.ejecucions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getTipoCambio() {
            this.ss.listTipoCambio().then(
                (result) => {
                    let response = result.data;
                    this.tipocambios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoComprobante() {
            this.ss.listTipoComprobante().then(
                (result) => {
                    let response = result.data;
                    this.tipocomprobantes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuentefinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newComprobanteContableDetalle() {
            this.comprobantecontabledetalle = {};
            this.comprobantecontabledetalle.ComprobanteContable = this.comprobantecontable.id;
            this.getCuenta();
            this.getSubcuenta();
            this.getReparticion();
            this.$refs['frm-comprobantecontabledetalle'].show();
        },
        cancelComprobanteContableDetalle() {
            if (this.comprobantecontable.id) {
                this.$refs['view-comprobantecontable'].show();
            }
            this.$refs['frm-comprobantecontabledetalle'].hide();
        },

        saveComprobanteContableDetalle() {
            this.ss.storeComprobanteContableDetalle(this.comprobantecontabledetalle).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-comprobantecontabledetalle'].hide();
                    this.showComprobanteContable(this.comprobantecontable.id);

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        newComprobanteContable() {
            this.comprobantecontable = {};
            this.$refs['frm-comprobantecontable'].show();
        },
        showComprobanteContable(id) {
            this.isLoading = true;
            this.ss.showComprobanteContable(id).then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontable = response.data;
                    this.$refs['view-comprobantecontable'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editComprobanteContable() {
            this.$refs['frm-comprobantecontable'].show();
            this.$refs['view-comprobantecontable'].hide();
        },
        cancelComprobanteContable() {
            if (this.comprobantecontable.id) {
                this.$refs['view-comprobantecontable'].show();
            }
            this.$refs['frm-comprobantecontable'].hide();
        },
        saveComprobanteContable() {
            this.ss.storeComprobanteContable(this.comprobantecontable).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-comprobantecontable'].hide();
                    this.$refs['datatable-comprobantecontable'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteComprobanteContable() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyComprobanteContable(this.comprobantecontable)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-comprobantecontable'].hide();
                                this.$refs['datatable-comprobantecontable'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-ComprobanteContable').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showComprobanteContable(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getTipoComprobante();
            this.getUnidadAcademica();
            this.getFuenteFinanciera();
            this.getTipoCambio();
            this.getEjecucion();
        }
    }
};
