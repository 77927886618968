import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ChequeAsignadoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ChequeAsignadoPage",
            ss: ss,
            ajax: {
                "url": ss.indexChequesAsignados(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Beneficiario', name: 'ct.Beneficiario', title: 'Beneficiario' },
                { data: 'Monto', name: 'ch.Monto', title: 'Monto' },
                { data: 'Numero', name: 'ct.Numero', title: 'Nùmero de cheque' },
                { data: 'Descripcion', name: 'ec.Descripcion', title: 'Estado del cheque' },
                { data: 'Talonario', name: 't.Talonario', title: 'Talonario' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
                
            ],
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            
            chequeTalonario: {},
            estadocheques:[],
            unidadacademicas:[],
            chequeTalonarios:[],
            talonarios:[],
            asignacionCheque: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        
        showChequeTalonario(id) {
            this.isLoading=true;
            this.ss.showChequeTalonario(id).then(
                (result) => {
                    let response = result.data;
                    this.chequeTalonario = response.data;
                    this.$refs['view-chequeTalonario'].show();
                    this.isLoading=false;
                    this.chequeTalonario.UnidadAcademica=this.chequeTalonario.talonario.UnidadAcademica;
                    this.getTalonario(this.chequeTalonario.talonario.UnidadAcademica);
                    this.getChequeTalonario(this.chequeTalonario.Talonario);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editChequeTalonario() {
            this.$refs['asignarCheque'].show();
            this.$refs['view-chequeTalonario'].hide();
        },
        cancelChequeTalonario() {
            this.$refs['asignarCheque'].hide();
        },
        saveChequeTalonario() {
            this.ss.storeChequeTalonario(this.chequeTalonario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['asignarCheque'].hide();
                    this.$refs['datatable-chequeTalonario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCheque() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCheque(this.cheque)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-cheque'].hide();
                                this.$refs['datatable-cheque'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getChequeTalonario(id) {
            this.ss.listChequeTalonario(id).then(
                (result) => {
                    let response = result.data;
                    this.chequeTalonarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        // getChequeTalonario() {
        //     this.ss.listChequeTalonario(this.chequeTalonario.Talonario).then(
        //         (result) => {
        //             let response = result.data;
        //             this.chequeTalonarios = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },

        getEstadoCheque() {
            this.ss.listEstadoCheque().then(
                (result) => {
                    let response = result.data;
                    this.estadocheques = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        // getTalonario() {

        //     this.ss.listTalonario(this.chequeTalonario.UnidadAcademica).then(
        //         (result) => {
        //             let response = result.data;
        //             this.talonarios = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },

        getTalonario(id) {

            this.ss.listTalonario(id).then(
                (result) => {
                    let response = result.data;
                    this.talonarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        imprimirCheque() {

            this.ss.imprimirChequeTalonario(this.chequeTalonario.id)
                .then((result) => {
                    let response = result.data;
                    window.open(this.rutaApp+response.data.url);
                    console.log(response, "Se imprimio el cheque");
                })
                .catch(error => {
                    console.log(error);
                })
        },

        draw() {
            window.$('.btn-datatable-ChequeTalonario').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showChequeTalonario(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        this.getEstadoCheque();
        // this.getTalonario(0);
        this.getUnidadAcademica();
        // this.getChequeTalonario();
    }
};
