import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CertificacionDescargoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CertificacionDescargoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexDescargo(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Preventivo', name: 'de.Preventivo', title: 'Preventivo' },
                { data: 'Subcuenta', name: 'sc.Subcuenta', title: 'Subcuenta' },
                { data: 'DescargoEstado', name: 'dee.DescargoEstado', title: 'Estado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            comprobanteContableDetalles: [],
            descargo: {},
            descargod: {},
            comprobantecontables: [],
            comprobantecontabledetalles: [],
            descargoestados: [],
            unidadacademicas: [],
            cuentas: [],
            subcuentas: [],
            cuenta: {},
            subcuenta: {},
            reparticions: [],
            placeholderSubcuenta: "",
            placeholderCuenta: "",
            searchCuenta: "",
            selectedCuenta: null,
            selectedSubcuenta: null,
            searchSubcuenta: "",
            isLoading: false,
            isLoadingFile: false,
            isLoadedFile: false,
            selecteddescargoestado: 0,
            selectedunidadacademica: 0,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            detalleDescargo:{},
            seguimiento:[],
            tipoRetencion:0
        };
    },
    methods: {
       
        getComprobanteContable() {
            this.ss.listComprobanteContable().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontables = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getComprobanteContableDetalle() {
            this.ss.listComprobanteContableDetalle().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontabledetalles = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion(0).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDescargoEstado() {
            this.ss.listDescargoEstado(0).then(
                (result) => {
                    let response = result.data;
                    this.descargoestados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newDescargo() {
            this.descargo = {};
            this.$refs['frm-descargo'].show();
            this.placeholderCuenta = "Escriba la cuenta...";
            this.placeholderSubcuenta = "Escriba la subcuenta...";
        },
        showDescargo(id) {
            this.tipoRetencion=0;
            this.isLoading = true;
            this.ss.showDescargo(id).then(
                (result) => {
                    let response = result.data;
                    this.descargo = response.data;
                    this.$refs['view-descargo'].show();
                    this.isLoading = false;
                    this.getSeguimiento(this.descargo.id);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editDescargo() {
            this.placeholderCuenta = this.descargo.cuenta ? this.descargo.cuenta.Codigo + ': ' + this.descargo.cuenta.Cuenta : "Escribe la cuenta..";
            this.placeholderSubcuenta = this.descargo.subcuenta ? this.descargo.subcuenta.Codigo + ': ' + this.descargo.subcuenta.Subcuenta : "Escribe la subcuenta..";
            this.$refs['frm-descargo'].show();
            this.$refs['view-descargo'].hide();
        },
        saveDescargo() {
            this.ss.storeDescargo(this.descargo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-descargo'].hide();
                    this.$refs['datatable-descargo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDescargo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyDescargo(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-descargo'].hide();
                                this.$refs['datatable-descargo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
       
        draw() {
            window.$('.btn-datatable-Descargo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDescargo(data);
            });
        },
        printDescargo() {
            this.ss.printDescargo(this.descargo.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
    },
    watch: {
        searchSubcuenta(q) {

            this.ss.select2Subcuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.subcuentas = res.data.items;

                } else {
                    this.subcuentas = [];
                }
            })


        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getCuenta();
            this.getReparticion(0);
            this.getSubcuenta();
            this.getComprobanteContable();
            this.getComprobanteContableDetalle();
            this.getDescargoEstado();
            this.getUnidadAcademica();
        }
    }
};
