import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReparticionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReparticionPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexReparticion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                //{ data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Gerencial' },
                { data: 'Codigo', name: 'r.Codigo', title: 'Código' },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                { data: 'Descripcion', name: 'r.Descripcion', title: 'Descripción' },
                { data: 'GradoComandante', name: 'r.GradoComandante', title: 'Grado Comandante' },
                { data: 'Subcuenta', name: 'suc.Subcuenta'  , title: 'Comandante' },
                { data: 'GradoCajero', name: 'r.GradoCajero', title: 'Grado Cajero' },
                { data: 'Subcuenta', name: 'suca.Subcuenta', title: 'Cajero' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            reparticiones: [],
            reparticion: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            subcuentas: [],
            selectedSubCuenta:null,
            searchSubcuenta: "",
        };
    },
    methods: {
      
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        showReparticion(id) {
            this.isLoading=true;
            this.ss.showReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.reparticion = response.data;
                    this.$refs['view-reparticion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editReparticion() {
            this.$refs['frm-reparticion'].show();
            this.$refs['view-reparticion'].hide();
        },
        cancelReparticion() {
            if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            }
            this.$refs['frm-reparticion'].hide();
        },
        saveReparticion() {
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.$refs['datatable-reparticion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteReparticion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33'
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyReparticion(this.reparticion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-reparticion'].hide();
                                this.$refs['datatable-reparticion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-reparticion'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-reparticion'].show();
        },
        changePassword() {
            this.password.Reparticion = this.reparticion.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-reparticion'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-Reparticion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showReparticion(data);
            });
        }
    },
    watch: {
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
            }

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            
        }
    }
};
