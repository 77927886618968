import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "HistorialCajeroPage",
    data() {
        let ss = new MainService();
        return {
            msg: "HistorialCajeroPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexCajero(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Reparticion', name: 're.Reparticion', title: 'Repartición' },
                { data: 'GradoComandante', name: 'ca.GradoComandante', title: 'Grado Comandante' },
                // { data: 'Comandante', name: 'suc.Subcuenta', title: 'Comandante' },
                {
                    data: 'Subcuenta', name: 'suc.Subcuenta', title: 'Comandante', render: function (data, type, row) {
                        return row.Comand;
                    }
                },
                { data: 'GradoCajero', name: 'ca.GradoCajero', title: 'Grado Cajero' },
                { data: 'Subcuenta', name: 'suca.Subcuenta', title: 'Cajero' },
                { data: 'Gestion', name: 'ca.Gestion', title: 'Gestión' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            cajero: {},
            antecedente: {},
           // reparticiones: {},
           baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
           rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            reparticiones: [],
            antecedentes: [],
            searchReparticion: "",
            selectedReparticion: null,
            subcuentas: [],
            selectedSubCuenta:null,
            searchSubcuenta: "",

        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.cajero.FotoComandante = result.data.data;
                             
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFilec(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.cajero.FotoCajero= result.data.data;
                             
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFilearchivo(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.antecedente.Archivo= result.data.data;
                             
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getReparticion() {
            this.ss.listReparticion({
            }).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });

        },
        getAntecedente() {
            this.ss.listAntecedente({
            }).then(
                (result) => {
                    let response = result.data;
                    this.antecedentes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });

        },
        newCajero() {
            this.cajero = {};
            this.$refs['frm-cajero'].show();
        },
        newAntecedente() {
            this.antecedente = {};
            this.antecedente.Antecedente = this.cajero.id;
            this.antecedente.Reparticion = this.cajero.Reparticion;
            this.antecedente.Subcuenta = this.cajero.subcuenta_caj.id;
            this.$refs['frm-antecedente'].show();
        },
        showCajero(id) {
            this.isLoading=true;
            this.ss.showCajero(id).then(
                (result) => {
                    let response = result.data;
                    this.cajero = response.data;
                    this.$refs['view-cajero'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCajero() {
            this.$refs['edifrm-cajero'].show();
            this.$refs['view-cajero'].hide();
        },
        cancelCajero() {
            if (this.cajero.id) {
                this.$refs['view-cajero'].show();
            }
            this.$refs['frm-cajero'].hide();
        },
        saveCajero() {
            this.ss.storeHistorialCajero(this.cajero).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-cajero'].hide();
                    this.$refs['edifrm-cajero'].hide();
                    this.$refs['datatable-cajero'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveAntecedente() {
            this.ss.storeAntecedente(this.antecedente).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-antecedente'].hide();
                    this.$refs['datatable-antecedente'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCajero() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCajero(this.cajero)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-cajero'].hide();
                                this.$refs['datatable-cajero'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-cajero').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCajero(data);
            });
        },
        ReporteCajero() {
            console.log("id del cajero",this.cajero.id);
            this.ss.printReporteCajero(this.cajero.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        abrirModalCajero() {
            this.cajero = {};
            this.$refs['frm-cajeroreportegral'].show();
        },
        setReset() {
            this.cajero = {};
        }, 
        imprimirReportreGralCajero() {
            this.ss.printReportreGralCajero(this.cajero.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirReportreGralCajeroGestion() {
            this.ss.printReportreGralCajerogestion(this.cajero.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
    },
    watch: {
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
            }

    },
    components: {
        dataTable,
        Loader
    },
    filters: {

        intToTipoIngreso: function (value) {
            let tipoingreso = "";
            if (value == 0) {
                tipoingreso = "Curso Presencial"
            } 
             else if (value == 1) {
                tipoingreso = "Curso Virtual"
            }
            else if (value == 2) {
                tipoingreso = "Memorandum"
            }
            else if (value == 3) {
                tipoingreso = "Otros"
            }
            return tipoingreso;
        },
        intToSemestre: function (value) {
            let tipoingreso = "";
            if (value == 0) {
                tipoingreso = "I"
            } 
             else if (value == 1) {
                tipoingreso = "II"
            }
            return tipoingreso;
        }
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        }
        else  {
            this.getReparticion();
            this.getAntecedente();
        }
    }
   
};
