import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";

window.$ = window.jQuery = require("jquery");

export default {
    name: "CargoCuentaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CargoCuentaPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexComprobanteContable(),
                headers: ss.getToken(),
                data: function (d) {
                    d.TipoComprobante = 11;
                }
            },

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Gerencial' },
                { data: 'Concatenado', name: 'Concatenado', title: 'Nº Documento' },
                { data: 'ComprobanteContable', name: 'ComprobanteContable', title: 'Glosa' },
                {
                    data: 'Aprobado', name: 'Aprobado', title: 'Estado', render: function (data, type, row) {
                        if (row.Aprobado == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>'; }
                        else { return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>'; }
                    }
                },

                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexComprobanteContableDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                }

            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                // { data: 'Poa', name: 'rd.Poa', title: 'N Cer Poa'  },
                {
                    data: 'Reparticion', name: 'tc.Reparticion', title: 'Repartición', render: function (data, type, row) {
                        return row.CodRep + ' - ' + row.Reparticion;
                    }
                },
                {
                    data: 'Cuenta', name: 'e.Cuenta', title: 'Cuenta', render: function (data, type, row) {
                        return row.CodCuenta + ' - ' + row.Cuenta;
                    }
                },
                {
                    data: 'Subcuenta', name: 'f.Subcuenta', title: 'Subcuenta', render: function (data, type, row) {
                        return row.CodSubcuenta + ' - ' + row.Subcuenta;
                    }
                },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                { data: 'debe', name: 'debe', title: 'Debe' },
                { data: 'haber', name: 'haber', title: 'Haber' },
                {
                    data: 'ChequeIva', name: 'ChequeIva', title: 'Factura', render: function (data, type, row) {
                        if (row.ChequeIva == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "></i>'; }
                        else { return '<i class="fa fa-check text-success  font-italic font-weight-bold"></i>'; }
                    }
                },
                // { data: 'ChequeIva', name: 'ChequeIva', title: 'Factura' },
                // { data: 'Monto', name: 'a.Monto', title: 'Monto Destino', render:function(data, type, row){
                //     if (row.Tipo)
                //         return row.Monto;
                //     else
                //         return 0;
                //      }},


                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            total: 0,
            // saldo:0,
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            //  editarDetalle:0,
            editar: 0,
            idFormulacion: 0,
            reparticion: {},
            reparticiones: [],
            fuentefinancieras: [],
            partida: {},
            partidas: [],
            comprobanteContableDetalles: [],
            comprobanteContableDetalle: {},
            personas: [],
            formulaciones: [],
            comprobanteContables: {},
            comprobanteContable: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            unidadAcademicas: [],
            ejecuciones: [],
            tipoCambios: [],
            searchCuenta: "",
            selectedCuenta: null,
            selectedSubcuenta: null,
            searchSubcuenta: "",
            tipoCambio: {},
            cuentas: [],
            cuenta: {},
            subcuenta: {},
            subcuentas: [],
            factura: false,
            placeholderSubcuenta: "",
            placeholderCuenta: "",
            excel: null
        };
    },
    methods: {
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoCambios() {
            this.ss.listTipoCambio().then(
                (result) => {
                    let response = result.data;
                    this.tipoCambios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuentefinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        setTipoCambio(fecha) {
            this.ss.listTipoCambio({ "Fecha": fecha }).then(
                (result) => {
                    let response = result.data;
                    console.log("hol2", response.data[0])
                    if (response.success) {
                        this.comprobanteContable.TipoCambio = response.data[0].id;

                        console.log(this.comprobanteContable);
                        //  vm.
                        this.$forceUpdate(); //obliga a actualizar el objeto
                    }
                    else {
                        this.ss.obtenerTipoCambio({ "Fecha": fecha }).then(
                            (result) => {
                                let response = result.data;
                                this.TipoCambio = response.data[0];
                                this.TipoCambio.Fecha = fecha;

                                console.log(this.TipoCambio);
                                this.ss.storeTipoCambio(this.TipoCambio).then(
                                    (result) => {
                                        console.log(result);
                                        let response = result.data;
                                        this.$bvToast.toast(
                                            response.msg,
                                            {
                                                title: 'Correcto',
                                                variant: 'success',
                                                autoHideDelay: 5000
                                            }
                                        )
                                        this.getTipoCambios();
                                        console.log(response);
                                        this.setTipoCambio(fecha);
                                    })
                                    .catch((error) => {
                                        this.errorBag = error.response.data.errors;
                                        this.$bvToast.toast(
                                            'Problema al Guardar el Registro, favor verificar los Datos',
                                            {
                                                title: 'Error',
                                                variant: 'danger',
                                                autoHideDelay: 5000
                                            }
                                        )
                                    });
                            })
                            .catch((error) => {
                                this.errorBag = error.response;
                                this.$bvToast.toast(
                                    'Problema al obtener el tipo de cambio BCB, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    }


                }
            ).catch(error => {
                console.log(error);
            });
        },

        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        saveReparticion() {
            this.reparticion.UnidadAcademica = this.comprobanteContable.UnidadAcademica;
            console.log(this.reparticion);
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.getReparticion();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

        },
        cancelReparticion() {
            if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            }
            this.$refs['frm-reparticion'].hide();
        },
        getReparticion(id) {
            console.log("REparticion", id);
            this.ss.listReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newComprobanteContable() {
            this.comprobanteContable = {};
            this.$refs['frm-comprobanteContable'].show();
            this.editar = 0;
            this.placeholderEjecucion = "Escriba la ejecucion a buscar...";

        },
        showComprobanteContable(id) {
            //console.log("id show certi", id);
            this.isLoading = true;
            this.ss.showComprobanteContable(id).then(
                (result) => {
                    let response = result.data;
                    this.comprobanteContable = response.data;
                    this.$refs['view-comprobanteContable'].show();
                    this.isLoading = false;
                    console.log(this.comprobanteContable.UnidadAcademica);
                    this.getReparticion(this.comprobanteContable.UnidadAcademica);
                    var dd = window.$('#datatable-comprobanteContableDetalle').DataTable();
                    console.log("filas", dd.rows());
                    this.ajaxDetalle.data = function (d) {
                        d.id = id;
                    }



                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editComprobanteContable(a) {
            this.$refs['frm-comprobanteContable'].show();
            this.$refs['view-comprobanteContable'].hide();
            this.editar = 1;
            this.placeholderEjecucion = a.ejecucion.Concatenado + ': ' + a.ejecucion.Ejecucion;

        },
        cancelComprobanteContable() {
            if (this.comprobanteContable.id) {
                this.$refs['view-comprobanteContable'].show();
            }
            this.$refs['frm-comprobanteContable'].hide();
        },
        openCargaExcel() {
            this.$refs['frm-cargaExcelApertura'].show();
            this.$refs['view-comprobanteContable'].hide();
        },
        cancelCargaExcel() {
            if (this.comprobanteContable.id) {
                this.$refs['view-comprobanteContable'].show();
            }
            this.$refs['frm-cargaExcelApertura'].hide();
        },
        loadExcel() {
            let input = this.excel;
            console.log(input);
            if (input) {
                var reader = new FileReader();
                reader.readAsDataURL(input);
                var data = new FormData();
                data.append('File', input);
                data.append('ComprobanteContable', this.comprobanteContable.id);
                this.ss.uploadExcelApertura(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        saveComprobanteContable() {

            this.comprobanteContable.TipoComprobante = 11;
            console.log(" save ComprobanteContable");
            console.log(this.comprobanteContable);
            this.ss.storeComprobanteContable(this.comprobanteContable).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    if (this.editar == 0) {
                        this.showComprobanteContable(response.data.id);
                    }
                    console.log(response);
                    this.$refs['frm-comprobanteContable'].hide();
                    this.$refs['datatable-comprobanteContable'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteComprobanteContable() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyComprobanteContable(this.comprobanteContable)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-comprobanteContable'].hide();
                                this.$refs['datatable-comprobanteContable'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        newComprobanteContableDetalle() {
            this.comprobanteContableDetalle = {};
            this.$refs['frm-comprobanteContableDetalle'].show();
            // this.editarDetalle=0;
            // this.saldo=0;
            this.placeholderCuenta = "Escriba la cuenta...";
            this.placeholderSubcuenta = "Escriba la subcuenta...";
        },
        showComprobanteContableDetalle(id) {
            this.isLoading = true;
            this.ss.showComprobanteContableDetalle(id).then(
                (result) => {

                    let response = result.data;
                    this.comprobanteContableDetalle = response.data;
                    console.log("show");
                    console.log(this.comprobanteContableDetalle);
                    this.$refs['view-comprobanteContableDetalle'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editComprobanteContableDetalle(a) {
            this.$refs['frm-comprobanteContableDetalle'].show();
            this.$refs['view-comprobanteContableDetalle'].hide();
            this.placeholderCuenta = a.cuenta.Codigo + ': ' + a.cuenta.Cuenta;
            this.placeholderSubcuenta = a.subcuenta.Codigo + ': ' + a.subcuenta.Subcuenta;
            if (a.ChequeIva)
                this.factura = true;
            else
                this.factura = false;


        },
        cancelComprobanteContableDetalle() {
            if (this.comprobanteContableDetalle.id) {
                this.$refs['view-comprobanteContableDetalle'].show();
            }
            this.$refs['frm-comprobanteContableDetalle'].hide();
            // this.saldo=0;
        },
        saveComprobanteContableDetalle() {
            ///
            this.comprobanteContableDetalle.ComprobanteContable = this.comprobanteContable.id;
            console.log(" save ComprobanteContable");
            console.log(this.comprobanteContableDetalle);

            if (this.comprobanteContableDetalle.Factura == true && this.comprobanteContableDetalle.Mes == null) {
                this.$bvToast.toast(
                    'Seleccione el mes la que pertenece la factura.',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )



            } else {
                this.ss.storeComprobanteContableDetalle(this.comprobanteContableDetalle).then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        //this.$refs['view-consulta'].show(); //para volver al modal
                        console.log(response);
                        this.$refs['frm-comprobanteContableDetalle'].hide();
                        this.$refs['datatable-comprobanteContableDetalle'].reload();
                        this.showComprobanteContable(this.comprobanteContable.id);
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }
        },
        deleteComprobanteContableDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyComprobanteContableDetalle(this.comprobanteContableDetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.showComprobanteContable(this.comprobanteContable.id);
                                this.$refs['view-comprobanteContableDetalle'].hide();
                                this.$refs['datatable-comprobanteContableDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        cambiopassword() {
            this.$refs['view-comprobanteContable'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-comprobanteContable'].show();
        },
        changePassword() {
            this.password.ComprobanteContable = this.comprobanteContable.id;
            this.ss.changePassword(this.password)
                .then(result => {
                    let response = result.data;
                    if (response.success) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-comprobanteContable'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        showMes(a) {
            console.log("Factura", a);
            if (a == true)
                this.factura = true;
            else
                this.factura = false;
        },

        aprobarComprobanteContable() {

            if (this.comprobanteContable.comprobante_contable_detalle.length === 0) {

                this.$bvToast.toast(
                    'Registre detalles de comprobanteContable.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            } else {

                this.isLoading = true;
                this.ss.aprobarComprobanteContable({ 'id': this.comprobanteContable.id, 'Aprobado': true }).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['datatable-comprobanteContable'].reload();
                        this.$refs['view-comprobanteContable'].hide();
                        this.isLoading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });



            }

        },
        desaprobarComprobanteContable() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de esta comprobanteContable.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarComprobanteContable({ 'id': this.comprobanteContable.id, 'Aprobado': false }).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['datatable-comprobanteContable'].reload();
                                this.$refs['view-comprobanteContable'].hide();
                                this.isLoading = false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        reporteComprobanteContable() {
            console.log("reporte comprobanteContable", this.comprobanteContable.id);
            this.ss.imprimirComprobanteContable(this.comprobanteContable.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        console.log(urlFile);
                        console.log("-------------------------------------------------------");
                        console.log(this.rutaApp);
                        var dir = this.rutaApp + urlFile.url;
                        console.log("-------------------------------------------------------");
                        console.log(dir);
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        draw() {
            window.$('.btn-datatable-ComprobanteContable').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showComprobanteContable(data);
            });
        },
        drawDetalle() {
            // this.idCertificacion=1;
            window.$('.btn-datatable-ComprobanteContableDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showComprobanteContableDetalle(data);
            });

        }
    },
    watch: {
        searchCuenta(q) {

            this.ss.select2Cuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.cuentas = res.data.items;

                } else {
                    this.cuentas = [];
                }
            })


        },
        searchSubcuenta(q) {

            this.ss.select2Subcuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.subcuentas = res.data.items;

                } else {
                    this.subcuentas = [];
                }
            })


        },

    },
    components: {
        dataTable,
        Loader
    },
    filters: {
        intToMonth: function (value) {
            if (!value) return '';
            let mes = "";
            if (value == 1) {
                mes = "Enero"
            } else if (value == 2) {
                mes = "Febrero"
            } else if (value == 3) {
                mes = "Marzo"
            } else if (value == 4) {
                mes = "Abril"
            } else if (value == 5) {
                mes = "Mayo"
            } else if (value == 6) {
                mes = "Junio"
            } else if (value == 7) {
                mes = "Julio"
            } else if (value == 8) {
                mes = "Agosto"
            } else if (value == 9) {
                mes = "Septiembre"
            } else if (value == 10) {
                mes = "Octubre"
            } else if (value == 11) {
                mes = "Noviembre"
            } else if (value == 12) {
                mes = "Diciembre"
            }
            return mes;
        },
        intToTipo: function (value) {
            //  console.log(value);
            if (value == null) return '';
            let tipo = "";
            //0 debe 1 Haber
            if (value == 0) {
                tipo = "Debe"
            } else if (value == 1) {
                tipo = "Haber"
            }
            return tipo;
        },
        intToFactura: function (value) {
            //  console.log(value);
            if (value == null) return '';
            let tipo = "";
            //0 debe 1 Haber
            if (value == false) {
                tipo = "No"
            } else if (value == true) {
                tipo = "Si"
            }
            return tipo;
        }

    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {

            this.getPersona();
            this.getFuenteFinanciera();
            this.getUnidadAcademica();
            this.getTipoCambios();

            this.getReparticion(0);


        }
    }
};
