import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PlanPagoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PlanPagoPage",
            ss: ss,
            ajax: {
                "url": ss.indexPlanPago(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'PlanPago', name: 'pp.PlanPago', title: 'PlanPago' },
                { data: 'Descripcion', name: 'pp.Descripcion', title: 'Descripcion' },
                { data: 'Tipo', name: 'pp.Tipo', title: 'Tipo' },
                { data: 'Porcentaje', name: 'pp.Porcentaje', title: 'Porcentaje %' },
                { data: 'Gestion', name: 'pp.Gestion', title: 'Gestion' },
                { data: 'NivelAcademico', name: 'na.NivelAcademico', title: 'Nivel Academico' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Gerencial' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            nivelesAcademicos: [],
            planpago: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getNivelAcademico() {
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.nivelesAcademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newPlanPago() {
            this.planpago = {};
            this.$refs['frm-planpago'].show();
        },
        showPlanPago(id) {
            this.isLoading=true;
            this.ss.showPlanPago(id).then(
                (result) => {
                    let response = result.data;
                    this.planpago = response.data;
                    this.$refs['view-planpago'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPlanPago() {
            this.$refs['frm-planpago'].show();
            this.$refs['view-planpago'].hide();
        },
        cancelPlanPago() {
            if (this.planpago.id) {
                this.$refs['view-planpago'].show();
            }
            this.$refs['frm-planpago'].hide();
        },
        savePlanPago() {
            this.ss.storePlanPago(this.planpago).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-planpago'].hide();
                    this.$refs['datatable-planpago'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePlanPago() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPlanPago(this.planpago)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-planpago'].hide();
                                this.$refs['datatable-planpago'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-PlanPago').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPlanPago(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }

        this.getUnidadAcademica();
        this.getNivelAcademico();
    }
};
