import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "RRTPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RRTPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexRRT(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Preventivo', name: 'de.Preventivo', title: 'Preventivo' },
                { data: 'Cuenta', name: 'c.Cuenta', title: 'Cuenta' },
                { data: 'Subcuenta', name: 'sc.Subcuenta', title: 'Subcuenta' },
                { data: 'Desembolsado', name: 'de.Desembolsado', title: 'Desembolsado' },
                { data: 'Ejecutado', name: 'de.Ejecutado', title: 'Ejecutado' },
                { data: 'Retenido', name: 'de.Retenido', title: 'Retenido' },
                { data: 'Revertido', name: 'de.Revertido', title: 'Revertido' },
                { data: 'Aprobado', name: 'de.Aprobado', title: 'Aprobado' },
                // {
                //     data: 'DescargoEstado',
                //     name: 'DescargoEstado',
                //     title: 'Estado',
                //     render: function (data, type, row) {
                //         if (row.DescargoEstado == 1) {
                //             return '<i class="fa fa-times text-warning font-italic font-weight-bold "> Pendiente </i>';
                //         } else if (row.DescargoEstado == 2) {
                //             return '<i class="fa fa-check text-info  font-italic font-weight-bold"> Enviado </i>';
                //         } else if (row.DescargoEstado == 3) {
                //             return '<i class="fa fa-check text-danger  font-italic font-weight-bold"> Observado </i>';
                //         } else if (row.DescargoEstado == 4) {
                //             return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';
                //         } else if (row.DescargoEstado == 5) {
                //             return '<i class="fa fa-check text-secondary  font-italic font-weight-bold"> Cerrado </i>';
                //         }
                //     }
                // },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            comprobanteContableDetalles: [],
            descargo: {},
            comprobantecontables: [],
            comprobantecontabledetalles: [],
            descargoestados: [],
            unidadacademicas: [],
            cuentas: [],
            subcuentas: [],
            cuenta: {},
            subcuenta: {},
            reparticions: [],
            placeholderSubcuenta: "",
            placeholderCuenta: "",
            searchCuenta: "",
            selectedCuenta: null,
            selectedSubcuenta: null,
            searchSubcuenta: "",
            isLoading: false,
            isLoadingFile: false,
            isLoadedFile: false,
            selecteddescargoestado: 0,
            selectedunidadacademica: 0,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            detalleDescargo: {},
            seguimiento: [],
            retenciones: [],
            tipoRetencion: 0,
            periodo: "",
        };
    },
    methods: {
        filtro() {
            let DescargoEstado = this.selecteddescargoestado;
            let UnidadAcademica = this.selectedunidadacademica;
            this.ajax.data = function (d) {
                d.DescargoEstado = DescargoEstado;
                d.UnidadAcademica = UnidadAcademica;
            }
            this.$refs['datatable-descargo'].reloadAjax(this.ajax);
        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.descargo.Respaldo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getComprobanteContable() {
            this.ss.listComprobanteContable().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontables = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getComprobanteContableDetalle() {
            this.ss.listComprobanteContableDetalle().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontabledetalles = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getRetenciones() {
            this.ss.listRetencion().then(
                (result) => {
                    let response = result.data;
                    this.retenciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion(0).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDescargoEstado() {
            this.ss.listDescargoEstado(0).then(
                (result) => {
                    let response = result.data;
                    this.descargoestados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newDescargo() {
            this.descargo = {};
            this.$refs['frm-descargo'].show();
            this.placeholderCuenta = "Escriba la cuenta...";
            this.placeholderSubcuenta = "Escriba la subcuenta...";
        },
        showDescargo(id) {
            this.tipoRetencion = 0;
            this.isLoading = true;
            this.ss.showDescargo(id).then(
                (result) => {
                    let response = result.data;
                    this.descargo = response.data;
                    this.$refs['view-descargo'].show();
                    this.isLoading = false;
                    this.getSeguimiento(this.descargo.id);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editDescargo() {
            this.placeholderCuenta = this.descargo.cuenta ? this.descargo.cuenta.Codigo + ': ' + this.descargo.cuenta.Cuenta : "Escribe la cuenta..";
            this.placeholderSubcuenta = this.descargo.subcuenta ? this.descargo.subcuenta.Codigo + ': ' + this.descargo.subcuenta.Subcuenta : "Escribe la subcuenta..";
            this.$refs['frm-descargo'].show();
            this.$refs['view-descargo'].hide();
        },
        openUpload() {
            this.$refs['frm-upload'].show();
            this.$refs['view-descargo'].hide();
        },
        cancelUpload() {
            if (this.descargo.id) {
                this.$refs['view-descargo'].show();
            }
            this.$refs['frm-upload'].hide();
        },
        cancelDescargo() {
            if (this.descargo.id) {
                this.$refs['view-descargo'].show();
            }
            this.$refs['frm-descargo'].hide();
        },
        openReverse() {
            this.$refs['frm-reverse'].show();
            this.$refs['view-descargo'].hide();
        },
        cancelReverse() {
            if (this.descargo.id) {
                this.$refs['view-descargo'].show();
            }
            this.$refs['frm-reverse'].hide();
        },

        openTributacion() {
            this.$refs['frm-tributacion'].show();
            this.$refs['view-descargo'].hide();
        },
        cancelTributacion() {
            if (this.descargo.id) {
                this.$refs['view-descargo'].show();
            }
            this.$refs['frm-tributacion'].hide();
        },

        openRetencion() {
            this.$refs['frm-retencion'].show();
            this.$refs['view-descargo'].hide();
        },
        cancelRetencion() {
            if (this.descargo.id) {
                this.$refs['view-descargo'].show();
            }
            this.$refs['frm-retencion'].hide();
        },
        openListRetencion() {
            this.$refs['frm-listRetencion'].show();
        },
        cancelListRetencion() {
            this.$refs['frm-listRetencion'].hide();
        },
        saveDescargo() {
            this.ss.storeDescargo(this.descargo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-descargo'].hide();
                    this.$refs['datatable-descargo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDescargo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyDescargo(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-descargo'].hide();
                                this.$refs['datatable-descargo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        uploadDescargo() {
            this.$swal({
                title: "Estas seguro que deseas cargar tus respaldos?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.uploadDescargo(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-upload'].hide();
                                this.showDescargo(this.descargo.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        closeDescargo() {
            this.$swal({
                title: "Estas seguro que deseas Finalizar y Archivar el descargo?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.closeDescargo(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.showDescargo(this.descargo.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },


        abrirModalAprobar() {
            this.$refs['modalAprobar'].show();
        },
        cerrarModalAprobar() {
            this.$refs['modalAprobar'].hide();
        },

        abrirModalSeguimiento() {
            this.$refs['modalSeguimiento'].show();
        },
        cerrarModalSeguimiento() {
            this.$refs['modalSeguimiento'].hide();
        },

        getSeguimiento(id) {
            this.ss.listDescargoDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.seguimiento = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        aprobarDescargo(estado) {
            this.$swal({
                title: "Estas seguro de derivar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.detalleDescargo.Descargo = this.descargo.id;
                        this.detalleDescargo.Persona = this.auth.id;
                        this.detalleDescargo.Rol = this.auth.Rol;
                        this.detalleDescargo.Aprobado = estado;
                        this.ss.storeDescargoDetalle(this.detalleDescargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.$refs['modalAprobar'].hide();
                                this.$refs['view-descargo'].hide();

                                this.$refs['datatable-descargo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        recalcular() {
            console.log("tipo reversion");
            if (this.tipoRetencion == 1) {
                // console.log("tr 1",this.tipoRetencion);
                // console.log(parseFloat(this.descargo.Desembolsado) * 0.155);
                this.descargo.Retenido = parseFloat(this.descargo.Desembolsado) * 0.155;
                this.descargo.Ejecutado = parseFloat(this.descargo.Desembolsado) - this.descargo.Retenido;
                this.descargo.Aprobado = this.descargo.Ejecutado;
            }
            if (this.tipoRetencion == 2) {
                // console.log("tr 1",this.tipoRetencion);

                this.descargo.Retenido = parseFloat(this.descargo.Desembolsado) * 0.08;
                this.descargo.Ejecutado = parseFloat(this.descargo.Desembolsado) - this.descargo.Retenido;
                this.descargo.Aprobado = this.descargo.Ejecutado;


            }
            if (this.tipoRetencion == 3) {
                // console.log("tr 1",this.tipoRetencion);

                this.descargo.Retenido = parseFloat(this.descargo.Desembolsado) * 0.13;
                this.descargo.Ejecutado = parseFloat(this.descargo.Desembolsado) - this.descargo.Retenido;
                this.descargo.Aprobado = this.descargo.Ejecutado;


            }
        },

        generateListRetencion() {
            this.$swal({
                title: "Estas seguro que deseas realizar esta accion?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.printListado();
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        reverseDescargo() {
            this.$swal({
                title: "Estas seguro que deseas revertir el monto?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.reverseDescargo(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-reverse'].hide();
                                this.showDescargo(this.descargo.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        printListado() {
            console.log("hola");
            console.log(this.periodo);
            this.ss.printRRT(this.periodo)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        console.log("hola", response);
                        console.log(urlFile);
                        console.log("-------------------------------------------------------");
                        console.log(this.rutaApp);
                        var dir = this.rutaApp + response.url;
                        console.log("-------------------------------------------------------");
                        console.log(dir);
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },

        addTributacion() {
            this.$swal({
                title: "Estas seguro que deseas realizar esta accion?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.addTributacion(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-tributacion'].hide();
                                this.showDescargo(this.descargo.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        finishRevert() {
            this.$swal({
                title: "Estas seguro que deseas confirmar la reversion y retencion?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true
            })
            .then((willDelete) => {
                if (willDelete.isConfirmed) {
                    this.ss.finishRRT(this.descargo.id)
                        .then((result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-descargo'].hide();
                            this.$refs['datatable-descargo'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
        },

        retencionDescargo() {
            this.$swal({
                title: "Estas seguro que desea agregar esta retención",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.retencionDescargo(this.descargo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-retencion'].hide();
                                this.showDescargo(this.descargo.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Descargo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDescargo(data);
            });
        },
        printDescargo() {
            this.ss.printDescargo(this.descargo.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        }
    },
    watch: {
        searchCuenta(q) {

            this.ss.select2Cuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.cuentas = res.data.items;

                } else {
                    this.cuentas = [];
                }
            })


        },
        searchSubcuenta(q) {

            this.ss.select2Subcuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.subcuentas = res.data.items;

                } else {
                    this.subcuentas = [];
                }
            })


        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getCuenta();
            this.getReparticion(0);
            this.getSubcuenta();
            this.getComprobanteContable();
            this.getComprobanteContableDetalle();
            this.getDescargoEstado();
            this.getUnidadAcademica();
            this.getRetenciones();
        }
    }
};
