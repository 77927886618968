var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    listPersonaRevisor() {
        return axios.get('api/Persona/listrevisor');
    }

    listPersonaSuper() {
        return axios.get('api/Persona/listsupervisor');
    }

    listPersonaAsignada() {
        return axios.get('api/Persona/listasignado');
    }

    listPersonaAsignadaBenFin() {
        return axios.get('api/Persona/listabenfin');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }


    indexRetencion() {
        return axios.defaults.baseURL + 'api/Retencion/index';
    }

    listRetencion() {
        return axios.get('api/Retencion/list');
    }

    storeRetencion(item) {
        return axios.post('api/Retencion/store', item);
    }

    destroyRetencion(item) {
        return axios.post('api/Retencion/destroy', item);
    }


    ////******  ComprobanteContable */
    showComprobanteContable(id) {
        return axios.get('api/ComprobanteContable/show?id=' + id);
    }

    indexComprobanteContable() {
        return axios.defaults.baseURL + 'api/ComprobanteContable/index';
    }

    indexComprobanteContableTotal() {
        return axios.defaults.baseURL + 'api/ComprobanteContable/indextotal';
    }

    indexComprobanteContablegasto() {
        return axios.defaults.baseURL + 'api/ComprobanteContable/indexgasto';
    }

    indexComprobanteContabletesore() {
        return axios.defaults.baseURL + 'api/ComprobanteContable/indextesore';
    }

    indexComprobanteContableArchivo() {
        return axios.defaults.baseURL + 'api/ComprobanteContable/indexArchivo';
    }

    listComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/list', item);
    }

    storeComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/store', item);
    }

    destroyComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/destroy', item);
    }
    imprimirComprobanteContable(id) {
        return axios.get('api/ComprobanteContable/imprimir?id=' + id);
    }

    imprimirCargoCuenta(id) {
        return axios.get('api/ComprobanteContable/imprimirCargoCuenta?id=' + id);
    }

    imprimirComprobanteTeso(id) {
        return axios.get('api/ComprobanteContable/imprimirComprobanteTeso?id=' + id);
    }

    imprimirComprobanteNota(id) {
        return axios.get('api/ComprobanteContable/imprimirComprobanteNota?id=' + id);
    }

    imprimirIngresoS(id) {
        return axios.get('api/IngresoPresupuestario/imprimirTesoS?id=' + id);
    }

    imprimirDevengadoRango(item) {
        return axios.post('api/IngresoPresupuestario/imprimirDevengadoRango', item);
    }

    imprimirIngresoFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirTesoSFechas',item);
    }

    imprimirRubroPadre(item) {
        return axios.post('api/IngresoPresupuestario/imprimirRubroPadre', item);
    }
    imprimirRubroPadreS(item) {
        return axios.post('api/IngresoPresupuestario/imprimirRubroPadreS', item);
    }

    imprimirRubroPadreFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirRubroPadreFechas', item);
    }

    imprimirRubro(item) {
        return axios.post('api/IngresoPresupuestario/imprimirRubro', item);
    }

    imprimirRubroFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirRubroFechas', item);
    }

    imprimirRubroGestion(item) {
        return axios.post('api/IngresoPresupuestario/imprimirRubroGestion', item);
    }

    imprimirRubroFechasSolo(item) {
        return axios.post('api/IngresoPresupuestario/imprimirRubroFechasSolo', item);
    }

    imprimirReparticionGestion(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionGestion', item);
    }

    imprimirReparticionRubroPadre(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionRubroPadre', item);
    }
    imprimirReparticionRubroPadreAnual(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionRubroPadreAnual', item);
    }

    imprimirReparticionRubro(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionRubro', item);
    }

    imprimirReparticionSoloRubro(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionSoloRubro', item);
    }

    imprimirReparticionFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionFechas', item);
    }

    imprimirReparticionRubroPadreFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionRubroPadreFechas', item);
    }

    imprimirReparticionRubroFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionRubroFechas', item);
    }

    imprimirReparticionSoloRubroFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirReparticionSoloRubroFechas', item);
    }

    imprimirIngresosFechas(item) {
        return axios.post('api/IngresoPresupuestario/imprimirIngresosFechas', item);
    }

    imprimirIngresosFavor(item) {
        return axios.post('api/IngresoPresupuestario/imprimirIngresosFavor', item);
    }


    /////////////////COMPROBANTE CONTABLE ////////////
    aprobarComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/aprobar', item);
    }

    mandarTesoDescargo(item) {
        return axios.post('api/ComprobanteContable/mandarTesoDescargo', item);
    }
    select2ComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/select2', item);
    }

    select24ComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/select24', item);
    }

    select26ComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/select26', item);
    }

    select2Comprobantefr(item) {
        return axios.post('api/ComprobanteContable/selectfr', item);
    }

    storeCierreArchivo(item) {
        return axios.post('api/IngresoPresupuestario/storeCierre', item);
    }

    storeCierreComprobante(item) {
        return axios.post('api/ComprobanteContable/storeCierreComprobante', item);
    }

    storeReversionG(item) {
        return axios.post('api/ComprobanteContable/storeReversionG', item);
    }

    revertirComprobanteContable(item) {
        return axios.post('api/ComprobanteContable/revertirComprobanteContable', item);
    }

    imprimirArchivofr(item) {
        return axios.post('api/ComprobanteContable/imprimirArchivofr', item);
    }

    imprimirArchivofrprev(item) {
        return axios.post('api/ComprobanteContable/imprimirArchivofrprev', item);
    }

    imprimirArchivofrrecibido(item) {
        return axios.post('api/ComprobanteContable/imprimirArchivofrrecibido', item);
    }


    ////******  ComprobanteContableDetalle */
    showComprobanteContableDetalle(id) {
        return axios.get('api/ComprobanteContableDetalle/show?id=' + id);
    }

    indexComprobanteContableDetalle() {
        return axios.defaults.baseURL + 'api/ComprobanteContableDetalle/index';
    }

    listComprobanteContableDetalle() {
        return axios.get('api/ComprobanteContableDetalle/list');
    }

    storeComprobanteContableDetalle(item) {
        return axios.post('api/ComprobanteContableDetalle/store', item);
    }

    destroyComprobanteContableDetalle(item) {
        return axios.post('api/ComprobanteContableDetalle/destroy', item);
    }

    uploadExcelApertura(item) {
        return axios.post('api/ComprobanteContableDetalle/uploadExcelApertura', item);
    }

    imprimirLibretaBancaria(item) {
        return axios.post('api/ComprobanteContableDetalle/libretaBancaria', item);
    }
    


    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }
    //FuenteFinanciera
    showFuenteFinanciera(id) {
        return axios.get('api/FuenteFinanciera/show?id=' + id);
    }

    indexFuenteFinanciera() {
        return axios.defaults.baseURL + 'api/FuenteFinanciera/index';
    }

    listFuenteFinanciera() {
        return axios.get('api/FuenteFinanciera/list');
    }

    storeFuenteFinanciera(item) {
        return axios.post('api/FuenteFinanciera/store', item);
    }

    destroyFuenteFinanciera(item) {
        return axios.post('api/FuenteFinanciera/destroy', item);
    }

    //Partida
    showPartida(id) {
        return axios.get('api/Partida/show?id=' + id);
    }

    indexPartida() {
        return axios.defaults.baseURL + 'api/Partida/index';
    }

    listPartida() {
        return axios.get('api/Partida/list');
    }

    storePartida(item) {
        return axios.post('api/Partida/store', item);
    }

    destroyPartida(item) {
        return axios.post('api/Partida/destroy', item);
    }

    select2Partida(item) {
         return axios.get('api/Partida/select2?q=' + item);
    }

    listpartidas(item) {
        return axios.get('api/Partida/listpartidas?q='+ item);
    }

    //CategoriaProgramatica
    showCategoriaProgramatica(id) {
        return axios.get('api/CategoriaProgramatica/show?id=' + id);
    }

    indexCategoriaProgramatica() {
        return axios.defaults.baseURL + 'api/CategoriaProgramatica/index';
    }

    listCategoriaProgramatica() {
        return axios.get('api/CategoriaProgramatica/list');
    }

    storeCategoriaProgramatica(item) {
        return axios.post('api/CategoriaProgramatica/store', item);
    }

    destroyCategoriaProgramatica(item) {
        return axios.post('api/CategoriaProgramatica/destroy', item);
    }
    select2CategoriaProgramatica(item) {
        return axios.get('api/CategoriaProgramatica/select2?q=' + item);
   }

    //Reparticion
    showReparticion(id) {
        return axios.get('api/Reparticion/show?id=' + id);
    }

    indexReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/index';
    }

    listReparticion(id) {
        return axios.get('api/Reparticion/list?UnidadAcademica=' + id);
    }

    listReparticion2(parametros) {
        return axios.get('api/Reparticion/list', { params: parametros });
    }

    storeReparticion(item) {
        return axios.post('api/Reparticion/store', item);
    }

    destroyReparticion(item) {
        return axios.post('api/Reparticion/destroy', item);
    }
    select2Reparticion(item) {
        return axios.get('api/Reparticion/select2?q=' + item);
   }

    //Formulacion
    showFormulacion(id) {
        return axios.get('api/Formulacion/show?id=' + id);
    }

    indexFormulacion() {
        return axios.defaults.baseURL + 'api/Formulacion/index';
    }

    listFormulacion() {
        return axios.get('api/Formulacion/list');
    }
    listAllFormulacion() {
        return axios.get('api/Formulacion/list?allData=true');
    }

    storeFormulacion(item) {
        return axios.post('api/Formulacion/store', item);
    }

    destroyFormulacion(item) {
        return axios.post('api/Formulacion/destroy', item);
    }

    imprimirFormulacionGral() {
        return axios.get('api/Formulacion/imprimirFormulacionGral');
    }

    imprimirFormulacion(id) {
        return axios.get('api/Formulacion/imprimir?id=' + id);
    }

    imprimirFormulacionIndividual(id) {
        return axios.get('api/Formulacion/imprimirFormulacionIndividual?id=' + id);
    }

    aprobarFormulacion(item) {
        return axios.post('api/Formulacion/aprobar', item);
    }

    select2Formulacion(item) {
        return axios.get('api/Formulacion/select2?q=' + item);
    }
    //FormulacionDetalle
    showFormulacionDetalle(id) {
        return axios.get('api/FormulacionDetalle/show?id=' + id);
    }

    indexFormulacionDetalle() {
        return axios.defaults.baseURL + 'api/FormulacionDetalle/index';
    }

    listFormulacionDetalle() {
        return axios.get('api/FormulacionDetalle/list');
    }

    storeFormulacionDetalle(item) {
        return axios.post('api/FormulacionDetalle/store', item);
    }

    destroyFormulacionDetalle(item) {
        return axios.post('api/FormulacionDetalle/destroy', item);
    }

    saldoFormulacionDetalle(item) {
        return axios.post('api/FormulacionDetalle/saldo', item);
    }
    listFormulacionCategoria(item) {
        return axios.post('api/FormulacionDetalle/listCategoria', item);
    }
    listFormulacionPartida(item) {
        return axios.post('api/FormulacionDetalle/listPartida', item);
    }
    listFormulacionReparticion(item) {
        return axios.post('api/FormulacionDetalle/listReparticion', item);
    }
    listFormulacionFuenteFinanciera(item) {
        return axios.post('api/FormulacionDetalle/listFuenteFinanciera', item);
    }
    //------------------------------ Ejecucion -----------------------
    showEjecucion(id) {
        return axios.get('api/Ejecucion/show?id=' + id);
    }

    indexEjecucion() {
        return axios.defaults.baseURL + 'api/Ejecucion/index';
    }

    listEjecucion() {
        return axios.get('api/Ejecucion/list');
    }

    storeEjecucion(item) {
        return axios.post('api/Ejecucion/store', item);
    }

    destroyEjecucion(item) {
        return axios.post('api/Ejecucion/destroy', item);
    }
    imprimirEjecucion(id) {
        return axios.get('api/Ejecucion/imprimir?id=' + id);
    }
    aprobarEjecucion(item) {
        return axios.post('api/Ejecucion/aprobar', item);
    }
    select2Ejecucion(item) {
        return axios.get('api/Ejecucion/select2?q=' + item);
    }
    select2EjecucionDevengado(item) {
        return axios.get('api/Ejecucion/select2?q=' + item );
        // return axios.get('api/Ejecucion/select2',item);
    }
    //------------------------------ Certificacion Ejecucion -----------------------
    showCertificacionEjecucion(id) {
        return axios.get('api/CertificacionEjecucion/show?id=' + id);
    }

    indexCertificacionEjecucion() {
        return axios.defaults.baseURL + 'api/CertificacionEjecucion/index';
    }

    listCertificacionEjecucion() {
        return axios.get('api/CertificacionEjecucion/list');
    }

    storeCertificacionEjecucion(item) {
        return axios.post('api/CertificacionEjecucion/store', item);
    }

    destroyCertificacionEjecucion(item) {
        return axios.post('api/CertificacionEjecucion/destroy', item);
    }

    //------------------------------ Ejecucion Detalle -----------------------
    showEjecucionDetalle(id) {
        return axios.get('api/EjecucionDetalle/show?id=' + id);
    }

    indexEjecucionDetalle() {
        return axios.defaults.baseURL + 'api/EjecucionDetalle/index';
    }

    listEjecucionDetalle() {
        return axios.get('api/EjecucionDetalle/list');
    }

    storeEjecucionDetalle(item) {
        return axios.post('api/EjecucionDetalle/store', item);
    }

    destroyEjecucionDetalle(item) {
        return axios.post('api/EjecucionDetalle/destroy', item);
    }

    //-------------------- Tipo Comprtobante ----------------
    showTipoComprobante(id) {
        return axios.get('api/TipoComprobante/show?id=' + id);
    }

    indexTipoComprobante() {
        return axios.defaults.baseURL + 'api/TipoComprobante/index';
    }

    listTipoComprobante() {
        return axios.get('api/TipoComprobante/list');
    }

    storeTipoComprobante(item) {
        return axios.post('api/TipoComprobante/store', item);
    }

    destroyTipoComprobante(item) {
        return axios.post('api/TipoComprobante/destroy', item);
    }
    //------------------------------ EjecucionSubcuenta -----------------------
    showEjecucionSubcuenta(id) {
        return axios.get('api/EjecucionSubcuenta/show?id=' + id);
    }

    indexEjecucionSubcuenta() {
        return axios.defaults.baseURL + 'api/EjecucionSubcuenta/index';
    }

    listEjecucionSubcuenta() {
        return axios.get('api/EjecucionSubcuenta/list');
    }

    storeEjecucionSubcuenta(item) {
        return axios.post('api/EjecucionSubcuenta/store', item);
    }

    destroyEjecucionSubcuenta(item) {
        return axios.post('api/EjecucionSubcuenta/destroy', item);
    }

    //------------------------------ Cuenta -----------------------
    showCuenta(id) {
        return axios.get('api/Cuenta/show?id=' + id);
    }

    indexCuenta() {
        return axios.defaults.baseURL + 'api/Cuenta/index';
    }

    listCuenta() {
        return axios.get('api/Cuenta/list');
    }

    storeCuenta(item) {
        return axios.post('api/Cuenta/store', item);
    }

    destroyCuenta(item) {
        return axios.post('api/Cuenta/destroy', item);
    }
    select2Cuenta(item) {
        return axios.get('api/Cuenta/select2?q=' + item);
    }
    select2CuentaAutocomplete(parametros) {
        return axios.get('api/Cuenta/select2autocomplete', { params: parametros });
    }
    //------------------------------ SubCuenta -----------------------
    showSubcuenta(id) {
        return axios.get('api/Subcuenta/show?id=' + id);
    }

    indexSubcuenta() {
        return axios.defaults.baseURL + 'api/Subcuenta/index';
    }

    listSubcuenta() {
        return axios.get('api/Subcuenta/list');
    }

    storeSubcuenta(item) {
        return axios.post('api/Subcuenta/store', item);
    }

    destroySubcuenta(item) {
        return axios.post('api/Subcuenta/destroy', item);
    }
    select2Subcuenta(item) {
        return axios.get('api/Subcuenta/select2?q=' + item);
    }

    select2SubcuentaAutocomplete(parametros) {
        return axios.get('api/Subcuenta/select2autocomplete', { params: parametros });
    }
    //-------------------- Tipo Cambio ----------------
    showTipoCambio(id) {
        return axios.get('api/TipoCambio/show?id=' + id);
    }

    indexTipoCambio() {
        return axios.defaults.baseURL + 'api/TipoCambio/index';
    }

    listTipoCambio(item) {
        return axios.post('api/TipoCambio/list', item);
    }

    obtenerTipoCambio(item) {
        //return axios.defaults.baseURL + 'api/TipoCambio/getValoresBancoCentral';
        return axios.post('api/TipoCambio/getValoresBancoCentral', item);
    }

    storeTipoCambio(item) {
        return axios.post('api/TipoCambio/store', item);
    }

    destroyTipoCambio(item) {
        return axios.post('api/TipoCambio/destroy', item);
    }

    //------------------------------ Gasto Contable -----------------------
    showGastoContable(id) {
        return axios.get('api/GastoContable/show?id=' + id);
    }

    indexGastoContable() {
        return axios.defaults.baseURL + 'api/GastoContable/index';
    }

    storeGastoContable(item) {
        return axios.post('api/GastoContable/store', item);
    }

    listGastoContable() {
        return axios.get('api/GastoContable/list');
    }

    destroyGastoContable(item) {
        return axios.post('api/GastoContable/destroy', item);
    }

    ////******  ComprobanteContableDetalle */
    showGastoContableDetalle(id) {
        return axios.get('api/GastoContableDetalle/show?id=' + id);
    }

    indexGastoContableDetalle() {
        return axios.defaults.baseURL + 'api/GastoContableDetalle/index';
    }

    listGastoContableDetalle() {
        return axios.get('api/GastoContableDetalle/list');
    }

    storeGastoContableDetalle(item) {
        return axios.post('api/GastoContableDetalle/store', item);
    }

    destroyGastoContableDetalle(item) {
        return axios.post('api/GastoContableDetalle/destroy', item);
    }

    //------------------------------ Rubro -----------------------
    showRubro(id) {
        return axios.get('api/Rubro/show?id=' + id);
    }

    indexRubro() {
        return axios.defaults.baseURL + 'api/Rubro/index';
    }

    listRubro() {
        return axios.get('api/Rubro/list');
    }
    listRubroPadre() {
        return axios.get('api/Rubro/listPadres');
    }
    listRubroHijo(id) {
        return axios.get('api/Rubro/listHijos?Padre='+id);
    }

    storeRubro(item) {
        return axios.post('api/Rubro/store', item);
    }

    destroyRubro(item) {
        return axios.post('api/Rubro/destroy', item);
    }

    //------------------------------ Reformulacion -----------------------
    showReformulacion(id) {
        return axios.get('api/Reformulacion/show?id=' + id);
    }

    indexReformulacion() {
        return axios.defaults.baseURL + 'api/Reformulacion/index';
    }

    listReformulacion() {
        return axios.get('api/Reformulacion/list');
    }

    storeReformulacion(item) {
        return axios.post('api/Reformulacion/store', item);
    }

    destroyReformulacion(item) {
        return axios.post('api/Reformulacion/destroy', item);
    }
    imprimirReformulacion(id) {
        return axios.get('api/Reformulacion/imprimir?id=' + id);
    }
    imprimirReformulacionnueva(id) {
        return axios.get('api/Reformulacion/imprimirreformulacion?id=' + id);
    }
    aprobarReformulacion(item) {
        return axios.post('api/Reformulacion/aprobar', item);
    }
    //------------------------------ ReformulacionDetalle -----------------------
    showReformulacionDetalle(id) {
        return axios.get('api/ReformulacionDetalle/show?id=' + id);
    }

    indexReformulacionDetalle() {
        return axios.defaults.baseURL + 'api/ReformulacionDetalle/index';
    }
    listDestinoReformulacionDetalle(id, OrigenReformulacionDetalle) {
        return axios.get('api/ReformulacionDetalle/listDestino?id=' + id + '&OrigenReformulacionDetalle=' + OrigenReformulacionDetalle);
    }
    listReformulacionDetalle() {
        return axios.get('api/ReformulacionDetalle/list');
    }

    storeReformulacionDetalle(item) {
        return axios.post('api/ReformulacionDetalle/store', item);
    }

    destroyReformulacionDetalle(item) {
        return axios.post('api/ReformulacionDetalle/destroy', item);
    }
    deleteReformulacionDetalle(item) {
        return axios.post('api/ReformulacionDetalle/delete', item);
    }
    limpiarReformulacionDetalle() {
        return axios.get('api/ReformulacionDetalle/limpiar');
    }
    guardarReformulacionDetalle() {
        return axios.get('api/ReformulacionDetalle/guardar');
    }
    //------------------------------ Transferencia -----------------------
    showTransferencia(id) {
        return axios.get('api/Transferencia/show?id=' + id);
    }

    indexTransferencia() {
        return axios.defaults.baseURL + 'api/Transferencia/index';
    }

    listTransferencia() {
        return axios.get('api/Transferencia/list');
    }

    storeTransferencia(item) {
        return axios.post('api/Transferencia/store', item);
    }

    destroyTransferencia(item) {
        return axios.post('api/Transferencia/destroy', item);
    }
    imprimirTransferencia(id) {
        return axios.get('api/Transferencia/imprimir?id=' + id);
    }
    aprobarTransferencia(item) {
        return axios.post('api/Transferencia/aprobar', item);
    }
    //------------------------------ TransferenciaDetalle -----------------------
    showTransferenciaDetalle(id) {
        return axios.get('api/TransferenciaDetalle/show?id=' + id);
    }

    indexTransferenciaDetalle() {
        return axios.defaults.baseURL + 'api/TransferenciaDetalle/index';
    }
    listDestinoTransferenciaDetalle(id, OrigenTransferenciaDetalle) {
        return axios.get('api/TransferenciaDetalle/listDestino?id=' + id + '&OrigenTransferenciaDetalle=' + OrigenTransferenciaDetalle);
    }
    listTransferenciaDetalle() {
        return axios.get('api/TransferenciaDetalle/list');
    }

    storeTransferenciaDetalle(item) {
        return axios.post('api/TransferenciaDetalle/store', item);
    }

    destroyTransferenciaDetalle(item) {
        return axios.post('api/TransferenciaDetalle/destroy', item);
    }
    deleteTransferenciaDetalle(item) {
        return axios.post('api/TransferenciaDetalle/delete', item);
    }
    limpiarTransferenciaDetalle() {
        return axios.get('api/TransferenciaDetalle/limpiar');
    }
    guardarTransferenciaDetalle() {
        return axios.get('api/TransferenciaDetalle/guardar');
    }
    //------------------------------ AsignacionReformulacionDetalle -----------------------
    showAsignacionReformulacionDetalle(id) {
        return axios.get('api/AsignacionReformulacionDetalle/show?id=' + id);
    }

    indexAsignacionReformulacionDetalle() {
        return axios.defaults.baseURL + 'api/AsignacionReformulacionDetalle/index';
    }

    listAsignacionReformulacionDetalle() {
        return axios.get('api/AsignacionReformulacionDetalle/list');
    }

    storeAsignacionReformulacionDetalle(item) {
        return axios.post('api/AsignacionReformulacionDetalle/store', item);
    }

    destroyAsignacionReformulacionDetalle(item) {
        return axios.post('api/AsignacionReformulacionDetalle/destroy', item);
    }

    //------------------------------ NIVEL ACADEMICO -----------------------
    showNivelAcademico(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexNivelAcademico() {
        return axios.defaults.baseURL + 'api/NivelAcademico/index';
    }

    listNivelAcademico() {
        return axios.get('api/NivelAcademico/list');
    }

    storeNivelAcademico(item) {
        return axios.post('api/NivelAcademico/store', item);
    }

    destroyNivelAcademico(item) {
        return axios.post('api/NivelAcademico/destroy', item);
    }


    //------------------------------ TIPO DE PAGO -----------------------
    showTipoPago(id) {
        return axios.get('api/TipoPago/show?id=' + id);
    }

    indexTipoPago() {
        return axios.defaults.baseURL + 'api/TipoPago/index';
    }

    listTipoPago() {
        return axios.get('api/TipoPago/list');
    }

    storeTipoPago(item) {
        return axios.post('api/TipoPago/store', item);
    }

    destroyTipoPago(item) {
        return axios.post('api/TipoPago/destroy', item);
    }
    //////////////////////////////////////// Banco
    showBanco(id) {
        return axios.get('api/Banco/show?id=' + id);
    }

    indexBanco() {
        return axios.defaults.baseURL + 'api/Banco/index';
    }

    listBanco() {
        return axios.get('api/Banco/list');
    }

    storeBanco(item) {
        return axios.post('api/Banco/store', item);
    }

    destroyBanco(item) {
        return axios.post('api/Banco/destroy', item);
    }
    /////////////////////////Certificacion
    showCertificacion(id) {
        return axios.get('api/Certificacion/show?id=' + id);
    }

    indexCertificacion() {
        return axios.defaults.baseURL + 'api/Certificacion/index';
    }

    listCertificacion() {
        return axios.get('api/Certificacion/list');
    }

    storeCertificacion(item) {
        return axios.post('api/Certificacion/store', item);
    }

    destroyCertificacion(item) {
        return axios.post('api/Certificacion/destroy', item);
    }
    select2Certificacion(item) {
        return axios.get('api/Certificacion/select2?q=' + item);
    }
    imprimirCertificacion(id) {
        return axios.get('api/Certificacion/imprimir?id=' + id);
    }

    aprobarCertificacion(item) {
        return axios.post('api/Certificacion/aprobar', item);
    }

    //////////////////////////////////////CertificacionDetalle
    showCertificacionDetalle(id) {
        return axios.get('api/CertificacionDetalle/show?id=' + id);
    }

    indexCertificacionDetalle() {
        return axios.defaults.baseURL + 'api/CertificacionDetalle/index';
    }

    listCertificacionDetalle() {
        return axios.get('api/CertificacionDetalle/list');
    }

    storeCertificacionDetalle(item) {
        return axios.post('api/CertificacionDetalle/store', item);
    }

    destroyCertificacionDetalle(item) {
        return axios.post('api/CertificacionDetalle/destroy', item);
    }

    ////////////////////////////////ModificacionCertificacion
    showModificacionCertificacion(id) {
        return axios.get('api/ModificacionCertificacion/show?id=' + id);
    }

    indexModificacionCertificacion() {
        return axios.defaults.baseURL + 'api/ModificacionCertificacion/index';
    }

    listModificacionCertificacion() {
        return axios.get('api/ModificacionCertificacion/list');
    }

    storeModificacionCertificacion(item) {
        return axios.post('api/ModificacionCertificacion/store', item);
    }

    destroyModificacionCertificacion(item) {
        return axios.post('api/ModificacionCertificacion/destroy', item);
    }

    //ModificacionCertificacionDetalle
    showModificacionCertificacionDetalle(id) {
        return axios.get('api/ModificacionCertificacionDetalle/show?id=' + id);
    }

    indexModificacionCertificacionDetalle() {
        return axios.defaults.baseURL + 'api/ModificacionCertificacionDetalle/index';
    }

    listModificacionCertificacionDetalle() {
        return axios.get('api/ModificacionCertificacionDetalle/list');
    }

    storeModificacionCertificacionDetalle(item) {
        return axios.post('api/ModificacionCertificacionDetalle/store', item);
    }

    destroyModificacionCertificacionDetalle(item) {
        return axios.post('api/ModificacionCertificacionDetalle/destroy', item);
    }
    //////////////////////////////////////// Fuerza
    showFuerza(id) {
        return axios.get('api/Fuerza/show?id=' + id);
    }

    indexFuerza() {
        return axios.defaults.baseURL + 'api/Fuerza/index';
    }

    listFuerza() {
        return axios.get('api/Fuerza/list');
    }

    storeFuerza(item) {
        return axios.post('api/Fuerza/store', item);
    }

    destroyFuerza(item) {
        return axios.post('api/Fuerza/destroy', item);
    }
    //////////////////////////////////////// Especialidad
    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }

    //////////////////////////////////////// PLANPAGO
    showPlanPago(id) {
        return axios.get('api/PlanPago/show?id=' + id);
    }

    indexPlanPago() {
        return axios.defaults.baseURL + 'api/PlanPago/index';
    }

    listPlanPago() {
        return axios.get('api/PlanPago/list');
    }

    storePlanPago(item) {
        return axios.post('api/PlanPago/store', item);
    }

    destroyPlanPago(item) {
        return axios.post('api/PlanPago/destroy', item);
    }


    //////////////////////////////////////// DEPOSITO
    showDeposito(id) {
        return axios.get('api/Deposito/show?id=' + id);
    }

    indexDeposito() {
        return axios.defaults.baseURL + 'api/Deposito/index';
    }

    listDeposito() {
        return axios.get('api/Deposito/list');
    }

    storeDeposito(item) {
        return axios.post('api/Deposito/store', item);
    }

    destroyDeposito(item) {
        return axios.post('api/Deposito/destroy', item);
    }


    //////////////////////////////////////// PROGRAMA
    showPrograma(id) {
        return axios.get('api/Programa/show?id=' + id);
    }

    indexPrograma() {
        return axios.defaults.baseURL + 'api/Programa/index';
    }

    listPrograma() {
        return axios.get('api/Programa/list');
    }

    storePrograma(item) {
        return axios.post('api/Programa/store', item);
    }

    destroyPrograma(item) {
        return axios.post('api/Programa/destroy', item);
    }


    //////////////////////////////////////// DETALLE FACTURA
    showDetalleFactura(id) {
        return axios.get('api/DetalleFactura/show?id=' + id);
    }

    indexDetalleFactura() {
        return axios.defaults.baseURL + 'api/DetalleFactura/index';
    }

    listDetalleFactura() {
        return axios.get('api/DetalleFactura/list');
    }

    storeDetalleFactura(item) {
        return axios.post('api/DetalleFactura/store', item);
    }

    destroyDetalleFactura(item) {
        return axios.post('api/DetalleFactura/destroy', item);
    }


    //------------------------------ GRADO -----------------------
    showGrado(id) {
        return axios.get('api/Grado/show?id=' + id);
    }

    indexGrado() {
        return axios.defaults.baseURL + 'api/Grado/index';
    }

    listGrado(parametros) {
        return axios.get('api/Grado/list', { params: parametros });
    }

    storeGrado(item) {
        return axios.post('api/Grado/store', item);
    }

    destroyGrado(item) {
        return axios.post('api/Grado/destroy', item);
    }
    //////////////////////////////////////// Periodo Academico
    showPeriodoAcademico(id) {
        return axios.get('api/PeriodoAcademico/show?id=' + id);
    }

    indexPeriodoAcademico() {
        return axios.defaults.baseURL + 'api/PeriodoAcademico/index';
    }

    listPeriodoAcademico() {
        return axios.get('api/PeriodoAcademico/list');
    }

    storePeriodoAcademico(item) {
        return axios.post('api/PeriodoAcademico/store', item);
    }

    destroyPeriodoAcademico(item) {
        return axios.post('api/PeriodoAcademico/destroy', item);
    }
    //////////////////////////////////////// Talonario
    showTalonario(id) {
        return axios.get('api/Talonario/show?id=' + id);
    }

    indexTalonario() {
        return axios.defaults.baseURL + 'api/Talonario/index';
    }
    
    listTalonario(id) {
        return axios.get('api/Talonario/list?UnidadAcademica=' + id);
    }

    listTalonariosimple() {
        return axios.get('api/Talonario/listsimple');
    }

    storeTalonario(item) {
        return axios.post('api/Talonario/store', item);
    }

    destroyTalonario(item) {
        return axios.post('api/Talonario/destroy', item);
    }

    //////////////////////////////////////// Cheque - Talonario
    showChequeTalonario(id) {
        return axios.get('api/ChequeTalonario/show?id=' + id);
    }

    indexChequeTalonario() {
        return axios.defaults.baseURL + 'api/ChequeTalonario/index';
    }
    
    indexChequesAsignados() {
        return axios.defaults.baseURL + 'api/ChequeTalonario/indexAsignados';
    }
    
    
    listChequeTalonario(id) {
        return axios.get('api/ChequeTalonario/list?Talonario=' + id);
    }

    storeChequeTalonario(item) {
        return axios.post('api/ChequeTalonario/store', item);
    }

    storeMasivoChequeTalonario(item) {
        return axios.post('api/ChequeTalonario/storeMasivo', item);
    }

    storeAsignarChequeTalonario(item) {
        return axios.post('api/ChequeTalonario/storeAsignarCheque', item);
    }

    storeUpdateChequeTalonario(item) {
        return axios.post('api/ChequeTalonario/updateChequeTalonario', item);
    }

    destroyChequeTalonario(item) {
        return axios.post('api/ChequeTalonario/destroy', item);
    }

    imprimirChequeTalonario(id) {
        return axios.get('api/ChequeTalonario/imprimir?id='+ id);
    }
    //////////////////////////////////////// Inscripcion
    showInscripcion(id) {
        return axios.get('api/Inscripcion/show?id=' + id);
    }

    indexInscripcion() {
        return axios.defaults.baseURL + 'api/Inscripcion/index';
    }

    listInscripcion() {
        return axios.get('api/Inscripcion/list');
    }

    storeInscripcion(item) {
        return axios.post('api/Inscripcion/store', item);
    }

    destroyInscripcion(item) {
        return axios.post('api/Inscripcion/destroy', item);
    }


    //////////////////////Factura////////////////////////////////////
    showFactura(id) {
        return axios.get('api/Factura/show?id=' + id);
    }

    indexFactura() {
        return axios.defaults.baseURL + 'api/Factura/index';
    }

    listFactura() {
        return axios.get('api/Factura/list');
    }

    storeFactura(item) {
        return axios.post('api/Factura/store', item);
    }

    destroyFactura(item) {
        return axios.post('api/Factura/destroy', item);
    }

    facturarPagos(item) {
        return axios.post('api/Factura/facturar', item);
    }

    imprimirFactura(id) {
        return axios.get('api/Factura/imprimir?id='+ id);
    }

    anularFactura(item) {
        return axios.post('api/Factura/anular', item);
    }

    //////////////////////Curso//////////////////////////////////////
    showCurso(id) {
        return axios.get('api/Curso/show?id=' + id);
    }

    indexCurso() {
        return axios.defaults.baseURL + 'api/Curso/index';
    }

    listCurso() {
        return axios.get('api/Curso/list');
    }

    storeCurso(item) {
        return axios.post('api/Curso/store', item);
    }

    destroyCurso(item) {
        return axios.post('api/Curso/destroy', item);
    }

    //////////////////////ItemTesoreria////////////////////////////////
    showItemTesoreria(id) {
        return axios.get('api/ItemTesoreria/show?id=' + id);
    }

    indexItemTesoreria() {
        return axios.defaults.baseURL + 'api/ItemTesoreria/index';
    }

    listItemTesoreria() {
        return axios.get('api/ItemTesoreria/list');
    }

    storeItemTesoreria(item) {
        return axios.post('api/ItemTesoreria/store', item);
    }

    destroyItemTesoreria(item) {
        return axios.post('api/ItemTesoreria/destroy', item);
    }

    ////******  IngresoPresupuestario */
    showIngresoPresupuestario(id) {
        return axios.get('api/IngresoPresupuestario/show?id=' + id);
    }

    indexIngresoPresupuestario() {
        return axios.defaults.baseURL + 'api/IngresoPresupuestario/index';
    }

    indexIngresoPresupuestarioActual() {
        return axios.defaults.baseURL + 'api/IngresoPresupuestario/indexactual';
    }

    listIngresoPresupuestario() {
        return axios.get('api/IngresoPresupuestario/list');
    }

    listIngresoPresupuestarioGestion(ges) {
        return axios.get('api/IngresoPresupuestario/listGestion?Gestion='+ges);
    }

    storeIngresoPresupuestario(item) {
        return axios.post('api/IngresoPresupuestario/store', item);
    }

    storeIngresoPresupuestarioPorDia(item) {
        return axios.post('api/IngresoPresupuestario/ingresosPorDia', item);
    }

    destroyIngresoPresupuestario(item) {
        return axios.post('api/IngresoPresupuestario/destroy', item);
    }

    printCierreArchivo(id) {
        return axios.get('api/IngresoPresupuestario/printCierreArchivo?id=' + id);
    }

    ////******  IngresoPresupuestarioDetalle */
    showIngresoPresupuestarioDetalle(id) {
        return axios.get('api/IngresoPresupuestarioDetalle/show?id=' + id);
    }

    indexIngresoPresupuestarioDetalle() {
        return axios.defaults.baseURL + 'api/IngresoPresupuestarioDetalle/index';
    }

    listIngresoPresupuestarioDetalle() {
        return axios.get('api/IngresoPresupuestarioDetalle/list');
    }

    storeIngresoPresupuestarioDetalle(item) {
        return axios.post('api/IngresoPresupuestarioDetalle/store', item);
    }

    destroyIngresoPresupuestarioDetalle(item) {
        return axios.post('api/IngresoPresupuestarioDetalle/destroy', item);
    }

    excellngresoPresupuestarioDetalle(item) {
        return axios.post('api/IngresoPresupuestarioDetalle/get_excel', item);
    }

    ////******  Reversion */
    showReversion(id) {
        return axios.get('api/Reversion/show?id=' + id);
    }

    indexReversion() {
        return axios.defaults.baseURL + 'api/Reversion/index';
    }

    listReversion() {
        return axios.get('api/Reversion/list');
    }

    storeReversion(item) {
        return axios.post('api/Reversion/store', item);
    }

    destroyReversion(item) {
        return axios.post('api/Reversion/destroy', item);
    }
    imprimirReversion(id) {
        return axios.get('api/Reversion/imprimir?id=' + id);
    }

    aprobarReversion(item) {
        return axios.post('api/Reversion/aprobar', item);
    }

    ////******  ReversionDetalle */
    showReversionDetalle(id) {
        return axios.get('api/ReversionDetalle/show?id=' + id);
    }

    indexReversionDetalle() {
        return axios.defaults.baseURL + 'api/ReversionDetalle/index';
    }

    listReversionDetalle() {
        return axios.get('api/ReversionDetalle/list');
    }

    storeReversionDetalle(item) {
        return axios.post('api/ReversionDetalle/store', item);
    }

    destroyReversionDetalle(item) {
        return axios.post('api/ReversionDetalle/destroy', item);
    }
    deleteReversionDetalle(item) {
        return axios.post('api/ReversionDetalle/delete', item);
    }

    //          MODALIDAD
    showModalidad(id) {
        return axios.get('api/Modalidad/show?id=' + id);
    }

    indexModalidad() {
        return axios.defaults.baseURL + 'api/Modalidad/index';
    }

    listModalidad() {
        return axios.get('api/Modalidad/list');
    }

    storeModalidad(item) {
        return axios.post('api/Modalidad/store', item);
    }

    destroyModalidad(item) {
        return axios.post('api/Modalidad/destroy', item);
    }

    //          DOSIfICACION
    showDosificacion(id) {
        return axios.get('api/Dosificacion/show?id=' + id);
    }

    indexDosificacion() {
        return axios.defaults.baseURL + 'api/Dosificacion/index';
    }

    listDosificacion() {
        return axios.get('api/Dosificacion/list');
    }

    storeDosificacion(item) {
        return axios.post('api/Dosificacion/store', item);
    }

    destroyDosificacion(item) {
        return axios.post('api/Dosificacion/destroy', item);
    }

    //------------------------------ PagoTesoreria -----------------------
    showPagoTesoreria(id) {
        return axios.get('api/PagoTesoreria/show?id=' + id);
    }

    indexPagoTesoreria() {
        return axios.defaults.baseURL + 'api/PagoTesoreria/index';
    }

    listPagoTesoreria() {
        return axios.get('api/PagoTesoreria/list');
    }

    listPagoTesoreria2(parametros) {
        return axios.get('api/PagoTesoreria/list', { params: parametros });
    }

    storePagoTesoreria(item) {
        return axios.post('api/PagoTesoreria/store', item);
    }

    destroyPagoTesoreria(item) {
        return axios.post('api/PagoTesoreria/destroy', item);
    }

    obtenerPagoDemasiaPagoTesoreria(item) {
        return axios.post('api/PagoTesoreria/obtenerPagoDemasia', item);
    }

    select2PagoTesoreria(parametros) {
        return axios.get('api/PagoTesoreria/select2', { params: parametros });
    }

    //------------------------------ PagoTesoreriaCurso -----------------------
    showPagoTesoreriaCurso(id) {
        return axios.get('api/PagoTesoreriaCurso/show?id=' + id);
    }

    indexPagoTesoreriaCurso() {
        return axios.defaults.baseURL + 'api/PagoTesoreriaCurso/index';
    }

    listPagoTesoreriaCurso() {
        return axios.get('api/PagoTesoreriaCurso/list');
    }

    storePagoTesoreriaCurso(item) {
        return axios.post('api/PagoTesoreriaCurso/store', item);
    }

    destroyPagoTesoreriaCurso(item) {
        return axios.post('api/PagoTesoreriaCurso/destroy', item);
    }

    //------------------------------ InscripcionPagoTesoreria -----------------------
    showInscripcionPagoTesoreria(id) {
        return axios.get('api/InscripcionPagoTesoreria/show?id=' + id);
    }

    indexInscripcionPagoTesoreria() {
        return axios.defaults.baseURL + 'api/InscripcionPagoTesoreria/index';
    }

    listPagosInscripcionPagoTesoreria(parametros) {
        return axios.get('api/InscripcionPagoTesoreria/listPagos', { params: parametros });
    }

    listInscripcionPagoTesoreria() {
        return axios.get('api/InscripcionPagoTesoreria/list');
    }

    storeInscripcionPagoTesoreria(item) {
        return axios.post('api/InscripcionPagoTesoreria/store', item);
    }

    storeInscripcionCurso(item) {
        return axios.post('api/InscripcionPagoTesoreria/inscripcionCurso', item);
    }

    storeinscripcionCursoCreditaje(item) {
        return axios.post('api/InscripcionPagoTesoreria/inscripcionCursoCreditaje', item);
    }

    agregarPagoTesoreria(item) {
        return axios.post('api/InscripcionPagoTesoreria/agregarPagoTesoreria', item);
    }

    destroyInscripcionPagoTesoreria(item) {
        return axios.post('api/InscripcionPagoTesoreria/destroy', item);
    }

    //------------------------------ Sintesis -----------------------
    showSintesis(id) {
        return axios.get('api/Sintesis/show?id=' + id) ;
    }

    indexSintesis() {
        return axios.defaults.baseURL + 'api/Sintesis/index';
    }

    descargarSintesis(parametros) {
        return axios.get('api/Sintesis/descargarSintesis', { params: parametros }) ;
    }

    registrarArchivoSintesis(item) {
        return axios.post('api/Sintesis/registrarArchivoSintesis', item) ;
    }

    consultarMontoSaga(item) {
        return axios.post('api/InscripcionPagoTesoreria/obtenerMontoSaga', item);
    }
    //------------------------------ Devengado -----------------------
    showDevengado(id) {
        return axios.get('api/Devengado/show?id=' + id);
    }

    indexDevengado() {
        return axios.defaults.baseURL + 'api/Devengado/index';
    }

    listDevengado() {
        return axios.get('api/Devengado/list');
    }

    storeDevengado(item) {
        return axios.post('api/Devengado/store', item);
    }

    destroyDevengado(item) {
        return axios.post('api/Devengado/destroy', item);
    }
    imprimirDevengado(id) {
        return axios.get('api/Devengado/imprimir?id=' + id);
    }
    aprobarDevengado(item) {
        return axios.post('api/Devengado/aprobar', item);
    }
    select2Devengado(item) {
        return axios.get('api/Devengado/select2?q=' + item);
    }

    desaprobarDevengado(item) {
        return axios.post('api/Devengado/desaprobar', item);
    }

    //------------------------------ DevengadoDetalle -----------------------
    showDevengadoDetalle(id) {
        return axios.get('api/DevengadoDetalle/show?id=' + id);
    }

    indexDevengadoDetalle() {
        return axios.defaults.baseURL + 'api/DevengadoDetalle/index';
    }

    listDevengadoDetalle() {
        return axios.get('api/DevengadoDetalle/list');
    }

    storeDevengadoDetalle(item) {
        return axios.post('api/DevengadoDetalle/store', item);
    }

    destroyDevengadoDetalle(item) {
        return axios.post('api/DevengadoDetalle/destroy', item);
    }
    imprimirDevengadoDetalle(id) {
        return axios.get('api/DevengadoDetalle/imprimir?id=' + id);
    }
    aprobarDevengadoDetalle(item) {
        return axios.post('api/DevengadoDetalle/aprobar', item);
    }
    select2DevengadoDetalle(item) {
        return axios.get('api/DevengadoDetalle/select2?q=' + item);
    }

    desaprobarDevengadoDetalle(item) {
        return axios.post('api/DevengadoDetalle/desaprobar', item);
    }

    //------------------------------ Tipo -----------------------
    showTipo(id) {
        return axios.get('api/Tipo/show?id=' + id);
    }

    indexTipo() {
        return axios.defaults.baseURL + 'api/Tipo/index';
    }

    listTipo() {
        return axios.get('api/Tipo/list');
    }

    storeTipo(item) {
        return axios.post('api/Tipo/store', item);
    }

    destroyTipo(item) {
        return axios.post('api/Tipo/destroy', item);
    }

    //////////////////////////////////////// Estudiante /////////////////////
    showEstudiante(id) {
        return axios.get('api/Estudiante/show?id=' + id);
    }

    indexEstudiante() {
        return axios.defaults.baseURL + 'api/Estudiante/index';
    }

    listEstudiante() {
        return axios.get('api/Estudiante/list');
    }

    storeEstudiante(item) {
        return axios.post('api/Estudiante/store', item);
    }

    destroyEstudiante(item) {
        return axios.post('api/Estudiante/destroy', item);
    }
      /////////////////////////Factura Egreso
      showFacturaEgreso(id) {
        return axios.get('api/FacturaEgreso/show?id=' + id);
    }

    indexFacturaEgreso() {
        return axios.defaults.baseURL + 'api/FacturaEgreso/index';
    }

    listFacturaEgreso() {
        return axios.get('api/FacturaEgreso/list');
    }

    storeFacturaEgreso(item) {
        return axios.post('api/FacturaEgreso/store', item);
    }

    destroyFacturaEgreso(item) {
        return axios.post('api/FacturaEgreso/destroy', item);
    }
    // select2FacturaEgreso(item) {
    //     return axios.get('api/FacturaEgreso/select2?q=' + item);
    // }
    // imprimirFacturaEgreso(id) {
    //     return axios.get('api/FacturaEgreso/imprimir?id='+id);
    // }

    aprobarFacturaEgreso(item) {
        return axios.post('api/FacturaEgreso/aprobar', item);
    }


    //////////////////////////////////////// DescargoEstado /////////////////////
    showDescargoEstado(id) {
        return axios.get('api/DescargoEstado/show?id=' + id);
    }

    indexDescargoEstado() {
        return axios.defaults.baseURL + 'api/DescargoEstado/index';
    }

    listDescargoEstado() {
        return axios.get('api/DescargoEstado/list');
    }

    storeDescargoEstado(item) {
        return axios.post('api/DescargoEstado/store', item);
    }

    destroyDescargoEstado(item) {
        return axios.post('api/DescargoEstado/destroy', item);
    }

    //////////////////////////////////////// Descargo /////////////////////
    showDescargo(id) {
        return axios.get('api/Descargo/show?id=' + id);
    }

    showDescargoEst(id) {
        return axios.get('api/Descargo/showEst?id=' + id);
    }

    showDescargoArchivo(id) {
        return axios.get('api/Descargo/showArchivo?id=' + id);
    }

    indexAsignarDescargo() {
        return axios.defaults.baseURL + 'api/Descargo/indexAsignar';
    }

    indexDescargoGral() {
        return axios.defaults.baseURL + 'api/Descargo/indexgral';
    }

    indexDescargoGralGestionPas() {
        return axios.defaults.baseURL + 'api/Descargo/indexgralgestionpasadas';
    }

    indexDescargo() {
        return axios.defaults.baseURL + 'api/Descargo/index';
    }

    indexDescargoRev() {
        return axios.defaults.baseURL + 'api/Descargo/indexRev';
    }

    indexDescargoFin() {
        return axios.defaults.baseURL + 'api/Descargo/indexFin';
    }

    indexDescargoSeg() {
        return axios.defaults.baseURL + 'api/Descargo/indexSeg';
    }

    indexDescargoCor() {
        return axios.defaults.baseURL + 'api/Descargo/indexCor';
    }

    indexDescargoCer() {
        return axios.defaults.baseURL + 'api/Descargo/indexCer';
    }

    indexDescargoEst() {
        return axios.defaults.baseURL + 'api/DescargoEst/index';
    }

    indexRRT() {
        return axios.defaults.baseURL + 'api/Descargo/indexRRT';
    }

    indexDescargoArchivo() {
        return axios.defaults.baseURL + 'api/Descargo/indexDescargoArchivo';
    }

    finishRRT(id) {
        return axios.get('api/Descargo/finishRRT?id=' + id);
    }

    listDescargo() {
        return axios.get('api/Descargo/list');
    }

    storeDescargo(item) {
        return axios.post('api/Descargo/store', item);
    }

    storeDescargod(item) {
        return axios.post('api/Descargo/stored', item);
    }

    storeAsignarDescargo(item) {
        return axios.post('api/Descargo/storeasignar', item);
    }

    storeCierre(item) {
        return axios.post('api/Descargo/storec', item);
    }

    storeCierreEstado(item) {
        return axios.post('api/Descargo/storeestado', item);
    }

    storeRadio(item) {
        return axios.post('api/Descargo/storeradio', item);
    }

    destroyDescargo(item) {
        return axios.post('api/Descargo/destroy', item);
    }

    uploadDescargo(item) {
        return axios.post('api/Descargo/upload', item);
    }

    observeDescargo(item) {
        return axios.post('api/Descargo/observe', item);
    }

    approveDescargo(item) {
        return axios.post('api/Descargo/approve', item);
    }

    closeDescargo(item) {
        return axios.post('api/Descargo/close', item);
    }

    reverseDescargo(item) {
        return axios.post('api/Descargo/reverse', item);
    }
    printRRT(item) {
        return axios.get('api/Descargo/printRRT?Numero='+ item);
    }

    retencionDescargo(item) {
        return axios.post('api/Descargo/retencion', item);
    }

    addTributacion(item) {
        return axios.post('api/Descargo/addTributacion', item);
    }

    printDescargoDocumento(id, doc) {
        return axios.get('api/Descargo/print?id=' + id+ '&Documento=' + doc);
        // return axios.get('api/Ejecucion/select2',item);
    }

    storeDescargoArchivo(item) {
        return axios.post('api/Descargo/storeCierreDescargo', item);
    }

    printCierreArchivoDes(id) {
        return axios.get('api/Descargo/printCierreArchivoDescargo?id=' + id);
    }

    imprimirReporteAsignacionInd(item) {
        return axios.post('api/Descargo/imprimirReporteAsignacionInd', item);
    }

    imprimirReporteAsignacionGral(item) {
        return axios.post('api/Descargo/imprimirReporteAsignacionGral', item);
    }

    
     //////////////////////////////////////// Cheque /////////////////////
    showCheque(id) {
        return axios.get('api/Cheque/show?id=' + id);
    }

    showChequeGeneral(id) {
        return axios.get('api/Cheque/showGeneral?id=' + id);
    }
    
    indexCheque() {
        return axios.defaults.baseURL + 'api/Cheque/index';
    }

    
    // listEstudiante() {
    //     return axios.get('api/Estudiante/list');
    // }
    
    storeCheque(item) {
        return axios.post('api/Cheque/store', item);
    }
    
    destroyCheque(item) {
        return axios.post('api/Cheque/destroy', item);
    }


     //////////////////////////////////////// Estado de cheques /////////////////////
    showEstadoCheque(id) {
        return axios.get('api/EstadoCheque/show?id=' + id);
    }
    
    indexEstadoCheque() {
        return axios.defaults.baseURL + 'api/EstadoCheque/index';
    }
    
    listEstadoCheque() {
        return axios.get('api/EstadoCheque/list');
    }
    
    storeEstadoCheque(item) {
        return axios.post('api/EstadoCheque/store', item);
    }
    
    destroyEstadoCheque(item) {
        return axios.post('api/EstadoCheque/destroy', item);
    }
    printDescargo(id) {
        return axios.get('api/Descargo/print?id=' + id);
    }
///////////////////////////////////////////// REPORTES DE DESCARGO////////////////////////
    printRevisor(id) {
        return axios.get('api/Descargo/printRevisor?id=' + id);
    }

    printRevisor2022(id) {
        return axios.get('api/Descargo/printRevisor2022?id=' + id);
    }

    printSeguimiento2022(id) {
        return axios.get('api/Descargo/printSeguimiento2022?id=' + id);
    }

    printSeguimiento(id) {
        return axios.get('api/Descargo/printSeguimiento?id=' + id);
    }

    printSupervisor(id) {
        return axios.get('api/Descargo/printSupervisor?id=' + id);
    }

    printRadioSup(id) {
        return axios.get('api/Descargo/printRadioSup?id=' + id);
    }

    printRadioUni(id) {
        return axios.get('api/Descargo/printRadioUni?id=' + id);
    }

    printEstadocargo(id) {
        return axios.get('api/Descargo/printEstadocargo?id=' + id);
    }
    printEstadofondo(id) {
        return axios.get('api/Descargo/printEstadofondo?id=' + id);
    }
    printEstadofondotres(id) {
        return axios.get('api/Descargo/printEstadofondotres?id=' + id);
    }

///////////////////////////////////////////// REPORTES DE DESCARGO////////////////////////

    obtenerDirigidoCargoRevisor(parametros) {
        return axios.get('api/Descargo/obtenerDirigidoCargoRevisor', { params: parametros });
    }


    // -----gestion cajeros -----
    indexCajeros() {
        return axios.defaults.baseURL + 'api/Persona/indexCajeros';
    } 


     //////////////////////////////////////// Cajero /////////////////////

     indexCajero() {
        return axios.defaults.baseURL + 'api/Cajero/index';
    }

    showCajero(id) {
        return axios.get('api/Cajero/show?id=' + id);
    }

    storeCajero(item) {
        return axios.post('api/Cajero/store', item);
    }

    storeHistorialCajero(item) {
        return axios.post('api/Cajero/storecajero', item);
    }
    
    listCajeroDosificaciones(id) {
        return axios.get('api/Cajero/list?Persona=' + id);
    }

    destroyCajeroDosificacion(id) {
        return axios.get('api/Cajero/destroy?id=' + id);
    }

    printReporteCajero(id) {
        return axios.get('api/Cajero/printCajero?id=' + id);
    }

    //////////////////////////////////////// LibroBancario /////////////////////
    showLibroBancario(id) {
        return axios.get('api/LibroBancario/show?id=' + id);
    }

    indexLibroBancario() {
        return axios.defaults.baseURL + 'api/LibroBancario/index';
    }

    listLibroBancario() {
        return axios.get('api/LibroBancario/list');
    }

    storeLibroBancario(item) {
        return axios.post('api/LibroBancario/store', item);
    }

    destroyLibroBancario(item) {
        return axios.post('api/LibroBancario/destroy', item);
    }

    reporteLibroBancario(item) {
        return axios.post('api/LibroBancario/reporteLibroBancario', item);
    }

    //////////////////////////////////////// Detalle descargo /////////////////////
    showDescargoDetalle(id) {
        return axios.get('api/DescargoDetalle/show?id=' + id);
    }

    indexDescargoDetalle() {
        return axios.defaults.baseURL + 'api/DescargoDetalle/index';
    }

    listDescargoDetalle(id) {
        return axios.get('api/DescargoDetalle/list?Descargo=' + id);
    }

    storeDescargoDetalle(item) {
        return axios.post('api/DescargoDetalle/store', item);
    }

    storeObservacionDetalle(item) {
        return axios.post('api/DescargoDetalle/storeobservacion', item);
    }

    destroyDescargoDetalle(item) {
        return axios.post('api/DescargoDetalle/destroy', item);
    }

    //------------------------------ Formulariobp -----------------------

    indexFormulariobp() {
        return axios.defaults.baseURL + 'api/Formulariobp/index';
    }

    listFormulariobp() {
        return axios.get('api/Formulariobp/list');
    }

    showFormulariobp(id) {
        return axios.get('api/Formulariobp/show?id=' + id);
    }

    storeFormulariobp(item) {
        return axios.post('api/Formulariobp/store', item);
    }

    destroyFormulariobp(item) {
        return axios.post('api/Formulariobp/destroy', item);
    }

    imprimirFormulariobp(id) {
        return axios.get('api/Formulariobp/imprimir?id=' + id);
    }

    select2Formulariobp(item) {
        return axios.get('api/Formulariobp/select2?q=' + item);
    }

    storeCierreFormulariobp(item) {
        return axios.post('api/Formulariobp/storeCierreFormulariobp', item);
    }

    printCierreArchivobp(id) {
        return axios.get('api/Formulariobp/printCierreArchivobp?id=' + id);
    }
    
    aprobarFormulariobp(item) {
        return axios.post('api/Formulariobp/aprobarFormulariobp', item);
    }

    imprimirPagoDirectoGral(item) {
        return axios.post('api/Formulariobp/imprimirPagoDirectoGral', item);
    }

    imprimirDesembolsoGral(item) {
        return axios.post('api/Formulariobp/imprimirDesembolsoGral', item);
    }

    imprimirBeneficiarioFinalGral(item) {
        return axios.post('api/Formulariobp/imprimirBeneficiarioFinalGral', item);
    }

    imprimirBeneficiarioFinalGralG(item) {
        return axios.post('api/Formulariobp/imprimirBeneficiarioFinalGralG', item);
    }

    imprimirBeneficiarioFinalGralGAprob(item) {
        return axios.post('api/Formulariobp/imprimirBeneficiarioFinalGralGAprob', item);
    }

    imprimirBFGralG(item) {
        return axios.post('api/Formulariobp/imprimirBFGralG', item);
    }

    select2Formulariobpfiltro(item) {
        return axios.post('api/Formulariobp/selectbp', item);
    }
                //reportes beneficiario final

    printreportebn(item) {
        return axios.post('api/Reporte/reportebn', item);
    }

    printreportebndh(item) {
        return axios.post('api/Reporte/reportebndh', item);
    }

    printreportebndhprev(item) {
        return axios.post('api/Reporte/reportebndhprev', item);
    }

    printreportesubcuentabn(item) {
        return axios.post('api/Reporte/reporteSubcuentabn', item);
    }

    printreportereparticionbn(item) {
        return axios.post('api/Reporte/reporteReparticionbn', item);
    }

    printreportefuentebn(item) {
        return axios.post('api/Reporte/reporteFuentebn', item);
    }

    printreportefechasbn(item) {
        return axios.post('api/Reporte/reporteFechasbn', item);
    }

    printreportefechasglosabn(item) {
        return axios.post('api/Reporte/reporteFechasGlosabn', item);
    }
                    //reportes pago directo
    printreportepd(item) {
        return axios.post('api/Reporte/reportepd', item);
    }

    printreportepddh(item) {
        return axios.post('api/Reporte/reportepddh', item);
    }

    printreportepddhprev(item) {
        return axios.post('api/Reporte/reportepddhprev', item);
    }

    printreportesubcuentapd(item) {
        return axios.post('api/Reporte/reporteSubcuentapd', item);
    }

    printreportereparticionpd(item) {
        return axios.post('api/Reporte/reporteReparticionpd', item);
    }

    printreportefuentepd(item) {
        return axios.post('api/Reporte/reporteFuentepd', item);
    }

    printreportefechaspd(item) {
        return axios.post('api/Reporte/reporteFechaspd', item);
    }

    printreportefechasglosapd(item) {
        return axios.post('api/Reporte/reporteFechasGlosapd', item);
    }

                    //reportes desembolsos

    printreportedesembo(item) {
        return axios.post('api/Reporte/reportedesembo', item);
    }

    printreportedesembodh(item) {
        return axios.post('api/Reporte/reportedesembodh', item);
    }

    printreportedesembodhprev(item) {
        return axios.post('api/Reporte/reportedesembodhprev', item);
    }

    printreportesubcuentadesembo(item) {
        return axios.post('api/Reporte/reporteSubcuentadesembo', item);
    }

    printreportereparticiondesembo(item) {
        return axios.post('api/Reporte/reporteReparticiondesembo', item);
    }

    printreportefuentedesembo(item) {
        return axios.post('api/Reporte/reporteFuentedesembo', item);
    }

    printreportefechasdesembo(item) {
        return axios.post('api/Reporte/reporteFechasdesembo', item);
    }

    printreportefechasglosadesembo(item) {
        return axios.post('api/Reporte/reporteFechasGlosadesembo', item);
    }

    //------------------------------ ArchivoC -----------------------

    indexArchivoC() {
        return axios.defaults.baseURL + 'api/ArchivoC/index';
    }

    listArchivoC() {
        return axios.get('api/ArchivoC/list');
    }

    showArchivoC(id) {
        return axios.get('api/ArchivoC/show?id=' + id);
    }

    storeArchivoC(item) {
        return axios.post('api/ArchivoC/store', item);
    }

    destroyArchivoC(item) {
        return axios.post('api/ArchivoC/destroy', item);
    }

    imprimirArchivoC(id) {
        return axios.get('api/ArchivoC/imprimir?id=' + id);
    }

    //------------------------------ Reporte -----------------------

    indexReporte() {
        return axios.defaults.baseURL + 'api/Reporte/index';
    }

    listReporte() {
        return axios.get('api/Reporte/list');
    }

    showReporte(id) {
        return axios.get('api/Reporte/show?id=' + id);
    }

    storeReporte(item) {
        return axios.post('api/Reporte/store', item);
    }

    destroyReporte(item) {
        return axios.post('api/Reporte/destroy', item);
    }

    printConsiDescargocc(item) {
        return axios.post('api/Reporte/imprimirConsiDescargocc', item);
    }

    printConsiDescargoff(item) {
        return axios.post('api/Reporte/imprimirConsiDescargoff', item);
    }

    printConsiDescargoccm(item) {
        return axios.post('api/Reporte/imprimirConsiDescargoccm', item);
    }

    printConsiDescargoffm(item) {
        return axios.post('api/Reporte/imprimirConsiDescargoffm', item);
    }

    printConsiContabilidadcc(item) {
        return axios.post('api/Reporte/imprimirConsiContabilidadcc', item);
    }

    printConsiContabilidadff(item) {
        return axios.post('api/Reporte/imprimirConsiContabilidadff', item);
    }

    printreportesubcuenta(item) {
        return axios.post('api/Reporte/imprimirReporteSubcuenta', item);
    }

    printreportereparticion(item) {
        return axios.post('api/Reporte/imprimirReporteReparticion', item);
    }

    printreportepartida(item) {
        return axios.post('api/Reporte/imprimirReportePartida', item);
    }

    printreportefuente(item) {
        return axios.post('api/Reporte/imprimirReporteFuente', item);
    }

    printreportefechas(item) {
        return axios.post('api/Reporte/imprimirReporteFechas', item);
    }

    printreportefechasglosa(item) {
        return axios.post('api/Reporte/imprimirReporteFechasGlosa', item);
    }

    printIndividualDescargoSuper(item) {
        return axios.post('api/Reporte/reporteIndividualSuper', item);
    }

    printDescargoGestionPasada(item) {
        return axios.post('api/Reporte/reporteGestionPasada', item);
    }

    printDescargoGestionPasadaDet(item) {
        return axios.post('api/Reporte/reporteGestionPasadaDet', item);
    }

    printcategoriaprogramatica(item) {
        return axios.post('api/Reporte/imprimirCategoriaProgramatica', item);
    }

    printreportereparticionpre(item) {
        return axios.post('api/Reporte/imprimirReporteReparticionPre', item);
    }

    printreportereparticionprepartida(item) {
        return axios.post('api/Reporte/imprimirReporteReparticionPrePartida', item);
    }

    printreportepartidapre(item) {
        return axios.post('api/Reporte/imprimirReportePartidaPre', item);
    }

    printreportefuentepre(item) {
        return axios.post('api/Reporte/imprimirReporteFuentePre', item);
    }

    printreportefechaspre(item) {
        return axios.post('api/Reporte/imprimirReporteFechasPre', item);
    }

    printreportefechaspredet(item) {
        return axios.post('api/Reporte/imprimirReporteFechasPredet', item);
    }

    printreportefechaspreglosa(item) {
        return axios.post('api/Reporte/imprimirReporteFechasPreGlosa', item);
    }

    printreportecheque(item) {
        return axios.post('api/Reporte/imprimirReporteCheques', item);
    }

    printreportechequesdh(item) {
        return axios.post('api/Reporte/imprimirReporteChequesDH', item);
    }

    printreportenumcheques(item) {
        return axios.post('api/Reporte/imprimirReporteNumCheque', item);
    }

    printreportesubcuentacheques(item) {
        return axios.post('api/Reporte/imprimirReporteChequeSubcuenta', item);
    }

    printreportereparticioncheques(item) {
        return axios.post('api/Reporte/imprimirReporteChequeReparticion', item);
    }

    printreporteretetalonario(item) {
        return axios.post('api/Reporte/imprimirReporteChequeTalonario', item);
    }

    printreportereteestadocheque(item) {
        return axios.post('api/Reporte/imprimirReporteEstadoCheques', item);
    }

    printreportefechascheques(item) {
        return axios.post('api/Reporte/imprimirReporteChequeFecha', item);
    }

    printreportefechaschequeglosa(item) {
        return axios.post('api/Reporte/imprimirReporteNumChequeGlosa', item);
    }

    printreportefechaschequeglosab(item) {
        return axios.post('api/Reporte/imprimirReporteNumChequeGlosaG', item);
    }

    printreportesubcuentaarchivo(item) {
        return axios.post('api/Reporte/imprimirReporteSubcuentaArchivo', item);
    }

    printreportereparticionarchivo(item) {
        return axios.post('api/Reporte/imprimirReporteReparticionArchivo', item);
    }

    printreportefuentearchivo(item) {
        return axios.post('api/Reporte/imprimirReporteFuenteArchivo', item);
    }

    printreportefechasarchivo(item) {
        return axios.post('api/Reporte/imprimirReporteFechasArchivo', item);
    }

    printreportefechasglosaarchivo(item) {
        return axios.post('api/Reporte/imprimirReporteFechasGlosaArchivo', item);
    }

//------------------------------ Certificado Solvencia -----------------------

    showCertificadoSolvencia(id) {
        return axios.get('api/CertificadoSolvencia/show?id=' + id);
    }

    indexCertificadoSolvencia() {
        return axios.defaults.baseURL + 'api/CertificadoSolvencia/index';
    }

    listCertificadoSolvencia() {
        return axios.get('api/CertificadoSolvencia/list');
    }

    storeCertificadoSolvencia(item) {
        return axios.post('api/CertificadoSolvencia/store', item);
    }

    destroyCertificadoSolvencia(item) {
        return axios.post('api/CertificadoSolvencia/destroy', item);
    }

    printCerAprobado(id) {
        return axios.get('api/CertificadoSolvencia/printCertificado?id=' + id);
    }
    printCerObservado(id) {
        return axios.get('api/CertificadoSolvencia/printCertificadoObs?id=' + id);
    }

    imprimirListaCertificados(item) {
        return axios.post('api/CertificadoSolvencia/imprimirListaCertificados', item);
    }

    showcantTipoSolvencia(id) {
        return axios.get('api/CertificadoSolvencia/cantidadtipocert?id=' + id);
    }

    //------------------------------ Tributacion -----------------------

    indexTributacion() {
        return axios.defaults.baseURL + 'api/Tributacion/index';
    }

    listTributacion() {
        return axios.get('api/Tributacion/list');
    }

    showTributacion(id) {
        return axios.get('api/Tributacion/show?id=' + id);
    }

    storeTributacion(item) {
        return axios.post('api/Tributacion/store', item);
    }

    destroyTributacion(item) {
        return axios.post('api/Tributacion/destroy', item);
    }

    imprimirTributacion(id) {
        return axios.get('api/Tributacion/print?id=' + id);
    }

    imprimirTributacionGral(item) {
        return axios.post('api/Tributacion/imprimirTributacionGral', item);
    }

    //-------------------- Tipo Solvencia ----------------
    showTipoSolvencia(id) {
        return axios.get('api/TipoSolvencia/show?id=' + id);
    }

    indexTipoSolvencia() {
        return axios.defaults.baseURL + 'api/TipoSolvencia/index';
    }

    listTipoSolvencia() {
        return axios.get('api/TipoSolvencia/list');
    }

    storeTipoSolvencia(item) {
        return axios.post('api/TipoSolvencia/store', item);
    }

    destroyTipoSolvencia(item) {
        return axios.post('api/TipoSolvencia/destroy', item);
    }

    

    //-------------------- Antecedente Cajero ----------------
    showAntecedente(id) {
        return axios.get('api/Antecedente/show?id=' + id);
    }

    indexAntecedente() {
        return axios.defaults.baseURL + 'api/Antecedente/index';
    }

    listAntecedente() {
        return axios.get('api/Antecedente/list');
    }

    storeAntecedente(item) {
        return axios.post('api/Antecedente/store', item);
    }

    destroyAntecedente(item) {
        return axios.post('api/Antecedente/destroy', item);
    }

    //-------------------- select 2 de comprobante de gasto ----------------
    select2ComprobanteGasto(item) {
        return axios.post('api/ComprobanteContable/select2g', item );
    }

    printComprobanteTesoreria(id) {
        return axios.get('api/ComprobanteContable/imprimirComprobanteTesoreria?id=' + id);
    }

    //-------------------------DASHBOARD-----------------------------------

    cantidadFormulacion() {
        return axios.get('api/Dashboard/cantidadformulacion');
    }

    cantidadFormulacion23() {
        return axios.get('api/Dashboard/cantidadformulacion23');
    }

    montosGenerales() {
        return axios.get('api/Dashboard/montosgenerales');
    }

    //////////////////////////////////////// CuentaCobro
    showCuentaCobro(id) {
        return axios.get('api/CuentaCobro/show?id=' + id);
    }

    indexCuentaCobro() {
        return axios.defaults.baseURL + 'api/CuentaCobro/index';
    }

    listCuentaCobro() {
        return axios.get('api/CuentaCobro/list');
    }

    storeCuentaCobro(item) {
        return axios.post('api/CuentaCobro/store', item);
    }

    destroyCuentaCobro(item) {
        return axios.post('api/CuentaCobro/destroy', item);
    }

 //////////////////////////////////////// CuentaPago
    showCuentaPago(id) {
        return axios.get('api/CuentaPago/show?id=' + id);
    }

    indexCuentaPago() {
        return axios.defaults.baseURL + 'api/CuentaPago/index';
    }

    listCuentaPago() {
        return axios.get('api/CuentaPago/list');
    }

    storeCuentaPago(item) {
        return axios.post('api/CuentaPago/store', item);
    }

    destroyCuentaPago(item) {
        return axios.post('api/CuentaPago/destroy', item);
    }

    //////////////////////////////////////// Cuentasxcp

    indexCuentasxcpcobro() {
        return axios.defaults.baseURL + 'api/Cuentasxcp/indexcobro';
    }

    indexCuentasxcpago() {
        return axios.defaults.baseURL + 'api/Cuentasxcp/indexpago';
    }

     showCuentasxcp(id) {
        return axios.get('api/Cuentasxcp/show?id=' + id);
    }

    listCuentasxcp() {
        return axios.get('api/Cuentasxcp/list');
    }

    storeCuentasxcp(item) {
        return axios.post('api/Cuentasxcp/store', item);
    }

    destroyCuentasxcp(item) {
        return axios.post('api/Cuentasxcp/destroy', item);
    }

    //////////////////////////////////////// CuentasxcpDetalle
    indexCuentasxcpDetallecobro() {
        return axios.defaults.baseURL + 'api/CuentasxcpDetalle/indexcobro';
    }

    indexCuentasxcpDetallecobrolista() {
        return axios.defaults.baseURL + 'api/CuentasxcpDetalle/indexcobrolista';
    }

    indexCuentasxcpDetallepago() {
        return axios.defaults.baseURL + 'api/CuentasxcpDetalle/indexpago';
    }

    indexCuentasxcpDetallepagolista() {
        return axios.defaults.baseURL + 'api/CuentasxcpDetalle/indexpagolista';
    }

     showCuentasxcpDetalle(id) {
        return axios.get('api/CuentasxcpDetalle/show?id=' + id);
    }

    listCuentasxcpDetalle() {
        return axios.get('api/CuentasxcpDetalle/list');
    }

    storeCuentasxcpDetalle(item) {
        return axios.post('api/CuentasxcpDetalle/store', item);
    }

    destroyCuentasxcpDetalle(item) {
        return axios.post('api/CuentasxcpDetalle/destroy', item);
    }

    //////////////////////////////////////// CuentasxcpMontos
    indexCuentasxcpMontoscobro() {
        return axios.defaults.baseURL + 'api/CuentasxcpMontos/indexcobro';
    }

    indexCuentasxcpMontospago() {
        return axios.defaults.baseURL + 'api/CuentasxcpMontos/indexpago';
    }

     showCuentasxcpMontos(id) {
        return axios.get('api/CuentasxcpMontos/show?id=' + id);
    }

    listCuentasxcpMontos() {
        return axios.get('api/CuentasxcpMontos/list');
    }

    Montos(item) {
        return axios.post('api/CuentasxcpMontos/montos', item);
    }

    storeCuentasxcpMontos(item) {
        return axios.post('api/CuentasxcpMontos/store', item);
    }

    destroyCuentasxcpMontos(item) {
        return axios.post('api/CuentasxcpMontos/destroy', item);
    }

    ///////////////////////////////REPORTES DE CUENTASXCP//////////

    imprimirCuentaCobroGral(item) {
        return axios.post('api/CuentasxcpDetalle/imprimirCuentaCobroGral', item);
    }

    imprimirCuentaCobroDetalle(item) {
        return axios.post('api/CuentasxcpDetalle/imprimirCuentaCobroDetalle', item);
    }

    imprimirCuentaCobro(item) {
        return axios.post('api/CuentasxcpDetalle/imprimirCuentaCobro', item);
    }

    imprimirCuentaCobroBeca(item) {
        return axios.post('api/CuentasxcpDetalle/imprimirCuentaCobroBeca', item);
    }

    imprimirCuentaPago(item) {
        return axios.post('api/CuentasxcpDetalle/imprimirCuentaPago', item);
    }

    imprimirCuentaCobroBecaIndividual(item) {
        return axios.post('api/CuentasxcpDetalle/imprimirCuentaMontoIndividual', item);
    }

    imprimirCuentaPagoIndividual(item) {
        return axios.post('api/CuentasxcpDetalle/imprimirCuentaPagoIndividual', item);
    }

    ////******  IngresosC */
    showIngresosc(id) {
        return axios.get('api/Ingresosc/show?id=' + id);
    }

    indexIngresosC() {
        return axios.defaults.baseURL + 'api/Ingresosc/index';
    }

    indexIngresosCactual() {
        return axios.defaults.baseURL + 'api/Ingresosc/indexactual';
    }

    listIngresosC() {
        return axios.get('api/Ingresosc/list');
    }

    storeIngresosC(item) {
        return axios.post('api/Ingresosc/store', item);
    }

    destroyIngresosC(item) {
        return axios.post('api/Ingresosc/destroy', item);
    }

    //////////////////////////////////////// IngresosCDetalle
    indexIngresoscDetalle() {
        return axios.defaults.baseURL + 'api/IngresoscDetalle/index';
    }
    
    showIngresoscDetalle(id) {
        return axios.get('api/IngresoscDetalle/show?id=' + id);
    }

    listIngresoscDetalle() {
        return axios.get('api/IngresoscDetalle/list');
    }

    storeIngresoscDetalle(item) {
        return axios.post('api/IngresoscDetalle/store', item);
    }

    destroyIngresoscDetalle(item) {
        return axios.post('api/IngresoscDetalle/destroy', item);
    }

    sumarMontos() {
        return axios.get('api/IngresoscDetalle/sumarMontos');
    }

    sumarMontosactual() {
        return axios.get('api/IngresoscDetalle/sumarMontosactual');
    }

    importExcelIngresoscDetalle(item) {
        return axios.post('api/IngresoscDetalle/importExcel', item);
    }

    select2Ingresoscdetalle(item) {
        return axios.get('api/IngresoscDetalle/select2?q=' + item);
   }

       //////////////////////////////////////// DASHBOARDINGRESOS

   cantidadrubros() {
    return axios.get('api/DashboardIngresos/cantidadrubros');
    }

    ////******  ReversionG */

    indexReversionG() {
        return axios.defaults.baseURL + 'api/ReversionG/index';
    }

    showReversionG(id) {
        return axios.get('api/ReversionG/show?id=' + id);
    }

    ////******  Socorro */
    showSocorro(id) {
        return axios.get('api/Socorro/show?id=' + id);
    }

    indexSocorro() {
        return axios.defaults.baseURL + 'api/Socorro/index';
    }

    listSocorro() {
        return axios.get('api/Socorro/list');
    }

    storeSocorro(item) {
        return axios.post('api/Socorro/store', item);
    }

    destroySocorro(item) {
        return axios.post('api/Socorro/destroy', item);
    }

    excelSocorro(item) {
        return axios.post('api/Socorro/get_excel', item);
    }

    importExcelSocorro(item) {
        return axios.post('api/Socorro/importExcel', item);
    }

    printreportesocorro(item) {
        return axios.post('api/Socorro/reporteSocorro', item);
    }

    ////******  CajaChica */
    showCajaChica(id) {
        return axios.get('api/CajaChica/show?id=' + id);
    }

    indexCajaChica() {
        return axios.defaults.baseURL + 'api/CajaChica/index';
    }

    listCajaChica() {
        return axios.get('api/CajaChica/list');
    }

    storeCajaChica(item) {
        return axios.post('api/CajaChica/store', item);
    }

    destroyCajaChica(item) {
        return axios.post('api/CajaChica/destroy', item);
    }

    printRecibo(id) {
        return axios.get('api/CajaChica/printRecibo?id=' + id);
    }


}

