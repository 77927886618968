import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-responsive");

export default {
    name: "TransferenciaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "TransferenciaPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexTransferencia(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Gerencial' },
               // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
                { data: 'Concatenado', name: 'r.Concatenado', title: 'Nº Documento' },
                // { data: 'Preventivo', name: 'r.Preventivo', title: 'Nº Preventivo' },
                { data: 'Transferencia', name: 'r.Transferencia', title: 'Glosa' },
               // { data: 'Anexo', name: 'r.Anexo', title: 'Anexo' },
                // { data: 'Monto', name: 'Monto', title: 'Monto' },
                {
                    data: 'Aprobado', name: 'Aprobado', title: 'Aprobado', render: function (data, type, row) {
                        if (row.Aprobado == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>'; }
                        else { return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>'; }
                    }
                },
                // { data: 'Persona', name: 'Persona', title: 'Aprobado Por' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexTransferenciaDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                }

            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Poa', name: 'rd.Poa', title: 'N Trans Poa' },
                {
                    data: 'FuenteFinanciera', name: 'ff.FuenteFinanciera', title: 'Fuente Financiamiento'
                },
                {
                    data: 'Reparticion', name: 'rr.Reparticion', title: 'Repartición', render: function (data, type, row) {
                        return row.CodRep + ' - ' + row.Reparticion;
                    }
                },
                {
                    data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render: function (data, type, row) {
                        return row.CodCat + ' - ' + row.CategoriaProgramatica;
                    }
                },
                {
                    data: 'Partida', name: 'p.Partida', title: 'Partida', render: function (data, type, row) {
                        return row.CodPar + ' - ' + row.Partida;
                    }
                },

                {
                    data: 'Monto', name: 'rd.Monto', title: 'Monto', render: function (data, type, row) {
                        if (row.OrigenTransferenciaDetalle == null) {
                            return '<span class="text-danger  font-weight-bold ">-' + row.Monto + '</span>'
                        }
                        else {
                            return '<span class="text-success font-weight-bold">+' + row.Monto + '</i></span>'
                        }

                    }
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                    render: function (data, type, row) {
                        if (row.Aprobado == 0) { return data }
                        else { return '<a class="btn btn-danger btn-xs" disabled><i class="fa fa-trash"></i> Eliminar</a>' }
                    }
                },
            ],
            suma: 0,
            swOrigen: 0,
            idOrigen: 0,
            origen: {},
            total: 0,
            saldo: 0,
            saldoDestino: 0,
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            editar: 0,
            idFormulacion: 0,
           // idUnidadAcademicaDestino:0,
            idReparticion: 0,
            idCategoria: 0,
            formulacionDetalle: {},
            transferenciaDetalles: [],
            transferenciaDetalle: {},
            transferenciaDetalleDestino: {},
            destinos: [],
            registroPOA: {},
            allFormulaciones: [],
            partida: {},
            categoriaProgramatica: {},
            reparticion: {},
            reparticiones: [],
            reparticionesReasignacion: [],
            categoriaProgramaticas: [],
            categoriaProgramaticasTodas: [],
            partidas: [],
            partidasTodas: [],
            personas: [],
            formulacions: [],
            unidadAcademicas: [],
            transferencias: [],
            fuenteFinancieras: [],
            fuenteFinancieraTodas: [],
            idFuenteFinanciera:0,
            transferencia: {},
            isLoading: false,
            errorBag: {},
            formulaciones: [],
            searchFormulacion: "",
            selectedFormulacion:null,
        };
    },
    methods: {
        getFuenteFinanciera(item) {
            console.log(item);
            this.ss.listFormulacionFuenteFinanciera({ "Formulacion": item }).then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFuenteFinancieraTodas(item) {
            
            this.ss.listFuenteFinanciera({ "Formulacion": item }).then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieraTodas = response.data;
                    console.log(this.fuenteFinancieraTodas);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPartida() {
            this.partida = {};
            this.$refs['frm-partida'].show();
        },
        savePartida() {
            this.ss.storePartida(this.partida).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-partida'].hide();
                    // this.$refs['datatable-partida'].reload();
                    this.getPartida();
                    this.getPartidaTodas();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        newCategoriaProgramatica() {
            this.categoriaProgramatica = {};
            this.$refs['frm-categoriaProgramatica'].show();
        },
        saveCategoriaProgramatica() {
            this.ss.storeCategoriaProgramatica(this.categoriaProgramatica).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-categoriaProgramatica'].hide();
                    // this.$refs['datatable-categoriaProgramatica'].reload();
                    this.getCategoriaProgramatica();
                    this.getCategoriaProgramaticaTodas();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cancelPartida() {
            if (this.partida.id) {
                this.$refs['view-partida'].show();
            }
            this.$refs['frm-partida'].hide();
        },
        cancelCategoriaProgramatica() {
            if (this.categoriaProgramatica.id) {
                this.$refs['view-categoriaProgramatica'].show();
            }
            this.$refs['frm-categoriaProgramatica'].hide();
        },
     
        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        saveReparticion() {
            console.log(this.transferenciaDetalleDestino.Formulacion);
          //  this.reparticion.UnidadAcademica = this.idUnidadAcademicaDestino;
            console.log("save Reparticion", this.reparticion);
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.getReparticionReasignacion(this.transferenciaDetalleDestino.Formulacion);

                    // this.$refs['datatable-reparticion'].reload();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

        },
        cancelReparticion() {
            if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            }
            this.$refs['frm-reparticion'].hide();
        },
        newTransferencia() {
            this.transferencia = {};
            this.$refs['frm-transferencia'].show();
            this.editar = 0;
            this.saldo = 0;
        },
        resetCategoriaPartida() {
            this.transferenciaDetalle.CategoriaProgramatica = "";
            this.transferenciaDetalle.Partida = "";
            this.saldo = 0;
        },
        resetPartida() {
            this.transferenciaDetalle.Partida = "";
            this.saldo = 0;
        },
        resetPartidaDestino() {
            this.transferenciaDetalle.PartidaDestino = "";
            this.saldoDestino = 0;
        },
        showTransferencia(id) {
            this.isLoading = true;
            this.ss.showTransferencia(id).then(
                (result) => {
                    let response = result.data;
                    this.transferencia = response.data;
                    //console.log(this.transferencia);
                    //this.$forceUpdate();
                    console.log("formulacion", this.transferencia.Formulacion);
                    console.log("id", id);
                    this.idFormulacion = this.transferencia.Formulacion;
                    this.getFuenteFinanciera(this.transferencia.Formulacion);
                   // this.getReparticionFormulacion(this.transferencia);
                    //this.idTransferencia=this.transferencia.Formulacion;
                    this.$refs['view-transferencia'].show();
                    this.isLoading = false;

                    var dd = window.$('#datatable-transferenciaDetalle').DataTable();
                    console.log("sumaaa", dd.rows());
                    this.ajaxDetalle.data = function (d) {
                        d.id = id;
                    }
                    console.log("numero", this.transferencia.transferencia_detalle.length);
                    // console.log("data", this.columnsDetalle[5]);
                    //this.total=
                    //dd.reload();

                    //  dd.column(5).data().sum();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editTransferencia() {
            this.$refs['frm-transferencia'].show();
            this.$refs['view-transferencia'].hide();
            this.editar = 1;
        },
        cancelTransferencia() {
            if (this.transferencia.id) {
                this.$refs['view-transferencia'].show();
            }
            this.$refs['frm-transferencia'].hide();
        },
        saveTransferencia() {

            // this.transferencia.Aprobado==true ? this.transferencia.Aprobado : false;
            console.log("data");
            console.log(this.transferencia);
            this.ss.storeTransferencia(this.transferencia).then(
                (result) => {
                    console.log("resultado");
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    if (this.editar == 0) {
                        this.showTransferencia(response.data.id);
                    }
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-transferencia'].hide();
                    this.$refs['datatable-transferencia'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        //aqui mori
        getFormulacion() {
            this.ss.listFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.formulaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAllFormulacion() {
            this.ss.listAllFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.allFormulaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getDestinosTransferenciaDetalle(id, Origen) {

            this.ss.listDestinoTransferenciaDetalle(id, Origen).then(
                (result) => {
                    let response = result.data;
                    this.destinos = response.data;
                    console.log("listdestino", result.data);
                    this.suma = 0;
                    console.log("destinossssss",this.destinos);

                    this.destinos.forEach(element => {
                        this.suma += parseFloat(element.Monto);
                    });
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSaldo(a, b, c, d) {
            console.log(this.idFormulacion, a, b, c, d);
            if (a === null || b === null || c === null || d === null) {
                alert("Debe seleccionar la Fuente, Reparticion, la Categoria Programatica y la partida");
            } else {
                this.ss.saldoFormulacionDetalle({ "Formulacion": this.idFormulacion, "FuenteFinanciera": d, "Reparticion": a, "CategoriaProgramatica": b, "Partida": c }).then(
                    (result) => {
                        let response = result.data;
                        console.log("saldo", response.data.Saldo);

                        this.saldo = response.data.Saldo;
                    }
                ).catch(error => {
                    console.log(error);
                    this.saldo = 0;
                });
            }

        },
        getSaldoDestino(a, b, c, d) {
            console.log(this.idFormulacion, a, b, c, d);
            if (a === null || b === null || c === null) {
                alert("Debe seleccionar la Fuente, Reparticion, la Categoria Programatica y la partida");
            } else {
                this.ss.saldoFormulacionDetalle({ "Formulacion": this.idFormulacion, "FuenteFinanciera": d, "Reparticion": a, "CategoriaProgramatica": b, "Partida": c }).then(
                    (result) => {
                        let response = result.data;
                        console.log("saldo", response.data.Saldo);

                        this.saldoDestino = response.data.Saldo;
                    }
                ).catch(error => {
                    console.log(error);
                    this.saldoDestino = 0;
                });
            }

        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        deleteTransferencia() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyTransferencia(this.transferencia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-transferencia'].hide();
                                this.$refs['datatable-transferencia'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
    
        getReparticionFormulacion(fuenteFinanciera) {
            this.idFuenteFinanciera=fuenteFinanciera;
            this.ss.listFormulacionReparticion({
                'FuenteFinanciera': fuenteFinanciera,
                'Formulacion': this.transferencia.Formulacion
            }).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticionReasignacion(id) {
            
            console.log("REparticion", id);
            //this.idUnidadAcademicaDestino=id;
          
            this.ss.listReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.reparticionesReasignacion = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramatica(id) {
            console.log("idRep", id);
            this.idReparticion = id;
            this.ss.listFormulacionCategoria({ "Formulacion": this.idFormulacion, "Reparticion": this.idReparticion, "FuenteFinanciera":this.idFuenteFinanciera }).then(

                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
            this.transferenciaDetalle.CategoriaProgramatica = null;
            this.transferenciaDetalle.Partida = null;
        },
        getPartida(id) {
            console.log("idCat", id);
            this.idCategoria = id;
            this.ss.listFormulacionPartida({ "Formulacion": this.idFormulacion, "Reparticion": this.idReparticion, "CategoriaProgramatica": this.idCategoria, "FuenteFinanciera":this.idFuenteFinanciera }).then(

                (result) => {
                    let response = result.data;
                    this.partidas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
            this.transferenciaDetalle.Partida = null;
        },
        getPartidaTodas() {
            this.ss.listPartida().then(
                (result) => {
                    let response = result.data;
                    this.partidasTodas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramaticaTodas() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticasTodas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newFormulacionDetalle() {
            this.formulacionDetalle = {};
            this.$refs['frm-formulacionDetalle'].show();
        },

        newTransferenciaDetalle() {
            this.transferenciaDetalle = {};
            this.registroPOA = {};
            this.$refs['frm-transferenciaDetalle'].show();
            this.swOrigen = 0;
            this.getDestinosTransferenciaDetalle(0, 0);
            this.limpiarTransferenciaDetalle();
            
        },
        showTransferenciaDetalle(id) {
            this.isLoading = true;
            this.ss.showTransferenciaDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.transferenciaDetalle = response.data;
                    this.$refs['view-transferenciaDetalle'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editTransferenciaDetalle() {
            this.$refs['frm-transferenciaDetalle'].show();
            this.$refs['view-transferenciaDetalle'].hide();
            this.getSaldo(this.transferenciaDetalle.Reparticion,
                this.transferenciaDetalle.CategoriaProgramatica,
                this.transferenciaDetalle.Partida,
                this.transferenciaDetalle.FuenteFinanciera);
        },
        cancelTransferenciaDetalle() {
            if (this.transferenciaDetalle.id) {
                this.$refs['view-transferenciaDetalle'].show();
            }
            this.limpiarTransferenciaDetalle();
            this.$refs['frm-transferenciaDetalle'].hide();
            this.saldo = 0;
            this.saldDestino = 0;
            this.limpiarTransferenciaDetalle();
            this.swOrigen = 0;
        },
        saveTransferenciaDetalle() {

            console.log("swO", this.swOrigen);
            console.log("transferenciaDetalle", this.transferenciaDetalle);
            if (this.swOrigen == 1 && parseFloat(this.transferenciaDetalle.Monto) == this.suma) {
                this.transferenciaDetalle.Transferencia = this.transferencia.id;
                this.transferenciaDetalle.Formulacion = this.transferencia.Formulacion;

                this.ss.guardarTransferenciaDetalle().then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        //this.$refs['view-consulta'].show(); //para volver al modal
                        console.log(response);
                        this.getDestinosTransferenciaDetalle(this.transferenciaDetalle.Transferencia, this.idOrigen);
                        this.$refs['frm-transferenciaDetalle'].hide();
                        this.$refs['datatable-transferenciaDetalle'].reload();
                        // this.showTransferencia(this.transferencia.id);
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }
            else {

                this.$bvToast.toast(
                    'Registre todos los campos en transferencia presupuesto y reasigne el presupuesto.',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                );

            }
        },
        reasignarPresupuesto() {
            var table = window.$('#datatable-transferenciaDetalleDestino').DataTable();
            console.log("destino", table.data().count());
            console.log("Reasignacion", this.transferenciaDetalleDestino);
            if (this.transferenciaDetalleDestino.Formulacion == null || this.transferenciaDetalleDestino.Reparticion == null || this.transferenciaDetalleDestino.MontoDestino == null) {
                this.$bvToast.toast(
                    'Registre todos los campos en reasignacion presupuesto.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );
            }
            else {
                console.log(this.transferenciaDetalle.Reparticion, this.transferenciaDetalle.Poa, this.transferenciaDetalle.Monto);
                if (this.transferenciaDetalle.Reparticion == null || this.transferenciaDetalle.Poa == null || this.transferenciaDetalle.Monto == null) {

                    this.$bvToast.toast(
                        'Registre todos los campos en transferencia presupuesto.',
                        {
                            title: 'Error',
                            variant: 'warning',
                            autoHideDelay: 5000
                        }
                    );
                } else {
                    console.log(this.transferenciaDetalle.Monto, this.transferenciaDetalleDestino.MontoDestino);
                    if (parseFloat(this.transferenciaDetalle.Monto) < parseFloat(this.transferenciaDetalleDestino.MontoDestino) + this.suma) {
                        this.$bvToast.toast(
                            'El monto a reasignar no puede ser superior al transferido.',
                            {
                                title: 'Error',
                                variant: 'warning',
                                autoHideDelay: 5000
                            }
                        );
                    } else {
                        this.transferenciaDetalle.Transferencia = this.transferencia.id;
                        this.transferenciaDetalle.Estado = 0;
                        if (this.swOrigen == 0) {
                            this.transferenciaDetalle.Formulacion = this.transferencia.Formulacion;
                            console.log(this.transferenciaDetalle , "esto envia inicialmente" )
                            this.ss.storeTransferenciaDetalle(this.transferenciaDetalle).then(
                                (result) => {
                                    console.log(result);
                                    let response = result.data;
                                    this.origen = response.data;
                                    console.log("response", this.origen, 'ffffff');
                                    console.log("idOrigen", result.data.data.id);
                                    this.idOrigen = result.data.data.id;
                                    this.swOrigen = 1;
                                    if (result.data.success) {
                                        this.transferenciaDetalle.Formulacion = this.transferenciaDetalleDestino.Formulacion;
                                        this.transferenciaDetalle.FuenteFinanciera = this.transferenciaDetalleDestino.FuenteFinanciera;
                                        this.transferenciaDetalle.Reparticion = this.transferenciaDetalleDestino.Reparticion;
                                        this.transferenciaDetalle.Monto = this.transferenciaDetalleDestino.MontoDestino;
                                        this.transferenciaDetalle.OrigenTransferenciaDetalle = this.idOrigen;
                                        console.log(this.transferenciaDetalle , "esto envia por segunda vez" )
                                        this.ss.storeTransferenciaDetalle(this.transferenciaDetalle).then(
                                            (result) => {
                                                console.log(result);
                                                let response = result.data;
                                                console.log(response);
                                                this.transferenciaDetalleDestino = {};

                                                this.transferenciaDetalle.FuenteFinanciera = this.origen.FuenteFinanciera;
                                                this.transferenciaDetalle.Reparticion = this.origen.Reparticion;
                                                this.transferenciaDetalle.CategoriaProgramatica = this.origen.CategoriaProgramatica;
                                                this.transferenciaDetalle.Partida = this.origen.Partida;
                                                this.transferenciaDetalle.Monto = this.origen.Monto;
                                                console.log(result.data.data.Transferencia, result.data.data.OrigenTransferenciaDetalle);
                                                this.getDestinosTransferenciaDetalle(result.data.data.Transferencia, result.data.data.OrigenTransferenciaDetalle);
                                                //  this.$refs['detalleTransferenciaDestino'].refresh();
                                                //                                           console.log("destinos", this.destinos );
                                                //                                     this.destinos.forEach(function(item) {
                                                //                                         console.log("item", item.Monto);
                                                //                                         this.suma += parseInt(item.Monto);
                                                //                                     });
                                                // console.log("suma", this.suma);
                                            })
                                            .catch((error) => {
                                                this.errorBag = error.response;
                                                this.$bvToast.toast(
                                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                                    {
                                                        title: 'Error',
                                                        variant: 'danger',
                                                        autoHideDelay: 5000
                                                    }
                                                )
                                            });
                                    }

                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(
                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                });


                        } else {
                            this.transferenciaDetalle.Formulacion = this.transferenciaDetalleDestino.Formulacion;
                            this.transferenciaDetalle.Reparticion = this.transferenciaDetalleDestino.Reparticion;
                            this.transferenciaDetalle.CategoriaProgramatica = this.transferenciaDetalleDestino.CategoriaProgramatica;
                            this.transferenciaDetalle.Partida = this.transferenciaDetalleDestino.Partida;
                            this.transferenciaDetalle.Monto = this.transferenciaDetalleDestino.MontoDestino;
                            this.transferenciaDetalle.OrigenTransferenciaDetalle = this.idOrigen;
                            this.ss.storeTransferenciaDetalle(this.transferenciaDetalle).then(
                                (result) => {
                                    console.log(result);
                                    let response = result.data;
                                    console.log(response);
                                    this.transferenciaDetalleDestino = {};
                                    this.transferenciaDetalle.Reparticion = this.origen.Reparticion;
                                    this.transferenciaDetalle.CategoriaProgramatica = this.origen.CategoriaProgramatica;
                                    this.transferenciaDetalle.Partida = this.origen.Partida;
                                    this.transferenciaDetalle.Monto = this.origen.Monto;
                                    console.log(result.data.data.Transferencia, result.data.data.OrigenTransferenciaDetalle);

                                    this.getDestinosTransferenciaDetalle(result.data.data.Transferencia, result.data.data.OrigenTransferenciaDetalle);
                                    // console.log("destinos", this.destinos );

                                    // this.ajaxDestino.data = function (d) {
                                    //     d.id = result.data.data.id;
                                    //     d.OrigenTransferenciaDetalle = result.data.data.OrigenTransferenciaDetalle;
                                    // }
                                    //this.$refs['detalleTransferenciaDestino'].refresh();
                                    //   console.log("suma", this.suma);
                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(
                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                });
                        }
                    }


                }


            }
        },

        deleteTransferenciaDetalle(id) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyTransferenciaDetalle({ 'id': id })
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-transferenciaDetalle'].hide();
                                this.$refs['datatable-transferenciaDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        deleteDestinoTransferenciaDetalle(destino) {
            console.log("delete", destino);
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.deleteTransferenciaDetalle(destino)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.getDestinosTransferenciaDetalle(this.transferenciaDetalle.Transferencia, this.idOrigen);
                                // this.$refs['view-transferenciaDetalle'].hide();
                                //this.$refs['datatable-transferenciaDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        limpiarTransferenciaDetalle() {
            this.ss.limpiarTransferenciaDetalle().then(
                (result) => {
                    let response = result.data;
                    //  this.categoriaProgramaticasTodas = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                }
            ).catch(error => {
                console.log(error);
            });

        },
        aprobarTransferencia() {


            if (this.transferencia.suma !== 0) {
                this.$bvToast.toast(
                    'El comprobante de transferencia no cuadra.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            }
            else {
                this.isLoading = true;
                this.ss.aprobarTransferencia({ 'id': this.transferencia.id, 'Aprobado': true, 'Monto': this.transferencia.suma }).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['datatable-transferencia'].reload();
                        // this.$swal.fire({
                        //     position: 'center',
                        //     icon: 'success',
                        //     title: 'Aprobado correctamente.',
                        //     showConfirmButton: false,
                        //     timer: 1500
                        //   });
                        this.$refs['view-transferencia'].hide();

                        this.isLoading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

            }



        },
        desaprobarTransferencia() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de esta transferencia.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarTransferencia({ 'id': this.transferencia.id, 'Aprobado': false }).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['datatable-transferencia'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-transferencia'].hide();

                                this.isLoading = false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        reporteTransferencia() {
            console.log("reporte transferencia", this.transferencia.id);
            this.ss.imprimirTransferencia(this.transferencia.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        console.log(urlFile);
                        console.log("-------------------------------------------------------");
                        console.log(this.rutaApp);
                        var dir = this.rutaApp + urlFile.url;
                        console.log("-------------------------------------------------------");
                        console.log(dir);
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },

        draw() {
            window.$('.btn-datatable-Transferencia').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showTransferencia(data);
            });
        },
        drawDetalle() {
            // this.idTransferencia=1;
            window.$('.btn-datatable-TransferenciaDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.deleteTransferenciaDetalle(data);
            });

        }
        // ,
        // drawDestino() {
        //     // this.idTransferencia=1;
        //     window.$('.btn-datatable-DestinoTransferenciaDetalle').on('click', (evt) => {
        //         const data = window.$(evt.target)[0].id;
        //         this.deleteDestinoTransferenciaDetalle(data);
        //     });

        // }

    },
    watch: {
        searchFormulacion(q) {
            this.ss.select2Formulacion(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.formulaciones = res.data.items;
                } else {
                    this.formulaciones = [];
                }
            })
        },
        searchPartida(q) {

            this.ss.select2Partida(q).then((res) => {
               // console.log("partida",res);
                if (res.data.success) {
                   
                    this.partidas = res.data.items;
                
                } else {
                    this.partidas = [];
                }
            });
   
    
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getFormulacion();
            this.getAllFormulacion();
            this.getPersona();
            this.getReparticionFormulacion();
            this.getCategoriaProgramatica();
            this.getPartida();
            this.getPartidaTodas();
            this.getCategoriaProgramaticaTodas();
            this.getReparticionReasignacion(0);
            this.getFuenteFinancieraTodas();
            this.getFuenteFinanciera();
            // this.getDestinosTransferenciaDetalle();
 
        }
    }
};
