import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "BuscadorPagoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "BuscadorPagoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexCuentasxcpDetallepagolista(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Fecha', name: 'co.Fecha', title: 'Fecha Origen', searchable: true },
                {
                    data: 'Cuenta', name: 'c.Cuenta', title: 'Cuenta', render: function (data, type, row) {
                        return row.CodCuenta + ' - ' + row.Cuenta;
                    }, searchable: false
                },
                { data: 'Preventivo', name: 'cd.Preventivo', title: 'Preventivo', searchable: true },
                { data: 'Subcuenta', name: 'su.Subcuenta', title: 'Cuentadante', searchable: true },
                { data: 'Codigo', name: 'su.Codigo', title: 'Carnet O NIT', searchable: true },
                { data: 'Glosa', name: 'cd.Glosa', title: 'Glosa' },
                { data: 'sumadebe', name: 'sumadebe', title: 'Total Debe', searchable: false },
                { data: 'sumahaber', name: 'sumahaber', title: 'Total Haber', searchable: false },

                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxMonto: {
                "url": ss.indexCuentasxcpMontospago(),
                headers: ss.getToken(),
            },
            columnsMonto: [
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Fecha', name: 'cm.Fecha', title: 'Fecha' },
                { data: 'Partida', name: 'cm.Partida', title: 'Partida' },
                { data: 'debe', name: 'cm.debe', title: 'Debe' },
                { data: 'haber', name: 'cm.haber', title: 'Haber' },
                { data: 'Monto', name: 'cm.Monto', title: 'Monto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            cuentapagos: [],
            cuentapago: {},
            isLoading: false,
            errorBag: {},
            cuentas: [],
            cuentapagoDetalle: {},
            reparticiones: [],
            fuentefinancieras: [],
            subcuentas: [],
            selectedSubcuenta: null,
            searchSubcuenta: "",
            placeholderSubcuenta: "",
            searchCuenta: "",
            placeholderCuenta: "",
            searchPartida: "",
            placeholderPartida: "",
            searchReparticion: "",
            placeholderReparticion: "",
            lipartidas: {},
            ids: "",
            Estado: '',
            estados: [
              { label: 'Pendiente', value: '0' },
              { label: 'Observado', value: '1' },
              { label: 'Cerrado', value: '2' }
            ],
            cuentapagoMonto: {},
            montos:{},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,

        };
    },
    methods: {
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuentefinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCuentaPago() {
            this.cuentapago = {};
            this.$refs['frm-cuentapago'].show();
        },
        showCuentaPago(id) {
            this.isLoading=true;
            this.ss.showCuentasxcp(id).then(
                (result) => {
                    let response = result.data;
                    this.cuentapago = response.data;
                    this.$refs['view-cuentapago'].show();
                    this.aplicarFiltroDetalle();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        showCuentaPagoDetalle(id) {
            this.isLoading = true;
            this.ss.showCuentasxcpDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.cuentapagoDetalle = response.data;
                    this.$refs['view-cuentapagoDetalle'].show();
                    this.aplicarFiltroMonto();
                    this.aplicarmontos();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        showCuentaPagoMonto(id) {
            this.isLoading = true;
            this.ss.showCuentasxcpMontos(id).then(
                (result) => {
                    let response = result.data;
                    this.cuentapagoMonto = response.data;
                    this.$refs['view-cuentapagoMonto'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editCuentaPago() {
            this.$refs['frm-cuentapago'].show();
            this.$refs['view-cuentapago'].hide();
        },
        cancelCuentaPago() {
            if (this.cuentapago.id) {
                this.$refs['view-cuentapago'].show();
            }
            this.$refs['frm-cuentapago'].hide();
        },
        saveCuentaPago() {
            this.cuentapago.Tipo = 2;
            this.ss.storeCuentasxcp(this.cuentapago).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-cuentapago'].hide();
                    this.$refs['datatable-cuentapago'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editCuentaPagoDetalle() {
            this.$refs['frm-cuentapagoDetalle'].show();
            this.$refs['view-cuentapagoDetalle'].hide();
        },
        cancelCuentaPagoDetalle() {
            if (this.cuentapagoDetalle.id) {
                this.$refs['view-cuentapagoDetalle'].show();
            }
            this.$refs['frm-cuentapagoDetalle'].hide();
        },
        deleteCuentaPago() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCuentasxcp(this.cuentapago)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-cuentapago'].hide();
                                this.$refs['datatable-cuentapago'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        editCuentaPagoMonto() {
            this.$refs['frm-cuentapagoMonto'].show();
            this.$refs['view-cuentapagoMonto'].hide();
        },
        cancelCuentaPagoMonto() {
            if (this.cuentapagoMonto.id) {
                this.$refs['view-cuentapagoMonto'].show();
            }
            this.$refs['frm-cuentapagoMonto'].hide();
        },
        deleteCuentaPagoMonto() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCuentasxcpMontos(this.cuentapagoMonto)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-cuentapagoDetalle'].hide();
                                this.$refs['datatable-cuentapagoDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        newcuentapagoDetalle() {
            this.cuentapagoDetalle = {};
            this.$refs['frm-cuentapagoDetalle'].show();
            this.placeholderCuenta = "Escriba la cuenta...";
            this.placeholderSubcuenta = "Escriba la subcuenta...";
        },
        listpartidas() {
            this.isLoading=true;
            this.ids = this.cuentapagoDetalle.Cuenta
            this.ss.listpartidas(this.ids).then(
                (result) => {
                    let response = result.data;
                    this.lipartidas = response.data;
                    console.log(this.lipartidas)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        saveComprobanteContableDetalle() {
            this.cuentapagoDetalle.Cuentasxcp = this.cuentapago.id
            this.cuentapagoDetalle.TipoCobro = 2
            this.ss.storeCuentasxcpDetalle(this.cuentapagoDetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-cuentapagoDetalle'].hide();
                    this.$refs['datatable-cuentapagoDetalle'].reload();
                    this.$refs['view-cuentapago'].show();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        reload() {
            // Llama a la función o método necesario para obtener los datos actualizados
            this.$refs['datatable-cuentapagoDetalle'] = this.ss.showCuentaPagoDetalle(); // Reemplaza esto con la forma en que obtienes los datos de la tabla
            this.$refs['datatable-cuentapagoMonto'] = this.ss.showCuentaPagoMonto(); // Reemplaza esto con la forma en que obtienes los datos de la tabla
        },
        newcuentapagoMonto() {
            this.cuentapagoMonto = {};
            this.$refs['frm-cuentapagoMonto'].show();
        },
        saveComprobanteContableMonto() {
            this.cuentapagoMonto.CuentasxcpDetalle = this.cuentapagoDetalle.id
            this.ss.storeCuentasxcpMontos(this.cuentapagoMonto).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-cuentapagoMonto'].hide();
                    this.$refs['datatable-cuentapagoMonto'].reload();
                    this.$refs['view-cuentapagoDetalle'].show();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        draw() {
            window.$('.btn-datatable-cuentapagoDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCuentaPagoDetalle(data);
            });

        },
        drawMonto() {
            window.$('.btn-datatable-cuentapagoMonto').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCuentaPagoMonto(data);
            });
        },
        aplicarFiltroDetalle(){
            let detallid = this.cuentapago.id
            this.ajaxDetalle.data = function(d){
                d.detalle = detallid
            }
        },
        limpiar(){
            document.getElementById("detallid").value = "";
            this.$refs['datatable-cuentapagoDetalle'].reloadAjax();
        },
        aplicarFiltroMonto(){
            let montoid = this.cuentapagoDetalle.id
            this.ajaxMonto.data = function(r){
                r.monto = montoid
            }
        },
        limpiardos(){
            document.getElementById("montoid").value = "";
            this.$refs['datatable-cuentapagoMonto'].reloadAjax();
        },
        aplicarmontos() {
            this.monto = this.cuentapagoDetalle.id
            console.log(this.monto, 'montossss');
            console.log(this.cuentapagoDetalle.id, 'montossss   iiddddd');
            this.ss.Montos({"id": this.monto}).then(
                (result) => {
                    let response = result.data;
                    this.montos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        abrirModalReporteCuentasxcp() {
            //this.formulariobp = {};
            this.$refs['frm-Reportes'].show();
        },
        imprimirCuentaPago() {
            this.ss.imprimirCuentaPago(4)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirCuentaPagoIndividual() {
            this.idPago = this.cuentapagoMonto.id
            this.ss.imprimirCuentaPagoIndividual({"idPago": this.idPago})
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
    },
    watch: {
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                if (res.data.success) {
                    this.reparticiones = res.data.items;
                } else {
                    this.reparticiones = [];
                }
            })
        },
        searchCuenta(q) {
            this.ss.select2Cuenta(q).then((res) => {
                this.listpartidas();
                if (res.data.success) {
                    this.cuentas = res.data.items;
                } else {
                    this.cuentas = [];
                }
                
            })
        },
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getFuenteFinanciera();
        }
    }
};
