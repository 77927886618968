import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CertificadoSolvenciaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CertificadoSolvenciaPage",
            ss: ss,
            auth: {},
            
            ajax: {
                "url": ss.indexCertificadoSolvencia(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: true, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'CodigoCer', name: 'cs.CodigoCer', title: 'Codigo Certificado' },
                { data: 'Subcuenta', name: 'su.Subcuenta', title: 'Subcuenta' },
                { data: 'Codigo', name: 'su.Codigo', title: 'Carnet de Identidad' },
                { data: 'FechaElaboracion', name: 'cs.FechaElaboracion', title: 'Elaboracion' },
                { data: 'EstadoCert', name: 'cs.EstadoCert', title: 'Estado', render: function (data, type, row) {
                    if (row.EstadoCert == 1) {
                            return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador </i>';
                        } else if (row.EstadoCert == 2) {
                            return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';
                        } else if (row.EstadoCert == 3) {
                            return '<i class="fa fa-times text-warning  font-italic font-weight-bold"> Anulado </i>';
                        } 
                    },
                },
                { data: 'Aprobado', name: 'cs.Aprobado', title: 'Aprobado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                    else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                    } 
                },
                // { data: 'AprobadoConta', name: 'cs.AprobadoConta', title: 'Aprobado Contabilidad', render:function(data, type, row){
                //     if (row.AprobadoConta==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                //     else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                //     } 
                // },
                // { data: 'AprobadoPresu', name: 'cs.AprobadoPresu', title: 'Aprobado Presupuesto', render:function(data, type, row){
                //     if (row.AprobadoPresu==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                //     else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                //     } 
                // },
                // { data: 'AprobadoDescargo', name: 'cs.AprobadoDescargo', title: 'Aprobado Descargo', render:function(data, type, row){
                //     if (row.AprobadoDescargo==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                //     else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                //     } 
                // },
                // { data: 'AprobadoAyudante', name: 'cs.AprobadoAyudante', title: 'Aprobado Ayudante', render:function(data, type, row){
                //     if (row.AprobadoAyudante==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                //     else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                //     } 
                // },
                { data: 'TipoSolvencia', name: 'ts.TipoSolvencia', title: 'Tipo Certificado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            certificadosolvencias: [],
            certificadosolvencia: {},
            isLoading: false,
            errorBag: {},
            persona: {},
            subcuentas: [],
            searchSubcuenta: "",
            selectedSubCuenta:null,
            contabilidad: {},
            presupuesto: {},
            descargo: {},
            ayudante: {},
            cantidadtipocert: {},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            solvencia: [],
            reporte: {},
            reportes: {},
            tiposolvencias: [],

        };
    },
    methods: {
        getTipoSolvencia() {
            this.ss.listTipoSolvencia().then(
                (result) => {
                    let response = result.data;
                    this.tiposolvencias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCertificadoSolvencia() {
            this.certificadosolvencia = {};
            this.$refs['frm-certificadosolvencia'].show();
        },
        showCertificadoSolvencia(id) {
            this.isLoading=true;
            this.ss.showCertificadoSolvencia(id).then(
                (result) => {
                    let response = result.data;
                    this.certificadosolvencia = response.data;
                    this.$refs['view-certificadosolvencia'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCertificadoSolvencia() {
            this.$refs['frm-certificadosolvencia'].show();
            this.$refs['view-certificadosolvencia'].hide();
        },
        cancelCertificadoSolvencia() {
            if (this.certificadosolvencia.id) {
                this.$refs['view-certificadosolvencia'].show();
            }
            this.$refs['frm-certificadosolvencia'].hide();
        },
        saveCertificadoSolvencia() {
            this.ss.storeCertificadoSolvencia(this.certificadosolvencia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-certificadosolvencia'].hide();
                    this.$refs['datatable-certificadosolvencia'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCertificadoSolvencia() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCertificadoSolvencia(this.certificadosolvencia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-certificadosolvencia'].hide();
                                this.$refs['datatable-certificadosolvencia'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-certificadosolvencia').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCertificadoSolvencia(data);
            });
        },
        abrirModalContabilidad(){
            this.$refs['modalContabilidad'].show();
        },
        abrirModalPresupuesto(){
            this.$refs['modalPresupuesto'].show();
        },
        abrirModalDescargo(){
            this.$refs['modalDescargo'].show();
        },
        abrirModalAyudante(){
            this.$refs['modalAyudante'].show();
        },
        abrirModalReportes() {
            this.reporte = {};
            this.$refs['frm-reportes'].show();
        }, 
        saveContabilidad() {
            this.ss.storeContabilidad(this.contabilidad).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['modalContabilidad'].hide();
                    this.$refs['datatable-certificadosolvencia'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        setReset() {
            this.reportes = {};
        },
        cancelArchivoC() {
            if (this.reportes.id) {
                this.$refs['frm-reportes'].show();
            }
            this.$refs['frm-reportes'].hide();
        },
        printCerAprobado() {
            console.log(this.certificadosolvencia.id);
            this.ss.printCerAprobado(this.certificadosolvencia.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        printCerObservado() {
            this.ss.printCerObservado(this.certificadosolvencia.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        imprimirListaCertificados() {
            this.ss.imprimirListaCertificados(this.reportes)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        showcantidadtipocert() {
            this.isLoading=true;
            console.log (this.certificadosolvencia.TipoSolvencia);
            this.ss.showcantTipoSolvencia(this.certificadosolvencia.TipoSolvencia).then(
                (result) => {
                    let response = result.data;
                    this.cantidadtipocert = response.data;
                    console.log(this.cantidadtipocert)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
    },
    watch: {
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
        }
    },
    components: {
        dataTable,
        Loader
    },
    filters: {
       
         intToExtension: function (value) {
            let estado = "";
            if (value == 'LP.') {
                estado = "La Paz"
            } 
             else if (value == 'CBBA.') {
                estado = "Cochabamba"
            }
            else if (value == 'SC.') {
                estado = "Santa Cruz"
            }
            else if (value == 'BN') {
                estado = "Beni"
            }
            return estado;
        },
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth= JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getTipoSolvencia();
        }
    }
};
