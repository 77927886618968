import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "TributacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "TributacionPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexTributacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'CargoCuenta', name: 'e.CargoCuenta', title: 'Tipo', 
                render: function (data, type, row) {
                             if (row.CargoCuenta == 1) {
                                 return '<i class="fa fa-check text-success font-italic font-weight-bold "> Cargo de Cuenta </i>';
                             } else if (row.CargoCuenta == 3) {
                                 return '<i class="fa fa-check text-info  font-italic font-weight-bold"> Fondo Rotativo </i>';
                             } 
                         }
                },
                { data: 'Preventivo', name: 'tr.Preventivo', title: 'Preventivo' },
                { data: 'Tributacion', name: 'tr.Tributacion', title: 'Tributacion' },
                { data: 'MontoTres', name: 'tr.MontoTres', title: 'Servicio 3%' },
                { data: 'MontoDoce', name: 'tr.MontoDoce', title: 'Servicio 13%' },
                { data: 'MontoTresc', name: 'tr.MontoTresc', title: 'Compra 3%' },
                { data: 'MontoCinco', name: 'tr.MontoCinco', title: 'Compra 5%' },
                { data: 'MontoTrece', name: 'tr.MontoTrece', title: 'Iva 13%' },
                { data: 'MontoMulta', name: 'tr.MontoMulta', title: 'Multa' },
                { data: 'MontoOtro', name: 'tr.MontoOtro', title: 'Otro Ingreso' },
                { data: 'MontoTotalGral', name: 'tr.MontoTotalGral', title: 'Monto Total' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            tributaciones: [],
            tributacion: {},
            isLoading: false,
            errorBag: {},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            subcuentas: [],
            egresos: [],
            egreso:{},
            searchSubcuenta: "",
            selectedSubCuenta:null,
            reparticiones: [],
            searchReparticion: "",
            selectedReparticion: null,
            fuenteFinancieras: [],
            archivo: {},
            searchEgreso: "",
            searchEgresoF: "",
            placeholderEgreso:"",
            unidadAcademicas: [],
            MontoTres: 0,
            MontoDoce: 0,
            MontoTresc: 0,
            MontoCinco: 0,
            montoTotalServicio: 0,
            montoTotalCompra: 0,
            montoTotalIva: 0,
            montoTotalMulta: 0,
            montoTotalOtro: 0,
            montoTotalGral: 0,
            MontoTrece: 0,
            MontoMulta: 0,
            MontoOtro: 0,
            MontoTotalGral: 0,

        };
    },
    methods: {
        calcularservicio(){
            
            this.MontoTres = parseFloat(this.tributacion.MontoServicio)* ( 3/16);
            this.MontoDoce = parseFloat(this.tributacion.MontoServicio) * ( 13/16);
            console.log(this.MontoTres.toFixed(2));
            console.log(this.MontoDoce.toFixed(2));
            this.montoTotalServicio = parseFloat(this.MontoTres) + parseFloat(this.MontoDoce);
            console.log(this.MontoTotalServicio);
        },
        calcularcompra(){
            
            this.MontoTresc = parseFloat(this.tributacion.MontoCompra) * ( 3/8);
            this.MontoCinco = parseFloat(this.tributacion.MontoCompra) * ( 5/8);
            console.log(this.MontoTresc);
            console.log(this.MontoCinco);
            this.montoTotalCompra = parseFloat(this.MontoTresc) + parseFloat(this.MontoCinco);
            console.log(this.MontoTotalCompra);
        },

        montototal(){
            this.montoTotalGral = parseFloat(this.montoTotalServicio) + parseFloat(this.montoTotalCompra) + parseFloat(this.MontoTrece) + parseFloat(this.MontoMulta) + parseFloat(this.MontoOtro);
        },
        
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newTributacion() {
            this.tributacion = {};
            this.$refs['frm-tributacion'].show();
        },
        abrirModalCierreTributacion() {
            this.tributacion = {};
            this.$refs['frm-Tributacion'].show();
        },   
        showTributacion(id) {
            this.isLoading=true;
            this.ss.showTributacion(id).then(
                (result) => {
                    let response = result.data;
                    this.tributacion = response.data;
                    this.$refs['view-tributacion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editTributacion() {
            this.$refs['frm-tributacion'].show();
            this.$refs['view-tributacion'].hide();
        },
        cancelTributacion() {
            if (this.tributacion.id) {
                this.$refs['view-tributacion'].show();
            }
            this.$refs['frm-tributacion'].hide();
        },
        saveTributacion() {
            console.log(this.tributacion, ' aqui estoy formulario');
            this.tributacion.MontoTres = this.MontoTres;
            this.tributacion.MontoDoce = this.MontoDoce;
            this.tributacion.MontoTotalServicio = this.montoTotalServicio;
            this.tributacion.MontoTresc = this.MontoTresc;
            this.tributacion.MontoCinco = this.MontoCinco;
            this.tributacion.MontoTotalCompra = this.montoTotalCompra;
            this.tributacion.MontoTrece = this.MontoTrece;
            this.tributacion.MontoTotalIva = this.montoTotalIva;
            this.tributacion.MontoMulta = this.MontoMulta;
            this.tributacion.MontoTotalMulta = this.montoTotalMulta;
            this.tributacion.MontoOtro = this.MontoOtro;
            this.tributacion.MontoTotalGral = this.montoTotalGral;
            this.tributacion.TipoComprobante = this.egreso.TipoComprobante;
            this.ss.storeTributacion(this.tributacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-tributacion'].hide();
                    this.$refs['datatable-tributacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteTributacion() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyTributacion(this.tributacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-tributacion'].hide();
                                this.$refs['datatable-tributacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-tributacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showTributacion(data);
            });
        },
        abrirModalCierreArchivobp(){
            this.$refs['modalCierreArchivobp'].show();
        },
        saveCierreArchivobp() {
             this.archivo.id = this.tributacion.id
             this.archivo.FojasArchivo = this.tributacion.FojasArchivo
             this.archivo.FechaCierreArchivo = this.tributacion.FechaCierreArchivo
             this.archivo.Observaciones = this.tributacion.Observaciones
             this.archivo.Activo = this.tributacion.Cerrado
             this.ss.storeCierreTributacion(this.archivo ).then(
                 (result) => {
                     console.log(result);
                     let response = result.data;
                     this.$bvToast.toast(
                         response.msg,
                         {
                             title: 'Correcto',
                             variant: 'success',
                             autoHideDelay: 5000
                         }
                     )
                     //this.$refs['view-descargo'].reload();
                     this.$refs['datatable-tributacion'].reload();
                     this.$refs['archivo'].reload();
                 })
                 .catch((error) => {
                     this.errorBag = error.response.data.errors;
                     this.$bvToast.toast(
                         'Problema al Guardar el Registro, favor verificar los Datos',
                         {
                             title: 'Error',
                             variant: 'danger',
                             autoHideDelay: 5000
                         }
                     )
                 });
         },
         print() {
             console.log("id al reporte archivo",this.tributacion.id);
             this.ss.printCierreArchivobp(this.tributacion.id)
                 .then(
                     result => {
                         let response = result.data;
                         var urlFile = response.data;
                         var dir = this.rutaApp + urlFile.url;
                         window.open(dir);
                     })
                 .catch(error => {
                     console.log(error);
                     this.isLoading = false;
                 })
 
         },
         aprobarTributacion() {
            if(this.tributacion.Aprobado.length===0){
                this.$bvToast.toast(
                    'Registre detalles de formulario.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );
            }else{
                    this.isLoading=true;
                    this.ss.aprobarTributacion({'id':this.tributacion.id, 'Aprobado':true}).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                           this.$refs['datatable-tributacion'].reload();
                            this.$refs['view-tributacion'].hide();
                            this.isLoading=false;
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }
        },
         desaprobarTributacion() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificación de este Proceso.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarTributacion({'id':this.tributacion.id, 'Aprobado':false}).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                               this.$refs['datatable-tributacion'].reload();
                               this.$refs['view-tributacion'].hide();
                               this.isLoading=false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                         //swal("Your imaginary file is safe!");
                    }
                });
        },
        setReset() {
            this.tributacion = {};
        },

        imprimirTributacionGral() {
            console.log(this.tributacion);
            this.ss.imprimirTributacionGral(this.tributacion)
                .then(
                    result => {
                        let response = result.data;
                        console.log(response);
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        
    },
    watch: {
        searchEgreso(q) {
            console.log("egreso",q);
            this.egreso.TipoComprobante=4 ;
            this.egreso.q=q;
            this.ss.select26ComprobanteContable(this.egreso).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.egresos = res.data.items;
                } else {
                    this.egresos = [];
                }
            })
        },
        searchEgresoF(q) {
            console.log("egreso",q);
            this.egreso.TipoComprobante=10;
            this.egreso.q=q;
            this.ss.select26ComprobanteContable(this.egreso).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.egresos = res.data.items;
                } else {
                    this.egresos = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
    filters: {
       
        intToTipo: function (value) {
            let tipo = "";
            //0 debe 1 Haber
            if (value == 0) {
                tipo = "Pago Directo"
            } 
             else if (value == 2) {
            tipo = "Beneficiario Final"
        }
            return tipo;
        },

        intToEstado: function (value) {
            let estado = "";
            //0 debe 1 Haber
            if (value == 0) {
                estado = "Sin revisar"
            } 
             else if (value == 1) {
                estado = "Aprobado"
            }
            else if (value == 2) {
                estado = "Observado"
            }
            else if (value == 3) {
                estado = "Sin Descargo"
            }
            return estado;
        },
      
        intToMonth: function (value) {
                if (!value) return '';
                let mes = "";
                if (value == 1) {
                    mes = "Enero"
                } else if (value == 2) {
                    mes = "Febrero"
                } else if (value == 3) {
                    mes = "Marzo"
                } else if (value == 4) {
                    mes = "Abril"
                } else if (value == 5) {
                    mes = "Mayo"
                } else if (value == 6) {
                    mes = "Junio"
                } else if (value == 7) {
                    mes = "Julio"
                } else if (value == 8) {
                    mes = "Agosto"
                } else if (value == 9) {
                    mes = "Septiembre"
                } else if (value == 10) {
                    mes = "Octubre"
                } else if (value == 11) {
                    mes = "Noviembre"
                } else if (value == 12) {
                    mes = "Diciembre"
                }
                return mes;
         
        }
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        this.getUnidadAcademica();
    }
};
