import { render, staticRenderFns } from "./CertificacionDetallePage.vue?vue&type=template&id=10c0780e&"
import script from "./CertificacionDetallePage.js?vue&type=script&lang=js&"
export * from "./CertificacionDetallePage.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports