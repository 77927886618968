import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-responsive");

export default {
    name: "ReformulacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReformulacionPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexReformulacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'r.Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'r.Fecha', title: 'Fecha' },
                //{ data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Gerencial' },
                // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
                { data: 'Concatenado', name: 'r.Concatenado', title: 'Nº Documento' },
                // { data: 'Preventivo', name: 'r.Preventivo', title: 'Nº Preventivo' },
                { data: 'Reformulacion', name: 'r.Reformulacion', title: 'Glosa' },
                //{ data: 'Anexo', name: 'r.Anexo', title: 'Anexo' },
                // { data: 'Monto', name: 'Monto', title: 'Monto' },
                {
                    data: 'Aprobado', name: 'Aprobado', title: 'Aprobado', render: function (data, type, row) {
                        if (row.Aprobado == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>'; }
                        else { return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>'; }
                    }
                },
                // { data: 'Persona', name: 'Persona', title: 'Aprobado Por' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones'
                },
            ],
            ajaxDetalle: {
                "url": ss.indexReformulacionDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                }

            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Poa', name: 'rd.Poa', title: 'N Mod Poa' },
                {
                    data: 'Reparticion', name: 'rr.Reparticion', title: 'Repartición', render: function (data, type, row) {
                        return row.CodRep + ' - ' + row.Reparticion;
                    }
                },
                { data: 'FuenteFinanciera', name: 'rd.FuenteFinanciera', title: 'Fuente Financiera' },
                {
                    data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render: function (data, type, row) {
                        return row.CodCat + ' - ' + row.CategoriaProgramatica;
                    }
                },
                {
                    data: 'Partida', name: 'p.Partida', title: 'Partida', render: function (data, type, row) {
                        return row.CodPar + ' - ' + row.Partida;
                    }
                },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                {
                    data: 'Monto', name: 'rd.Monto', title: 'Monto', render: function (data, type, row) {
                        if (row.OrigenReformulacionDetalle == null) {
                            return '<span class="text-danger  font-weight-bold ">-' + row.Monto + '</span>'
                        }
                        else {
                            return '<span class="text-success font-weight-bold">+' + row.Monto + '</i></span>'
                        }

                    }
                },
                // { data: 'Monto', name: 'a.Monto', title: 'Monto Destino', render:function(data, type, row){
                //     if (row.Tipo)
                //         return row.Monto;
                //     else
                //         return 0;
                //      }},


                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                    render: function (data, type, row) {
                        if (row.Aprobado == 0) { return data }
                        else { return '<a class="btn btn-danger btn-xs" disabled><i class="fa fa-trash"></i> Eliminar</a>' }
                    }
                },
            ],
            // ajaxDestino: {
            //     "url": ss.indexDestinoReformulacionDetalle(),
            //     headers: ss.getToken(),
            //     data: function (d) {
            //         d.id = 1;
            //         d.OrigenReformulacionDetalle = 1;
            //     }

            // },
            // columnsDestino: [
            //     { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
            //      { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
            //     {
            //         data: 'Reparticion', name: 'rr.Reparticion', title: 'Repartición', render: function (data, type, row) {
            //             return row.CodRep + ' - ' + row.Reparticion;
            //         }
            //     },
            //     {
            //         data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render: function (data, type, row) {
            //             return row.CodCat + ' - ' + row.CategoriaProgramatica;
            //         }
            //     },
            //     {
            //         data: 'Partida', name: 'p.Partida', title: 'Partida', render: function (data, type, row) {
            //             return row.CodPar + ' - ' + row.Partida;
            //         }
            //     },
            //     // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
            //     {
            //         data: 'Monto', name: 'rd.Monto', title: 'Monto', render: function (data, type, row) {

            //             return '+' + row.Monto;
            //         }
            //     },

            //     {
            //         data: 'action',
            //         orderable: false,
            //         title: 'Acciones',
            //         searchable: false
            //     },

            // ],
            // "footerCallback": function ( ) {
            //     var api = this.api();

            //     // Remove the formatting to get integer data for summation
            //     var intVal = function ( i ) {
            //         return typeof i === 'number' ?
            //                 i : 0;
            //     };
            //     // Total over all pages
            //     // total = api
            //     //     .column( 4 )
            //     //     .data()
            //     //     .reduce( function (a, b) {
            //     //         return intVal(a) + intVal(b);
            //     //     }, 0 );

            //     // Total over this page
            //     var pageTotal = api
            //         .column( 4, { page: 'current'} )
            //         .data()
            //         .reduce( function (a, b) {
            //             return intVal(a) + intVal(b);
            //         }, 0 );

            //     var Total2 = pageTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

            //     // Update footer
            //     // $( api.column( 3 ).footer() ).html(
            //     //     '$'+pageTotal +' ( $'+ total +' total)'
            //     // );
            //     window.$( api.column( 4 ).footer() ).html(
            //         'Bs. '+ Total2 +''
            //     );
            // },
            cuentas: [],
            suma: 0,
            swOrigen: 0,
            idOrigen: 0,
            origen: {},
            total: 0,
            saldo: 0,
            restante: 0,
            saldoDestino: 0,
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            editar: 0,
            idFormulacion: 0,
            idReparticion: 0,
            idCategoria: 0,
            formulacionDetalle: {},
            reformulacionDetalles: [],
            reformulacionDetalle: {},
            reformulacionDetalleDestino: {},
            destinos: [],
            registroPOA: {},
            fuenteFinancieras: [],
            partida: {},
            categoriaProgramatica: {},
            reparticion: {},
            reparticiones: [],
            categoriaProgramaticas: [],
            categoriaProgramaticasTodas: [],
            partidas: [],
            partidasTodas: [],
            personas: [],
            formulacions: [],
            unidadAcademicas: [],
            reformulacions: [],
            reformulacion: {},
            isLoading: false,
            errorBag: {},
            selectedPartida: null,
            searchPartida: "",
            placeholderPartida:"",
            idFuenteFinanciera:0,
            formulaciones: [],
            searchFormulacion: "",
            selectedFormulacion:null,
        };
    },
    methods: {
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPartida() {
            this.partida = {};
            this.$refs['frm-partida'].show();
        },
        savePartida() {
            this.ss.storePartida(this.partida).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-partida'].hide();
                    // this.$refs['datatable-partida'].reload();
                    //this.getPartida();
                    this.getPartidaTodas();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        newCategoriaProgramatica() {
            this.categoriaProgramatica = {};
            this.$refs['frm-categoriaProgramatica'].show();
        },
        saveCategoriaProgramatica() {
            this.ss.storeCategoriaProgramatica(this.categoriaProgramatica).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-categoriaProgramatica'].hide();
                    // this.$refs['datatable-categoriaProgramatica'].reload();
                    // this.getCategoriaProgramatica();
                    this.getCategoriaProgramaticaTodas();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cancelPartida() {
            if (this.partida.id) {
                this.$refs['view-partida'].show();
            }
            this.$refs['frm-partida'].hide();
        },
        cancelCategoriaProgramatica() {
            if (this.categoriaProgramatica.id) {
                this.$refs['view-categoriaProgramatica'].show();
            }
            this.$refs['frm-categoriaProgramatica'].hide();
        },
        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        saveReparticion() {
            //this.reparticion.Formulacion = this.reformulacion.UnidadAcademica;
            console.log(this.reparticion);
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.getReparticionFormulacion(this.reformulacion);
                    // this.$refs['datatable-reparticion'].reload();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

        },
        cancelReparticion() {
            if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            }
            this.$refs['frm-reparticion'].hide();
        },
        borrarReparticion() {
            this.reformulacionDetalle.Reparticion = "";
            this.reformulacionDetalle.CategoriaProgramatica = "";
            this.reformulacionDetalle.Partida = "";
            this.saldo = 0;
        },
        borrarCategoriaProgramatica() {
            this.reformulacionDetalle.CategoriaProgramatica = "";
            this.reformulacionDetalle.Partida = "";
            this.saldo = 0;
        },
        borrarPartida() {
            this.reformulacionDetalle.Partida = "";
            this.saldo = 0;
        },

        borrarCategoriaProgramaticaDestino() {
            this.reformulacionDetalleDestino.CategoriaProgramatica = "";
            this.reformulacionDetalleDestino.Partida = "";
            this.saldoDestino = 0;
        },
        borrarPartidaDestino() {
            this.reformulacionDetalleDestino.Partida = "";
            this.saldoDestino = 0;
        },
        newReformulacion() {
            this.reformulacion = {};
            this.$refs['frm-reformulacion'].show();
            this.editar = 0;
            this.saldo = 0;
        },
        resetCategoriaProgramatica() {
            this.reformulacionDetalle.CategoriaProgramatica = "";
            this.reformulacionDetalle.Partida = "";
            this.saldo = 0;
        },
        resetPartida() {
            this.reformulacionDetalle.Partida = "";
            this.saldo = 0;
        },
        resetPartidaDestino() {
            this.reformulacionDetalleDestino.Partida = "";
            this.saldoDestino = 0;
        },
        showReformulacion(id) {
            console.log(id);
            this.isLoading = true;
            this.ss.showReformulacion(id).then(
                (result) => {
                    let response = result.data;
                    this.reformulacion = response.data;
                    //console.log(this.reformulacion);
                    //this.$forceUpdate();
                    console.log("formulacion", this.reformulacion.Formulacion);
                    console.log("id", id);
                    this.idFormulacion = this.reformulacion.Formulacion;
                    this.getFuenteFinanciera(this.reformulacion.Formulacion);
                    
                    //this.getReparticionFormulacion(this.reformulacion);
                    //this.idReformulacion=this.reformulacion.Formulacion;
                    this.$refs['view-reformulacion'].show();
                    this.isLoading = false;

                    var dd = window.$('#datatable-reformulacionDetalle').DataTable();
                    console.log("sumaaa", dd.rows());
                    this.ajaxDetalle.data = function (d) {
                        d.id = id;
                    }
                    console.log("numero", this.reformulacion.reformulacion_detalle.length);
                    // console.log("data", this.columnsDetalle[5]);
                    //this.total=
                    //dd.reload();

                    //  dd.column(5).data().sum();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editReformulacion() {
            this.$refs['frm-reformulacion'].show();
            this.$refs['view-reformulacion'].hide();
            this.editar = 1;
        },
        cancelReformulacion() {
            if (this.reformulacion.id) {
                this.$refs['view-reformulacion'].show();
            }
            this.$refs['frm-reformulacion'].hide();
        },
        saveReformulacion() {

            // this.reformulacion.Aprobado==true ? this.reformulacion.Aprobado : false;
            //console.log("data");
            //console.log(this.reformulacion);
            this.ss.storeReformulacion(this.reformulacion).then(
                (result) => {
                    //console.log("resultado");
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    if (this.editar == 0) {
                        this.showReformulacion(response.data.id);
                    }
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reformulacion'].hide();
                    this.$refs['datatable-reformulacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFormulacion() {
            this.ss.listFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.formulaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFuenteFinanciera(item) {
            console.log(item);
            this.ss.listFormulacionFuenteFinanciera({ "Formulacion": item }).then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDestinosReformulacionDetalle(id, Origen) {

            this.ss.listDestinoReformulacionDetalle(id, Origen).then(
                (result) => {
                    let response = result.data;
                    this.destinos = response.data;
                    console.log("listdestino", result.data);
                    this.suma = 0;

                    this.destinos.forEach(element => {
                        this.suma += parseFloat(element.Monto);
                    });
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSaldo(a, b, c, d) {
            console.log(this.idFormulacion, a, b, c, d);
            if (a === null || b === null || c === null || d === null) {
                alert("Debe seleccionar la Fuente, Reparticion, la Categoria Programatica y la partida");
            } else {
                this.ss.saldoFormulacionDetalle({ "Formulacion": this.idFormulacion, "FuenteFinanciera": d, "Reparticion": a, "CategoriaProgramatica": b, "Partida": c }).then(
                    (result) => {
                        let response = result.data;
                        console.log("saldo", response.data.Saldo);

                        this.saldo = response.data.Saldo;
                    }
                ).catch(error => {
                    console.log(error);
                    this.saldo = 0;
                });
            }

        },
        getSaldoDestino(a, b, c, d) {
            console.log(this.idFormulacion, a, b, c, d);
            if (a === null || b === null || c === null) {
                alert("Debe seleccionar la Fuente, Reparticion, la Categoria Programatica y la partida");
            } else {
                this.ss.saldoFormulacionDetalle({ "Formulacion": this.idFormulacion, "FuenteFinanciera": d, "Reparticion": a, "CategoriaProgramatica": b, "Partida": c }).then(
                    (result) => {
                        let response = result.data;
                        console.log("saldo", response.data.Saldo);

                        this.saldoDestino = response.data.Saldo;
                    }
                ).catch(error => {
                    console.log(error);
                    this.saldoDestino = 0;
                });
            }

        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        deleteReformulacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyReformulacion(this.reformulacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-reformulacion'].hide();
                                this.$refs['datatable-reformulacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getReparticionFormulacion(fuenteFinanciera) {
            this.idFuenteFinanciera=fuenteFinanciera;
            this.ss.listFormulacionReparticion({
                'FuenteFinanciera': fuenteFinanciera,
                'Formulacion': this.reformulacion.Formulacion
            }).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramatica(id) {
            console.log("idRep", id);
            this.idReparticion = id;
            this.ss.listFormulacionCategoria({ "Formulacion": this.idFormulacion, "Reparticion": this.idReparticion, "FuenteFinanciera":this.idFuenteFinanciera }).then(

                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
            this.reformulacionDetalle.CategoriaProgramatica = null;
            this.reformulacionDetalle.Partida = null;
        },
        getPartida(id) {
            console.log("idCat", id);
            this.idCategoria = id;
            this.ss.listFormulacionPartida({ "Formulacion": this.idFormulacion, "Reparticion": this.idReparticion, "CategoriaProgramatica": this.idCategoria, "FuenteFinanciera":this.idFuenteFinanciera }).then(

                (result) => {
                    let response = result.data;
                    this.partidas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });

            this.reformulacionDetalle.Partida = null;
        },
        getPartidaTodas() {
            this.ss.listPartida().then(
                (result) => {
                    let response = result.data;
                    this.partidasTodas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramaticaTodas() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticasTodas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newFormulacionDetalle() {
            this.formulacionDetalle = {};
            this.$refs['frm-formulacionDetalle'].show();
            this.placeholderPartida="Escriba la partida a buscar...";
        },

        newReformulacionDetalle() {
            this.reformulacionDetalle = {};
            this.reformulacionDetalleDestino = {};
            this.registroPOA = {};
            this.$refs['frm-reformulacionDetalle'].show();
            this.swOrigen = 0;
            // this.getReparticion(this.reformulacion.UnidadAcademica);
            this.getDestinosReformulacionDetalle(0, 0);
            this.limpiarReformulacionDetalle();

        },
        showReformulacionDetalle(id) {
            this.isLoading = true;
            this.ss.showReformulacionDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.reformulacionDetalle = response.data;
                    this.$refs['view-reformulacionDetalle'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editReformulacionDetalle() {
            this.$refs['frm-reformulacionDetalle'].show();
            this.$refs['view-reformulacionDetalle'].hide();
            this.getSaldo(this.reformulacionDetalle.Reparticion,
                this.reformulacionDetalle.CategoriaProgramatica,
                this.reformulacionDetalle.Partida, this.reformulacionDetalle.FuenteFinanciera);
                if(this.reformulacionDetalle.Partida==null){
                    this.placeholderPartida="Escriba la partida a buscar...";
                   }else{
                    this.placeholderPartida=this.reformulacionDetalle.partida.Codigo+': '+this.reformulacionDetalle.partida.Partida;
                   }
        },
        cancelReformulacionDetalle() {
            if (this.reformulacionDetalle.id) {
                this.$refs['view-reformulacionDetalle'].show();
            }
            //this.limpiarReformulacionDetalle();
            this.$refs['frm-reformulacionDetalle'].hide();
            this.saldo = 0;
            this.saldDestino = 0;
            this.limpiarReformulacionDetalle();
            this.swOrigen = 0;
        },
        saveReformulacionDetalle() {

            console.log("swO", this.swOrigen);
            console.log("reformulacionDetalle", this.reformulacionDetalle);
            if (this.swOrigen == 1 && parseFloat(this.reformulacionDetalle.Monto) == this.suma) {
                this.reformulacionDetalle.Reformulacion = this.reformulacion.id;
                this.reformulacionDetalle.Formulacion = this.reformulacion.Formulacion;

                this.ss.guardarReformulacionDetalle().then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        //this.$refs['view-consulta'].show(); //para volver al modal
                        console.log(response);
                        this.getDestinosReformulacionDetalle(this.reformulacionDetalle.Reformulacion, this.idOrigen);
                        this.$refs['frm-reformulacionDetalle'].hide();
                        this.$refs['datatable-reformulacionDetalle'].reload();
                        // this.showReformulacion(this.reformulacion.id);
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }
            else {

                this.$bvToast.toast(
                    'Registre todos los campos en reformulacion presupuesto y reasigne el presupuesto.',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                );

            }
        },
        reasignarPresupuesto() {
            var table = window.$('#datatable-reformulacionDetalleDestino').DataTable();
            console.log("destino", table.data().count());
            console.log("Reasignacion", this.reformulacionDetalleDestino);
            if (this.reformulacionDetalleDestino.CategoriaProgramatica == null  || this.reformulacionDetalleDestino.MontoDestino == null) {
                this.$bvToast.toast(
                    'Registre todos los campos en reasignacion presupuesto.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );
            }
            else {
                console.log(this.reformulacionDetalle.Reparticion, this.reformulacionDetalle.Poa, this.reformulacionDetalle.Monto);
                if (this.reformulacionDetalle.Reparticion == null || this.reformulacionDetalle.Poa == null || this.reformulacionDetalle.Monto == null) {

                    this.$bvToast.toast(
                        'Registre todos los campos en reformulacion presupuesto.',
                        {
                            title: 'Error',
                            variant: 'warning',
                            autoHideDelay: 5000
                        }
                    );
                } else {
                    this.restante = parseFloat(this.reformulacionDetalle.Monto) - parseFloat(this.suma) + 1; 
                    console.log(this.reformulacionDetalle.Monto, this.reformulacionDetalleDestino.MontoDestino, 'aquiestpoy', this.suma, this.restante);
                    if (parseFloat(this.reformulacionDetalle.Monto) < parseFloat(this.reformulacionDetalleDestino.MontoDestino) + parseFloat(this.suma)) {
                        this.$bvToast.toast(
                            'El monto a reasignar no puede ser superior al reformulado',
                            {
                                title: 'Error',
                                variant: 'warning',
                                autoHideDelay: 5000
                            }
                        );
                    } else {
                        this.reformulacionDetalle.Reformulacion = this.reformulacion.id;
                        this.reformulacionDetalle.Formulacion = this.reformulacion.Formulacion;
                        this.reformulacionDetalle.Tipo = 0;
                        if (this.swOrigen == 0) {
                            this.ss.storeReformulacionDetalle(this.reformulacionDetalle).then(
                                (result) => {
                                    console.log(result);
                                    let response = result.data;
                                    this.origen = response.data;
                                    console.log("response", this.origen);
                                    console.log("idOrigen", result.data.data.id);
                                    this.idOrigen = result.data.data.id;
                                    this.swOrigen = 1;
                                    if (result.data.success) {
                                       
                                        this.reformulacionDetalle.CategoriaProgramatica = this.reformulacionDetalleDestino.CategoriaProgramatica;
                                        this.reformulacionDetalle.Partida = this.reformulacionDetalleDestino.Partida;
                                        this.reformulacionDetalle.Monto = this.reformulacionDetalleDestino.MontoDestino;
                                        this.reformulacionDetalle.OrigenReformulacionDetalle = this.idOrigen;
                                        console.log("Reasignar", this.reformulacionDetalle);
                                        this.ss.storeReformulacionDetalle(this.reformulacionDetalle).then(
                                            (result) => {
                                                console.log(result);
                                                let response = result.data;
                                                console.log(response);
                                                this.reformulacionDetalleDestino = {};



                                                this.reformulacionDetalle.CategoriaProgramatica = this.origen.CategoriaProgramatica;
                                                this.reformulacionDetalle.Partida = this.origen.Partida;
                                                this.reformulacionDetalle.Monto = this.origen.Monto;
                                                console.log(result.data.data.Reformulacion, result.data.data.OrigenReformulacionDetalle);
                                                this.getDestinosReformulacionDetalle(result.data.data.Reformulacion, result.data.data.OrigenReformulacionDetalle);
                                                //  this.$refs['detalleReformulacionDestino'].refresh();
                                                //                                           console.log("destinos", this.destinos );
                                                //                                     this.destinos.forEach(function(item) {
                                                //                                         console.log("item", item.Monto);
                                                //                                         this.suma += parseInt(item.Monto);
                                                //                                     });
                                                // console.log("suma", this.suma);
                                            })
                                            .catch((error) => {
                                                this.errorBag = error.response;
                                                this.$bvToast.toast(
                                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                                    {
                                                        title: 'Error',
                                                        variant: 'danger',
                                                        autoHideDelay: 5000
                                                    }
                                                )
                                            });
                                    }

                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(
                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                });


                        } else {
                            this.reformulacionDetalle.CategoriaProgramatica = this.reformulacionDetalleDestino.CategoriaProgramatica;
                            this.reformulacionDetalle.Partida = this.reformulacionDetalleDestino.Partida;
                            this.reformulacionDetalle.Monto = this.reformulacionDetalleDestino.MontoDestino;
                            this.reformulacionDetalle.OrigenReformulacionDetalle = this.idOrigen;
                            this.ss.storeReformulacionDetalle(this.reformulacionDetalle).then(
                                (result) => {
                                    console.log(result);
                                    let response = result.data;
                                    console.log(response);
                                    this.reformulacionDetalleDestino = {};
                                    this.reformulacionDetalle.CategoriaProgramatica = this.origen.CategoriaProgramatica;
                                    this.reformulacionDetalle.Partida = this.origen.Partida;
                                    this.reformulacionDetalle.Monto = this.origen.Monto;
                                    console.log(result.data.data.Reformulacion, result.data.data.OrigenReformulacionDetalle);

                                    this.getDestinosReformulacionDetalle(result.data.data.Reformulacion, result.data.data.OrigenReformulacionDetalle);
                                    // console.log("destinos", this.destinos );

                                    // this.ajaxDestino.data = function (d) {
                                    //     d.id = result.data.data.id;
                                    //     d.OrigenReformulacionDetalle = result.data.data.OrigenReformulacionDetalle;
                                    // }
                                    //this.$refs['detalleReformulacionDestino'].refresh();
                                    //   console.log("suma", this.suma);
                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(
                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                });
                        }
                    }


                }


            }
        },
        deleteReformulacionDetalle(id) {
            console.log(id);
            // console.log(this.reformulacionDetalle);
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyReformulacionDetalle({ 'id': id })
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-reformulacionDetalle'].hide();
                                this.$refs['datatable-reformulacionDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        deleteDestinoReformulacionDetalle(destino) {
            console.log("delete", destino);
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.deleteReformulacionDetalle(destino)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.getDestinosReformulacionDetalle(this.reformulacionDetalle.Reformulacion, this.idOrigen);
                                // this.$refs['view-reformulacionDetalle'].hide();
                                //this.$refs['datatable-reformulacionDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        limpiarReformulacionDetalle() {
            this.ss.limpiarReformulacionDetalle().then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    //  this.categoriaProgramaticasTodas = response.data;
                    // this.$bvToast.toast(
                    //     response.msg,
                    //     {
                    //         title: 'Correcto',
                    //         variant: 'success',
                    //         autoHideDelay: 5000
                    //     }
                    // )
                }
            ).catch(error => {
                console.log(error);
            });

        },
        aprobarReformulacion() {


            if (this.reformulacion.suma !== 0) {
                this.$bvToast.toast(
                    'El comprobante de reformulacion no cuadra.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            }
            else {
                this.isLoading = true;
                this.ss.aprobarReformulacion({ 'id': this.reformulacion.id, 'Aprobado': true, 'Monto': this.reformulacion.suma }).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['datatable-reformulacion'].reload();
                        // this.$swal.fire({
                        //     position: 'center',
                        //     icon: 'success',
                        //     title: 'Aprobado correctamente.',
                        //     showConfirmButton: false,
                        //     timer: 1500
                        //   });
                        this.$refs['view-reformulacion'].hide();

                        this.isLoading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

            }



        },
        desaprobarReformulacion() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de esta reformulacion.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarReformulacion({ 'id': this.reformulacion.id, 'Aprobado': false }).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['datatable-reformulacion'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-reformulacion'].hide();

                                this.isLoading = false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        reporteReformulacion() {
            console.log("reporte reformulacion", this.reformulacion.id);
            this.ss.imprimirReformulacion(this.reformulacion.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        console.log(urlFile);
                        console.log("-------------------------------------------------------");
                        console.log(this.rutaApp);
                        var dir = this.rutaApp + urlFile.url;
                        console.log("-------------------------------------------------------");
                        console.log(dir);
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        reporteReformulacionnueva() {
            console.log("reporte reformulacion", this.reformulacion.id);
            this.ss.imprimirReformulacionnueva(this.reformulacion.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        console.log(urlFile);
                        console.log("-------------------------------------------------------");
                        console.log(this.rutaApp);
                        var dir = this.rutaApp + urlFile.url;
                        console.log("-------------------------------------------------------");
                        console.log(dir);
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },

        draw() {
            window.$('.btn-datatable-Reformulacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showReformulacion(data);
            });
        },
        drawDetalle() {
            // this.idReformulacion=1;
            window.$('.btn-datatable-ReformulacionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.deleteReformulacionDetalle(data);
            });

        }
        //,
        // drawDestino() {
        //     // this.idReformulacion=1;
        //     window.$('.btn-datatable-DestinoReformulacionDetalle').on('click', (evt) => {
        //         const data = window.$(evt.target)[0].id;
        //         this.deleteDestinoReformulacionDetalle(data);
        //     });

        // }

    },
    watch: {
        searchFormulacion(q) {
            this.ss.select2Formulacion(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.formulaciones = res.data.items;
                } else {
                    this.formulaciones = [];
                }
            })
        },
        searchPartida(q) {

            this.ss.select2Partida(q).then((res) => {
               // console.log("partida",res);
                if (res.data.success) {
                   
                    this.partidas = res.data.items;
                
                } else {
                    this.partidas = [];
                }
            });
   
    
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getCuenta();
            this.getUnidadAcademica();
            this.getFormulacion();
            this.getPersona();
            this.getReparticionFormulacion();
            this.getCategoriaProgramatica();
            this.getPartida();
            this.getPartidaTodas();
            this.getCategoriaProgramaticaTodas();
            this.getDestinosReformulacionDetalle();
            this.getFuenteFinanciera();
        }
    }
};
