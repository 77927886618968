import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ComprobanteContablePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ComprobanteContablePage",
            ss: ss,
            ajax: {
                "url": ss.indexComprobanteContableTotal(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'TipoComprobante', name: 't.TipoComprobante', title: 'Tipo Comprobante' },
                { data: 'Gestion', name: 'd.Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'd.Fecha', title: 'Fecha' },
                // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
                // { data: 'Numero', name: 'Numero', title: 'Numero' },
                { data: 'Concatenado', name: 'd.Concatenado', title: 'Nº Documento' },
                { data: 'Preventivo', name: 'd.Preventivo', title: 'Preventivo' },
                // { data: 'Aprobado', name: 'Aprobado', title: 'Aprobado' },
                 { data: 'Persona', name: 'p.Persona', title: 'Aprobado Por' },
                { data: 'Aprobado', name: 'd.Aprobado', title: 'Estado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>';}
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';}
                } }
            ],
            comprobanteContables: [],
            comprobantecontable: {},
            comprobantecontabledetalle: {},
            tipocomprobantes: [],
            unidadacademicas: [],
            fuentefinancieras: [],
            cuentas: [],
            subcuentas: [],
            reparticions: [],
            tipocambios: [],
            ejecucions: [],
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newComprobanteContableDetalle() {
            this.comprobantecontabledetalle = {};
            this.comprobantecontabledetalle.ComprobanteContable = this.comprobantecontable.id;
            this.getCuenta();
            this.getSubcuenta();
            this.getReparticion();
            this.$refs['frm-comprobantecontabledetalle'].show();
        },
        cancelComprobanteContableDetalle() {
            if (this.comprobantecontable.id) {
                this.$refs['view-comprobantecontable'].show();
            }
            this.$refs['frm-comprobantecontabledetalle'].hide();
        },

        showComprobanteContable(id) {
            this.isLoading = true;
            this.ss.showComprobanteContable(id).then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontable = response.data;
                    this.$refs['view-comprobantecontable'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        cancelComprobanteContable() {
            if (this.comprobantecontable.id) {
                this.$refs['view-comprobantecontable'].show();
            }
            this.$refs['frm-comprobantecontable'].hide();
        },
        draw() {
            window.$('.btn-datatable-ComprobanteContable').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showComprobanteContable(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
