import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "TalonarioPage",
    data() {
        let ss = new MainService();
        return {
            msg: "BancoPage",
            ss: ss,
            ajax: {
                "url": ss.indexTalonario(),
                headers: ss.getToken(),
            },

            ajaxCheques: {
                "url": ss.indexChequeTalonario(),
                headers: ss.getToken(),
            },

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Talonario', name: 'Talonario', title: 'Talonario' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Gerencial' },
                { data: 'RangoIni', name: 'RangoIni', title: 'Rango Inicial' },
                { data: 'RangoFin', name: 'RangoFin', title: 'Rango Final' },
                // { data: 'Numero', name: 'Numero', title: 'Número' },
                // { data: 'Estado', name: 'Estado', title: 'Estado' },
                { data: 'Estado', name: 'Estado', title: 'Estado', render: function(data, type, row) {
                    if ( row.Estado == true ) {
                        return '<p class="text-success text-center mb-0 pb-0"><i class="fas fa-check"></i></i> Disponible</p>';



                    } else if ( row.Estado == false ){
                        return '<p class="text-danger text-center mb-0 pb-0"><i class="fas fa-times"></i></i> No disponible</p>';
                        
                    }
                }},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            columnsCheques: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Numero', name: 'ct.Numero', title: 'Numero' },
                // { data: 'Estado', name: 'Estado', title: 'Estado' },
                { data: 'Estado', name: 'ct.Estado', title: 'Estado', render: function(data, type, row) {
                    if ( row.Estado == true ) {
                        return '<p class="text-success text-center mb-0 pb-0"><i class="fas fa-money-check-alt"></i></i> Asignado</p>';


                    } else if ( row.Estado == false ){
                        return '<p class="text-primary text-center mb-0 pb-0"><i class="fas fa-money-bill-wave-alt"></i></i> No Asignado</p>';
                        
                    }
                }},
    
                { data: 'Beneficiario', name: 'ct.Beneficiario', title: 'Beneficiario' },
                // { data: 'EstadoCheque', name: 'ct.EstadoCheque', title: 'Estado Cheque' },
                { data: 'Descripcion', name: 'ec.Descripcion', title: 'Estado Cheque' },

                // { data: 'RangoIni', name: 'RangoIni', title: 'Monto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            unidadacademicas: [],
            unidadacademica: {},

            chequeTalonario:{},

            talonarios: [],
            talonario: {},

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newTalonario() {
            this.talonario = {};
            this.$refs['frm-talonario'].show();
            this.talonario.Estado = false;
        },

        generarCheques() {
            console.log("generar cheques");
            this.ss.storeMasivoChequeTalonario(this.talonario).then(
                (result) => {
                    let response = result.data;
                    // this.unidadacademicas = response.data;
                    console.log(response);
                    this.aplicarFiltroTalonario();

                }
            ).catch(error => {
                console.log(error);
            });
        },

        showTalonario(id) {
            this.isLoading=true;
            this.ss.showTalonario  (id).then(
                (result) => {
                    let response = result.data;
                    this.talonario = response.data;
                    this.$refs['view-talonario'].show();
                    this.isLoading=false;
                    this.aplicarFiltroTalonario();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editTalonario() {
            this.$refs['frm-talonario'].show();
            this.$refs['view-talonario'].hide();
        },
        cancelTalonario() {
            if (this.talonario.id) {
                this.$refs['view-talonario'].show();
            }
            this.$refs['frm-talonario'].hide();
        },
        saveTalonario() {
            this.ss.storeTalonario(this.talonario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-talonario'].hide();
                    this.$refs['datatable-talonario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteTalonario() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyTalonario(this.talonario)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-talonario'].hide();
                                this.$refs['datatable-talonario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        aplicarFiltroTalonario(){
            console.log("se aplican los filtros");
            let idTalonario = this.talonario.id;
            this.ajaxCheques.data = function (d) {
                d.Talonario = idTalonario;
            }
            this.$refs['datatable-cheques'].reloadAjax(this.ajaxCheques);

        },

        showChequeTalonario(id){
            console.log("show cheque talonario",id);

            this.isLoading=true;
            this.ss.showChequeTalonario(id).then(
                (result) => {
                    let response = result.data;
                    this.chequeTalonario = response.data;
                    this.$refs['view-chequeTalonario'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        cancelVistaChequeTal(){
            this.$refs['view-chequeTalonario'].hide();
        },

        draw() {
            window.$('.btn-datatable-Talonario').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showTalonario(data);
            });
        },

        drawCheques() {
            window.$('.btn-datatable-ChequeTalonario').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showChequeTalonario(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));

        this.getUnidadAcademica();

        if (!persona) {
          this.$router.push('/Login');
        }
    }
};
