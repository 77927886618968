import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "BuscadorCobroPage",
    data() {
        let ss = new MainService();
        return {
            msg: "BuscadorCobroPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexCuentasxcpDetallecobrolista(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Fecha', name: 'co.Fecha', title: 'Fecha Origen', searchable: true },
                {
                    data: 'Cuenta', name: 'c.Cuenta', title: 'Cuenta', render: function (data, type, row) {
                        return row.CodCuenta + ' - ' + row.Cuenta;
                    },searchable: false 
                },
                { data: 'Preventivo', name: 'cd.Preventivo', title: 'Preventivo Num.CMPTE.', searchable: false },
                { data: 'Numcctext', name: 'cd.Numcctext', title: 'Num. C.C.', searchable: false },
                { data: 'Subcuenta', name: 'su.Subcuenta', title: 'Cuentadante', searchable: true },
                { data: 'Codigo', name: 'su.Codigo', title: 'Carnet O NIT', searchable: true },

                { data: 'Glosa', name: 'cd.Glosa', title: 'Glosa', searchable: true  },
                { data: 'sumadebe', name: 'sumadebe', title: 'Total Debe', searchable: false },
                { data: 'sumahaber', name: 'sumahaber', title: 'Total Haber', searchable: false },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxMonto: {
                "url": ss.indexCuentasxcpMontoscobro(),
                headers: ss.getToken(),
            },
            columnsMonto: [
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Fecha', name: 'cm.Fecha', title: 'Fecha' },
                { data: 'Partida', name: 'cm.Partida', title: 'Partida' },
                { data: 'debe', name: 'cm.debe', title: 'Debe' },
                { data: 'haber', name: 'cm.haber', title: 'Haber' },
                { data: 'Monto', name: 'cm.Monto', title: 'Monto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            cuentacobros: [],
            cuentacobro: {},
            reporte: {},
            reportedetalle: {},
            idMonto: {},
            isLoading: false,
            errorBag: {},
            cuentas: [],
            cuentacobroDetalle: {},
            reparticiones: [],
            fuentefinancieras: [],
            subcuentas: [],
            selectedSubcuenta: null,
            searchSubcuenta: "",
            placeholderSubcuenta: "",
            searchCuenta: "",
            placeholderCuenta: "",
            searchPartida: "",
            placeholderPartida: "",
            searchReparticion: "",
            placeholderReparticion: "",
            lipartidas: {},
            ids: "",
            Estado: '',
            estados: [
              { label: 'Pendiente', value: '0' },
              { label: 'Observado', value: '1' },
              { label: 'Cerrado', value: '2' },
              { label: 'Descuento Haberes', value: '3' }
            ],
            cuentacobroMonto: {},
            montos:{},
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,

        };
    },
    methods: {
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuentefinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCuentaCobro() {
            this.cuentacobro = {};
            this.$refs['frm-cuentacobro'].show();
        },
        showCuentaCobro(id) {
            this.isLoading=true;
            this.ss.showCuentasxcp(id).then(
                (result) => {
                    let response = result.data;
                    this.cuentacobro = response.data;
                    this.$refs['view-cuentacobro'].show();
                    this.aplicarFiltroDetalle();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        showCuentaCobroDetalle(id) {
            this.isLoading = true;
            this.ss.showCuentasxcpDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.cuentacobroDetalle = response.data;
                    this.$refs['view-cuentacobroDetalle'].show();
                    this.aplicarFiltroMonto();
                    this.aplicarmontos();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        showCuentaCobroMonto(id) {
            this.isLoading = true;
            this.ss.showCuentasxcpMontos(id).then(
                (result) => {
                    let response = result.data;
                    this.cuentacobroMonto = response.data;
                    this.$refs['view-cuentacobroMonto'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editCuentaCobro() {
            this.$refs['frm-cuentacobro'].show();
            this.$refs['view-cuentacobro'].hide();
        },
        cancelCuentaCobro() {
            if (this.cuentacobro.id) {
                this.$refs['view-cuentacobro'].show();
            }
            this.$refs['frm-cuentacobro'].hide();
        },
        saveCuentaCobro() {
            this.cuentacobro.Tipo = 1;
            this.ss.storeCuentasxcp(this.cuentacobro).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-cuentacobro'].hide();
                    this.$refs['datatable-cuentacobro'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        editCuentaCobroDetalle() {
            this.$refs['frm-cuentacobroDetalle'].show();
            this.$refs['view-cuentacobroDetalle'].hide();
        },
        cancelCuentaCobroDetalle() {
            if (this.cuentacobroDetalle.id) {
                this.$refs['view-cuentacobroDetalle'].show();
            }
            this.$refs['frm-cuentacobroDetalle'].hide();
        },

        editCuentaCobroMonto() {
            this.$refs['frm-cuentacobroMonto'].show();
            this.$refs['view-cuentacobroMonto'].hide();
        },
        cancelCuentaCobroMonto() {
            if (this.cuentacobroMonto.id) {
                this.$refs['view-cuentacobroMonto'].show();
            }
            this.$refs['frm-cuentacobroMonto'].hide();
        },
        newcuentacobroDetalle() {
            this.cuentacobroDetalle = {};
            this.$refs['frm-cuentacobroDetalle'].show();
            this.placeholderCuenta = "Escriba la cuenta...";
            this.placeholderSubcuenta = "Escriba la subcuenta...";
        },
        listpartidas() {
            this.isLoading=true;
            this.ids = this.cuentacobroDetalle.Cuenta
            this.ss.listpartidas(this.ids).then(
                (result) => {
                    let response = result.data;
                    this.lipartidas = response.data;
                    console.log(this.lipartidas)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        saveComprobanteContableDetalle() {
            this.cuentacobroDetalle.Cuentasxcp = this.cuentacobro.id
            this.ss.storeCuentasxcpDetalle(this.cuentacobroDetalle).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-cuentacobroDetalle'].hide();
                    this.$refs['datatable-cuentacobroDetalle'].reload();
                    this.$refs['view-cuentacobro'].show();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        reload() {
            // Llama a la función o método necesario para obtener los datos actualizados
            this.$refs['datatable-cuentacobroDetalle'] = this.ss.showCuentaCobroDetalle(); // Reemplaza esto con la forma en que obtienes los datos de la tabla
            this.$refs['datatable-cuentacobroMonto'] = this.ss.showCuentaCobroMonto(); // Reemplaza esto con la forma en que obtienes los datos de la tabla
        },
        newcuentacobroMonto() {
            this.cuentacobroMonto = {};
            this.$refs['frm-cuentacobroMonto'].show();
        },
        saveComprobanteContableMonto() {
            this.cuentacobroMonto.CuentasxcpDetalle = this.cuentacobroDetalle.id
            this.ss.storeCuentasxcpMontos(this.cuentacobroMonto).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-cuentacobroMonto'].hide();
                    this.$refs['datatable-cuentacobroMonto'].reload();
                    this.$refs['view-cuentacobroDetalle'].show();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        draw() {
            window.$('.btn-datatable-cuentacobroDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCuentaCobroDetalle(data);
            });

        },
        drawMonto() {
            window.$('.btn-datatable-cuentacobroMonto').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCuentaCobroMonto(data);
            });
        },
        aplicarFiltroDetalle(){
            let detallid = this.cuentacobro.id
            this.ajaxDetalle.data = function(d){
                d.detalle = detallid
            }
        },
        limpiar(){
            document.getElementById("detallid").value = "";
            this.$refs['datatable-cuentacobroDetalle'].reloadAjax();
        },
        aplicarFiltroMonto(){
            let montoid = this.cuentacobroDetalle.id
            this.ajaxMonto.data = function(r){
                r.monto = montoid
            }
        },
        limpiardos(){
            document.getElementById("montoid").value = "";
            this.$refs['datatable-cuentacobroMonto'].reloadAjax();
        },
        aplicarmontos() {
            this.monto = this.cuentacobroDetalle.id
            console.log(this.monto, 'montossss');
            console.log(this.cuentacobroDetalle.id, 'montossss   iiddddd');
            this.ss.Montos({"id": this.monto}).then(
                (result) => {
                    let response = result.data;
                    this.montos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        abrirModalReporteCuentasxcp() {
            //this.formulariobp = {};
            this.$refs['frm-Reportes'].show();
        },
        abrirModalReporteCuentasxcpDetalle() {
            //this.formulariobp = {};
            this.$refs['frm-ReportesDetalle'].show();
        },
        imprimirCuentaCobroGral() {
            this.ss.imprimirCuentaCobroGral(this.reporte, 1)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirCuentaCobroDetalle() {
            this.reportedetalle.id = this.cuentacobro.id
            this.ss.imprimirCuentaCobroDetalle(this.reportedetalle)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirCuentaCobro() {
            this.ss.imprimirCuentaCobro(2)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirCuentaCobroBeca() {
            this.ss.imprimirCuentaCobroBeca(3)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        imprimirCuentaMontoIndividual() {
            console.log(this.idMonto, 'id monto');
            console.log(this.cuentacobroMonto.id, 'monto id monto');
            this.idMonto = this.cuentacobroMonto.id
            this.ss.imprimirCuentaCobroBecaIndividual({"idMonto": this.idMonto})
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        
    },
    watch: {
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                if (res.data.success) {
                    this.reparticiones = res.data.items;
                } else {
                    this.reparticiones = [];
                }
            })
        },
        searchCuenta(q) {
            this.ss.select2Cuenta(q).then((res) => {
                this.listpartidas();
                if (res.data.success) {
                    this.cuentas = res.data.items;
                } else {
                    this.cuentas = [];
                }
                
            })
        },
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getFuenteFinanciera();
        }
    }
};
