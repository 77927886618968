import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "EjecucionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "EjecucionPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexEjecucion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'c.Gestion', title: 'Gestión', searchable: false  },
                { data: 'Fecha', name: 'c.Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'c.UnidadAcademica', title: 'Unidad Gerencial', searchable: false  },
                // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },
               
                // { data: 'Numero', name: 'Numero', title: 'Numero' },
                { data: 'Concatenado', name: 'c.Concatenado', title: 'Nº Documento' },
                { data: 'Preventivo', name: 'c.Preventivo', title: 'Nº Preventivo' },
                { data: 'Ejecucion', name: 'c.Ejecucion', title: 'Glosa', searchable: false  },
                // { data: 'Anexo', name: 'Anexo', title: 'Anexo' },              
                // { data: 'Aprobado', name: 'Aprobado', title: 'Aprobado' },
                // { data: 'AprobadoPor', name: 'AprobadoPor', title: 'Aprobado Por' },
                { data: 'Aprobado', name: 'c.Aprobado', title: 'Estado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                    else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                } },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexEjecucionDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.persona.id;
                    }
    
            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                 { data: 'Concatenado', name: 'f.Concatenado', title: 'N Certificacion'  },
                { data: 'Reparticion', name: 'rr.Reparticion', title: 'Repartición', render:function(data, type, row){
                    return row.CodRep +' - ' + row.Reparticion;
               }  },
               { data: 'FuenteFinanciera', name: 'ff.FuenteFinanciera', title: 'Fuente Financiera'  },
                { data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render:function(data, type, row){
                    return row.CodCat +' - ' + row.CategoriaProgramatica; 
                }  },
                { data: 'Partida', name: 'p.Partida', title: 'Partida', render:function(data, type, row){
                    return row.CodPar +' - ' + row.Partida; 
                } },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                { data: 'Monto', name: 'Monto', title: 'Monto' },
                // { data: 'Monto', name: 'a.Monto', title: 'Monto Destino', render:function(data, type, row){
                //     if (row.Tipo)
                //         return row.Monto;
                //     else
                //         return 0;
                //      }},
                     
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
                // {
                //     data: 'action',
                //     orderable: false,
                //     title: 'Acciones',
                //     searchable: false,
                //      render: function (data, type, row) {
                //         if (row.Aprobado == 0) {return data   }
                //         else {  return '<a class="btn btn-danger btn-xs" disabled><i class="fa fa-trash"></i> Eliminar</a>'} 
                //     }
                // },
            ],
            editar:0,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            idFormulacion:0,
            ejecucions: [],
            ejecucion: {},
            certificacionEjecucion: {},
            formulaciones:[],
            unidadAcademicas:[],
            isLoading: false,
            errorBag: {},
            certificaciones: [],
            Personas: [],
            searchCertificacion: "",
            selectedCertificacion:null,
            selectedSubCuenta:null,
           // reparticion: {},
            reparticiones: [],
            //categoriaProgramatica: {},
            categoriaProgramaticas: [],
            //partida: {},
            partidas: [],
            certificacionDetalles: [],
            certificacionDetalle: {},
            ejecucionDetalles: [],
            ejecucionDetalle: {},
            ejecucionSubcuentas: [],
            ejecucionSubcuenta: {},
            ////
           options: [],
           subcuenta: {},
           subcuentaElegida:{},
           /// 
           subcuentas: [],
            searchSubcuenta: "",
        };
    },
    methods: {
        buscarPersona(search, loading){
            loading(true);
            this.ss.select2Subcuenta(search).then((res) => {
                console.log(res.data);
                this.options = res.data.items;
                loading(false);
            })
        },
        asignarPersona(value){
            this.ejecucion.Subcuenta = value.id;
            this.subcuenta = value;
        },
      
        getFormulacion() {
            this.ss.listFormulacion().then(
                (result) => {
                    let response = result.data;
                    this.formulaciones = response.data;
                    console.log("formulaciones");
                    console.log(this.formulaciones);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newEjecucion() {
            this.ejecucion = {};
            this.$refs['frm-ejecucion'].show();
            this.editar=0;
        },
        getCertificacion() {
            this.ss.listCertificacion().then(
                (result) => {
                    let response = result.data;
                    this.certificaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.Personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
    
        resetCertificacion(){
            
            this.searchCertificacion="";
           this.selectedCertificacion=null;
            this.ejecucion.Certificacion= "";
        },

        showEjecucion(id) {
            this.isLoading = true;
            this.ss.showEjecucion(id).then(
                (result) => {
                    let response = result.data;
                    this.ejecucion = response.data;
                    this.$refs['view-ejecucion'].show();
                    this.isLoading = false;
                   
                    this.idFormulacion=this.ejecucion.Formulacion;
                    console.log("ejecucion Formulacion",  this.ejecucion.Formulacion);
                    //this.getReparticion(this.ejecucion.Formulacion);
                    var dd =  window.$('#datatable-ejecucionDetalle').DataTable();
                    console.log("filas", dd.rows());
                    this.ajaxDetalle.data = function(d){
                        d.id = id;
                    }
                   
                  
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editEjecucion() {
            this.$refs['frm-ejecucion'].show();
            this.$refs['view-ejecucion'].hide();
            this.editar=1;
            
        },
        cancelEjecucion() {
            if (this.ejecucion.id) {
                this.$refs['view-ejecucion'].show();
            }
            this.$refs['frm-ejecucion'].hide();
        },
        // selectedCertificacion($event){
        //     this.getCertificacion();
        // },
        saveEjecucion() {
            console.log("save Ejecusion", this.ejecucion);
        
            if(this.editar==0){
                console.log("longitud", this.ejecucion.detallesCertificacion.length);
            if(this.ejecucion.Formulacion==null || this.ejecucion.Certificacion==null || this.ejecucion.Monto == null || this.ejecucion.Subcuenta == null ){
                this.$bvToast.toast(
                    'Registre todos los campos',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            }else{
                    if(parseFloat(this.ejecucion.Monto)>parseFloat(this.ejecucion.montoDisponible) ){
                        this.$bvToast.toast(
                            'El monto a ejecutar no puede ser mayor al disponible.',
                            {
                                title: 'Error',
                                variant: 'warning',
                                autoHideDelay: 5000
                            }
                        );
    
                    }else{
                        if(this.ejecucion.detallesCertificacion.length>1 && parseFloat(this.ejecucion.Monto)<parseFloat(this.ejecucion.montoDisponible)){
                            this.$bvToast.toast(
                                'La ejecucion debe ser por el total certificado.',
                                {
                                    title: 'Error',
                                    variant: 'warning',
                                    autoHideDelay: 5000
                                }
                            );
                        }else{
                           if(this.ejecucion.CargoCuenta==null)
                                this.ejecucion.CargoCuenta=3;

                            this.ss.storeEjecucion(this.ejecucion).then(
                                (result) => {
                                    console.log(result);
                                    let response = result.data;
                                    this.$bvToast.toast(
                                        response.msg,
                                        {
                                            title: 'Correcto',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    )
                                    //this.$refs['view-consulta'].show(); //para volver al modal
                                    console.log(response);
                                    if(this.editar==0){
                                        this.showEjecucion(response.data.id);
                                       } 
                                    this.$refs['frm-ejecucion'].hide();
                                    this.$refs['datatable-ejecucion'].reload();
                                    this.getCertificacion();
                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(
                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                });
                            }
                        }
    
                    }
                }else{
                    if(this.ejecucion.CargoCuenta==null)
                                this.ejecucion.CargoCuenta=3;
                            this.ss.storeEjecucion(this.ejecucion).then(
                                (result) => {
                                    console.log(result);
                                    let response = result.data;
                                    this.$bvToast.toast(
                                        response.msg,
                                        {
                                            title: 'Correcto',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    )
                                    //this.$refs['view-consulta'].show(); //para volver al modal
                                    console.log(response);
                                    if(this.editar==0){
                                        this.showEjecucion(response.data.id);
                                       } 
                                    this.$refs['frm-ejecucion'].hide();
                                    this.$refs['datatable-ejecucion'].reload();
                                    this.getCertificacion();
                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(
                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                });
                }
              
              
          
           
        },
        deleteEjecucion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyEjecucion(this.ejecucion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ejecucion'].hide();
                                this.$refs['datatable-ejecucion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        aprobarEjecucion() {
           
            if(this.ejecucion.ejecucion_detalle.length===0){
                
                this.$bvToast.toast(
                    'Registre detalles de ejecucion.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            }else{
               
                    this.isLoading=true;
                    this.ss.aprobarEjecucion({'id':this.ejecucion.id, 'Aprobado':true, 'Monto': this.ejecucion.suma}).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                           this.$refs['datatable-ejecucion'].reload();
                            // this.$swal.fire({
                            //     position: 'center',
                            //     icon: 'success',
                            //     title: 'Aprobado correctamente.',
                            //     showConfirmButton: false,
                            //     timer: 1500
                            //   });
                            this.$refs['view-ejecucion'].hide();
                                       
                            this.isLoading=false;
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
                     
                 
              
            }
           
        },
        desaprobarEjecucion() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de esta ejecucion.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarEjecucion({'id':this.ejecucion.id, 'Aprobado':false}).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                               this.$refs['datatable-ejecucion'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-ejecucion'].hide();
                                           
                                this.isLoading=false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        reporteEjecucion() {
            console.log("reporte ejecucion", this.ejecucion.id);
            this.ss.imprimirEjecucion(this.ejecucion.id)
                .then( 
                    result => {
                    let response = result.data;
                    var urlFile = response.data;
                    console.log(urlFile);
                    console.log("-------------------------------------------------------");
                    console.log(this.rutaApp);
                    var dir =this.rutaApp + urlFile.url;
                    console.log("-------------------------------------------------------");
                    console.log(dir);
                    window.open(dir);
                })
                .catch( error => {
                    console.log(error);
                    this.isLoading=false;
                })  
       
        },
        newEjecucionSubcuenta() {
            this.ejecucionSubcuenta = {};
            this.$refs['frm-ejecucionSubcuenta'].show();
        },
        showEjecucionSubcuenta(id) {
            this.isLoading = true;
            this.ss.show(id).then(
                (result) => {
                    let response = result.data;
                    this.ejecucionSubcuenta = response.data;
                    this.$refs['view-ejecucionSubcuenta'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editEjecucionSubcuenta() {
            this.$refs['frm-ejecucionSubcuenta'].show();
            this.$refs['view-ejecucionSubcuenta'].hide();
        },
        cancelEjecucionSubcuenta() {
            if (this.ejecucionSubcuenta.id) {
                this.$refs['view-ejecucionSubcuenta'].show();
            }
            this.$refs['frm-ejecucionSubcuenta'].hide();
        },
        saveEjecucionSubcuenta() {
            this.ejecucionSubcuenta.Ejecucion=this.ejecucion.id;
            console.log(this.ejecucionSubcuenta.Ejecucion);
            this.ss.storeEjecucionSubcuenta(this.ejecucionSubcuenta).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                 //   this.ejecucionSubcuenta=result.data.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                      
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-ejecucionSubcuenta'].hide();
              
                   
                    this.showEjecucion(result.data.data.Ejecucion);
                    console.log(this.ejecucion.ejecucion_subcuenta);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteEjecucionSubcuenta($id, $eje) {
           console.log("ejecucionSubcuenta",$id);
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyEjecucionSubcuenta({'id':$id})
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                           //     this.$refs['view-ejecucionSubcuenta'].hide();
                                this.showEjecucion($eje);
                                //this.$refs['datatable-ejecucionSubcuenta'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        newEjecucionDetalle() {
            this.ejecucionDetalle = {};
            this.$refs['frm-ejecucionDetalle'].show();
        },
        showEjecucionDetalle(id) {
            this.isLoading = true;
            this.ss.showEjecucionDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.ejecucionDetalle = response.data;
                    this.$refs['view-ejecucionDetalle'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editEjecucionDetalle() {
            this.$refs['frm-ejecucionDetalle'].show();
            this.$refs['view-ejecucionDetalle'].hide();
        },
        cancelEjecucionDetalle() {
            if (this.ejecucionDetalle.id) {
                this.$refs['view-ejecucionDetalle'].show();
            }
            this.$refs['frm-ejecucionDetalle'].hide();
        },
        saveEjecucionDetalle() {
            
          
             if(parseFloat(this.ejecucionDetalle.Monto)>parseFloat(this.ejecucionDetalle.montoDisponible) ){
                 this.$bvToast.toast(
                     'El monto a ejecutar no puede ser mayor al disponible.',
                     {
                         title: 'Error',
                         variant: 'warning',
                         autoHideDelay: 5000
                     }
                 );

             }else{
                 if(this.ejecucionDetalle.detallesCertificacion.length>1 && parseFloat(this.ejecucionDetalle.Monto)<parseFloat(this.ejecucionDetalle.montoDisponible)){
                     this.$bvToast.toast(
                         'La ejecucion debe ser por el total certificado.',
                         {
                             title: 'Error',
                             variant: 'warning',
                             autoHideDelay: 5000
                         }
                     );
                 }else{
                    this.ejecucionDetalle.Ejecucion=this.ejecucion.id;
                    console.log(this.ejecucionDetalle);
                    this.ss.storeEjecucionDetalle(this.ejecucionDetalle).then(
                        (result) => {
                            console.log(result);
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            //this.$refs['view-consulta'].show(); //para volver al modal
                            console.log(response);
                            this.$refs['frm-ejecucionDetalle'].hide();
                            this.$refs['datatable-ejecucionDetalle'].reload();
                            this.showEjecucion(this.ejecucion.id);
                            this.getCertificacion();
                        })
                        .catch((error) => {
                            this.errorBag = error.response.data.errors;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                 }
                }
           
         
        },
        deleteEjecucionDetalle($id) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyEjecucionDetalle({"id":$id})
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                // var dd =  window.$('#datatable-ejecucionDetalle').DataTable();
                                // console.log("filas", dd.rows());
                                // this.ajaxDetalle.data = function(d){
                                //     d.id = id;
                                // }
                                // this.$refs['view-ejecucionDetalle'].hide();
                                this.$refs['datatable-ejecucionDetalle'].reload();
                                this.showEjecucion(this.ejecucion.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Ejecucion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showEjecucion(data);
            });
        },
        drawDetalle() {
          // this.idCertificacion=1;
            window.$('.btn-datatable-EjecucionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.deleteEjecucionDetalle(data);
            });

        }
    },
    
    watch: {
        
        searchCertificacion(q) {
            // if(this.ejecucion.Formulacion==null){
            //     this.$bvToast.toast(
            //         'Seleccione la Formulacion.',
            //         {
            //             title: 'Error',
            //             variant: 'warning',
            //             autoHideDelay: 5000
            //         }
            //     );
            // }else{
            //     console.log("select2 Formulacion",this.ejecucion.Formulacion);
            this.ss.select2Certificacion(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.certificaciones = res.data.items;
                } else {
                    this.certificaciones = [];
                }
            })
           // }
            
        },
        searchSubcuenta(q) {
   
            this.ss.select2Subcuenta(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
            }
            
        

    },
    components: {
        dataTable,
        Loader
    },
    filters: {
       
        intToTipo: function (value) {
          //  console.log(value);
            if (value==null) return '';
            let tipo = "";
            //0 debe 1 Haber
            if (value == 0) {
                tipo = "Pago Directo"
            } else if (value == 1) {
                tipo = "Cargo a Cuenta"
            }
             else if (value == 2) {
            tipo = "Beneficiario Final"
        }
            return tipo;
        },
        

    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getCertificacion();
            this.getPersona();
            this.getFormulacion();
            this.getUnidadAcademica();
        }
    }
};
