import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "AsignarDescargoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "AsignarDescargoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexAsignarDescargo(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false},
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'CargoCuenta', name: 'e.CargoCuenta', title: 'Tipo', 
                render: function (data, type, row) {
                             if (row.CargoCuenta == 1) {
                                 return '<i class="fa fa-check text-success font-italic font-weight-bold "> Cargo de Cuenta </i>';
                             } else if (row.CargoCuenta == 3) {
                                 return '<i class="fa fa-check text-info  font-italic font-weight-bold"> Fondo Rotativo </i>';
                             } 
                         },searchable: false
                },
                { data: 'Preventivo', name: 'de.Preventivo', title: 'Preventivo' },
                { data: 'Subcuenta', name: 'sc.Subcuenta', title: 'Subcuenta',searchable: false },
                { data: 'Codigo', name: 'sc.Codigo', title: 'Carnet de Ide.',searchable: false },
                { data: 'Desembolsado', name: 'de.Desembolsado', title: 'Desembolsado',searchable: false },
                { data: 'RevisorAsignado', name: 'de.RevisorAsignado', title: 'Rev. Asignado ',searchable: false },
                { data: 'SupervisorAsignado', name: 'de.SupervisorAsignado', title: 'Sup. y Cierre Asignado',searchable: false },
                { data: 'Gestion', name: 'cc.Gestion', title: 'Gestión',searchable: false },
                { data: 'DescargoEstado', name: 'de.DescargoEstado', title: 'Estado',
                    render: function (data, type, row) {
                        if (row.DescargoEstado == 1) {
                            return '<i class="fa fa-times text-warning font-italic font-weight-bold "> Sin Asignar </i>';
                        } else if (row.DescargoEstado == 2) {
                            return '<i class="fa fa-check text-info  font-italic font-weight-bold"> Aprobado po Revisor </i>';
                        } else if (row.DescargoEstado == 3) {
                            return '<i class="fa fa-check text-danger  font-italic font-weight-bold"> Observado </i>';
                        } else if (row.DescargoEstado == 5) {
                            return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Cerrado </i>';
                        }
                    },searchable: false
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            descargo: {},
            asignardescargo: {},
            comprobantecontables: [],
            descargoestados: [],
            subcuentas: [],
            personarevs: [],
            personasups: [],
            asignado: [],
            subcuenta: {},
            reparticions: [],
            selectedSubcuenta: null,
            searchSubcuenta: "",
            isLoading: false,
            isLoadingFile: false,
            isLoadedFile: false,
            selecteddescargoestado: 0,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            detalleDescargo:{},
            seguimiento:[],
            tipoRetencion:0,
            reporte: {},
        };
    },
    methods: {
        getPersonaRev() {
            this.ss.listPersonaRevisor().then(
                (result) => {
                    let response = result.data;
                    this.personarevs = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersonaSup() {
            this.ss.listPersonaSuper().then(
                (result) => {
                    let response = result.data;
                    this.personasups = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAsignado() {
            this.ss.listPersonaAsignada().then(
                (result) => {
                    let response = result.data;
                    this.asignado = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getComprobanteContable() {
            this.ss.listComprobanteContable().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontables = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion(0).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDescargoEstado() {
            this.ss.listDescargoEstado(0).then(
                (result) => {
                    let response = result.data;
                    this.descargoestados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       
        showAsignarDescargo(id) {
            this.tipoRetencion=0;
            this.isLoading = true;
            this.ss.showDescargo(id).then(
                (result) => {
                    let response = result.data;
                    this.descargo = response.data;
                    this.$refs['view-asignardescargo'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        saveAsignarDescargo() {
            this.asignardescargo.ComprobanteContable = this.descargo.ComprobanteContable
            this.asignardescargo.RevisorAleatorio = this.personarevs.id
            this.asignardescargo.SupervisorAleatorio = this.personarevs.id
            //this.asignardescargo.SupervisorAsignado = this.asignardescargo.Revisado
            console.log(this.asignardescargo.ComprobanteContable);
            this.ss.storeAsignarDescargo(this.asignardescargo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['view-asignardescargo'].hide();
                    this.$refs['datatable-asignardescargo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        setReset() {
            this.descargo = {};
        },
       
        draw() {
            window.$('.btn-datatable-asignardescargo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showAsignarDescargo(data);
            });
        },
        printDescargo() {
            this.ss.printDescargo(this.descargo.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printRevisor() {
            console.log(this.descargo.comprobante_contable.id);
            this.ss.printRevisor(this.descargo.comprobante_contable.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printEstadocargo() {
            console.log(this.descargo.Subcuenta);
            this.ss.printEstadocargo(this.descargo.Subcuenta)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        printEstadofondo() {
            console.log(this.descargo.Subcuenta);
            this.ss.printEstadofondo(this.descargo.Subcuenta)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },

        abrirModalReporte() {
            this.reporte = {};
            this.$refs['frm-reporte'].show();
        },
        cancelReporte() {
            this.$refs['frm-reporte'].hide();
        },
        imprimirReporte() {
            this.ss.imprimirReporteAsignacionInd(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        imprimirReporteGral() {
            this.ss.imprimirReporteAsignacionGral(this.reporte)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
    },
    watch: {
       
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getPersonaRev();
            this.getPersonaSup();
            this.getAsignado();
            this.getReparticion(0);
            this.getSubcuenta();
            this.getComprobanteContable();
            this.getDescargoEstado();
        }
    }
};
