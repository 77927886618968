import { render, staticRenderFns } from "./ComprobanteGastoPage.vue?vue&type=template&id=31f1904e&"
import script from "./ComprobanteGastoPage.js?vue&type=script&lang=js&"
export * from "./ComprobanteGastoPage.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports