import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReversionGPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReversionGPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexReversionG(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Preventivo', name: 'd.Preventivo', title: 'Preventivo' },
                //{ data: 'Gestion', name: 'd.Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'd.Fecha', title: 'Fecha' },
                //{ data: 'FuenteFinanciera', name: 'f.FuenteFinanciera', title: 'F.F.' },
                { data: 'ComprobanteContable', name: 'd.ComprobanteContable', title: 'Glosa' },
                { data: 'Monto', name: 'd.Monto', title: 'Monto' },
                { data: 'ReparticionNombre', name: 'r.Reparticion', title: 'Repartición' },  // Nueva columna Repartición
                { data: 'SubcuentaNombre', name: 's.Subcuenta', title: 'Subcuenta' },  // Nueva columna Subcuenta
                { data: 'PartidaNombre', name: 'pa.Partida', title: 'Partida' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            isLoading: false,
            errorBag: {},
            subcuentas: [],
            searchSubcuenta: "",
            selectedSubCuenta: null,
            reparticiones: [],
            searchReparticion: "",
            selectedReparticion: null,
            partidas: [],
            searchPartida: "",
            selectedPartida: null,
            Personas: [],
            reversiong: {
                Gestion: null,
            },
            fuenteFinancieras: [],
            availableYears: this.getAvailableYears(),
        };
    },
    methods: {
        getAvailableYears() {
            const currentYear = new Date().getFullYear();
            const startYear = 2018;
            const years = [];
            for (let year = startYear; year <= currentYear; year++) {
                years.push(year);
            }
            return years;
        },
        getFuenteFinanciera() {
            this.ss.listFuenteFinanciera().then(
                (result) => {
                    let response = result.data;
                    this.fuenteFinancieras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newReversionG() {
            this.reversiong = {};
            this.$refs['frm-reversiong'].show();
        },
        showReversionG(id) {
            this.isLoading = true;
            this.ss.showReversionG(id).then(
                (result) => {
                    let response = result.data;
                    this.reversiong = response.data;
                    this.$refs['view-reversiong'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editReversionG() {
            this.$refs['frm-reversiong'].show();
            this.$refs['view-reversiong'].hide();
        },
        cancelReversionG() {
            if (this.reversiong.id) {
                this.$refs['view-reversiong'].show();
            }
            this.$refs['frm-reversiong'].hide();
        },
        saveReversionG() {
            this.reversiong.TipoComprobante = 8
            this.ss.storeReversionG(this.reversiong).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reversiong'].hide();
                    this.$refs['datatable-reversiong'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteReversionG() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                showCancelButton: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyReversionG(this.reversiong)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-reversiong'].hide();
                                this.$refs['datatable-reversiong'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-ReversionG').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showReversionG(data);
            });
        }
    },
    watch: {
        searchSubcuenta(q) {
            this.ss.select2Subcuenta(q).then((res) => {
                console.log("", res);
                if (res.data.success) {
                    this.subcuentas = res.data.items;
                } else {
                    this.subcuentas = [];
                }
            })
        },
        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                console.log("", res);
                if (res.data.success) {

                    this.reparticiones = res.data.items;

                } else {
                    this.reparticiones = [];
                }
            })
        },
        searchPartida(q) {
            this.ss.select2Partida(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.partidas = res.data.items;

                } else {
                    this.partidas = [];
                }
            })
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getFuenteFinanciera();
        }
    }
};
