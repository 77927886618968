import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ChequePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ChequePage",
            ss: ss,
            ajax: {
                "url": ss.indexCheque(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Beneficiario', name: 'ch.Beneficiario', title: 'Beneficiario' },
                { data: 'Monto', name: 'ch.Monto', title: 'Monto' },
                // {
                //     data: 'action',
                //     orderable: false,
                //     title: 'Acciones',
                //     searchable: false
                // },
                {
                    data: 'asignar',
                    orderable: false,
                    title: 'Asignar Cheque',
                    searchable: false
                },
            ],
            
            cheque: {},
            estadocheques:[],
            unidadacademicas:[],
            chequeTalonarios:[],
            talonarios:[],
            asignacionCheque: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newCheque() {
            this.cheque = {};
            this.$refs['frm-cheque'].show();
        },
        showCheque(id) {
            this.isLoading=true;
            this.ss.showCheque(id).then(
                (result) => {
                    let response = result.data;
                    this.cheque = response.data;
                    this.$refs['view-cheque'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCheque() {
            this.$refs['frm-cheque'].show();
            this.$refs['view-cheque'].hide();
        },
        cancelCheque() {
            if (this.cheque.id) {
                this.$refs['view-cheque'].show();
            }
            this.$refs['frm-cheque'].hide();
        },
        saveCheque() {
            this.ss.storeCheque(this.cheque).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-cheque'].hide();
                    this.$refs['datatable-cheque'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCheque() {
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "¿Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCheque(this.cheque)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-cheque'].hide();
                                this.$refs['datatable-cheque'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getChequeTalonario() {
            this.ss.listChequeTalonario(this.asignacionCheque.Talonario).then(
                (result) => {
                    let response = result.data;
                    this.chequeTalonarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        getEstadoCheque() {
            this.ss.listEstadoCheque().then(
                (result) => {
                    let response = result.data;
                    this.estadocheques = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        getTalonario() {

            // console.log(this.asignacionCheque.UnidadAcademica);
            this.ss.listTalonario(this.asignacionCheque.UnidadAcademica).then(
                (result) => {
                    let response = result.data;
                    this.talonarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        asignarCheque(id){
            this.isLoading=true;
            this.ss.showCheque(id).then(
                (result) => {
                    let response = result.data;
                    this.asignacionCheque = response.data;
                    this.$refs['asignarCheque'].show();

                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });

        },

        cancelAsignarCheque() {
            
            this.$refs['asignarCheque'].hide();
        },
        saveAsignarCheque() {
            this.ss.storeAsignarChequeTalonario(this.asignacionCheque).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['asignarCheque'].hide();
                    this.$refs['datatable-cheque'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },



        draw() {
            window.$('.btn-datatable-Cheque').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCheque(data);
            });

            window.$('.btn-datatable-Asignar').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.asignarCheque(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        this.getEstadoCheque();
        // this.getTalonario();
        this.getUnidadAcademica();
        // this.getChequeTalonario();
    }
};
