import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReversionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReversionPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexReversion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Gerencial' },
                // { data: 'Formulacion', name: 'Formulacion', title: 'Formulación' },

                // { data: 'Numero', name: 'Numero', title: 'Numero' },
                { data: 'Concatenado', name: 'Concatenado', title: 'Nº Documento' },

                { data: 'Reversion', name: 'Reversion', title: 'Glosa' },
                { data: 'Anexo', name: 'Anexo', title: 'Anexo' },
                // { data: 'Aprobado', name: 'Aprobado', title: 'Aprobado' },
                // { data: 'AprobadoPor', name: 'AprobadoPor', title: 'Aprobado Por' },
                {
                    data: 'Aprobado', name: 'Aprobado', title: 'Estado', render: function (data, type, row) {
                        if (row.Aprobado == 0) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>'; }
                        else { return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>'; }
                    }
                },

                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexReversionDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                }

            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Concatenado', name: 'f.Concatenado', title: 'N Certificacion' },
                {
                    data: 'Reparticion', name: 'rr.Reparticion', title: 'Repartición', render: function (data, type, row) {
                        return row.CodRep + ' - ' + row.Reparticion;
                    }
                },
                {
                    data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render: function (data, type, row) {
                        return row.CodCat + ' - ' + row.CategoriaProgramatica;
                    }
                },
                {
                    data: 'Partida', name: 'p.Partida', title: 'Partida', render: function (data, type, row) {
                        return row.CodPar + ' - ' + row.Partida;
                    }
                },
                // { data: 'Formulacion', name: 'f.Formulacion', title: 'Formulación' },
                { data: 'Monto', name: 'Monto', title: 'Monto' },
                // { data: 'Monto', name: 'a.Monto', title: 'Monto Destino', render:function(data, type, row){
                //     if (row.Tipo)
                //         return row.Monto;
                //     else
                //         return 0;
                //      }},


                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                   
                },
            ],
            editar: 0,
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
           // idFormulacion: 0,
            reversions: [],
            reversion: {},


            unidadAcademicas: [],
            isLoading: false,
            errorBag: {},
            ejecuciones: [],
            Personas: [],
            searchEjecucion: "",
            selectedEjecucion: null,
            nuevo:0,
            // reparticion: {},
            reparticiones: [],
            //categoriaProgramatica: {},
            categoriaProgramaticas: [],
            //partida: {},
            partidas: [],
            certificacionDetalles: [],
            certificacionDetalle: {},
            reversionDetalles: [],
            reversionDetalle: {},
            certificaciones: [],
            ejecucionDetalles: [],
           
            ////
            options: [],
            subcuenta: {},
            subcuentaElegida: {},
            /// 
            sumaEjecucion:0,
            sumaReversion:0,

        };
    },
    methods: {
        getCertificacion() {
            //    alert(this.idUnidadAcademica);
            this.ss.listCertificacion().then(
                (result) => {
                    let response = result.data;
                    this.certificaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion(id) {
            //    alert(this.idUnidadAcademica);
            this.ss.listReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoriaProgramatica() {
            this.ss.listCategoriaProgramatica().then(
                (result) => {
                    let response = result.data;
                    this.categoriaProgramaticas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPartida() {
            this.ss.listPartida().then(
                (result) => {
                    let response = result.data;
                    this.partidas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        buscarPersona(search, loading) {
            loading(true);
            this.ss.select2Subcuenta(search).then((res) => {
                console.log(res.data);
                this.options = res.data.items;
                loading(false);
            })
        },
        asignarPersona(value) {
            this.reversion.Subcuenta = value.id;
            this.subcuenta = value;
        },


        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newReversion() {
            this.reversion = {};
            this.$refs['frm-reversion'].show();
            this.editar = 0;
        },

        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.Personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        dropReversionDetalle(destino) {
            console.log("drop", destino);
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.deleteReversionDetalle(destino)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                console.log("drop",result.data.Reversion);
                                this.showReversion(result.data.data.Reversion);
                               // this.$refs['view-reformulacionDetalle'].hide();
                                //this.$refs['datatable-reformulacionDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        showReversion(id) {
            this.isLoading = true;
            this.ss.showReversion(id).then(
                (result) => {
                    let response = result.data;
                    this.reversion = response.data;
                    this.$refs['view-reversion'].show();
                    this.isLoading = false;

                   // this.idFormulacion = this.reversion.Formulacion;
                    this.ejecucionDetalles=this.reversion.ejecucion.ejecucion_detalle;
                    this.reversionDetalles=this.reversion.reversion_detalle;
                    this.sumaEjecucion=0;
                    this.sumaReversion=0;
                    this.ejecucionDetalles.forEach(element => {
                        this.sumaEjecucion += parseFloat(element.Monto);
                      });
                      this.reversionDetalles.forEach(element => {
                        this.sumaReversion += parseFloat(element.Monto);
                      });
                   
                 //  console.log("detalle ejecucion", this.reversion.ejecucion.ejecucion_detalle);
                   // this.getReparticion(this.reversion.detalle_ejecucion);
                    var dd = window.$('#datatable-reversionDetalle').DataTable();
                    console.log("filas", dd.rows());
                    this.ajaxDetalle.data = function (d) {
                        d.id = id;
                    }


                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editReversion() {
            this.$refs['frm-reversion'].show();
            this.$refs['view-reversion'].hide();
            this.editar = 1;

        },
        cancelReversion() {
            if (this.reversion.id) {
                this.$refs['view-reversion'].show();
            }
            this.$refs['frm-reversion'].hide();
        },
        cancelReversionDetalleUno() {
            if (this.reversionDetalle.id) {
                this.$refs['view-reversionDetalleUno'].show();
            }
            this.$refs['frm-reversionDetalleUno'].hide();
        },
        // selectedCertificacion($event){
        //     this.getEjecucion();
        // },
        saveReversion() {
            console.log("save Ejecusion", this.reversion);

                this.ss.storeReversion(this.reversion).then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        //this.$refs['view-consulta'].show(); //para volver al modal
                        console.log(response);
                        if (this.editar == 0) {
                            this.showReversion(response.data.id);
                        }
                        this.$refs['frm-reversion'].hide();
                        this.$refs['datatable-reversion'].reload();
                      //  this.getEjecucion();
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
 
        },
        deleteReversion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyReversion(this.reversion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-reversion'].hide();
                                this.$refs['datatable-reversion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        aprobarReversion() {

            if (this.reversion.reversion_detalle.length === 0) {

                this.$bvToast.toast(
                    'Registre detalles de reversion.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            } else {
                console.log(this.reversion.suma);
                this.isLoading = true;
                this.ss.aprobarReversion({ 'id': this.reversion.id, 'Aprobado': true, 'Monto': this.reversion.suma }).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['datatable-reversion'].reload();
                        // this.$swal.fire({
                        //     position: 'center',
                        //     icon: 'success',
                        //     title: 'Aprobado correctamente.',
                        //     showConfirmButton: false,
                        //     timer: 1500
                        //   });
                        this.$refs['view-reversion'].hide();

                        this.isLoading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });



            }

        },
        desaprobarReversion() {
            this.$swal({
                title: "Estas seguro que deseas desaprobar?",
                text: "Esta accion permitira la modificacion de esta reversion.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.aprobarReversion({ 'id': this.reversion.id, 'Aprobado': false }).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['datatable-reversion'].reload();
                                // this.$swal.fire({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: 'Aprobado correctamente.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   });
                                this.$refs['view-reversion'].hide();

                                this.isLoading = false;
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        reporteReversion() {
            console.log("reporte reversion", this.reversion.id);
            this.ss.imprimirReversion(this.reversion.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        console.log(urlFile);
                        console.log("-------------------------------------------------------");
                        console.log(this.rutaApp);
                        var dir = this.rutaApp + urlFile.url;
                        console.log("-------------------------------------------------------");
                        console.log(dir);
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        newReversionDetalleUno() {
           
            this.$refs['frm-reversionDetalleUno'].show();
        },
        newReversionDetalle(detalle) {
            console.log('detalle reversion', detalle);
            this.reversionDetalle=detalle;
            this.reversionDetalle.MontoEjecutado = detalle.Monto;
            this.$refs['frm-reversionDetalle'].show();
            this.nuevo=1;
        },
        showReversionDetalle(id) {
            this.isLoading = true;
            this.ss.showReversionDetalle(id).then(
                (result) => {
                    let response = result.data;
                    console.log(result.data);
                    this.reversionDetalle = response.data;
                    this.$refs['view-reversionDetalle'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editReversionDetalle() {
            this.$refs['frm-reversionDetalle'].show();
            this.$refs['view-reversionDetalle'].hide();
            this.nuevo=0;
        },
        cancelReversionDetalle() {
            // if (this.reversionDetalle.id) {
            //     this.$refs['view-reversionDetalle'].show();
            // }
            this.$refs['frm-reversionDetalle'].hide();
        },
        saveReversionDetalle() { 
           // alert(this.reversionDetall);
            console.log(this.reversionDetalle);
            if (parseFloat(this.reversionDetalle.Monto) > parseFloat(this.reversionDetalle.MontoEjecutado)) {
                this.$bvToast.toast(
                    'El monto a revertir no puede ser mayor al ejecutado.',
                    {
                        title: 'Error',
                        variant: 'warning',
                        autoHideDelay: 5000
                    }
                );

            } else {
                if(this.nuevo==1){
                 this.detalle={};
                this.detalle.Certificacion = this.reversionDetalle.Certificacion;
                this.detalle.Reparticion = this.reversionDetalle.Reparticion;
                this.detalle.CategoriaProgramatica = this.reversionDetalle.CategoriaProgramatica;
                this.detalle.Partida = this.reversionDetalle.Partida;
                this.detalle.Reversion = this.reversion.id;
                this.detalle.Monto = this.reversionDetalle.Monto;
                this.reversionDetalle={};
                this.reversionDetalle= this.detalle;
                }
                
               
                console.log(this.reversionDetalle);
                this.ss.storeReversionDetalle(this.reversionDetalle).then(
                    (result) => {
                        console.log(result);
                        let response = result.data;
                        if(!response.success){
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Error',
                                    variant: 'warning',
                                    autoHideDelay: 5000
                                }
                            )
                        }else{
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            //this.$refs['view-consulta'].show(); //para volver al modal
                            console.log(response);
                            this.$refs['frm-reversionDetalle'].hide();
                            this.$refs['datatable-reversionDetalle'].reload();
                            this.showReversion(result.data.data.Reversion);
                        }
                        
                        //  this.getEjecucion();
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });

            }


        },
        deleteReversionDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyReversionDetalle(this.reversionDetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                var dd =  window.$('#datatable-reversionDetalle').DataTable();
                                console.log("filas", dd.rows());
                                // this.ajaxDetalle.data = function(d){
                                //     d.id = id;
                                // }
                                 this.$refs['view-reversionDetalle'].hide();
                                this.$refs['datatable-reversionDetalle'].reload();
                                this.showReversion(this.reversion.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Reversion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showReversion(data);
            });
        },
        drawDetalle() {
            // this.idCertificacion=1;
            window.$('.btn-datatable-ReversionDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showReversionDetalle(data);
            });

        }
    },

    watch: {

        searchEjecucion(q) {

            this.ss.select2Ejecucion(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.ejecuciones = res.data.items;
                } else {
                    this.ejecuciones = [];
                }
            })
            // }

        }



    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            // this.getEjecucion();
            this.getPersona();
            // this.getFormulacion();
            this.getUnidadAcademica();
            this.getReparticion(0);
            this.getCategoriaProgramatica();
            this.getPartida();
            this.getCertificacion();
        }
    }
};
