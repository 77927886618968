var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"page-header-title"},[_c('br'),_c('h4',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t("nav.TipoReporteVer")))])]),_c('div',{staticClass:"page-content-wrapper"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-md-8 offset-md-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"TipoReporte"}},[_vm._v("Seleccione el Reporte")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.reporte.TipoReporte
                                                    ),expression:"\n                                                        reporte.TipoReporte\n                                                    "}],staticClass:"form-control",attrs:{"id":"TipoReporte","name":"TipoReporte"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.reporte, "TipoReporte", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.verParametros()}]}},_vm._l((_vm.tipoReportes),function(tp){return _c('option',{key:tp.id,domProps:{"value":tp.id}},[_vm._v(" "+_vm._s(tp.TipoReporte)+" ")])}),0)])]),(_vm.enable.UnidadAcademica)?_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('div',{staticClass:"form-group",attrs:{"id":"selectUA"}},[_c('label',{attrs:{"for":"UndidadAcademica"}},[_vm._v("Unidad Gerencial")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.reporte.UnidadAcademica
                                                    ),expression:"\n                                                        reporte.UnidadAcademica\n                                                    "}],staticClass:"form-control",attrs:{"id":"UnidadAcademica","name":"UnidadAcademica"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.reporte, "UnidadAcademica", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.unidadAcademicas),function(ua){return _c('option',{key:ua.id,domProps:{"value":ua.id}},[_vm._v(" "+_vm._s(ua.UnidadAcademica)+" ")])}),0)])]):_vm._e(),(_vm.enable.Persona)?_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('div',{staticClass:"form-group",attrs:{"id":"selectPersona"}},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"Persona"}},[_vm._v("Persona:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.reporte.Persona
                                                    ),expression:"\n                                                        reporte.Persona\n                                                    "}],staticStyle:{"width":"100%"},attrs:{"name":"persona","id":"Persona"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.reporte, "Persona", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}}),(_vm.errorBag.Persona)?_c('ul',{staticClass:"parsley-errors-list filled",attrs:{"id":"parsley-id-19"}},[_c('li',{staticClass:"parsley-required"},[_vm._v(" "+_vm._s(_vm.errorBag.Persona)+" ")])]):_vm._e()])]):_vm._e(),(_vm.enable.Desde)?_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('div',{staticClass:"form-group",attrs:{"id":"selectDesde"}},[_c('label',{attrs:{"for":"Desde"}},[_vm._v("Desde")]),_c('div',{staticClass:"input-group m-b-15"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                            _vm.reporte.Desde
                                                        ),expression:"\n                                                            reporte.Desde\n                                                        "}],staticClass:"form-control",attrs:{"type":"date","name":"Desde","id":"Desde"},domProps:{"value":(
                                                            _vm.reporte.Desde
                                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.reporte, "Desde", $event.target.value)}}}),_vm._m(1)]),(_vm.errorBag.Desde)?_c('ul',{staticClass:"parsley-errors-list filled",attrs:{"id":"parsley-id-19"}},[_c('li',{staticClass:"parsley-required"},[_vm._v(" "+_vm._s(_vm.errorBag.Desde)+" ")])]):_vm._e()])]):_vm._e(),(_vm.enable.Hasta)?_c('div',{staticClass:"form-group col-md-6"},[_c('div',{staticClass:"form-group",attrs:{"id":"selectHasta"}},[_c('label',{attrs:{"for":"Hasta"}},[_vm._v("Hasta")]),_c('div',{staticClass:"input-group m-b-15"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                            _vm.reporte.Hasta
                                                        ),expression:"\n                                                            reporte.Hasta\n                                                        "}],staticClass:"form-control",attrs:{"type":"date","name":"Hasta","id":"Hasta"},domProps:{"value":(
                                                            _vm.reporte.Hasta
                                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.reporte, "Hasta", $event.target.value)}}}),_vm._m(2)]),(_vm.errorBag.Hasta)?_c('ul',{staticClass:"parsley-errors-list filled",attrs:{"id":"parsley-id-19"}},[_c('li',{staticClass:"parsley-required"},[_vm._v(" "+_vm._s(_vm.errorBag.Hasta)+" ")])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-xs-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a',{staticClass:"btnwith btn btn-success",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.generaReporte.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-download"}),_vm._v(" GENERAR")])]),_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btnwith btn btn-primary",attrs:{"type":"reset"},on:{"click":_vm.setReset}},[_c('i',{staticClass:"fas fa-undo-alt"}),_vm._v(" LIMPIAR ")])])])])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title text-dark m-0"},[_vm._v(" Detalle de Registros ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"input-group-addon"},[_c('i',{staticClass:"mdi mdi-calendar"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"input-group-addon"},[_c('i',{staticClass:"mdi mdi-calendar"})])
}]

export { render, staticRenderFns }