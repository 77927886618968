import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import axios from 'axios';
window.$ = window.jQuery = require("jquery");

export default {
    name: "IngresosCactualPage",
    data() {
        let ss = new MainService();
        return {
            msg: "IngresosCactualPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexIngresosCactual(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Ingresosc', name: 'Ingresosc', title: 'Ingreso C-21' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Numero', name: 'Numero', title: 'Número' },
                { data: 'Concatenado', name: 'Concatenado', title: 'Código' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ajaxDetalle: {
                "url": ss.indexIngresoscDetalle(),
                headers: ss.getToken(),
            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Tipo', name: 'Tipo', title: 'Tipo C. Oper.', 
                render: function (data, type, row) {
                             if (row.Tipo == 1) {
                                 return '<i class=" text-success font-italic font-weight-bold "> DEP </i>';
                             } else if (row.Tipo == 2) {
                                 return '<i class=" text-info  font-italic font-weight-bold"> TFD </i>';
                             } else if (row.Tipo == 3) { 
                                return '<i class="text-danger font-italic font-weight-bold">TEC</i>'; 
                              } else if (row.Tipo == 4) { 
                                return '<i class="text-purple font-italic font-weight-bold">CRV</i>'; 
                              } else if (row.Tipo == 5) { 
                                return '<i class="text-yellow font-italic font-weight-bold">ARC</i>'; 
                              }  
                         }
                },
                { data: 'NroDeposito', name: 'NroDeposito', title: 'Nro. Depósito' },
                { data: 'FechaDeposito', name: 'FechaDeposito', title: 'Fecha Depósito' },
                { data: 'Glosa', name: 'Glosa', title: 'Favor' },
                { data: 'Debito', name: 'Debito', title: 'Debito' },
                { data: 'Credito', name: 'Credito', title: 'Credito' },
                { data: 'Monto', name: 'Monto', title: 'Monto - Saldo' },
                { data: 'Sip', name: 'Sip', title: 'Sip.C-21' },
                {
                    data: 'Reconocido', name: 'Reconocido', title: 'Reconocido', render: function (data, type, row) {
                        if (row.Reconocido == false) { return '<i class="fa fa-times text-danger font-italic font-weight-bold "> No</i>'; }
                        else { return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Si </i>'; }
                    },
                    createdRow: function (row, data, ) {
                        if (data.Reconocido == false) {
                          (row).css('background-color', 'yellow');
                        }
                      }
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                },
            ],
            personas: [],
            mensajeexcel: {},
            sumamonto: {},
            reconocido: {},
            noreconocido: {},
            ingresosc: {},
            ingresoscdetalle: {},
            isLoading: false,
            errorBag: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            reporte: {},
        };
    },
    methods: {
        // loadFile(input) {
        //     this.isLoadingFile = true;
        //     input = event.target;
        //     if (input.files && input.files[0]) {
        //       var reader = new FileReader();
        //       reader.readAsDataURL(input.files[0]);
        //       var data = new FormData();
        //       data.append('File', input.files[0]);
        //       this.ss.importExcelIngresoscDetalle(data)
        //         .then(result => {
        //           if (result.data.success) {
        //             this.$bvToast.toast(result.data.msg, {
        //               title: 'Correcto',
        //               variant: 'info',
        //               autoHideDelay: 5000
        //             });
        //             //this.persona.Foto = result.data.data;
        //           } else {
        //             this.$bvToast.toast(result.data.msg, {
        //               title: 'Oops!',
        //               variant: 'danger',
        //               autoHideDelay: 5000
        //             });
        //           }
        //           this.isLoadingFile = false;
        //         })
        //         .catch(error => {
        //           console.log(error);
        //           this.$bvToast.toast('Error subiendo archivo', {
        //             title: 'Oops!',
        //             variant: 'danger',
        //             autoHideDelay: 5000
        //           });
        //           this.isLoadingFile = false;
        //         });
        //     }
        //   },
        handleFileUpload(event) {
            this.selectedFile = event.target.files[0];
          },
          uploadFile() {
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            axios.post('http://127.0.0.1:8000/api/IngresoscDetalle/importExcel', formData)
            .then(
                (result) => {
                let response = result.data;
                console.log('dhfjsdhfjsdhfj');
                    this.mensajeexcel = response.data;
              }).catch(error => {
                console.log(error);
            });
          },

          uploadFilea() {
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            this.ss.importExcelIngresoscDetalle(formData).then(
                (result) => {
                    let response = result.data;
                    console.log(result.data);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.mensajeexcel = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        calcularMontoTotal() {
            this.ss.sumarMontosactual().then(
                (result) => {
                    let response = result.data;
                    this.sumamonto = response.data;
                    this.reconocido = response.data1;
                    this.noreconocido = response.data2;
                }
            ).catch(error => {
                console.log(error);
            });
          },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newIngresosC() {
            this.ingresosc = {};
            this.$refs['frm-ingresosc'].show();
        },
        cancelIngresosCDetalle(){
            this.$refs['frm-ingresoscdetalle'].hide();

        },

        showIngresosc(id) {
            this.isLoading=true;
            this.ss.showIngresosc(id).then(
                (result) => {
                    let response = result.data;
                    this.ingresosc = response.data;
                    this.$refs['view-ingresosc'].show();
                    this.isLoading=false;
                    this.aplicarFiltroDetalle(id);

                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editIngresosC() {
            this.$refs['frm-ingresosc'].show();
            this.$refs['view-ingresosc'].hide();
        },
        cancelIngresosC() {
            if (this.ingresosc.id) {
                this.$refs['view-ingresosc'].show();
            }
            this.$refs['frm-ingresosc'].hide();
        },
        saveIngresosC() {
            this.ss.storeIngresosC(this.ingresosc).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-ingresosc'].hide();
                    //this.$refs['view-ingresosc'].show(); //para volver al modal
                    this.$refs['datatable-ingresosc'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteIngresosC() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyIngresosC(this.ingresosc)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ingresosc'].hide();
                                this.$refs['datatable-ingresosc'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        newIngresosCDetalle() {
            this.ingresoscdetalle = {};
            this.$refs['frm-ingresoscdetalle'].show();
        },
        saveIngresosCDetalle() {
            this.ingresoscdetalle.Ingresosc = this.ingresosc.id;
            this.ingresoscdetalle.Deposito = this.ingresoscdetalle.NroDeposito;
            this.ss.storeIngresoscDetalle(this.ingresoscdetalle).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    this.$refs['frm-ingresoscdetalle'].hide();
                    this.$refs['datatable-ingresoscDetalle'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        showIngresoscDetalle(id) {
            this.isLoading=true;
            this.ss.showIngresoscDetalle(id).then(
                (result) => {
                    let response = result.data;
                    this.ingresoscdetalle = response.data;
                    this.$refs['view-ingresoscdetalle'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editIngresosCDetalle() {
            this.$refs['frm-ingresoscdetalle'].show();
            this.$refs['view-ingresoscdetalle'].hide();
        },
        deleteIngresosCDetalle() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyIngresosCDetalle(this.ingresoscdetalle)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-ingresoscdetalle'].hide();
                                this.$refs['datatable-ingresoscDetalle'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        excelIngresosCDetalle(){
            let url = "api/IngresosCDetalle/get_excel?IngresosC="+this.ingresosc.id;
            console.log(this.rutaApp+url);
            window.open(this.rutaApp+url);
        },
        aplicarFiltroDetalle(){
            console.log("se aplican los filtros");
            let Ingresosc = this.ingresosc.id;
            this.ajaxDetalle.data = function (d) {
                d.Ingresosc = Ingresosc;
            }
            this.$refs['datatable-ingresoscDetalle'].reloadAjax(this.ajaxDetalle);
            // window.e2 = this.$refs["datatable-ingresopresupuestarioDetalle"];
        },
        draw() {
            window.$('.btn-datatable-ingresosc').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showIngresosc(data);
            });
        },
        drawDetalle() {
            window.$('.btn-datatable-ingresoscDetalle').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showIngresoscDetalle(data);
            });
        },
        abrirModalReporte(){
            this.$refs['frm-reporte'].show();
        },
        cancelarModalReporte(){
            this.reporte = {};
            this.$refs['frm-reporte'].hide();
        },

        abrirModalCierreArchivo(){
            this.$refs['modalCierreArchivo'].show();
        },


    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getPersona();
            this.calcularMontoTotal();
        }
    }
};
