import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DescargoArchivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DescargoArchivoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexComprobanteContableArchivo(),
                headers: ss.getToken(),
                data: function (d) {
                            d.TipoComprobante = 4 ;
                            }
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Gestion', name: 'd.Gestion', title: 'Gestión' },
                { data: 'Fecha', name: 'd.Fecha', title: 'Fecha' },
                //{ data: 'Concatenado', name: 'd.Concatenado', title: 'Nº Documento' },
                { data: 'Preventivo', name: 'd.Preventivo', title: 'Nº Preventivo' },
                { data: 'Monto', name: 'd.Monto', title: 'Monto Total' },
                { data: 'ComprobanteContable', name: 'd.ComprobanteContable', title: 'Glosa' },
                { data: 'Aprobado', name: 'd.Aprobado', title: 'Estado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i>';}
                    else{ return '<i class="fa fa-check text-success  font-italic font-weight-bold"> Aprobado </i>';}
                } },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
           
            comprobanteContableDetalles: [],
            descargoa: {},
            descargo: {},
            comprobantecontables: [],
            comprobantecontabledetalles: [],
            descargoestados: [],
            unidadacademicas: [],
            reparticions: [],
            comprobanteContable: {},
            placeholderSubcuenta: "",
            placeholderCuenta: "",
            searchCuenta: "",
            searchSubcuenta: "",
            isLoading: false,
            isLoadingFile: false,
            isLoadedFile: false,
            selecteddescargoestado: 0,
            selectedunidadacademica: 0,
            baseURL: process.env.VUE_APP_MAIN_SERVICE + "storage/documents/",
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            detalleDescargo:{},
            seguimiento:[],
            tipoRetencion:0,
            archivo: {}
        };
    },
    methods: {
        getComprobanteContable() {
            this.ss.listComprobanteContable().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontables = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getComprobanteContableDetalle() {
            this.ss.listComprobanteContableDetalle().then(
                (result) => {
                    let response = result.data;
                    this.comprobantecontabledetalles = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCuenta() {
            this.ss.listCuenta().then(
                (result) => {
                    let response = result.data;
                    this.cuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getSubcuenta() {
            this.ss.listSubcuenta().then(
                (result) => {
                    let response = result.data;
                    this.subcuentas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion(0).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        abrirModalCierreArchivoDes(id){
            this.isLoading = true;
            this.ss.showComprobanteContable(id).then(
                (result) => {
                    let response = result.data;
                    this.comprobanteContable = response.data;
                    this.$refs['modalCierreArchivoDes'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
              
        saveCierreArchivo() {
            console.log("id estyoy aqui", this.comprobanteContable.id);
            this.archivo.id = this.comprobanteContable.id
            this.archivo.FojasArchivo = this.descargoa.FojasArchivo
            this.archivo.FechaCierreArchivo = this.descargoa.FechaCierreArchivo
            this.archivo.Observaciones = this.descargoa.Observaciones
            this.archivo.Activo = this.descargoa.Cerrado
            this.ss.storeCierreComprobante(this.archivo ).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-descargo'].reload();
                    this.$refs['datatable-cierrearchivo'].reload();
                    this.$refs['archivo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        printCierreArchivoDes() {
            console.log("id al reporte archivo",this.descargo.id);

            this.ss.printCierreArchivoDes(this.descargo.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })

        },
        showComprobanteContableArchivo(id) {
            //console.log("id show certi", id);
            this.isLoading=true;
            this.ss.showComprobanteContableArchivo(id).then(
                (result) => {
                    let response = result.data;
                    this.comprobanteContable = response.data;
                    this.$refs['view-comprobanteContable'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        draw() {
            window.$('.btn-datatable-ComprobanteContableArchivo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.abrirModalCierreArchivoDes(data);
            });
        }
    },
    watch: {
        searchCuenta(q) {

            this.ss.select2Cuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.cuentas = res.data.items;

                } else {
                    this.cuentas = [];
                }
            })


        },
        searchSubcuenta(q) {

            this.ss.select2Subcuenta(q).then((res) => {
                console.log(res);
                if (res.data.success) {

                    this.subcuentas = res.data.items;

                } else {
                    this.subcuentas = [];
                }
            })


        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {

            this.getComprobanteContable();
            this.getComprobanteContableDetalle();

        }
    }
};
