import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "GastoContablePage",
    data() {
        let ss = new MainService();
        return {
            msg: "GastoContablePage",
            ss: ss,
            ajax: {
                "url": ss.indexGastoContable(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'ComprobanteContable', name: 'cc.ComprobanteContable', title: 'Glosa' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Gerencial' },
                { data: 'Gestion', name: 'cc.Gestion', title: 'Gestion' },
                { data: 'UFV', name: 'tc.TipoCambio', title: 'UFV' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            ajaxDetalle: {
                "url": ss.indexGastoContableDetalle(),
                headers: ss.getToken(),
                data: function (d) {
                    d.id = 1;
                    }
            },
            columnsDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                // { data: 'Poa', name: 'rd.Poa', title: 'N Cer Poa'  },
                { data: 'Reparticion', name: 'rr.Reparticion', title: 'Repartición', render:function(data, type, row){
                    return row.CodRep +' - ' + row.Reparticion;
               }  },
                { data: 'CategoriaProgramatica', name: 'cp.CategoriaProgramatica', title: 'Categoria Programática', render:function(data, type, row){
                    return row.CodCat +' - ' + row.CategoriaProgramatica; 
                }  },
                { data: 'Partida', name: 'p.Partida', title: 'Partida', render:function(data, type, row){
                    return row.CodPar +' - ' + row.Partida; 
                } },
                { data: 'Monto', name: 'Monto', title: 'Monto Bs' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            gastoContables: [],
            gastoContable: {},
            unidadAcademicas: [],
            tipoCambios: [],
            idUnidadAcademica:0,
            gastoContableDetalles: [],
            gastoContableDetalle: {},
            isLoading: false,
            isEditing: false,
            errorBag: {}
        };
    },
    methods: {
        newGastoContable() {
            this.GastoContable = {};
            this.$refs['frm-GastoContable'].show();
        },
        showGastoContable(id) {
            this.isLoading=true;
            this.ss.showGastoContable(id).then(
                (result) => {
                    let response = result.data;
                    this.GastoContable = response.data;
                    this.$refs['view-GastoContable'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editGastoContable() {
            this.$refs['frm-GastoContable'].show();
            this.$refs['view-GastoContable'].hide();
        },
        cancelGastoContable() {
            if (this.GastoContable.id) {
                this.$refs['view-GastoContable'].show();
            }
            this.$refs['frm-GastoContable'].hide();
        },
        getGastoContable() {
            let fecha = this.GastoContable.Fecha;
            this.ss.obtenerGastoContable({params:{Fecha:fecha}}).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                   
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                   this.GastoContable.Compra=response.Compra;
                   this.GastoContable.Venta=response.Venta;
                   this.GastoContable.UFV=response.UFV;
                    this.$refs['datatable-gastoContable'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveGastoContable() {
            this.ss.storeGastoContable(this.gastoContable).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-GastoContable'].hide();
                    this.$refs['datatable-gastoContable'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteGastoContable() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyGastoContable(this.GastoContable)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-GastoContable'].hide();
                                this.$refs['datatable-gastoContable'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
      
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getTipoCambio() {
            this.ss.listTipoCambio().then(
                (result) => {
                    let response = result.data;
                    this.tipoCambios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        draw() {
            window.$('.btn-datatable-gastoContable').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showGastoContable(data);
            });
        },

        drawDetalle() {
            // this.idCertificacion=1;
              window.$('.btn-datatable-gastoContableDetalle').on('click', (evt) => {
                  const data = window.$(evt.target)[0].id;
                  this.showGastoContableDetalle(data);
              });
        },
    },

    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }  else {
           
            this.getUnidadAcademica();
            this.getTipoCambio();
            // this.getFuenteFinanciera();
            // this.getPersona();
            // this.getReparticion(0);
            // this.getCategoriaProgramatica();
            // this.getPartida();
            
        }
    }
};
